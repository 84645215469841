:root {
  --color-primary: #2ba84a;
  --color-secondary: #dd0000;
  --color-additional: #ffce00;
  --color-body: #839c9f;
  --color-background: #fbfcfc;
  --color-background-white: #ffffff;
  --color-headerapply:#ffffff;
  --color-white: #ffffff;
  --background-gray: #f3f3f3;
  --color-txt-df: #091f1f;
  --color-over-effect: #2ba84a;
  --color-success-lgt: #d6ffe0;
  --font-var: "Manrope";
}
