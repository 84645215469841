/* ========= Contact Page ========= */
.contact-box {
    padding: 160px 0 100px 0;
    @include lg {
        padding: 125px 0 80px 0;
    }
    @include md {
        padding-top: 120px;
        padding-bottom: 60px;
    }
    @include sm {
        padding-top: 100px;
        padding-bottom: 20px;
    }
    .container {
        overflow: unset;
    }
    .front-title-block {
        margin-top: 85px;
        @include sm {
            margin-top: 0;
        }
    }
    .contact-address {
        margin-top: 77px;
        font-size: 1.25rem;
        color: rgba($color-text-default,0.50);
        @include md {
            font-size: 1rem;
            margin-top: 50px;
        }
        @include sm {
            margin-top: 22px;
        }
        .i-address {
            color: $color-text-default;
        }
        .call-mail {
            margin-top: 20px;
            @include sm {
                margin-top: 8px;
            }
            a {
                color: rgba($color-text-default,0.50);
                &:hover {
                    color: $color-primary;
                }
            }
            .call-div {
                + .call-div {
                    margin-top: 15px;
                    @include sm {
                        margin-top: 6px;
                    }
                }
            }
            .svg-icon {
                width: 38px;
                display: inline-block;
                vertical-align: middle;
                @include md {
                    width: 25px;
                }
                .fill {
                    fill: $color-text-default;
                }
                svg {
                    @include md {
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
            .contact-div {
                display: inline-block;
                width: calc(100% - 38px);
                vertical-align: middle;
                @include md {
                    width: calc(100% - 25px);
                }
            }
        }
    }
    .contact-dropbox {
        background: $bg-white;
        box-shadow: $box-shadow-global;
        border-radius: 8px;
        padding: 46px 100px;
        margin-top: 25px;
        @include lg {
            padding: 40px 60px;
        }
        @include md {
            padding: 30px;
        }
        @include sm {
            margin-top: 30px;
            padding: 25px 20px;
        }
        h2 {
            text-align: center;
            font-size: 2.5rem;
            color: rgba(#828D8D,0.50);
            line-height: 1;
            font-weight: $font-bold;
            margin: 0 0 55px 0;
            @include md {
                font-size: 2rem;
                margin: 0 0 35px 0;
            }
            @include sm {
                margin-bottom: 26px;
                font-size: 1.75rem;
            }
        }
        .form-group {
            @include sm {
                margin-bottom: 25px;
            }
        }
        textarea {
            height: 160px;
            @include sm {
                height: 140px;
            }
        }
        .recepcha {
            max-width: 304px;
            margin: -6px auto 30px;
            @include sm {
                margin: 0 auto 20px;
                max-width: 242px;
                height: 65px;
                > div {
                    transform: scale(0.80) !important;
                    -webkit-transform: scale(0.80) !important;
                    transform-origin: 0 0 0 !important;
                    -webkit-transform-origin: 0 0 !important;
                }
            }
        }        
    }
}