/* ========= Accordion ========= */
.accordion {
    box-shadow: $box-shadow-global;
    border-radius: 8px;
    padding: 12px 40px;
    @include md {
        padding: 6px 20px;
    }    
    .card {
        box-shadow: none;
        padding: 0;
        text-align: left;
        border-bottom: solid 2px rgba($color-body,0.10) !important;
        display: flex;
        flex-wrap: wrap;
        background: transparent !important;
        &:last-child {
            border: none !important;
        }
        > div {
            &:first-child {
                order: 2;
            }
        }
    }
    .card-header {
        background: transparent;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border: none;
        align-items: flex-start;
        order: 1;
        cursor: pointer;
        @include md {
            padding: 15px 0;
        }
        @include sm {
            padding: 12px 0;
        }
        .btn-link {
            padding: 0;
            min-width: 0;
            text-align: center;
            width: 20px;
            transition: all 0.05s ease;
            -webkit-transition: all 0.05s ease;
            -moz-transition: all 0.05s ease;
            -ms-transition: all 0.05s ease;
            -o-transition: all 0.05s ease;
            i {
                color: $color-text-default;
                font-size: 1.9rem;
                font-weight: $font-regular;
                width: 20px;
                @include md {
                    font-size: 1.5rem;
                }
            }
        }
        h4 {
            margin: 0;
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $color-text-default;
            width: calc(100% - 20px);
            @include md {
                font-size: 1rem;
                line-height: 1.4rem;
            }
        }
    }
    .card-body {
        line-height: 1.5rem;
        padding: 10px 16px 25px 16px;
        border-radius: 0;
        border: none;
        @include md {
            font-size: 14px;
        }
        @include sm {
            padding: 8px 8px 16px 8px;
        }
        p {
            padding-bottom: 15px;
        }
    }
    .show , .collapsing {
        ~ .card-header {
            .btn-link {
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                i {
                    left: -2px;
                    color: $color-body;
                    position: relative;
                }
            }
        }
    }
    &.with-number {
        counter-reset: my-awesome-counter;
        .card {
            counter-increment: my-awesome-counter;
            position: relative;
            &:before {
                content: counter(my-awesome-counter) ".";
                position: absolute;
                top: 1.5rem;
                left: 0;
                font-size: 1.25rem;
                line-height: 1.5rem;
                color: $color-text-default;
                font-weight: $font-medium;
                @include md {
                    font-size: 1rem;
                    top: 15px;
                }
                @include sm {
                    top: 13px;
                }
            }
            .card-header {
                padding-left: 30px;
                @include md {
                    padding: 14px 0 14px 25px;
                }
            }
            .card-body {
                padding: 0 0 5px 30px;
                @include md {
                    padding: 0 0 5px 25px;
                }
            }
        }
    }
    &.help-faq {
        background: transparent;
        box-shadow: none;
        padding: 0;
        .card {
            border-width: 1px !important;
            &:before {
                font-size: 1rem;
                top: 1rem;
            }
        }
        .card-header {
            padding: 16px 0;
            h4 {
                font-size: 1rem;
                font-weight: $font-medium;
                padding-right: 1rem;
                @include sm {
                    padding-right: 5px;
                }
            }
            .btn-link {
                i {
                    font-size: 1.7rem;
                }
            }
        }
    }
}


/* ========= FAQ Page ========= */
.faqs-content {
    padding: 160px 0 200px 0;
    @include lg {
        padding: 140px 0 120px 0;
    }
    @include md {
        padding: 130px 0 80px 0;
    }
    @include sm {
        padding: 100px 0 40px 0;
    }
    .container {
        overflow: unset;
    }
    .accordion {
        margin-top: 45px;
        @include md {
            margin-top: 35px;
        }
        @include sm {
            margin-top: 25px;
        }
    }
}