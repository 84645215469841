@import "../helpers/variables";
@import "../helpers/mixins";
/*Headings*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-weight: $font-regular;
  color: $color-body;
}
h1,
.h1 {
  font-size: 2.5rem;
  line-height: 3rem;
}
h2,
.h2 {
  font-size: 2rem;
  line-height: 2.75rem;
  color: $color-text-default;
  // color: $color-text-title;
  font-weight: $font-medium;
  @include md {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  @include sm {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}
h2 span {
  font-weight: $font-light;
}
h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $color-text-default;
}
h4,
.h4 {
  font-size: 1rem;
  line-height: 1.5rem;
}
h5,
.h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
h6,
.h6 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}
//min-width-button
.mw-auto {
  min-width: auto !important;
}
