.about-page {    
    .about-us {
        margin-top: 160px;
        @include md {
            margin-top: 100px;
        }
        &.our-database {
            margin-top: 130px;
            @include lg {
                margin-top: 120px;
            }
            @include md {
                margin-top: 100px;
            }
            @include sm {
                margin-top: 40px;
                margin-bottom: 0;
            }            
        }           
    }
}
.about-us {
    .row {
        align-items: center;
    }
    .about-us-wrapper {
        &.with-border {
            flex-wrap: wrap;
        }
        .front-title-block {
            + .front-title-block {
                border-top: solid 2px $border-color;
                margin-top: 15px;
                padding-top: 40px;
                @include md {
                    margin-top: 0;
                    padding-top: 22px;
                }
            }
        }
    }
    &.about-left {
        margin-top: 130px;
        @include lg {
            margin-top: 100px;
        }
        @include md {
            margin-top: 60px;
        }
        @include sm {
            margin-top: 0;
        }
        .about-us-img {
            float: none;
        }
    }
    &.our-mission {
        @include sm {
            margin-top: 10px;
        }
        .row {
            > div {
                @include sm {
                    &:first-child {
                        order: 2;
                    }
                }
            }
        }
    }
    &.landing-bottom {
        margin-bottom: 160px;
        @include lg {
            margin-bottom: 130px;
        }
        @include md {
            margin-bottom: 40px;
        }
        @include sm {
            margin-bottom: 10px;
        }
    }
    &.our-support {
        @include sm {
            .row {
                > div {
                    &:first-child {
                        order: 2;
                    }
                }
            }
        }
    }
}
.ilife-team {
    padding: 80px 0 60px 0;
    @include lg {
        padding: 20px 0 10px 0;
    }
    @include md {
        padding: 50px 0 10px 0;
    }
    @include sm {
        padding: 80px 0 10px 0;
    }
    .container {
        padding-bottom: 50px;
        border-bottom: solid 6px rgba($color-body,0.10);
        @include sm {
            padding-bottom: 30px;
            border-width: 4px;
        }
    }
    .team-box {
        margin-top: 40px;
        text-align: center;
        margin-bottom: 5px;
        @include md {
            margin-top: 30px;
            margin-bottom: 0;
        }
        @include md {
            margin-top: 25px;
        }
        .team-image {
            img {
                border-radius: 100%;
                object-fit: cover;
            }
        }
    }
    .team-content {
        margin-top: 25px;
        font-size: 1.25rem;
        @include md {
            font-size: 1rem;
            margin-top: 14px;
        }
        .team-member {
            color: $color-text-default;
            font-weight: $font-bold;
        }
    }
}

.privacy-box {
    margin-bottom: 130px;
    margin-top: 130px;
    background: $bg-white;
    @include lg {
        margin-bottom: 100px;
        margin-top: 100px;
    }
    @include md {
        margin-bottom: 80px;
        margin-top: 80px;
    }
    @include sm {
        margin-bottom: 60px;
        margin-top: 0;
    }
    .container {
        overflow: unset;        
    }
    .privacy-inner {
        box-shadow: -9px 12px 48px 1px rgba(131,156,159,0.10);
        padding: 60px 100px;
        text-align: center;
        @include md {
            padding: 35px 50px;
        }
        @include sm {
            padding: 20px;
        }
    }
}

// How It's work new design

.how-work {
    .title {
        text-align: center;
    }
    .container {
        overflow: unset;
        // @media only screen and (max-width: 1299px) {
        //     padding-left: 40px;
        //     padding-right: 40px;
        // }
        @include lg {
            // padding-left: 50px;
            // padding-right: 50px;
            max-width: 100%;
        }
        @include md {
            padding-right: 15px;
            padding-left: 15px;
            max-width: 96%;
        }
        // &:before {
        //     content: "";
        //     width: 10px;
        //     height: 10px;
        //     background: $bg-white;
        //     border: solid 1px $color-primary;
        //     left: 0;
        //     top: calc(50% + 17px);
        //     transform: translate(0,-50%);
        //     -webkit-transform: translate(0,-50%);
        //     position: absolute;
        //     border-radius: 100%;
        //     @include md {
        //         display: none;
        //     }
        // }
        // &:after {
        //     content: "";
        //     right: 0;
        //     top: calc(50% - 18px);
        //     transform: translate(0,-50%);
        //     -webkit-transform: translate(0,-50%);
        //     position: absolute;
        //     height: 12px;
        //     width: 8px;
        //     @include md {
        //         display: none;
        //     }
        // }
    }
    .work-middle {
        margin: 100px 0 150px 0;
        position: relative;
        @media only screen and (max-width:1499px) {
            margin: 70px 0 130px 0;
        }
        @include lg {
            margin-bottom: 90px;
        }
        @include md {
            margin: 70px 0 30px 0;
        }
        @include sm {
            margin: 50px 0 20px 0;
        }
        &:before {
            content: "";
            width: calc(50% - 1200px / 2);
            height: 36px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid $color-primary;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            pointer-events: none;
            border-radius: 20px 0 20px 0;
            border-right: 1px solid $color-primary;            
            @media only screen and (max-width: 1299px) {
                width: calc(50% - 1150px / 2);
            }
            @include lg {
                width: 35px;
            }
            @include md {
                display: none;
            }
        }
        &:after {
            content: "";
            width: calc(50% - 1200px / 2);
            height: 36px;
            border-top: 1px solid $color-primary;
            border-bottom: 1px solid transparent;
            border-left: 1px solid $color-primary;
            border-radius: 20px 0 20px 0;
            position: absolute;
            right: 1px;
            top: 50%;
            transform: translate(0, -50%);
            pointer-events: none;
            @media only screen and (max-width: 1299px) {
                width: calc(50% - 1150px / 2);
            }
            @include lg {
                width: 35px;
            }
            @include md {
                display: none;
            }
        }
        .work-arrow1 {
            background: url('/public/assets/images/bgi/arrow-work.png') no-repeat center center;
            height: 12px;
            width: calc(50% - 1200px / 2);
            transform: translate(0,-50%);
            top: calc(50% + 17px);
            position: absolute;
            @media only screen and (max-width: 1299px) {
                width: calc(50% - 1150px / 2);
            }
            @media only screen and (max-width: 1240px) {
                display: none;
            }
        }
        .work-arrow2 {
            background: url('/public/assets/images/bgi/arrow-work.png') no-repeat center center;
            height: 12px;
            width: 8px;
            top: 54%;
            position: absolute;
            right: -3px;
            transform: rotate(90deg);
            @include md {
                display: none;
            }
        }
        .work-arrow3 {
            background: url('/public/assets/images/bgi/arrow-work.png') no-repeat center center;
            height: 12px;
            width: 8px;
            top: 29%;
            position: absolute;
            right: -3px;
            transform: rotate(-90deg);
            @include md {
                display: none;
            }
        }
    }
    .work-box {        
        counter-reset: my-awesome-counter;
        .work-progress {
            border: solid 1px #EEEEEE;
            border-radius: 8px;
            padding: 40px 22px;
            height: calc(100% - 50px);
            margin-bottom: 50px;
            color: $color-text-default;
            counter-increment: my-awesome-counter;
            position: relative;
            @include lg {
                padding: 40px 15px 20px 15px;
            }
            @include md {
                padding: 40px 20px 20px 20px;
            }
            &:before {
                content: counter(my-awesome-counter);
                position: absolute;
                width: 60px;
                height: 60px;
                background: $bg-white;
                border: solid 1px #EEEEEE;
                border-radius: 100%;
                text-align: center;
                top: -40px;
                left: 50%;
                transform: translate(-50%,0);
                -webkit-transform: translate(-50%,0);
                font-size: 2rem;
                line-height: 1;
                color: $color-primary;
                font-weight: $font-regular;
                padding: 13px;
                pointer-events: none;
                z-index: 1;
                @include lg {
                    width: 50px;
                    height: 50px;
                    font-size: 1.32rem;
                    top: -26px;
                    padding: 15px;
                }
            }
            p {
                padding: 0;
            }
        }
        .row {
            > div {
                z-index: 1;
                position: relative;
                &:first-child, &:nth-child(3) {
                    &:before {
                        position: absolute;
                        content: "";
                        height: 50%;
                        width: 35px;
                        border-top: 1px solid transparent;
                        border-left: 1px solid $color-primary;
                        border-radius: 20px 20px 0 0;
                        left: -1px;
                        top: -17px;
                        @include md {
                            display: none;
                        }
                    }
                    &:after {
                        position: absolute;
                        content: "";
                        width: calc(100% + 2px);
                        height: 35px;
                        border-top: solid 1px $color-primary;
                        border-left: 1px solid transparent;
                        border-right: 1px solid $color-primary;
                        border-radius: 20px 20px 0 0;
                        left: -2px;
                        top: -18px;
                        @include md {
                            display: none;
                        }
                    }
                    .work-progress {
                        &:after {
                            position: absolute;
                            content: "";
                            height: calc(100% - 20px);
                            width: 35px;
                            border-bottom: 1px solid transparent;
                            border-left: 1px solid $color-primary;
                            border-radius: 0 0 0 0;
                            right: -50px;
                            top: 10px;
                            left: auto !important;
                            transform: translate(0, 0);
                            -webkit-transform: translate(0, 0);
                            @include md {
                                display: none;
                            }
                        }
                    }
                }
                &:nth-child(3) {
                    &:before {
                        height: calc(100% - 45px);
                    }
                }
                &:nth-child(2n) {
                    .work-progress {
                        padding-top: 22px;
                        @include md {
                            padding-top: 40px;
                        }
                        &:after {
                            position: absolute;
                            content: "";
                            width: calc(100% + 33px);
                            height: 35px;
                            border-bottom: solid 1px $color-primary;
                            border-left: 1px solid $color-primary;
                            border-right: 1px solid $color-primary;
                            border-radius: 0 0 20px 20px;
                            left: -17px;
                            bottom: -20px;
                            @include md {
                                display: none;
                            }
                        }
                        &:before {
                            top: auto;
                            bottom: -44px;
                            @include md {
                                bottom: auto;
                                top: -26px;
                            }
                        }
                    }
                }
                &:last-child {
                    &:before {
                        position: absolute;
                        content: "";
                        height: calc(50% - 55px);
                        width: 35px;
                        border-top: 1px solid transparent;
                        border-left: 1px solid $color-primary;
                        border-radius: 0;
                        right: -34px;
                        bottom: 55px;
                        @include md {
                            display: none;
                        }
                    }
                }             
            }
        }
        h4 {
            font-size: 1.5rem;
            line-height: 28px;
            font-weight: $font-medium;
            color: $color-primary;
            margin-bottom: 15px;
            @include lg {
                font-size: 1.30rem;
                line-height: 24px;
            }
        }
    }
}
.our-database {
    @include mdUp {
        .front-title-block {
            .title {
                font-size: 2.44rem;
            }
        }
    }
}