/* You can add global styles to this file, and also import other style files */
@import "base/import";
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: $font-regular;
  src: url("../assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2")
    format("woff2");
}
@import "base/modules/date_picker";
@import "base/modules/about";
@import "base/modules/contact";
@import "base/modules/faq";

/* ========== Fonts ============= */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");

/* ======general purpose classes========== */

body {
  background: $bg-body;
  font-family: $font-global;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $color-body;
  font-weight: $font-regular;
  padding-right: 0 !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // font-smooth: antialiased;
  overflow-x: hidden !important;
  @include sm {
    font-size: $font-size-base - 1px;
  }
}

html,
body {
  // height: 100%;
  font-size: $font-size-base;
  @include mdtolg {
    font-size: 14px;
  }
  @include sm {
    font-size: 14px;
  }
}

.txt-black,
.text-black {
  color: #000 !important;
}
.container {
  // overflow-x: hidden;
  padding-bottom: 40px;
  padding-right: 20px;
  padding-left: 20px;
  @include xlUp {
    max-width: 1210px;
  }
  @include lg {
    max-width: calc(96% - -10px);
  }
  @include sm {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.page-wrapper {
  padding-top: 120px;
  @include md {
    padding-top: 110px;
  }
  @include sm {
    padding-top: 100px;
  }
}

.page-wrapper-welcome {
  padding-top: 85px;
  @include md {
    padding-top: 110px;
  }
  @include sm {
    padding-top: 100px;
  }
  .welcome-header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/* =====Begin: Links============*/

button:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

a {
  text-decoration: none !important;
  color: $color-primary;
  cursor: pointer;
  outline: 0;
  &:hover,
  &:focus {
    outline: 0;
    color: $color-body;
  }
}

.text-link {
  color: $color-primary;
  position: relative;
  display: inline-block;
  margin-left: 5px;
  font-weight: $font-medium;
  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -ms-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  &:hover {
    color: $color-primary;
    text-decoration: none;
    &:after {
      border-bottom: 1px solid $color-primary;
      width: 100%;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
  }
}

.text-link-gray {
  color: $color-body;
  margin: 0;
  position: relative;
  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1.25rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }
  &:hover {
    color: $color-primary;
    text-decoration: none;
    &:after {
      border-bottom: 1px solid $color-primary;
      width: 100%;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
  }
}

.text-primary {
  color: $color-primary !important;
}
.text-default {
  color: $color-text-default !important;
  font-size: 14px;
}
a {
  &.text-primary {
    &:hover {
      color: $color-primary !important;
    }
  }
}
.copy-icon {
  font-size: 20px;
  cursor: pointer;
}

.no-border {
  border: none !important;
}
/* =====End: Links ============*/

/* =====Begin: helper Classe============*/

p {
  padding: 0 0 10px 0;
  margin: 0;
}

img {
  max-width: 100%;
  object-fit: contain;
}

.material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

hr {
  border-top: 1px dashed $border-color;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  &.thickborder {
    border-top: 2px solid $border-color;
  }
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mt-5 {
  margin-top: 2rem !important;
}
.mb-5 {
  margin-bottom: 2rem !important;
}
h3.font-24 {
  font-size: 1.5rem;
  line-height: 2rem;
  // color: $color-text-default;
  color: $color-text-title;
  font-weight: $font-medium;
}
.text-medium {
  font-weight: $font-medium;
  color: $color-text-default;
}

/*===== Begin: Input Type Number Spinners Disabling CSS ======================*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*===== End: Input Type Number Spinners Disabling CSS ======================*/

/*===== Begin: Button Style ======================*/

.btn {
  font-weight: $font-semibold;
  font-family: $font-global;
  color: $color-primary;
  cursor: pointer;
  box-shadow: none;
  border: 2px solid $color-primary;
  text-transform: capitalize;
  @include border-radius($border-radius);
  font-size: 1rem;
  line-height: 18px;
  padding: 9px 16px;
  min-width: 120px;
  @include sm {
    padding: 7px 15px;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none !important;
  }
  &.disabled,
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:active,
  &.active {
    background-image: none;
    box-shadow: none !important;
  }
  &.btn-green {
    background: $color-primary;
    color: $color-white;
    border-color: $color-primary;
    /*@include x-linear-gradient($direction, 90, $from, $to);*/
    &:focus,
    &:hover:not(:disabled),
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background: $color-primary;
      border-color: $color-primary;
    }
    &.disable {
      background-color: #eeeeee;
      color: #c0bebe;
      cursor: not-allowed;
      border-color: #eeeeee;
    }
  }
  &.btn-gradient {
    background: rgb(1, 160, 178);
    background: linear-gradient(
      90deg,
      rgba(1, 160, 178, 1) 0%,
      rgba(42, 168, 81, 1) 100%
    );
    color: #fff;
    border-color: transparent;
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(42, 168, 81, 1) 0%,
        rgba(1, 160, 178, 1) 100%
      );
    }
  }
  &.btn-green-outline {
    background-color: #f3f3f3;
    border: 2px solid #2ba84a;
    color: #45b260;
    &:hover {
      // border: 2px solid #cacaca;
      // color: #cacaca;
      box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
    }
    &.transprant {
      background-color: transparent;
    }
  }
  // &.sendmessage{
  //   background: $color-primary;
  //   color: $color-white;
  //   border-color: $color-primary;
  //   /*@include x-linear-gradient($direction, 90, $from, $to);*/
  //   &:focus,
  //   &:hover:not(:disabled),
  //   &:active &:not(:disabled):not(.disabled):active,
  //   &:not(:disabled):not(.disabled).active,
  //   .show > &.dropdown-toggle {
  //     background: darken($color-primary, 10);
  //     border-color: darken($color-primary, 10);
  //   }
  // }
  &.btn-primary {
    // background: $color-primary;
    // color: $color-white;
    // border-color: $color-primary;
    // /*@include x-linear-gradient($direction, 90, $from, $to);*/
    // &:focus,
    // &:hover:not(:disabled),
    // &:active, &:not(:disabled):not(.disabled):active,
    // &:not(:disabled):not(.disabled).active,
    // .show > &.dropdown-toggle {
    //   background: $color-primary;
    //   border-color: $color-primary;
    // }
    background: $bg-white;
    color: $color-text-default;
    border-color: $color-text-default;
    &:focus,
    &:hover:not(:disabled),
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background: $color-text-default;
      border-color: $color-text-default;
      color: $color-white;
    }
  }
  &.btn-secondary {
    background: $color-body;
    color: $color-white;
    border-color: $color-body;
    &:focus,
    &:hover:not(:disabled),
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background: darken($color-body, 10);
      border-color: darken($color-body, 10);
      color: $color-white;
    }
  }
  &.btn-light {
    font-size: 1rem;
    line-height: 1.32rem;
    color: $color-text-default;
    font-weight: $font-regular;
    border-radius: 35px;
    background: $color-light;
    border: 0;
    &:focus,
    &:hover:not(:disabled),
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background: darken($color-body, 10);
      border-color: darken($color-body, 10);
      color: $color-white;
    }
  }
  &.btn-primary-outline {
    background: $bg-white;
    color: $color-text-default;
    border-color: $color-text-default;
    &:focus,
    &:hover:not(:disabled),
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background: $color-text-default;
      border-color: $color-text-default;
      color: $color-white;
    }
  }
  &.btn-outline-secondary {
    background: $bg-white;
    color: $color-body;
    border-color: $color-body;
    &:focus,
    &:hover:not(:disabled),
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background: darken($color-body, 10);
      border-color: darken($color-body, 10);
      color: $color-white;
    }
  }

  &.btn-lg {
    padding: 13px 32px;
    @include sm {
      padding: 8px 18px;
    }
  }
  &.btn-sm {
    font-size: 0.75rem;
    line-height: 1.5rem;
    font-weight: $font-medium;
    padding: 0;
    min-width: 60px;
  }
  &.btn-xs {
    font-size: 0.875rem;
    line-height: 1.8rem;
    font-weight: $font-medium;
    padding: 0 18px;
    min-width: 80px;
  }
  &.btn-outline-with-icon {
    background: $bg-white;
    color: $color-text-default;
    border-color: $color-text-default;
    padding: 4px 10px;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    .material-icons {
      font-size: 1rem;
      padding-right: 3px;
      text-align: left;
    }
    &:hover {
      background-color: $color-text-default;
      color: #fff;
    }
  }
}

.btn-link {
  color: $color-body;
  border: none;
  font-weight: $font-medium;
  &:focus,
  .focus {
    box-shadow: none;
    outline: 0;
    text-decoration: none;
  }
  &:hover {
    color: $color-text-default;
    text-decoration: none;
  }
  &.link-primary {
    color: $color-text-default;
    font-weight: $font-medium;
  }
}

.btn-groups {
  margin: -8px;
  .btn {
    margin: 8px;
  }
}

/*========End: Button Style ======================*/

/*========Begin: Checkobox/Radio =====================*/

.custom-radio,
.custom-checkbox {
  &.vertical {
    label {
      display: block;
    }
  }
  label {
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding: 0 0 0 25px;
    margin: 0 10px 7px 0;
    color: $color-body;
    font-weight: $font-regular;
    font-size: 14px;
    input[type="checkbox"],
    input[type="radio"] {
      display: none;
    }
    input[type="checkbox"] {
      + span {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include border-radius(2px);
        background: $bg-white;
        border: 2px solid $color-body;
        font-family: "LineAwesome", sans-serif;
        &:after {
          content: "";
          position: absolute;
          display: none;
          font-weight: $font-bold;
        }
      }
      &:checked + span {
        border: 2px solid $color-primary;
        background: $color-primary;
        color: $color-white;
        transition: all 0.3s;
        &:after {
          top: 50%;
          left: 50%;
          margin-left: -2px;
          margin-top: -6px;
          width: 5px;
          height: 10px;
          border-width: 0 2px 2px 0 !important;
          transform: rotate(45deg);
          display: block;
          border: solid $color-white;
        }
      }
      &:disabled {
        opacity: 0.6;
        + span {
          opacity: 0.6;
        }
      }
    }
    input[type="radio"] {
      + span {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include border-radius(50%);
        background: $bg-white;
        border: 2px solid $color-body;
        &:after {
          content: "";
          position: absolute;
          display: none;
          font-weight: $font-bold;
        }
      }
      &:checked + span {
        border: 2px solid $color-primary;
        transition: all 0.3s;
        &:after {
          content: "";
          display: block;
          top: 50%;
          width: 10px;
          height: 10px;
          background: $color-primary;
          @include border-radius(50%);
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
      &:disabled {
        opacity: 0.6;
        + span {
          opacity: 0.6;
        }
      }
    }
  }
  &.small-checkbox {
    label {
      padding: 0 0 0 15px;
      input[type="checkbox"] {
        + span {
          width: 12px;
          height: 12px;
        }
        &:checked + span {
          &:after {
            margin-left: -1px;
            margin-top: -5px;
            width: 3px;
            height: 7px;
            border-width: 0 1px 1px 0 !important;
          }
        }
      }
      input[type="radio"] {
        + span {
          width: 12px;
          height: 12px;
        }
        &:checked + span {
          border: 2px solid $color-primary;
          transition: all 0.3s;
          &:after {
            margin-left: -1px;
            margin-top: -5px;
            width: 3px;
            height: 7px;
            border-width: 0 1px 1px 0 !important;
          }
        }
      }
    }
  }
  &.checkbox-green {
    label {
      color: $color-primary;
      input[type="checkbox"] {
        + span {
          border-color: $color-primary;
        }
      }
    }
  }
  &.checkbox-red {
    label {
      color: $color-danger;
      input[type="checkbox"] {
        + span {
          border-color: $color-danger;
        }
        &:checked + span {
          background-color: $color-danger;
        }
      }
    }
  }
  &.checkbox-black {
    label {
      color: $color-title;
      input[type="checkbox"] {
        + span {
          border-color: $color-title;
        }
        &:checked + span {
          background-color: $color-title;
        }
      }
    }
  }
  &.checkbox-blue {
    label {
      color: $fb-bg;
      input[type="checkbox"] {
        + span {
          border-color: $fb-bg;
        }
        &:checked + span {
          background-color: $fb-bg;
        }
      }
    }
  }
}

.disable-checkbox {
  opacity: 0.5;
}
/*========Begin: Checkobox/Radio box modal=====================*/

.boxmodal-radio,
.boxmodal-checkbox {
  display: block;
  cursor: pointer;
  position: relative;
  text-align: center;
  height: calc(100% - 32px);
  margin-bottom: 32px;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }
  &.vertical {
    display: block;
  }
  input[type="checkbox"] {
    + .card-inner {
      background: $bg-white;
      box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 40px 24px;
      height: 100%;
      margin-bottom: 0;
      @include mdtolg {
        padding: 2rem 1rem;
      }
      .card-img {
        margin: 0 auto 24px auto;
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      h2 {
        margin-bottom: 1rem;
      }
      .card-text {
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $color-text-default;
        padding-bottom: 0;
        margin-bottom: 24px;
        min-height: 48px;
        font-weight: $font-regular;
      }
      .card-btn {
        margin-bottom: 24px;
      }
      .boxmodal-radiobtn {
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        border: 2px solid $color-hover;
        margin: 0 auto;
        margin-top: 16px;
        padding: 2px;
        background: $bg-white;
        span {
          background: transparent;
          width: 100%;
          height: 100%;
          display: inline-block;
          border-radius: 50%;
        }
      }
    }
    &:checked + .card-inner {
      background: $color-hover;
      border: 1px solid $color-hover;
      transform: scale(1.03);
      .boxmodal-radiobtn {
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        border: 2px solid $color-hover;
        margin: 0 auto;
        margin-top: 16px;
        padding: 2px;
        background: $bg-white;
        span {
          background: $color-hover;
        }
      }
    }
    &:disabled {
      opacity: 0.6;
      + span {
        opacity: 0.6;
      }
    }
  }
  input[type="radio"] {
    + .card-inner {
      &.with-border {
        border: 1px solid $border-color;
        box-shadow: none;
      }
      background: $bg-white;
      box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 40px 24px;
      -webkit-transition: all 300ms ease-in-out 0s;
      transition: all 300ms ease-in-out 0s;
      height: 100%;
      margin-bottom: 0;
      @include mdtolg {
        padding: 2rem 1rem;
      }
      .card-img {
        margin: 0 auto 15px auto;
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      h2 {
        margin-bottom: 1rem;
      }
      .card-text {
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $color-text-default;
        padding-bottom: 0;
        margin-bottom: 24px;
        min-height: 48px;
        font-weight: $font-regular;
      }
      .card-btn {
        margin-bottom: 24px;
      }
      .boxmodal-radiobtn {
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        border: 2px solid $color-primary;
        margin: 0 auto;
        margin-top: 16px;
        padding: 2px;
        background: $bg-white;
        span {
          background: transparent;
          width: 100%;
          height: 100%;
          display: inline-block;
          border-radius: 50%;
        }
      }
    }
    &:checked + .card-inner {
      // background: $color-light;
      border: 1px solid $color-primary;
      transform: scale(1.03);
      .boxmodal-radiobtn {
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        border: 2px solid $color-primary;
        margin: 0 auto;
        margin-top: 16px;
        padding: 2px;
        background: $bg-white;
        span {
          background: $color-primary;
        }
      }
    }
    &:disabled {
      opacity: 0.6;
      + span {
        opacity: 0.6;
      }
    }
  }
}

.link-block {
  display: inline-block;
  width: 100%;
  height: 100%;
  .card {
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    border: 1px solid transparent;
  }
  .boxmodal-radio,
  .boxmodal-checkbox {
    input[type="checkbox"],
    input[type="radio"] {
      + .card-inner {
        transition: all 0.3s ease-in-out;
        transform: scale(1);
        border: 1px solid transparent;
      }
    }
  }
  &:hover {
    .card {
      border: 1px solid $color-primary;
      background: $color-hover;
      transform: scale(1.03);
      .btn-next {
        background: $color-primary !important;
        border-color: $color-primary !important;
        span {
          color: $color-white !important;
        }
      }
    }
    .boxmodal-radio,
    .boxmodal-checkbox {
      input[type="checkbox"],
      input[type="radio"] {
        + .card-inner {
          border: 1px solid $color-primary;
          background: $color-hover;
          transform: scale(1.03);
        }
      }
    }
  }
  .notes {
    color: $color-body;
  }
}

/*========End: Checkobox/Radio =====================*/

/*========Begin: Form Control ========================*/

label {
  font-weight: $font-medium;
}

.form-group {
  margin-bottom: 32px;
  position: relative;
  // label {
  //     color: $color-secondary;
  // }
  &.fixed-floating-label {
    .fixed-label {
      font-size: 0.75rem;
      line-height: 0.875rem;
      position: absolute;
      left: 16px;
      top: -6px;
      background: $color-white;
      margin: 0;
      padding: 0 3px;
    }
  }
  .label-small {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
  .text-danger {
    font-size: 0.85rem;
  }
}

textarea {
  resize: none;
  border: 1px solid $border-disabled;
  border-radius: 4px;
  padding: 15px;
}

.col-form-label {
  color: $color-secondary;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

/* WebKit browsers */

input:focus:-moz-placeholder {
  color: transparent;
}

/* Mozilla Firefox 4 to 18 */

input:focus::-moz-placeholder {
  color: transparent;
}

/* Mozilla Firefox 19+ */

input:focus:-ms-input-placeholder {
  color: transparent;
}

/* Internet Explorer 10+ */

input[type="file"] {
  opacity: 0;
  display: none;
}

.form-control {
  font-family: $font-global;
  @include border-radius(4px);
  color: $color-text-default;
  line-height: 1.429rem;
  border: 1px solid $border-disabled;
  font-size: 1em;
  padding: 10px 18px;
  height: 48px;
  width: 100%;
  //background-color: transparent;
  @include sm {
    height: 40px;
    padding: 8px 18px;
  }
  @include placeholder {
    color: $color-secondary;
  }
  &.form-control-sm {
    padding: 0.358rem 1.071rem;
    font-size: 0.875rem;
    line-height: 1.35rem;
  }
  &.focus,
  &:focus {
    border-color: $color-primary;
    color: $color-text-default;
    box-shadow: none;
    @include placeholder {
      color: $color-primary;
    }
  }

  &[readonly],
  &:disabled {
    background-color: transparent;
    /*color: $color-secondary;*/
    opacity: 0.5;
    ~ {
      label {
        opacity: 0.5;
      }
    }
  }
  &.line-input {
    border: none;
    border-bottom: 1px solid $border-color;
    @include border-radius(0);
    padding: 1rem 0;
    @include placeholder {
      color: $color-secondary;
    }
    &.focus,
    &:focus {
      border-color: $color-primary;
      color: $color-primary;
      @include placeholder {
        color: $color-primary;
      }
    }
  }
}

.form-control-feedback {
  font-weight: $font-regular;
  font-size: 0.85rem;
}

.has-danger {
  color: $color-danger;
  .form-control {
    border: 1px solid $color-danger;
  }
}

.form-control::placeholder {
  opacity: 0;
}

.form-control-search::placeholder {
  opacity: 1;
}

.input-effect .form-control ~ label {
  color: $color-dark-gray;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 12px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  margin: 0;
  padding: 0 3px;
  white-space: nowrap;
  max-width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  @include sm {
    top: 9px;
  }
}
.input-effect .form-control:focus ~ label,
.has-content.form-control ~ label,
.input-effect .form-control:not(:placeholder-shown) ~ label {
  top: -13px;
  font-size: 12px;
  transform: translateY(0);
  background: #ffffff; //$color-white;
  opacity: 1;
  @include sm {
    top: -7px;
    line-height: 14px;
  }
}
.input-effect .form-control:-webkit-autofill ~ label {
  top: -13px;
  font-size: 14px;
  transform: translateY(0);
  background: #ffffff; //$bg-white;
  @include sm {
    top: -7px;
    line-height: 14px;
    font-size: 12px;
  }
}
.input-effect {
  position: relative;
  &.otp-inputs {
    input.form-control {
      padding-right: 130px;
    }
    .btn {
      padding: 0;
      position: absolute;
      top: 12px;
      right: 0.7rem;
      min-width: auto;
      line-height: 26px;
      font-weight: $font-medium;
      @include sm {
        top: 7px;
        right: 1rem;
      }
      &.btn-resend {
        color: $color-body;
        cursor: text;
      }
    }
  }
  &.with-icon {
    .floating-input {
      padding-right: 30px;
    }
    .form-control {
      padding-right: 38px;
      @include sm {
        padding-right: 35px;
      }
    }
    .view-password {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      &.viewed {
        color: $color-primary;
      }
      @include sm {
        font-size: 20px;
        top: 10px;
        right: 10px;
      }
    }
    .input-icon {
      position: absolute;
      top: 17px;
      right: 10px;
      @include sm {
        top: 10px;
      }
      &.show {
        position: absolute;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        @include sm {
          width: 20px;
          height: 20px;
          background-size: 100%;
        }
      }
      &.hide {
        position: absolute;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        @include sm {
          width: 20px;
          height: 20px;
          background-size: 100%;
        }
      }
    }
    .info-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      line-height: 1;
      // @include sm{
      //     top: 10px;
      // }
    }
  }
}
.edit-input {
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 14px;
  color: $color-body;
  line-height: 1;
  &:hover {
    color: $color-primary;
  }
  @include sm {
    right: 13px;
    top: 11px;
  }
  .material-icons {
    font-size: 1.3rem;
  }
}

textarea {
  &.form-control {
    height: auto;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: $color-text-default;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.input-group {
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  background: #ffffff; //$color-white;
  max-width: 160px;
  .input-group-text {
    background: transparent;
    color: $color-primary;
    border: none;
    padding: 10px 10px;
  }
  .form-control {
    border: transparent;
    font-weight: $font-medium;
    padding: 10px;
    &::placeholder {
      opacity: 1;
      display: inline-block;
    }
  }
  .input-group-append {
    height: 48px;
    .btn {
      min-width: unset;
      max-width: 100px;
      color: $color-primary;
      border: 1px solid $color-secondary;
      border-radius: 0 4px 4px 0;
      line-height: 1.5rem;
      padding: 12px 32px;
    }
    @include sm {
      height: 40px;
      .btn {
        padding: 8px 18px;
      }
    }
  }
}

/*========Error msg Style================*/
.error-block {
  color: $color-danger;
  font-size: 13px;
  line-height: 16px;
}

/*===DropDown==========*/

.dropdown-menu {
  border: 1px solid $color-secondary;
  /*box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);*/
  margin: 0;
  font-size: 1em;
  &.show {
    -webkit-animation: dropdown-fade-in 0.3s ease 1,
      dropdown-move-up 0.3s ease-out 1;
    -moz-animation: dropdown-fade-in 0.3s ease 1,
      dropdown-move-up 0.3s ease-out 1;
    -ms-animation: dropdown-fade-in 0.3s ease 1,
      dropdown-move-up 0.3s ease-out 1;
    -o-animation: dropdown-fade-in 0.3s ease 1, dropdown-move-up 0.3s ease-out 1;
    animation: dropdown-fade-in 0.3s ease 1, dropdown-move-up 0.3s ease-out 1;
  }

  .dropdown-item {
    padding: 10px 0px;
    border-bottom: 1px solid $color-secondary;
    white-space: inherit;
    display: flex;
    align-items: center;
    color: $color-body;
    .fa {
      margin-right: 10px;
      color: $color-primary;
      font-size: 20px;
    }
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-bottom: none;
    }
    &:focus,
    &:hover {
      color: $color-text-default;
      background: transparent;
    }
    &.active,
    &:active {
      color: $color-body;
      background: transparent;
    }
    &.view-notification {
      color: $color-primary;
      padding: 10px 16px 12px 16px !important;
      @include sm {
        padding: 10px 14px 12px 14px !important;
      }
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    border: 5px solid $color-secondary;
    border-color: transparent transparent $color-white $color-white;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -1px 1px 1px 0 rgba(131, 156, 159, 0.16);
  }
}

.profile-dropdown {
  display: flex;
  flex-wrap: wrap;
  .dropdown {
    #dropdownNotification,
    .profile-img {
      cursor: pointer;
    }
    // cursor: pointer;
    @include sm {
      position: inherit;
    }
  }
  .notification-icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .counter-icon {
    background-color: $color-primary;
    color: $color-white;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    top: -5px;
    right: -6px;
    line-height: 1;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    padding: 3px 0;
    @include sm {
      top: -7px;
      right: -6px;
    }
  }
  > div {
    padding-left: 1rem;
    padding-right: 1rem;
    @include sm {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    @media only screen and (max-width: 359px) {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      font-size: 11px;
    }
    + div {
      position: relative;
      @include sm {
        position: inherit;
      }
      &:before {
        content: "";
        width: 1px;
        height: 32px;
        background: rgba($border-color, 0.24);
        position: absolute;
        left: 0;
        top: -3px;
        @include sm {
          display: none;
        }
      }
    }
  }
  .inquires-toggle {
    .inq-text {
      display: inline-block;
      margin-right: 10px;
      font-size: 14px;
      @include sm {
        margin-right: 5px;
        font-size: 12px;
      }
      @media only screen and (max-width: 359px) {
        font-size: 11px;
      }
    }
    .custom-switch {
      display: inline-block;
      vertical-align: middle;
      @include sm {
        label {
          width: 36px;
          height: 20px;
          &::after {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
  .dropdown-menu {
    min-width: 270px;
    padding: 1.5rem;
    background: #ffffff;
    box-shadow: 0 -2px 8px 0 rgba(131, 156, 159, 0.08),
      0 8px 16px 0 rgba(131, 156, 159, 0.16);
    border: none;
    top: 40px;
    right: 11px;
    @include sm {
      right: 0;
      top: 61px;
      &::before {
        right: 45px;
      }
    }
    @media only screen and (max-width: 359px) {
      right: 0;
      &::before {
        right: 36px;
      }
    }
    &.dropdown-menu-notifications {
      width: 470px;
      padding: 0.5rem;
      @include sm {
        width: 100%;
        &::before {
          right: 82px;
        }
      }
      @media only screen and (max-width: 359px) {
        &::before {
          right: 74px;
        }
      }
      .notification-outer {
        padding: 0.5rem 0;
        border-bottom: 0;
        .text-primary {
          font-weight: $font-medium;
        }
        .notification-msg {
          width: 100%;
        }
        .notification-date {
          width: 100%;
        }
      }
    }
    .text-primary {
      &.dropdown-item {
        border: none;
      }
    }
    .scrollbar-container {
      max-height: 480px;
      padding: 0.3rem 1rem 0 1rem;
      @include sm {
        max-height: 280px;
      }
      @include xs {
        max-height: 360px;
      }
      .ps__rail-x {
        display: none !important;
      }
      .ps__rail-y {
        opacity: 0.4 !important;
      }
      &:hover {
        .ps__rail-y {
          opacity: 0.8 !important;
        }
      }
      .notification-outer {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  .dropdown-item {
    display: flex;
    border-bottom: 1px solid $border-light;
    .dropdown-icon {
      margin-right: 16px;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .profile-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    @include md {
      margin-right: 8px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

@-webkit-keyframes dropdown-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes dropdown-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes dropdown-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dropdown-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes dropdown-move-up {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}

@-moz-keyframes dropdown-move-up {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}

@-o-keyframes dropdown-move-up {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes dropdown-move-up {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes height-fade-in {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
@keyframes height-fade-out {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
@keyframes dropdown-zoom-in {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
/* =======  search input ======== */
.search-box {
  position: relative;
  .form-group {
    margin-bottom: 1.5rem;
  }
  .form-control {
    padding-right: 130px;
  }
  input[type="search"] {
    + .btn {
      &.btn-light {
        border-radius: 4px;
        color: $color-white;
        background: $border-color;
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        @include sm {
          padding: 4px 15px;
        }
      }
    }
  }
  input[type="search"] {
    &:focus {
      border-color: $border-color;
      background: transparent;
      + .btn {
        &.btn-light {
          background: $color-primary;
        }
      }
    }
  }
}
/* ========= Reusable components ==========*/

.notes {
  p {
    font-size: 0.75rem;
    padding-bottom: 0;
    line-height: 1.5rem;
  }
}

/* ====== Begin: Select Box ========== */
// .plan-list{
//     .custom-select__control {
//         .custom-select__value-container{
//             .custom-select__placeholder{
//                 color: $color-text-default;
//             }
//         }
//     }
// }
.plan-list {
  .planlist-bottombtn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .btn-link {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 10px;
      &:first-child {
        padding: 0;
      }
    }
  }
  .boxmodal-radio,
  .boxmodal-checkbox {
    input[type="checkbox"] {
      + .card-inner {
        box-shadow: none;
        border: 1px solid $border-color;
      }
      &:checked + .card-inner {
        background: $bg-white;
        border: 1px solid $color-hover;
        transform: scale(1);
        -webkit-transform: scale(1);
      }
      &:hover + .card-inner {
        border: 1px solid var(--color-primary);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }
    input[type="radio"] {
      + .card-inner {
        box-shadow: none;
        border: 1px solid $border-color;
      }
      &:checked + .card-inner {
        background: $color-hover;
        border: 1px solid $color-hover;
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }
  }
  // .owl-carousel{
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  .owl-carousel {
    @include lg {
      padding-left: 0;
      padding-right: 0;
    }
    .owl-nav {
      @include lg {
        top: -36px;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        left: auto;
        right: 10px;
        width: auto;
      }
      .owl-prev {
        left: 6px;
        position: relative;
        @include lg {
          left: 0;
        }
      }
    }
  }
  .item {
    margin: 0 15px;
    .card-inner {
      margin: 0;
    }
  }
}
.plan-loaders {
  text-align: center;
  padding-left: 30px;
  padding-right: 15px;
  @include lg {
    padding-left: 0;
    padding-right: 0;
  }
  @include md {
    > .row {
      > div {
        + div {
          display: none;
        }
      }
    }
  }
  .card-inner {
    padding: 40px 24px;
    @include mdtolg {
      padding: 2rem 1rem;
    }
  }
}
.default-select {
  text-align: left;
  .css-26l3qy-menu {
    box-shadow: 0 0 8px 0 rgba(131, 156, 159, 0.22);
  }
  .custom-select__menu {
    background: $color-white;
    z-index: 2;
  }
  .css-yt9ioa-option {
    &:active {
      background-color: #ecfdf1;
    }
  }
  .custom-select-menu {
    .custom-select__control {
      min-height: 48px;
      border: 0;
      box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16) !important;
      @include sm {
        min-height: 40px;
      }
      &.custom-select__control--is-focused {
        outline: none;
        box-shadow: none;
      }
      .custom-select__value-container {
        padding: 5px 0.75rem;
        font-weight: $font-medium;
        .custom-select__placeholder {
          font-size: 1rem;
          line-height: 1.625rem;
          font-weight: $font-regular;
          color: $color-body;
          padding: 0;
          margin: 0 0 0 2px;
        }
      }
      .custom-select__menu {
        background: $color-white;
        .custom-select__option {
          font-size: 1rem;
        }
      }
      .custom-select__indicator-separator {
        display: none;
      }
      .custom-select__indicators {
        svg {
          color: $color-text-default;
        }
      }
      .custom-select__single-value--is-disabled {
        color: $color-text-default;
        opacity: 0.5;
      }
    }
  }
  &.no-shadow {
    .custom-select-menu {
      .custom-select__control {
        box-shadow: none !important;
      }
      // .custom-select__control{
      //     .custom-select__placeholder{
      //         font-size: 2rem;
      //         font-weight: $font-light;
      //         color: $color-text-default;
      //     }
      //     .custom-select__menu{
      //         .custom-select__option{
      //             font-size: 1rem;
      //         }
      //     }
      //     .custom-select__indicators{
      //         svg{
      //             color: $color-text-default;
      //         }
      //     }
      // }
    }
  }
  &.with-border {
    .custom-select-menu {
      .custom-select__control {
        box-shadow: none !important;
        border: 1px solid $color-secondary;
        border-radius: 4px;
      }
    }
  }
  .custom-select__menu-list {
    padding: 0;
    border-radius: 4px;
    max-height: 250px;
    .custom-select__option {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .custom-select__option--is-focused {
      background-color: transparent;
      border: 1px solid $color-hover;
      border-radius: 4px;
    }
    .custom-select__option--is-selected {
      background-color: $color-primary;
    }
  }
}
/* ====== End: Select Box ========== */

/* ====== Begin: Modal ========== */
.modal {
  .default-select {
    .custom-select__menu-list {
      max-height: 100px;
    }
  }
  .modal-dialog {
    max-width: 970px;
    margin: 0 auto;
    padding: 24px 0;
    @include md {
      max-width: calc(100% - 30px);
    }
    @include xs {
      padding: 0;
    }
    .modal-content {
      border-radius: 8px;
      .modal-header {
        padding: 1.5rem;
        border-bottom: 0;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
        .modal-title {
          font-size: 1.25rem;
          line-height: 1.625rem;
          font-weight: $font-medium;
          color: $color-text-default;
        }
        .close {
          font-size: 1.75rem;
          font-weight: $font-light;
          color: $color-body;
          opacity: 1;
          padding: 0;
          margin: 0;
        }
      }
      .modal-body {
        padding: 1.5rem 1.5rem 2rem;
        background: $bg-body;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
      }
      .modal-footer {
        &.btns-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  // &.fade{
  //     .modal-dialog{
  //         @include smUp{
  //             top: 50%;
  //             transform: translateY(-50%);
  //         }
  //     }
  // }
  // &.show{
  //     .modal-dialog{
  //         @include smUp{
  //             transform: translateY(-50%);
  //         }
  //     }
  // }
  &.custom-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .close {
            display: none;
          }
        }
        .modal-body {
          position: static;
        }
      }
    }
  }
  @include lg {
    padding: 0 !important;
  }
  // Call Agent modal
  &.call-agent-modal {
    .modal-dialog {
      max-width: 600px;
      @include sm {
        max-width: calc(100% - 30px);
      }
      .custom-radio {
        label {
          margin-bottom: 15px;
        }
      }
      textarea {
        height: 110px;
      }
    }
  }
}
.modal-open {
  overflow: hidden !important;
}
.modal-small {
  &.modal {
    .modal-dialog {
      max-width: 700px;
      .modal-content {
        .modal-body {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          padding: 2rem;
        }
      }
      @include sm {
        max-width: calc(100% - 30px);
      }
    }
  }
}
.documentin-modal {
  .col-lg-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .white-box2 {
    margin-bottom: 0;
  }
}

.pdf-modal {
  &.modal {
    padding-right: 0 !important;
    .modal-dialog {
      max-width: 1200px;

      &.modal-90w {
        max-width: 90%;
        @include sm {
          max-width: 94%;
        }
      }
      .modal-content {
        // height: calc(100vh - 100px);
        .modal-body {
          padding-top: 0;
        }
      }
    }
  }
}

.modal {
  .modal-dialog {
    &.modal-90w {
      max-width: 90%;
      @include lg {
        iframe {
          max-height: 600px;
        }
      }
      @include sm {
        max-width: 94%;
        iframe {
          max-height: 550px;
        }
      }
    }
  }
}

.add-bank-account-modal {
  &.modal {
    // padding-right: 0 !important;
    .modal-dialog {
      max-width: 418px;
      .modal-content {
        .modal-header {
          display: inline-block;
          position: relative;
          margin-bottom: 4px;
          box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.1);
          .modal-subtitle {
            padding-bottom: 0;
          }
          .close {
            position: absolute;
            top: 22px;
            right: 20px;
            margin: 0;
          }
        }
        .modal-body {
          padding-top: 2rem;
          padding-bottom: 0;
          .text-small {
            font-size: 0.875rem;
            color: $color-text-default;
            line-height: 1.5rem;
            padding-bottom: 0.75rem;
          }
        }
        .modal-footer {
          border-top: 0;
          padding: 2rem 1.5rem 2.5rem;
        }
      }
    }
  }
}
.agent-modal {
  .modal-dialog {
    max-width: 870px;
    @include sm {
      max-width: calc(100% - 30px);
    }
    .modal-content {
      .modal-body {
        padding-right: 55px;
      }
    }
  }
}
/* ====== End: Modal ========== */
/* ====== Begin: Common Chat Icon ========== */
.chat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: $color-primary;
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 9999999;

  .chat-counter {
    color: #fff;
    background: #dc3545;
    padding: 6px;
    min-width: 22px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: 2px;
    line-height: 10px;
    font-size: 14px;
  }
  svg {
    width: 32px;
  }
  @include sm {
    width: 40px;
    height: 40px;
    bottom: 15px;
    right: 15px;
    svg {
      width: 20px;
    }
  }
}

/* ====== Meterial Icom ========== */
.material-icons {
  vertical-align: middle;
}
/* ====== End: Common Chat Icon ========== */

/* ========= White-box ======== */
.white-box {
  background: $bg-white;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 40px 25px 40px;
  margin-bottom: 40px;
}
.white-box2 {
  background: $bg-white;
  box-shadow: $box-shadow-light;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  &.with-border {
    box-shadow: none;
    border: 1px solid $border-light;
    border-radius: 4px;
  }
}
.card-inner {
  background: $color-white;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 1.5rem;
  transition: all 300ms ease-in-out 0s;
  height: calc(100% - 32px);
  margin-bottom: 2rem;
  &.with-border {
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid $border-color;
  }
}
.chips-outer {
  margin: -4px;
  .chips-label {
    padding: 1px 8px;
    margin: 4px;
    background: rgba(9, 31, 31, 0.9);
    line-height: 1.5rem;
    display: inline-block;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff; //$color-white;
    text-transform: uppercase;
    font-weight: $font-medium;
  }
}

/* ===== Loader ======= */
.pre-loader {
  display: none;
}

.loader-outer {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.57);
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  div {
    transform-origin: 9px 9px;
    animation: lds-spinner 1.2s linear infinite;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 14px;
      left: 8px;
      width: 1.4px;
      height: 5px;
      border-radius: 20%;
      background: $color-white;
    }
    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
  &.loader-lg {
    width: 80px;
    height: 80px;
    div {
      transform-origin: 40px 40px;
      &:after {
        top: 3px;
        left: 37px;
        width: 6px;
        height: 18px;
        border-radius: 20%;
        background: #fff;
      }
    }
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.btn-loader {
  .btn {
    max-height: 40px;
    min-width: 88px;
  }
}
.loader-withtext {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  .lds-spinner {
    div {
      &:after {
        background: $color-primary;
      }
    }
  }
  h5 {
    color: $color-primary;
  }
}

/* ========== custom range slider ============ */
.custom-slider {
  text-align: center;
  max-width: 970px;
  margin: 0 auto;
  .value {
    display: inline-block;
    h2 {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      display: inline-block;
      margin-bottom: 0;
      span {
        font-size: 1.25rem;
        margin-left: 15px;
        font-weight: $font-medium;
        vertical-align: top;
        display: inline-block;
      }
      input[type="number"],
      .form-control {
        border: solid 1px $border-color;
        padding: 5px 8px;
        width: auto;
        height: 44px;
        border-radius: 4px;
        display: inline-block;
        font-weight: 500;
        max-width: 180px;
        @include lg {
          height: 37px;
          max-width: 162px;
        }
        @include md {
          max-width: 166px;
        }
        @include sm {
          height: 30px;
          max-width: 130px;
        }
        &.year-input {
          max-width: 60px;
          padding: 5px 5px;
          @include lg {
            max-width: 50px;
          }
          @include md {
            max-width: 56px;
          }
          @include md {
            max-width: 42px;
          }
        }
      }
    }
    .edit-icon {
      font-size: 1.2rem;
      color: $color-body;
      position: relative;
      top: -16px;
      margin-right: -8px;
      left: 2px;
      @include sm {
        top: -14px;
        margin-right: -12px;
        left: 1px;
      }
      &:hover {
        color: $color-primary;
      }
    }
  }
  .rangeslider,
  .rangeslider__fill {
    box-shadow: none;
  }
  .rangeslider-horizontal {
    height: 32px;
    border-radius: 4px;
    background: $color-secondary;
    .rangeslider__fill {
      border-radius: 4px;
      background: $color-text-default;
    }
    .rangeslider__handle {
      width: 24px;
      height: 48px;
      border-radius: 4px;
      background: $color-text-default;
      box-shadow: none;
      border: 2px solid $color-white;
      &:after {
        width: 10px;
        height: 18px;
        background: url("../assets/images/icon/drage-slider.png") transparent;
        box-shadow: none;
        border-radius: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .rangeslider__handle-label {
        display: none;
      }
    }
  }
  .rangeslider__labels {
    .rangeslider__label-item {
      white-space: nowrap;
    }
  }
}

/* ========= custom-tab ========= */
.custom-tab {
  .nav-tabs {
    border: none;
    margin-bottom: 2rem;
    .nav-link {
      color: $color-body;
      padding: 0;
      padding-bottom: 1rem;
      margin-right: 2.5rem;
      border: none;
      border-bottom: 2px solid transparent;
      font-size: 1.5rem;
      font-weight: $font-medium;
      &.active {
        color: $color-text-default;
        border-bottom: 2px solid $color-primary;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/*========= Card ==========*/
.animated {
  height: 100%;
  display: inline-block;
  width: 100%;
  animation-fill-mode: none;
}
.animation-outer {
  .animated {
    height: auto;
    display: inline-block;
    width: 100%;
  }
}
.card-outer {
  background: $bg-white;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border-radius: 8px;
  padding: 24px;
  display: block;
  margin-bottom: 1rem;
  &.with-border {
    box-shadow: none;
    background: transparent;
    border: 1px solid $border-color;
  }
  &.approved-steps {
    padding: 0 24px;
    .card-body {
      padding: 24px 0;
      border-bottom: 1px solid $border-light;
      &:last-child {
        border-bottom: 0;
      }
      h2 {
        color: $color-text-title;
      }
    }
  }
}

.chat-notification-text {
  background: $bg-white;
  padding-left: 24px;
  display: block;
}

.card-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -15px;
}
.card {
  background: $bg-white;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 40px 24px;
  height: 100%;
  text-align: center;
  display: block;
  &.with-border {
    box-shadow: none;
    border: 1px solid $border-color;
  }
  @include mdtolg {
    padding: 2rem 1rem 1rem;
  }
  .card-img {
    margin-bottom: 24px;
    height: 110px;
  }
  h2 {
    margin-bottom: 1rem;
  }
  .card-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $color-text-default;
    padding-bottom: 0;
    margin-bottom: 24px;
    min-height: 48px;
    font-weight: $font-regular;
  }
  .card-btn {
    margin-bottom: 24px;
  }
  .boxmodal-radiobtn {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid $color-primary;
    margin: 0 auto;
    margin-top: 16px;
    padding: 2px;
    background: $bg-white;
    span {
      background: transparent;
      width: 100%;
      height: 100%;
      display: inline-block;
      border-radius: 50%;
    }
  }
}

/*========= Form Wrapper ==========*/
.form-wrapper {
  padding: 40px 50px;
  border-radius: 8px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  background: $color-white;
  @include sm {
    padding: 40px 24px;
  }
}
/* Please wait page */
.please-wait-img {
  margin-bottom: 40px;
}

/*========== temporary Guidlines css =========*/
.used-color {
  .color-bg-base {
    background: #fbfcfc;
    color: $color-text-default;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-bg-default {
    background: #ffffff;
    color: $color-text-default;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-text-base {
    background: #839c9f;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-text-default {
    background: #091f1f;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-text-title {
    background: #091f1f;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-primary {
    background: $color-primary;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-secondary {
    background: #cdd7d9;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-danger {
    background: #dc3545;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-info {
    background: #1aade2;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
  .color-warning {
    background: #f8e9db;
    color: $color-white;
    padding: 15px;
    box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  }
}

/*========= Begin: Header ==========*/
header {
  height: 80px;
  background: $color-header-new;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1030;
  .container {
    overflow-x: visible;
  }
  .navbar-brand {
    a {
      display: inline-block;
    }
    @include lg {
      padding: 0;
    }
    @include sm {
      margin: 0;
      max-width: 67px;
    }
    @media only screen and (max-width: 359px) {
      max-width: 60px;
    }
    h1 {
      margin-bottom: 0;
      line-height: 0;
      font-size: 0;
    }
    img {
      height: auto;
    }
  }
  .navbar {
    padding: 1rem 0;
    height: 80px;
    justify-content: space-between;
    @include md {
      height: 68px;
    }
    @include sm {
      height: 62px;
    }
    .navbar-collapse {
      justify-content: flex-end;
      @include md {
        box-shadow: 0 1px 4px 0 rgba(131, 156, 159, 0.3);
        background: $bg-white;
        padding: 0;
        position: fixed;
        top: 68px;
        width: 100%;
        left: -100%;
        transition: 0.3s ease-in-out all;
        height: calc(100% - 68px) !important;
        max-width: 300px;
      }
      @include sm {
        top: 60px;
        height: calc(100% - 60px) !important;
      }
      &.collapsing {
        height: calc(100% - 60px);
      }
      &.show {
        @include md {
          left: 0;
          &:after {
            position: fixed;
            content: "";
            height: calc(100vh - 68px);
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            top: 68px;
            left: 0;
            right: 0;
            z-index: -1;
            transition: 0.3s ease-in-out all;
          }
        }
        @include sm {
          &:after {
            height: calc(100vh - 60px);
            top: 60px;
          }
        }
      }
    }
    .login {
      background: transparent;
      @include md {
        display: inline-block;
      }
      .login-link {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        margin-left: 2rem;
        font-size: 1rem;
        font-weight: $font-bold;
        color: $color-primary;
        background: transparent;
        @include md {
          padding: 0;
          margin: 0 5px 0 12px;
        }
        @include xs {
          font-size: 13px;
        }
        @media only screen and (max-width: 359px) {
          margin: 0 3px 0 8px;
          font-size: 12px;
        }
      }
      h4 {
        margin: 0;
        @include xs {
          font-size: 13px;
        }
        @media only screen and (max-width: 359px) {
          font-size: 11px;
        }
      }
      .copy-icon {
        svg {
          @include xs {
            max-width: 16px;
          }
          @media only screen and (max-width: 359px) {
            max-width: 14px;
          }
        }
      }
      .become-reps {
        @include md {
          padding: 10px 20px;
          font-size: 15px;
        }
        @include sm {
          padding: 7px 14px;
          font-size: 14px;
        }
        @include xs {
          padding: 5px 12px;
          font-size: 12px;
        }
        @media only screen and (max-width: 359px) {
          padding: 3px 10px;
          font-size: 11px;
        }
      }
    }
    .navbar-toggler {
      padding: 0 0 0 0.5rem;
      border: none;
      color: $color-body;
      @include sm {
        .navbar-toggler-icon {
          width: 1.3em;
          height: 1.3em;
        }
      }
    }
  }
  @include md {
    height: 68px;
    .navbar {
      padding: 0.75rem 0;
    }
  }
  @include sm {
    height: 62px;
    .navbar {
      padding: 0.5rem 0;
    }
  }
  .header-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    @include sm {
      max-width: calc(100% - 80px);
    }
    form.login {
      + .profile-dropdown {
        position: relative;
        margin-left: 1rem;
        justify-content: flex-end;
        &::before {
          content: "";
          width: 1px;
          height: 32px;
          background: rgba(205, 215, 217, 0.24);
          position: absolute;
          left: 0;
          top: -3px;
          @include sm {
            display: none;
          }
        }
        @include sm {
          margin-left: 6px;
        }
        @include xs {
          margin-top: 2px;
          margin-right: 0;
          .dropdown-menu {
            top: 40px;
            &::before {
              right: 7px;
            }
          }
        }
      }
    }
  }
  .custom-nav {
    .navbar {
      .navbar-toggler {
        border: none;
      }

      .navbar-toggler-icon {
        background-size: 35px;
      }

      .close-icon {
        position: absolute;
        right: 20px;
        top: 15px;
        width: 15px;
        height: 15px;
      }

      .close-icon:before,
      .close-icon:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 15px;
        width: 2px;
        background-color: $color-white;
      }

      .close-icon:before {
        transform: rotate(45deg);
      }

      .close-icon:after {
        transform: rotate(-45deg);
      }

      background-color: $bg-white;

      @include md {
        position: static;
      }

      li.nav-item {
        &.active {
          a {
            color: $color-primary;
          }
        }
        a {
          padding: 0 20px;
          font-size: 16px;
          font-weight: $font-semibold;
          color: $color-body;

          @include md {
            display: block;
            padding: 10px 15px;
            border-bottom: 1px solid $bg-white;
            color: $color-white;
          }
        }
      }

      @include md {
        .navbar-nav {
          margin-top: 30px;
        }

        .navbar-collapse {
          position: absolute;
          width: 200px;
          right: -200px;
          top: 0px;
          height: 100vh !important;
          text-align: center;
          background: $light-black-bg;
          z-index: 999;
          transition: all ease 0.5s;
          opacity: 0;

          &.show {
            right: 0;
            opacity: 1;
            left: initial !important;
            &:after {
              content: none !important;
            }
          }
        }
      }
    }
  }
  .agent-info {
    display: flex;
    align-items: center;
    padding-right: 30px;
    padding-left: 30px;
    .img-container {
      justify-content: center;
      align-items: center;
      padding-right: 15px;
      svg {
        fill: $color-primary;
      }
    }
    .agent-name {
      font-family: $font-global;
      font-weight: 700;
      font-size: 13px;
      line-height: normal;
      color: $light-black-bg;
      text-align: left;
    }
    .agent-info {
      font-family: $font-global;
      font-weight: 400;
      line-height: normal;
      padding-top: 1.5px;
      font-size: 13px;
      color: $light-black-bg;
      text-align: left;
      padding-left: 0;
    }
  }
  // .ilife-profile-logo {
  //   pointer-events: none;
  // }
  .profile-logo {
    // width: 50px;
    // height: 50px !important;
    max-width: 120px;
    max-height: 60px;
    object-fit: cover;
    display: inline-block;
    vertical-align: middle;
    @include sm {
      width: 40px;
      height: 40px !important;
    }
  }
  .powered {
    display: block;
    // color: $color-primary;
    color: #333333;
    // font-size: 0.75rem;
    font-size: 0.65rem;
  }
  .company-info {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    font-size: 1.2rem;
    margin-left: 18px;
    padding-left: 18px;
    position: relative;
    line-height: 1.4;
    color: $color-primary;
    @include sm {
      margin-left: 14px;
      padding-left: 14px;
      max-width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @include lgtomd {
      margin-left: 14px;
      padding-left: 14px;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::before {
      content: "";
      width: 2px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: rgba($border-color, 0.5);
      @include sm {
        width: 1px;
        height: 20px;
      }
    }
  }
  .company-info-logged-in {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    font-size: 1.2rem;
    margin-left: 18px;
    padding-left: 18px;
    position: relative;
    line-height: 1.4;
    color: $color-primary;
    @include sm {
      margin-left: 14px;
      padding-left: 14px;
      max-width: 104px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @include lgtomd {
      margin-left: 14px;
      padding-left: 14px;
      max-width: 370px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::before {
      content: "";
      width: 2px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: rgba($border-color, 0.5);
      @include sm {
        width: 1px;
        height: 20px;
      }
    }
  }
}

.login-link {
  .profile-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $bg-white;
    @include md {
      width: 100%;
      padding-left: 15px;
    }
    .nav-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 5px;
    }
    .dropdown-menu {
      left: unset;
      right: 0;
      @include md {
        width: 100%;
      }
    }
  }
}

/*========= Begin: Navbar for Login, Forgot Password and Link Sent Successfully page ==========*/
.login-navbar {
  &.navbar-nav {
    background: $bg-white;
    @include md {
      height: 100%;
    }
    .become-reps {
      margin-left: 1.125rem;
      text-transform: none;
      @include md {
        margin: 15px 15px 0;
      }
    }
    .nav-link {
      align-self: center;
      padding: 0 1rem;
      margin: 0 0.75rem;
      font-size: 1rem;
      font-weight: $font-medium;
      color: $color-body;
      position: relative;
      // &:last-child{
      //     &:after{
      //         display: none;
      //     }
      // }
      &:after {
        content: "";
        position: absolute;
        left: 16px;
        bottom: -5px;
        width: 0%;
        height: 2px;
        background: $color-primary;
        transform-origin: left;
        transition: all 0.2s ease;
        @include md {
          width: auto;
          left: 15px;
          right: 15px;
          bottom: 0;
        }
      }
      &.active,
      &:hover,
      &:focus {
        &:after {
          width: 20%;
          transform-origin: left;
          transition: all 0.2s ease;
          @include md {
            width: auto;
            left: 15px;
            right: 15px;
            bottom: 0;
          }
        }
      }
      @include md {
        padding: 10px 15px;
        margin: 0;
        width: 100%;
      }
    }
  }
}
/*========= End: Navbar for Login, Forgot Password and Link Sent Successfully page ==========*/

/*========= Begin: Navbar for after user loged in ==========*/
.after-login-navbar {
  &.navbar-nav {
    padding: 5px 0 5px 20px;
    margin-left: 20px;
    margin-right: auto;
    border-left: 1px solid $color-secondary;
    background: $bg-white;
    @include md {
      padding: 0;
      margin: 0;
      border: 0;
    }
    .nav-link {
      padding: 0 20px;
      font-size: 1rem;
      font-weight: $font-medium;
      color: $color-body;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 20px;
        bottom: -5px;
        width: 0%;
        height: 2px;
        background: $color-primary;
        transform-origin: left;
        transition: all 0.2s ease;
        @include md {
          width: auto;
          left: 15px;
          right: 15px;
          bottom: 0;
        }
      }
      &.active,
      &:hover {
        &:after {
          width: 20%;
          transform-origin: left;
          transition: all 0.2s ease;
          @include md {
            width: auto;
            left: 15px;
            right: 15px;
            bottom: 0;
          }
        }
      }
      @include md {
        padding: 10px 15px;
        margin: 0;
      }
    }
  }
}
/*========= End: Navbar for after user loged in ==========*/
/*========= End: Header ==========*/

/*=============== Begin: Footer ===============*/
footer {
  //padding-top: 165px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100px;
    right: -100px;
    height: 100%;
    opacity: 0.5;
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(131, 156, 159, 0.08) 100%
    );
    border-radius: 50% 50% 0 0;
    z-index: -1;
  }
  @include md {
    padding-top: 65px;
    &::before {
      display: none;
    }
  }
  @include sm {
    padding-top: 30px;
  }
}
/*===== Begin: Footer Block =====*/
.footer-block {
  padding-bottom: 99px;
  @include md {
    padding-bottom: 1.875rem;
  }
  .front-title-block {
    .text-link-gray {
      &:after {
        width: 100%;
        top: unset;
        bottom: 2px;
        border-bottom: 1px solid $color-body;
      }
    }
    @include md {
      margin-bottom: 1.5rem;
    }
  }
}
.footer-nav {
  .footer-link {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      font-weight: $font-medium;
      line-height: 1.25rem;
    }
  }
  @include sm {
    margin-bottom: 1rem;
  }
}

.footer-nav-title {
  font-size: 1rem;
  color: $color-text-default;
  font-weight: $font-medium;
  letter-spacing: 0;
  text-transform: capitalize;
  margin-bottom: 2.5rem;
  @include sm {
    margin-bottom: 1rem;
  }
}
/*===== End: Footer Block =====*/

/*===== Begin: Copy Right =====*/
.copyright-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 0 3.688rem;
  flex-wrap: wrap;
  border-top: 1px solid rgba(131, 156, 159, 0.1);
  @include sm {
    flex-direction: column;
    padding: 1rem 0;
    .footer-logo {
      img {
        width: 75px;
        height: auto;
      }
    }
    .copyright {
      margin: 1rem 0;
    }
  }
}
.disclaimer-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3.688rem;
  padding-top: 20px;
  flex-wrap: wrap;
  border-top: 1px solid rgba(131, 156, 159, 0.1);
  @include sm {
    flex-direction: column;
    padding: 10px 0;
    .footer-logo {
      img {
        width: 75px;
        height: auto;
      }
    }
    .disclaimer {
      margin: 1rem 0;
      @include sm {
        margin: 5px 0;
      }
    }
  }
}
.social-links {
  li {
    display: inline-block;
    margin-left: 1.25rem;
    &:first-child {
      margin-left: 0;
    }
    a {
      svg {
        g {
          fill: #839c9f;
        }
      }
      &:hover {
        svg {
          g {
            fill: $color-primary;
            opacity: 1;
          }
        }
      }
    }
  }
}
/*===== End: Copy Right =====*/
/*=============== End: Footer ===============*/

/* ======== cms pages ========*/
/*===== Begin: Common Title Block =====*/
.front-title-block {
  .subtitle {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: $font-black;
    letter-spacing: 5.83px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @include md {
      margin-bottom: 0.7rem;
    }
  }
  .title {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: $font-bold;
    margin-bottom: 1.2rem;
    color: $color-title;
    @include md {
      font-size: 1.75rem;
      line-height: 2rem;
      margin-bottom: 1rem;
    }
  }
  .common-para {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: $font-regular;
    padding: 0;
    @include md {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .btn {
    margin-top: 2.1rem;
    @include md {
      margin-top: 1.6rem;
    }
    &.btn-lg {
      @include xs {
        padding: 8px 5px;
      }
    }
  }
}
/*===== End: Common Title Block =====*/

/*=============== Begin: Landing page ===============*/
/*===== Begin: Hero Section =====*/
.hero-section {
  padding-top: 160px;
  padding-bottom: 80px;
  overflow-x: hidden;
  @media only screen and (max-width: 1499px) {
    padding-top: 130px;
    padding-bottom: 100px;
  }
  @include md {
    padding-top: 110px;
    padding-bottom: 60px;
  }
  @include sm {
    padding-bottom: 30px;
  }
  .container {
    overflow: unset;
  }
  .front-title-block {
    padding-top: 60px;
    @include lg {
      padding-top: 30px;
    }
    @include sm {
      padding-top: 0;
    }
    .btn {
      @include lgtomd {
        padding: 12px 22px;
        font-size: 15px;
      }
    }
  }
  .hero-image {
    margin: -36px -323px 0 -140px;
    position: relative;
    z-index: -1;
    @media only screen and (max-width: 1749px) {
      margin: -36px -298px 0 -165px;
    }
    @media only screen and (max-width: 1499px) {
      margin: -16px -190px 0 -165px;
    }
    @include lg {
      margin: -36px -150px 0 -130px;
    }
    @include md {
      margin: -16px -120px 0 -90px;
    }
    @include sm {
      margin: 0 -75px 0 -75px;
    }
  }
}
.how-work {
  overflow-x: hidden;
}
/*===== End: Hero Section =====*/

/*===== Begin: Become Member =====*/
.become-member {
  position: relative;
  margin: 160px 0 2px 0;
  @include md {
    margin: 120px 0 2px 0;
  }
  @include sm {
    margin: 110px 0 2px 0;
  }
  .container {
    overflow: unset;
  }
  .row {
    > div {
      position: inherit;
      @include sm {
        &:first-child {
          order: 2;
        }
      }
    }
  }
  .become-member-img {
    position: absolute;
    left: 0;
    top: 0;
    img {
      @media only screen and (max-width: 1499px) {
        max-height: 680px;
      }
      @include lg {
        max-height: 520px;
      }
      @include md {
        max-height: 350px;
      }
    }
    @include sm {
      position: relative;
      img {
        max-height: none;
        margin-left: -15px;
      }
    }
  }
  .become-member-wrapper {
    min-height: 848px;
    padding-top: 130px;
    padding-bottom: 40px;
    @media only screen and (max-width: 1499px) {
      min-height: 680px;
      padding-top: 100px;
    }
    @include lg {
      min-height: 520px;
      padding-top: 60px;
    }
    @include md {
      min-height: 350px;
      padding-top: 40px;
    }
    @include sm {
      padding: 0 0 70px 0;
      min-height: 0;
    }
    .front-title-block {
      max-width: 490px;
      @include lg {
        max-width: 390px;
      }
      @include sm {
        max-width: 300px;
      }
    }
  }
}
/*===== End: Become Member =====*/

/*===== Begin: About Us =====*/
.about-us {
  margin-bottom: 100px;
  .container {
    overflow: unset;
  }
  @include lg {
    margin-bottom: 80px;
  }
  @include md {
    margin-bottom: 50px;
  }
  @include sm {
    margin-bottom: 20px;
  }
  .about-us-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    .title {
      span {
        display: block;
      }
    }
  }
  &.client-about {
    @include smUp {
      .row {
        > div {
          &:first-child {
            order: 2;
          }
        }
      }
    }
    .about-us-img {
      float: none;
      &:after {
        right: 30px;
        @include md {
          right: 20px;
        }
        @include sm {
          right: 12px;
        }
      }
    }
  }
}
.about-us-img {
  // max-width: 470px;
  // margin: 0 auto;
  float: right;
  border-radius: 12px;
  @include lg {
    // max-width: 380px;
  }
  @include sm {
    float: none;
    max-width: 50%;
  }
  @include xs {
    float: none;
    max-width: 80%;
  }
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    right: -30px;
    bottom: -30px;
    border-radius: 16px;
    background: rgba($color-primary, 0.08);
    @include lg {
      right: -24px;
      bottom: -24px;
    }
    @include md {
      right: -20px;
      bottom: -20px;
    }
    @include sm {
      right: -12px;
      bottom: -12px;
    }
  }
  &.shadow-left {
    &:after {
      left: -30px;
      right: auto;
      @include lg {
        left: -24px;
      }
      @include md {
        left: -20px;
      }
      @include sm {
        left: -12px;
      }
    }
  }
  &.no-shadow {
    &:after {
      display: none;
    }
  }
  @include sm {
    max-height: unset;
    margin: 30px auto 40px;
  }
  img {
    object-fit: contain;
    object-position: center;
    border-radius: 12px;
    position: relative;
    z-index: 1;
  }
}
/*===== End: About Us =====*/

/*===== Begin: Testimonials =====*/
.testimonials {
  margin: 130px 0;
  @include lg {
    margin: 60px 0 80px 0;
  }
  @include md {
    margin: 50px 0 30px 0;
  }
  @include sm {
    margin: 10px 0;
  }
  .testi-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .title {
      span {
        display: block;
      }
    }
  }
}
.testimonial-block {
  padding: 40px 16px;
  box-shadow: 0 2px 0 0 rgba(131, 156, 159, 0.2);
  margin-bottom: 15px;
  transition: all 0.3s ease;
  @include md {
    padding-left: 0;
    padding-right: 0;
  }
  @include xlUp {
    &:hover {
      box-shadow: -9px 12px 48px 1px rgba(131, 156, 159, 0.1);
    }
  }
  .testi-stars {
    margin-bottom: 1.688rem;
    span {
      color: $testmonial-star;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .testi-text {
    font-size: 1.25rem;
    color: $color-body;
    line-height: 2.125rem;
    font-weight: $font-light;
    font-style: italic;
    margin-bottom: 1.5rem;
    p {
      padding-bottom: 0;
    }
  }
}
.testi-profile {
  display: flex;
  align-items: center;
  .testi-profile-img {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1.5rem;
    img {
      object-fit: cover;
      object-position: center;
    }
  }
  .testi-profile-details {
    max-width: calc(100% - 108px);
    p {
      padding-bottom: 0;
    }
  }
}
/*===== End: Testimonials =====*/
/*=============== End: Landing page ===============*/

/* ======== End cms pages ========*/

/*========= Steps ==========*/
.title-header {
  margin-bottom: 2rem;
  text-align: center;
  .page-title {
    &.assign-header-title {
      font-size: 1.9rem;
      @include md {
        font-size: 1.6rem;
      }
    }
    margin-bottom: 8px;
    color: $color-text-title;
  }
  h4 {
    margin-bottom: 0;
    .text-primary {
      font-weight: $font-medium;
    }
  }
}
.sub-title {
  font-weight: $font-medium;
  color: $color-text-default;
  font-size: 1.25rem;
}
.footer-buttons {
  text-align: center;
  max-width: 120px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  .btn {
    display: block;
    margin: 0 auto;
    margin-bottom: 0;
    order: 2;
    &:last-child {
      order: 1;
      margin-bottom: 2rem;
    }
  }
}
.steps {
  .boxmodal-radio,
  .boxmodal-checkbox {
    input[type="checkbox"],
    input[type="radio"] {
      + .card-inner {
        transition: all 0.3s ease-in-out;
        transform: scale(1);
        // border: 1px solid transparent;
      }
    }
    &:hover {
      input[type="checkbox"],
      input[type="radio"] {
        + .card-inner {
          // border: 1px solid $color-primary;
          border: 1px solid $color-hover;
          // background: $color-hover;
          background: transparent;
          transform: scale(1.03);
        }
      }
    }
    // &:checked + .card-inner {
    //   input[type=checkbox],
    //   input[type=radio] {
    //     + .card-inner {
    //       border: 1px solid red;
    //     }
    //   }
    // }
  }
}

/*========= Begin: Login Page ==========*/
.login-wrapper {
  max-width: 460px;
  margin: 0 auto;
}
.forgot-part {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.not-member {
  margin: 40px 0;
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $font-regular;
    text-align: center;
    a {
      color: $color-primary;
      font-weight: $font-medium;
    }
  }
}
/*========= End: Login Page ==========*/

/* ============ Scan details =========== */
.scandetails-wrapper {
  max-width: 470px;
  margin: 0 auto;
  h3 {
    font-weight: $font-medium;
    margin-bottom: 2rem;
  }
  .scan-btn {
    // display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 8px;
    display: inline-block;
    img {
      margin-right: 1.25rem;
    }
  }
  h5 {
    margin-bottom: 1.5rem;
  }
}
.or-part {
  position: relative;
  margin-bottom: 2rem;
  &:after {
    width: 100%;
    height: 1px;
    background: $border-color;
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
  }
  h4 {
    padding: 0 8px;
    background: $color-white;
    position: relative;
    z-index: 3;
    display: inline-block;
    margin-bottom: 0;
  }
}

/*========= Begin: Link Sent Successfully Page ==========*/
.link-sent {
  .successfull-svg {
    text-align: center;
    margin: 25px 0 40px;
  }
  .form-group {
    .btn {
      padding: 10px 28px;
    }
  }
}
.more-info {
  padding-bottom: 60px;
  .react-datepicker-popper[data-placement^="top"] {
    top: 58px !important;
    transform: translate3d(0px, 0, 0px) !important;
    -webkit-transform: translate3d(0px, 0, 0px) !important;
    @include sm {
      top: 50px !important;
    }
    .react-datepicker__triangle {
      top: -8px;
      background: transparent !important;
      border: transparent !important;
      &::before {
        border-width: 8px;
        border-bottom-color: #2ba84a;
        border-top: none;
        transform: rotate(0);
        bottom: -8px;
      }
    }
  }
}
/* =========== End: Link Sent Successfully Page =============== */
/* ====== Begin: OTP Varification Page ========== */
.signup-otp-wrapper {
  margin: 0 auto;
  .form-wrapper {
    // padding: 2.5rem 5.63px;
    .form-text {
      max-width: 590px;
      margin: 0 auto;
      .material-icons {
        color: $color-body;
        vertical-align: middle;
      }
      h3 {
        line-height: 1.3;
      }
    }
    .otp-input-wrapper {
      margin-bottom: 12px;
      & > div {
        justify-content: center;
        flex-wrap: wrap;
        @include xs {
          justify-content: space-around;
        }
      }
    }
  }
}
.signup-otp {
  .otp-input-wrapper {
    > div {
      justify-content: center;
      @include xs {
        justify-content: space-around;
      }
    }
  }
}
.otp-input {
  width: 48px !important;
  height: 48px !important;
  text-align: center;
  font-size: 1rem;
  color: $color-text-default;
  background: $bg-white;
  border: 1px solid $border-color;
  border-radius: 4px;
  margin: 0 12px 12px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include xs {
    width: 40px !important;
    height: 40px !important;
    margin-left: 8px;
    margin-right: 8px;
  }
  @include xxs {
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    margin-left: 0;
  }
}

.verification-modal {
  .modal-dialog {
    max-width: 770px;
    @include md {
      max-width: calc(100% - 30px);
    }
  }
  .signup-otp {
    .form-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      box-shadow: none;
      background: transparent;
      max-width: 570px;
      margin: 0 auto;
    }
  }
}
/* ====== End: OTP Varification Page ========== */

/* ====== Begin: register-steps ========== */
/* ====== Begin: register-steps1 ========== */
.register-steps {
  margin-bottom: 2rem;
  .form-wrapper {
    padding: 1.5rem;
    form {
      &:last-child {
        .register-form-block {
          margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
}
.register-form-block {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);

  h3 {
    font-weight: $font-medium;
  }
  .form-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    &.height-input {
      border-bottom: 1px solid rgba(205, 215, 217, 0.24);
    }
    .form-group {
      max-width: 160px;
      margin: 0 0.5rem 1.5rem;
      @include sm {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      .input-effect {
        position: relative;
        .form-control {
          padding-right: 2.5rem;
          & ~ label {
            color: $color-text-default;
          }
        }
        span {
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
    .fact-finder-third-question-option1 {
      max-width: 208px;
      @include sm {
        max-width: 100%;
      }
    }
    .fact-finder-third-question-option2 {
      max-width: 238px;
      @include sm {
        max-width: 100%;
      }
    }
    .fact-finder-third-question-option3 {
      max-width: 164px;
      @include sm {
        max-width: 100%;
      }
    }
    .fact-finder-third-question-option4 {
      max-width: 200px;
      @include sm {
        max-width: 100%;
      }
    }
  }
  &.weight-block {
    .form-group {
      max-width: 240px;
    }
  }
}
/* ====== End: register-steps1 ========== */
/* ====== Begin: register-steps2 ========== */
.radio-block {
  &.form-block {
    .form-group {
      width: 100%;
      border: 1px solid $border-disabled;
      border-radius: 4px;
      label {
        padding: 12px 18px 12px 50px;
        width: 100%;
        color: $color-text-default;
        span {
          left: 18px;
        }
      }
    }
  }
}
.form-block-questions {
  padding-bottom: 1.5rem;
  .custom-checkbox,
  .custom-radio {
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .questions-wrapper {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin: -0.5rem;
    position: relative;
    .form-group {
      width: 100%;
      max-width: 33.33%;
      margin: 0.5rem 0.5rem 0.5rem;
      &.full {
        max-width: calc(100% - 16px);
      }
    }

    @include sm {
      flex-wrap: wrap;
      .form-group {
        max-width: 100%;
        margin-right: 0;
      }
    }
    .default-select {
      .custom-select__menu-list {
        max-height: 180px;
      }
    }
    .add-remove {
      position: absolute;
      right: -5px;
      top: 2px;
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 30px;
      @include sm {
        width: 22px;
      }
      .btn-link {
        background-color: transparent;
        padding: 0;
        border: none;
        min-width: 0;
        display: block;
        width: 100%;
      }
    }
  }
  &.register-step3 {
    .questions-wrapper {
      flex-wrap: wrap;
      .form-group {
        max-width: calc(50% - 16px);
        @include sm {
          max-width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
/* ====== End: register-steps2 ========== */
/* ====== End: register-steps ========== */

/* =========== getstart-page =========== */
.getstart-page {
  .card {
    margin-bottom: 2rem;
    height: calc(100% - 2rem);
  }
  .card-inner {
    display: flex;
    flex-direction: column;
    .card-body {
      display: flex;
      flex-direction: column;
      h2.page-title {
        max-height: 50px;
        height: 100%;
        line-height: 1.6;
        font-size: 24px;
        margin-bottom: 30px;
        @include xlUptoDesktopLarge {
          font-size: 20px;
        }
        @include xlUptoDesktopSmall {
          font-size: 20px;
        }
        @include lg {
          font-size: 18px;
        }
      }
      .notes {
        margin-top: auto;
        height: 100%;
        max-height: 75px;
        @include lgtomd {
          height: 100%;
          min-height: 75px;
          font-size: 18px;
        }
      }
      .cart-desc {
        min-height: 170px;
        margin-bottom: 20px;
      }
    }
  }
}

/* ========= Approved page ========== */

/* === guardian-outer === */
.guardian-outer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  @include xs {
    justify-content: center;
  }
  .guardian-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 10px;
    @include md {
      width: 100%;
      padding-right: 0;
      margin-bottom: 1rem;
    }
    @include xs {
      justify-content: center;
    }
    .guardian-img {
      width: 48px;
      height: 48px;
      margin-right: 1rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .guardian-name {
      // width: calc(100% - 64px);
      h2 {
        margin-bottom: 0;
      }
    }
  }
  .guardian-info {
    @include xs {
      width: 100%;
      text-align: center;
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -1.25rem;
      @include xs {
        margin: -0.5rem;
        justify-content: center;
      }
      li {
        margin: 0 1.25rem;
        @include sm {
          margin: 0.5rem;
        }
        @include xxs {
          width: 100%;
          text-align: center;
        }
        h3 {
          font-weight: $font-medium;
          margin-bottom: 4px;
          text-align: center;
          span {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }
        span {
          font-size: 14px;
          line-height: 20px;
          display: block;
        }
      }
    }
  }
  .edit-icon {
    display: flex;
    align-items: center;
    @include sm {
      margin-left: auto;
    }
    a {
      color: $color-body;
      &:hover {
        color: $color-primary;
      }
    }
  }
}

/* ==== policy-outer ==== */
.policy-outer {
  .policy-name {
    display: flex;
    flex-wrap: wrap;
    @include xs {
      justify-content: center;
      text-align: center;
    }
    h3 {
      font-weight: $font-regular;
      font-size: 1.2rem;
      margin-right: 8px;
      @include sm {
        margin-right: 0;
      }
    }
    .chips-outer {
      @include sm {
        width: calc(100% - 8px);
        margin-bottom: 0.5rem;
      }
    }
  }
  h4 {
    height: 48px;
    @include xs {
      text-align: center;
    }
  }
}
/* ==== policy-holder-outer ==== */
.policy-holder-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .policy-holder-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include sm {
      width: 100%;
      margin-bottom: 1rem;
    }
    .policy-holder-img {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }
    .policy-holder-name {
      h3 {
        margin-bottom: 0;
        font-weight: $font-medium;
        text-transform: capitalize;
      }
    }
  }
  .policy-holder-info {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -1.25rem;
      @include sm {
        margin: -0.5rem;
      }
      @include xxs {
        margin: 0;
      }
      li {
        margin: 0 1.25rem;
        @include sm {
          margin: 0.5rem;
        }
        @include xxs {
          width: 100%;
          margin: 0 0 0.5rem;
        }
        h4 {
          font-weight: $font-medium;
          font-size: 14px;
          margin-bottom: 0;
          span {
            font-size: 1rem;
            margin-left: 8px;
            color: $color-text-default;
          }
        }
      }
    }
  }
}
/* ==== policy-amount-outer ==== */

.payment-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include xs {
    justify-content: center;
  }
  .payment-amount {
    padding-right: 0.5rem;
    @include sm {
      width: 100%;
      padding-right: 0;
      margin-bottom: 1rem;
    }
    @include xs {
      text-align: center;
    }
    h3 {
      font-weight: $font-medium;
      margin-bottom: 0;
      span {
        color: $color-primary;
      }
    }
    p {
      font-size: 14px;
      .custom-checkbox {
        margin-right: 0.5rem;
        label {
          text-transform: inherit;
        }
      }
      .text-link {
        font-weight: $font-regular;
        margin-left: 0;
      }
    }
  }
}

/* === agent-outer === */
.agent-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .agent-details {
    display: flex;
    align-items: center;
    width: 100%;
    .agent-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .agent-help {
      width: calc(100% - 64px);
      h4 {
        font-weight: $font-medium;
        color: $color-text-default;
        margin-bottom: 0;
      }
      h5 {
        margin-bottom: 0;
      }
    }
  }
  &.agent-shortchat {
    margin: 1.3rem 0 1.3rem 0;
    width: 100%;
    .agent-help {
      width: calc(100% - 70px);
      h5 {
        line-height: 1.4;
      }
    }
    @include md {
      .ml-auto {
        margin: auto;
      }
    }
  }
  .chat-icons {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
    &.ml-auto {
      @include md {
        margin: auto;
      }
    }
    svg {
      width: 26px;
      height: 30px;
      fill: $color-primary;
      @include lg {
        width: 26px;
        height: 28px;
      }
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include md {
        flex-wrap: wrap;
        justify-content: center;
      }
      li {
        display: inline-block;
        width: 100%;
        @include md {
          width: auto;
        }
        @include sm {
          // margin-left: 0;
          width: 100%;
        }
        + li {
          margin-left: 12px;
          @include lg {
            margin-left: 10px;
          }
          @include sm {
            margin-left: 0;
          }
        }
        &.email-icon {
          svg {
            width: 30px;
            height: 30px;
            @include lg {
              width: 28px;
              height: 28px;
            }
          }
        }
        .btn-with-icon {
          width: 100%;
          @include md {
            margin-bottom: 15px;
          }
          svg {
            margin-right: 15px;
            fill: $color-text-default;
            stroke: $color-text-default;
            #phone {
              stroke: $color-text-default;
            }
            #message-square {
              stroke: $color-text-default;
            }
          }
          &:not(:disabled):not(.disabled).active,
          .show > &.dropdown-toggle,
          &:hover {
            background-color: $color-text-default;
            color: #fff;

            svg {
              margin-right: 15px;
              fill: #fff;
              stroke: #fff;
              #phone {
                stroke: #fff;
              }
              #message-square {
                stroke: #fff;
              }
            }
          }
          &:focus,
          &:active,
          &.focusOnChatICon {
            background-color: $color-text-default;
            color: #fff;

            svg {
              margin-right: 15px;
              fill: #fff;
              stroke: #fff;
              #phone {
                stroke: #fff;
              }
              #message-square {
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }
}

/* === help-outer === */
.help-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .help-label {
    h4 {
      font-weight: $font-medium;
      color: $color-text-default;
      margin-bottom: 0;
      @include md {
        margin-bottom: 1rem;
      }
    }
  }
  .help-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .phone-number {
      margin-right: 0.5rem;
    }
    .material-icons {
      color: $color-primary;
    }
    a {
      color: $color-text-default;
      font-size: 1.25rem;
      font-weight: $font-medium;
      line-height: 1.5rem;
    }
  }
}
.terms-condition {
  ul {
    list-style: disc;
    padding-left: 1rem;
    li {
      list-style: outside;
      float: left;
      width: 100%;
      padding-right: 1.2rem;
      @include sm {
        width: 100%;
      }
      span {
        font-size: 12px;
      }
    }
  }
}

/* ===== payment-method ====== */
.payment-method {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid $border-color;
  -webkit-animation: height-fade-in 0.3s ease 1;
  -moz-animation: height-fade-in 0.3s ease 1;
  -ms-animation: height-fade-in 0.3s ease 1;
  -o-animation: height-fade-in 0.3s ease 1;
  animation: height-fade-in 0.3s ease 1;
  h4 {
    color: $color-text-default;
    margin-bottom: 1.5rem;
    font-weight: $font-medium;
  }
  .payment-mode {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    .custom-radio {
      margin-right: 86px;
      &:last-child {
        margin-right: 0;
      }
      @include xxs {
        margin-right: 1rem;
      }
    }
  }
  .payment-option {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -4px;
    .boxmodal-radio,
    .boxmodal-checkbox {
      height: auto;
      width: calc(12.5% - 8px);
      margin: 4px;
      @include sm {
        width: calc(25% - 8px);
      }
      @include xs {
        width: calc(33.33% - 8px);
      }
      @include xxs {
        width: calc(50% - 8px);
      }
      input[type="checkbox"] {
        + .card-inner {
          background: transparent;
          box-shadow: none;
          border: 1px solid $border-color;
          border-radius: 4px;
          padding: 8px;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:checked + .card-inner {
          background: $color-hover;
          border: 1px solid $color-primary;
        }
      }
      input[type="radio"] {
        + .card-inner {
          background: transparent;
          box-shadow: none;
          border: 1px solid $border-color;
          border-radius: 4px;
          padding: 8px;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:checked + .card-inner {
          background: $color-hover;
          border: 1px solid $color-primary;
        }
      }
    }
  }
  .proceed-btn {
    margin-top: 1.5rem;
  }
  .bank-detail-box {
    margin: -8px;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    -webkit-animation: height-fade-in 0.3s ease 1;
    -moz-animation: height-fade-in 0.3s ease 1;
    -ms-animation: height-fade-in 0.3s ease 1;
    -o-animation: height-fade-in 0.3s ease 1;
    animation: height-fade-in 0.3s ease 1;
    .input-effect {
      width: calc(33.33% - 16px);
      margin: 8px;
      @include sm {
        width: calc(50% - 16px);
      }
      @include xs {
        width: calc(100% - 16px);
      }
    }
  }
}

/* === verification-info ======= */
.verification-outer {
  .added-hospital {
    .btn-upload {
      min-width: 140px;
    }
  }
}

.verification-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include xs {
    justify-content: center;
  }
  .verification-details {
    @include md {
      margin-bottom: 1rem;
      width: 100%;
    }
    @include xs {
      text-align: center;
    }
    h3 {
      margin-bottom: 2px;
      font-weight: $font-medium;
    }
    h5 {
      margin-bottom: 0;
      .text-primary {
        font-weight: $font-medium;
      }
    }
  }
  small {
    font-size: 12px;
  }
  .uploade-document {
    width: 200px;
  }
  .uploade-document-outer {
    // position: relative;
    // width: 200px;
    // border: 1px solid $border-color;
    @include xs {
      width: 100%;
      margin: 0 auto !important;
    }
  }
}
.uploade-document {
  position: relative;
  z-index: 1;
  width: 100%;
  @include xs {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  input[type="file"] {
    width: 100%;
  }
  label {
    // height: 100%;
    width: 100%;
    line-height: 1.429rem;
    border: 1px solid #dbe2de;
    font-size: 13px;
    padding: 10px 18px;
    height: 48px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    @include sm {
      height: 40px;
    }
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
    background: $color-white;
    min-width: 150px;
  }
  &.large-file {
    label,
    input[type="file"] {
      height: 100px;
    }
  }
}
.added-bank {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include xs {
    width: 100%;
    justify-content: center;
  }
  .account-number {
    padding: 10px 16px;
    border: 1px solid $border-color;
    border-radius: 4px;
    margin-right: 1rem;
    min-width: 277px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-text-default;
    font-weight: $font-medium;
    @include xs {
      min-width: 100%;
      margin-bottom: 1rem;
    }
  }
  .btn {
    min-width: 57px;
  }
}

.added-hospital {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include xs {
    width: 100%;
    justify-content: center;
  }
  .hospital-info {
    padding: 10px 16px;
    border: 1px solid $border-color;
    border-radius: 4px;
    margin-right: 1rem;
    min-width: 277px;
    display: flex;
    align-items: center;
    color: $color-text-default;
    font-weight: $font-medium;
    @include xs {
      min-width: 100%;
      margin-bottom: 1rem;
    }
    .hospital-name {
      width: calc(100% - 44px);
    }
  }

  .pdf-info {
    padding: 10px 16px;
    border: 1px solid $border-color;
    border-radius: 4px;
    margin-right: 1rem;
    min-width: 277px;
    display: flex;
    align-items: center;
    color: $color-text-default;
    font-weight: $font-medium;
    @include xs {
      min-width: 100%;
      margin-bottom: 1rem;
    }
    .material-icons {
      color: $color-primary;
      margin-right: 0.5rem;
      width: 24px;
    }
    .svg-icon {
      width: 24px;
      margin-right: 0.5rem;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .pdf-name {
      width: calc(100% - 44px);
    }
  }
  .hospital-logo {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .btn {
    min-width: 57px;
  }
}
// Approval Process Uploaded documents
.documents-smaller {
  .pdf-info {
    min-width: 0;
    margin: 0;
    width: calc(100% - 55px);
    @include sm {
      width: calc(100% - 42px);
    }
  }
  .plan-icons-outer {
    .plan-icons {
      @include sm {
        width: 38px;
        height: 38px;
      }
    }
  }
  .added-hospital {
    margin-top: 1rem;
  }
  .more-documents {
    margin-top: 10px;
    a {
      color: $color-primary;
    }
  }
}

/* ====== Medical verifiaction steps ========= */
.modal-part {
  .title-header {
    margin-bottom: 1.5rem;
    h3 {
      margin-bottom: 0.5rem;
      font-weight: $font-medium;
    }
  }
  h3 {
    margin-bottom: 12px;
  }
  .boxmodal-radio,
  .boxmodal-checkbox {
    input[type="checkbox"] {
      + .card-inner {
        h2 {
          margin-bottom: 1rem;
        }
        .card-text {
          font-size: 1rem;
          &.text-grey {
            color: $color-body;
          }
        }
        .card-btn {
          margin-bottom: 24px;
        }
      }
    }
    input[type="radio"] {
      + .card-inner {
        h2 {
          margin-bottom: 1rem;
        }
        .card-text {
          font-size: 1rem;
          &.text-grey {
            color: $color-body;
          }
        }
        .card-btn {
          margin-bottom: 24px;
        }
      }
    }
    &:hover {
      input[type="checkbox"],
      input[type="radio"] {
        + .card-inner {
          border: 1px solid $color-hover;
          background: transparent;
          transform: scale(1.03);
        }
      }
    }
  }
  .powered-by {
    padding-bottom: 0;
    font-size: 14px;
    font-weight: $font-regular;
    color: $border-color;
    &.position-top {
      position: absolute;
      top: 26px;
      right: 1.5rem;
      @include xxs {
        top: 45px;
      }
    }
  }
  &.option-selection {
    .boxmodal-radio,
    .boxmodal-checkbox {
      input[type="radio"] {
        + .card-inner {
          padding: 36px 16px;
          h2 {
            font-size: 1.6rem;
            line-height: 2rem;
            margin-bottom: 0.5rem;
          }
          .card-text {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.hospital-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -8px;
  margin-bottom: 1.5rem;
  .boxmodal-radio,
  .boxmodal-checkbox {
    width: calc(20% - 16px);
    margin: 8px;
    height: auto;
    @include sm {
      width: calc(33.33% - 16px);
    }
    @include xxs {
      width: calc(50% - 16px);
    }
    input[type="checkbox"] {
      + .card-inner {
        padding: 1rem;
        border-radius: 4px;
      }
    }
    input[type="radio"] {
      + .card-inner {
        padding: 1rem;
        border-radius: 4px;
      }
    }
  }
  .hospital-logo {
    width: 48px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 12px;
    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
  }
  h4 {
    margin-bottom: 0;
    color: $color-text-default;
  }
}

/* =======  search result ======== */
.search-result {
  margin-bottom: 1.5rem;
  .boxmodal-radio,
  .boxmodal-checkbox {
    margin: 0;
    height: auto;
    text-align: left;
    input[type="checkbox"] {
      + .search-result-list {
        display: flex;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 1rem;
        border: 1px solid $border-color;
        border-radius: 4px;
        margin-bottom: 0.5rem;
        .hospital-logo {
          width: 48px;
          height: 48px;
          padding: 5px;
          margin-right: 16px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .hospital-details {
          width: calc(100% - 64px);
          h4 {
            margin-bottom: 0;
            color: $color-text-default;
          }
          h5 {
            margin-bottom: 0;
          }
        }
      }
      &:checked + .search-result-list {
        background: $color-hover;
      }
    }
    input[type="radio"] {
      + .search-result-list {
        display: flex;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 1rem;
        border: 1px solid $border-color;
        border-radius: 4px;
        margin-bottom: 0.5rem;
        .hospital-logo {
          width: 48px;
          height: 48px;
          padding: 5px;
          margin-right: 16px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .hospital-details {
          width: calc(100% - 64px);
          h4 {
            margin-bottom: 0;
            color: $color-text-default;
          }
          h5 {
            margin-bottom: 0;
          }
        }
      }
      &:checked + .search-result-list {
        background: $color-hover;
        border-color: $color-primary;
      }
    }
  }
}

/* ====== hospital-portal ======== */

.hospital-portal {
  .hospital-logo {
    width: 48px;
    height: 48px;
    padding: 5px;
    margin: 0 auto;
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h4 {
    margin-bottom: 0;
    &.hospital-name {
      margin-bottom: 1rem;
      color: $color-text-default;
    }
  }
  .login-info {
    font-size: 12px;
    padding-bottom: 0;
    margin-bottom: 1rem;
  }
}

/* =========  End approved flow =========== */

/* ====== Begin: Plan List ========== */
.filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  -webkit-animation: height-fade-in 0.3s ease 1;
  -moz-animation: height-fade-in 0.3s ease 1;
  -ms-animation: height-fade-in 0.3s ease 1;
  -o-animation: height-fade-in 0.3s ease 1;
  animation: height-fade-in 0.3s ease 1;
  position: relative;
  // z-index: 3;
  @include md {
    margin-bottom: 1.4rem;
  }
  @include xs {
    padding: 0;
    // padding-top: 50px;
  }
  // &.filter-block2 {
  //   z-index: 4;
  // }
  .filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .filter-item {
      display: flex;
      justify-content: flex-start;
      align-items: start;
      flex-wrap: wrap;
      margin-right: 6px;
      margin-bottom: 1rem;
      flex-direction: column;
      @include xxs {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .input-effect,
      .custom-select__control {
        border: 1px solid $color-primary;
        border-radius: 0px;
        // overflow: hidden;
        font-size: 0.9rem;
      }
      .filter-item-lable {
        font-size: 0.9rem;
        font-weight: $font-regular;
        color: $color-text-default;
        @include xxs {
          width: 100%;
          margin-bottom: 0.625rem;
        }
      }
      // .input-group{
      //   max-width: 130px;
      // }
      .default-select {
        min-width: 120px;
        margin-left: 0;
        max-width: 160px;
        &.term-lenth {
          min-width: 120px;

          @include xs {
            width: 100%;
            max-width: 100%;
          }
        }
        .custom-select-menu {
          width: 100%;
          .custom-select__control {
            box-shadow: none !important;
          }
        }
        @include xxs {
          min-width: 100%;
          margin-left: 0;
          margin-bottom: 0.625rem;
        }
      }
      @include xxs {
        width: 100%;
        margin-left: 0;
        padding-right: 0;
      }
      .input-effect {
        @include xxs {
          width: 100%;
          margin-left: 0 !important;
        }
      }
      .input-group {
        @include xxs {
          max-width: 100%;
        }
      }
    }
    @include xxs {
      width: 100%;
    }
  }
  &:first-child {
    z-index: 3;
  }
}

.filter-btn-outer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  justify-content: center !important;
}

.filter-btn {
  &.btn {
    &.btn-secondary {
      font-size: 1rem;
      color: $color-body;
      font-weight: $font-medium;
      line-height: 0.8rem;
      background: none;
      padding: 0;
      border: 0;
      border-radius: 0;
      min-width: auto;
      border-bottom: 1px solid $color-body;
      &:hover {
        &:not(:disabled),
        &:not(.disabled) {
          background: none;
          color: $color-body;
        }
      }
    }
  }
}

.plan-block {
  h2 {
    color: $color-text-default;
  }
  .page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(205, 215, 217, 0.24);

    .page-title-logo {
      width: 200px;
      height: 125px;
      // border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .boxmodal-radio {
    input[type="radio"] {
      &:checked + .card-inner {
        background: $color-hover;
        border: 1px solid black;
      }
      & + .card-inner {
        .card-text {
          font-size: 1rem;
          font-weight: $font-regular;
          margin: 1.5rem 0 1rem;
          min-height: auto;
        }
      }
    }
  }
}
.card-value-list-min-height {
  min-height: 210px;
  h3 {
    font-size: 16px;
  }
  @include md {
    min-height: inherit;
    h3 {
      font-size: 14px;
    }
  }
  @include Desktop {
    min-height: 210px;
  }
}
.card-value-list {
  display: inline-block;
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
  .value-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;
    height: 25px;
    &:last-child {
      margin: 0;
    }

    .list-label {
      display: inline-block;
      align-items: center;
      line-height: 1.5rem;
      font-weight: $font-regular;
      margin-right: 1rem;
      text-align: left;
      // white-space: nowrap;
      .material-icons {
        font-size: 17px;
        color: #605656;
        margin-left: 5px;
        margin-top: 5px;
      }

      .list-info {
        margin-left: 0.5rem;
        position: relative;
        z-index: 1;
        cursor: pointer;
        &:hover {
          svg {
            g {
              fill: $info-svg-hover;
            }
          }
        }
      }
    }

    h3 {
      font-weight: $font-medium;
      margin: 0;
    }
    h4 {
      font-weight: $font-regular;
      margin: 0;
      color: $color-text-default;
    }
    .btn-link {
      padding: 0;
      min-width: auto;
      color: $color-body;
    }
  }
}

.plan-modals-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  .btn {
    max-width: 130px;
    margin: 0.5rem;
    white-space: pre-wrap;
    @include mdtolg {
      max-width: inherit;
    }
  }
  @include sm {
    justify-content: center;
    .btn {
      max-width: 100%;
    }
  }
}

.plan-modal {
  &.modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding-top: 40px;
          overflow-x: hidden;
          min-height: 210px;
          @include sm {
            padding-top: 30px;
            min-height: 200px;
          }
          .chart-outer {
            @include md {
              overflow-x: auto;
              min-height: 340px;
            }
          }
          .risk-return {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .default-select {
              width: 100%;
              max-width: 270px;
              margin-bottom: 1rem;
            }
            margin-bottom: 0.5rem;
          }
          .recharts-wrapper {
            position: relative;
            padding-top: 2.5rem;
            margin-bottom: 2rem;
            // overflow: hidden;
            // @include md {
            //   margin-top: 15px;
            // }
            .chart-year {
              position: absolute;
              bottom: 20px;
              left: 0;
            }
            .recharts-legend-wrapper {
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              width: auto !important;
              top: 0;
              left: unset !important;
              right: 5px;
              bottom: unset !important;
              .recharts-legend-item {
                &:last-child {
                  margin-right: 0 !important;
                }
              }
              @include md {
                display: block;
                // position: static !important;
              }
            }
          }
          .no-plan {
            margin-top: 10px;
          }
        }
      }
    }
    &.analytics-modal {
      .modal-body {
        overflow-x: auto;
      }
    }
  }
  .min-width {
    width: 170px;
    display: inline-block;
    vertical-align: top;
    @include lg {
      width: 150px;
    }
  }
  .term-value {
    display: inline-block;
    color: $color-title;
    vertical-align: top;
  }
  .table {
    &.risk-table {
      @include sm {
        width: 100%;
      }
    }
  }
  .notes {
    margin-top: 12px;
    font-size: 0.85rem;
    line-height: 1.4rem;
  }
}

.plan-btn-block {
  .btn {
    margin: 0.75rem;
  }
}

.comparison-block {
  display: block;
  cursor: default;
  position: relative;
  text-align: center;
  height: calc(95% - 10px);
  .card-inner {
    background: $bg-white;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 40px 24px;
    transition: all 300ms ease-in-out 0s;
    height: calc(100% - 0px);
    margin-bottom: 8px;
    position: relative;
    @include mdtolg {
      padding: 2rem 1rem;
    }
    @include sm {
      height: auto;
    }
    .card-title-fixed {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.75rem;
      line-height: 1rem;
      text-transform: uppercase;
    }
    .card-value-list {
      border-bottom: 0;
    }
  }
}
.compare-bank-acc {
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
  .default-select {
    .custom-select-menu {
      .custom-select__control {
        .custom-select__single-value {
          color: $color-text-default;
        }
        .custom-select__value-container {
          padding-left: 0;
          .custom-select__placeholder {
            font-size: 2rem;
            font-weight: $font-light;
            color: $color-text-default;
          }
        }
      }
    }
  }
  .custom-select__single-value {
    font-size: 2rem;
    line-height: 2.75rem;
    font-weight: $font-light;
    color: $color-text-default;
  }
}
/* ====== End: Plan List ========== */

/* ====== Begin: Chat Screen ========== */
/* ====== Begin: Chat Profile ========== */
.chat-details-wrapper {
  padding: 0 0 1rem;
  text-align: center;
  .chat-img {
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .chat-details {
    h4 {
      color: $color-text-default;
      font-weight: $font-medium;
      text-transform: capitalize;
    }
    .chat-ratings {
      .rating-star {
        font-size: 1.25rem;
        color: $color-primary;
        margin-right: 5px;
      }
    }
  }
}
.certification {
  padding: 1rem 0 0;
  border-top: 1px solid rgba($border-color, 0.24);
  h5 {
    margin: 0 0 0.75rem;
    text-transform: uppercase;
  }
}
.certification-list {
  .certification-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    .certification-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border: 1px solid $color-light;
      border-radius: 50%;
    }
    .certification-details {
      width: calc(100% - 60px);
      h5 {
        margin-bottom: 0;
      }
      .certification-name {
        color: $color-text-default;
        font-weight: $font-medium;
        margin-bottom: 0;
        text-transform: capitalize;
      }
    }
  }
}
/* ====== End: Chat Profile ========== */

/* ====== Begin: Reting and Review ========== */
.rating-review {
  h4 {
    color: $color-text-default;
  }
  .rating-stars-wrapper {
    margin: 0.5rem 0 1rem;
    .rating-star {
      font-size: 1.5rem;
      color: $color-primary;
      font-weight: $font-bold;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
/* ====== End: Reting and Review ========== */

/* ====== Begin: Chat Component Header ========== */
.chat-screen,
.conversation-page {
  .card-outer {
    box-shadow: $box-shadow-light;
  }
}
.chat-component-header {
  .card-outer {
    padding: 0;
    .guardian-outer {
      padding: 1.25rem;
    }
    .policy-outer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 1.25rem;
      border-top: 1px solid rgba($border-color, 0.24);
    }
  }
}
/* ====== End: Chat Component Header ========== */

/* ====== Begin: Chat Box Header ========== */
.chat-box-header {
  padding: 1.25rem;
  border-bottom: 1px solid rgba($border-color, 0.24);
  h3 {
    font-weight: $font-medium;
  }
}
.conversation-header {
  padding: 1rem 1.5rem;
  background: $color-light;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  .conversation-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      vertical-align: inherit;
    }
  }
  h3 {
    font-weight: $font-medium;
    width: calc(100% - 32px);
  }
}
/* ====== End: Chat Box Header ========== */

/* ====== Begin: Chat Area ========== */
.chat-box {
  position: relative;
  height: 410px;
  overflow-y: auto;
  .chat-area {
    padding: 1.25rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include lg {
      height: 390px;

    }
    .chat-msg-item {
      display: flex;
      margin-bottom: 1.25rem;
      @include sm {
        margin-bottom: 3rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .chat-msg {
        display: inline-block;
        max-width: calc(100% - 142px);
        padding: 1rem;
        background: $color-sucess-light;
        border-radius: 12px 12px 12px 0;
        position: relative;
        @include sm {
          width: auto;
          max-width: unset;
          padding: 0.7rem;
          border-radius: 8px 8px 8px 0;
        }
        p {
          font-weight: $font-regular;
          color: $color-text-default;
          padding-bottom: 0;
          word-break: break-word;
        }
        .msg-time {
          position: absolute;
          top: 0;
          right: -60px;
          font-size: 0.75rem;
          line-height: 1.25rem;
          max-width: 55px;
          white-space: nowrap;
          @include sm {
            top: unset;
            left: 0;
            right: auto;
            bottom: -36px;
            min-width: 50px;
            max-width: none;
          }
        }
        &.no-padding {
          padding: 0;
          background-color: transparent;
          .msg-time {
            right: -165px;
          }
        }
      }
      &.sent-msg {
        justify-content: flex-end;
        @include sm {
          margin-bottom: 2.2rem;
        }
        .chat-msg {
          background: $color-light;
          border-radius: 12px 12px 0 12px;
          @include sm {
            border-radius: 8px 8px 0 8px;
          }
        }
        .no-padding {
          .msg-time {
            right: auto;
          }
        }
        .msg-time {
          right: unset;
          left: -60px;
          @include sm {
            top: unset;
            left: auto;
            right: 0;
            bottom: -20px;
          }
        }
      }
      &.chat-date {
        justify-content: center;
        font-size: 0.8rem;
        margin: 0 0 20px 0;
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 0.5px;
          background: rgba($border-color, 0.4);
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }
        .chat-date-span {
          border: solid 1px rgba($border-color, 0.5);
          border-radius: 8px;
          line-height: 1;
          padding: 4px 10px;
          position: relative;
          z-index: 1;
          background-color: $bg-white;
        }
      }
      .chat-image {
        max-width: 100px;
        @include sm {
          max-width: 60px;
        }
        img {
          border-radius: 4px;
        }
        .svg-icon {
          display: flex;
          justify-content: center;
          a {
            color: $color-danger;
            font-size: 2rem;
          }
          svg {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
    .css-1y8mk30-SkeletonTheme {
      @include lg {
        .mb-2 {
          margin-bottom: 22px !important;
        }
      }
      @include xs {
        .react-loading-skeleton {
          height: 36px !important;
          width: 180px !important;
        }
      }
    }
  }
  .scrollbar-container {
    padding-right: 12px;
    .ps__rail-y {
      width: 6px;
    }
    .ps__thumb-y {
      right: 0;
    }
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
      width: 6px;
      right: 0;
    }
  }
  .no-conve {
    text-align: center;
    margin: 20px 0 0 0;
    position: absolute;
    width: 100%;
    @include md {
      position: relative;
    }
  }
}
/* ====== End: Chat Area ========== */

/* ====== Begin: Chat Buttons ========== */
.chat-buttons {
  padding: 1.25rem;
  @include sm {
    padding: 1rem;
  }
  form,
  .chat-button-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .chat-input {
      width: calc(100% - 108px);
      @include sm {
        width: calc(100% - 70px);
      }
    }
    .choose-file {
      position: absolute;
      right: 2px;
      top: 1px;
      .profile-input {
        display: none !important;
      }
      .add-file {
        margin: 0;
        cursor: pointer;
        height: 46px;
        width: 46px;
        text-align: center;
        padding: 9px;
        border-left: 1px solid $border-disabled;
        background-color: rgba($color-light, 0.75);
        @include sm {
          height: 38px;
          width: 38px;
          font-size: 1.5rem;
          padding: 8px;
        }
      }
    }
    input:focus::-webkit-input-placeholder,
    input:focus:-moz-placeholder,
    input:focus::-moz-placeholder,
    input:focus:-ms-input-placeholder {
      color: $color-body;
    }
    .form-control::placeholder {
      opacity: 1;
      @include sm {
        font-size: 0.75rem;
      }
    }
    .btn {
      width: 100px;
      height: 48px;
      border-radius: 4px;
      text-transform: uppercase;
      @include sm {
        width: 65px;
        height: 40px;
        font-size: 0.75rem;
        padding: 0 5px;
      }
    }
  }
  .text-danger {
    font-size: 0.9rem;
  }
}
/* ====== End: Chat Buttons ========== */
/* ====== End: Chat Screen ========== */

/* ====== Begin: Policy Details page ========== */
.policy-detail {
  .guardian-outer {
    padding-bottom: 1.5rem;
    .guardian-details {
      padding-left: 0;
      margin-right: auto;
    }
    .btn-groups {
      @include xxs {
        margin-left: -8px !important;
      }
    }
  }
  .policy-outer {
    padding: 1.5rem 0;
    border-top: 1px solid rgba(205, 215, 217, 0.24);
  }
  .policy-scheduled {
    padding-top: 1.5rem;
    border-top: 1px solid rgba(205, 215, 217, 0.24);
    @include xs {
      text-align: center;
    }
    h4 {
      color: $color-text-default;
      font-weight: $font-medium;
    }
  }
}
.thank-you {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5rem;
  margin: 0 0 1rem;
  @include sm {
    padding: 1rem 0;
  }
  @include xs {
    justify-content: center;
  }
  h3 {
    font-weight: $font-light;
    color: $color-body;
    margin-bottom: 0;
    @include xs {
      margin-bottom: 1rem;
    }
  }
}
/* ====== End: Policy Details page ========== */

/* ====== Begin: Document Sign page ========== */
.document-sign {
  .document-wrapper {
    .card-outer {
      max-width: 1000px;
      margin: 0 auto 2.5rem;
      img {
        width: 100%;
      }
    }
  }
}
/* ====== End: Document Sign page ========== */

/* ======= Sidebar ========= */
.responsive-menu {
  display: none;
  @include md {
    padding-right: 0 !important;
    display: block;
  }
  a {
    color: $color-body;
  }
}

.sidebar-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  pointer-events: none;
  z-index: 1028;
}
.sidebar-part {
  @include md {
    position: fixed;
    left: -100%;
    top: 68px;
    z-index: 1029;
    padding: 15px;
    height: calc(100% - 68px);
    transition: 0.3s ease-in-out all;
    background: $color-white;
    // box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
    min-width: 320px;
    overflow-y: auto;
    box-shadow: none;
  }
  @include sm {
    top: 60px;
    height: calc(100% - 60px);
  }
}
.sidebar-open {
  .sidebar-part {
    @include md {
      left: 0;
    }
  }
  .sidebar-overlay {
    @include md {
      opacity: 1;
      pointer-events: inherit;
    }
  }
}
.sidebar {
  .sidebar-profile {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    position: relative;
    .sidebar-profile-img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 1rem;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
      &.active {
        position: relative;
        &:after {
          content: "";
          border-radius: 100%;
          height: 16px;
          width: 16px;
          background: $color-primary;
          position: absolute;
          right: 2px;
          bottom: -1px;
          border: solid 2px $bg-white;
        }
      }
    }
    h3 {
      margin-bottom: 0;
      font-weight: $font-medium;
    }
    h5 {
      margin-bottom: 0;
    }
    .back-btn {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      color: $color-body;
      .material-icons {
        font-size: 1rem;
        max-width: 20px;
      }
    }
  }
  .sidebar-links-outer {
    padding: 12px;
    .sidebar-link {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      .with-arrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-text-default;
        width: 100%;
        margin: 0;
        font-weight: $font-medium;
        span {
          margin: 0;
          color: $color-body !important;
        }
      }
      a {
        padding: 12px;
        display: flex;
        align-items: center;
        color: $color-text-default;
        font-weight: $font-medium;
        border-radius: 4px;
        span {
          color: $color-body;
          margin-right: 1rem;
          &.svg-icon {
            position: relative;
            top: -2px;
          }
          .fill {
            fill: $color-body;
          }
        }
        &:hover,
        &.active {
          // background: $color-sucess-light;
          span {
            color: $color-primary;
            .fill {
              fill: $color-primary;
            }
          }
        }
      }
      .accordion {
        padding: 0;
        box-shadow: none;
        .btn {
          padding: 0;
          width: 100%;
          a {
            padding-right: 0;
            &:hover,
            &.active {
              background: transparent;
              span {
                color: $color-body;
                .fill {
                  fill: $color-body;
                }
              }
            }
          }
        }
      }
      .submenu {
        padding: 0.5rem 0 0 32px;
        // display: none;
        // &.active {
        //     display: block;
        // }
        .sidebar-link {
          a {
            padding: 3px 20px;
            font-size: 0.875rem;
            color: $color-body;
            &.active,
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }
  }
  .sidebar-profile {
    .email-id {
      font-size: 0.875rem;
    }
  }
  .sidebar-link {
    a {
      svg {
        g {
          path {
            fill: $color-body;
          }
        }
      }
      &:hover {
        svg {
          g {
            path {
              fill: $color-primary;
            }
          }
        }
      }
      &.active {
        svg {
          g {
            path {
              fill: $color-primary;
            }
          }
        }
      }
    }
  }
}

/* dashboard-header */

.box-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $border-light;
  h3 {
    margin-bottom: 0;
    font-weight: $font-medium;
  }
  .with-semititle {
    h3 {
      margin-bottom: 4px;
    }
  }
  .btn-link {
    padding: 0;
    min-width: auto;
    font-weight: $font-medium;
  }
}
.box-main-title {
  margin-bottom: 1rem;
}
.dashboard-header-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .dashboard-header {
    max-width: 600px;
    @include lg {
      margin-bottom: 12px;
    }
    .font-24 {
      margin-bottom: 4px;
    }
    h4 {
      margin-bottom: 0;
      @include sm {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.plan-outer {
  padding: 1rem 0;
  border-bottom: 1px solid $border-light;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .policy-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .policy-company-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: inherit;
      }
    }
    .policy-details {
      width: calc(100% - 60px);
      @include xs {
        width: 100%;
        margin-top: 0.5rem;
      }
      h3 {
        font-weight: $font-medium;
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 0;
        @include xs {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
      .policy-name {
        display: flex;
        flex-wrap: wrap;
        h3 {
          margin-right: 1rem;
          @include xs {
            margin-right: 0;
            margin-bottom: 0.5rem;
            width: 100%;
          }
        }
      }
    }
  }
  &.plan-outer2 {
    padding-bottom: 10px;
  }
}

.plan-icons-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -8px;
  .plan-icons {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    margin: 4px;
    cursor: pointer;
    &.active,
    &:hover,
    &:focus {
      background: $color-light;
      color: $color-text-default;
    }
    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background: $color-white;
      color: $color-body;
    }
  }
  a {
    color: $color-body;
  }
}
.policy-right-btn {
  display: inline-block;
  min-width: 81px;
  vertical-align: top;
}

/* profile-page */
.profile-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
  @include xs {
    display: block;
  }
  .profile-pic {
    padding: 4px;
    background: $color-white;
    width: 92px;
    height: 92px;
    border-radius: 50%;
    // overflow: hidden;
    border: 2px dashed $border-color;
    margin-right: 1rem;
    position: relative;
    display: inline-block;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .profile-cam {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 26px;
      height: 26px;
      background-color: $color-primary;
      border-radius: 100%;
      display: inline-block;
      z-index: 1;
      color: $color-white;
      font-size: 15px;
      text-align: center;
      padding: 6px;
      cursor: pointer;
    }
    .profile-input {
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    input[type="file"],
    input[type="file"]::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  .btn-link {
    font-weight: $font-medium;
    min-width: 0;
    @include xs {
      min-width: inherit;
      padding-left: 6px;
      text-align: left;
    }
  }
}
.my-profile-page {
  .form-group {
    margin-bottom: 1.5rem;
  }
  .default-select {
    .custom-select__menu-list {
      max-height: 180px;
    }
  }
}
.ReactCrop .ReactCrop__crop-selection {
  box-shadow: none;
}
.crop-image {
  text-align: center;
  .crp-img {
    border-radius: 100%;
  }
  .crop-lable {
    display: block;
    color: $color-text-default;
    margin-bottom: 1.1rem;
    font-size: 1.1rem;
  }
  .row {
    > div {
      + div {
        @include xs {
          margin-top: 1.3rem;
        }
      }
    }
  }
}

/* ====== notification ======== */
.notification-outer {
  padding: 1rem 0;
  border-bottom: 1px solid $border-light;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .notification-msg {
    @include xs {
      width: 100%;
    }
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .notification-title {
    font-weight: $font-medium;
    color: $color-text-default;
  }
  h4 {
    margin-bottom: 0;
  }
  .notification-msg {
    width: calc(100% - 150px);
    @include sm {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

/* ========== ConversationExpert ============= */
.converexp {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .conexp-left {
    width: calc(100% - 300px);
    overflow: hidden;
    box-shadow: $box-shadow-light;
    background: $bg-white;
    @include lg {
      width: calc(100% - 220px);
    }
    @include md {
      width: 100%;
    }
  }
  .convertop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 4px 0 rgba(131, 156, 159, 0.08);
    margin-bottom: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 50px;
    padding: 5px 20px;
    margin-left: -5px;
    margin-right: -5px;
    h3 {
      margin: 0;
    }
    .righticon {
      a {
        color: $color-body;
      }
      i {
        font-size: 1.4rem;
      }
    }
  }
  .card-outer {
    box-shadow: none;
    .chat-area {
      padding-top: 1rem;
      @include md {
        height: auto;
        max-height: 500px;
      }
      .scrollbar-container {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        height: auto;
        max-height: 100%;
        .ps__rail-x {
          display: none;
        }
      }
      .chat-msg-item {
        width: 100%;
        .chat-msg {
          max-width: calc(100% - 80px);
          @include sm {
            max-width: 100%;
          }
        }
      }
    }
  }
  .chat-buttons {
    background: $color-light;
    .form-control {
      background-color: $bg-white;
      padding: 12px 58px 12px 18px;
      height: 48px;
      resize: none;
      @include sm {
        height: 40px;
        padding: 9px 46px 9px 10px;
        font-size: 0.9rem;
      }
    }
  }
  .conexp-links {
    width: 270px;
    @include lg {
      width: 220px;
    }
    @include md {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .link-box {
      padding: 1rem;
      box-shadow: $box-shadow-light;
      margin-bottom: 1.5rem;
    }
    .documents {
      .document-content {
        padding-left: 8px;
      }
    }
    .pdf-info {
      width: 20px;
      height: 20px;
      position: relative;
      top: -4px;
      .material-icons {
        color: $color-danger;
        font-size: 22px;
      }
      .svg-icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    h3 {
      border-bottom: solid 1px rgba($border-color, 0.24);
      display: flex;
      align-items: center;
      font-weight: $font-medium;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
    ul {
      li {
        + li {
          margin-top: 0.5rem;
        }
        a {
          color: $color-body;
          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }
  .btn {
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: table;
  }
  .chat-component-header {
    .card-outer {
      .policy-outer {
        padding: 1.25rem 1.25rem 0.5rem 1.25rem;
      }
    }
  }
  .guardian-outer {
    .guardian-details {
      margin-bottom: 10px;
    }
  }
}

/* ========== Lead conversations ============= */
.conversation-page {
  &.lead-conver {
    .conversation-list {
      width: 100%;
    }
    .conversation-list-outer {
      padding: 10px;
    }
    .conversation-person-details {
      padding-right: 130px;
      @include sm {
        padding-right: 0;
      }
    }
    .conversation-person {
      cursor: pointer;
      position: relative;
      .conversation-person-details {
        .msg-counter {
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          bottom: 10px;
          right: 10px;
        }
      }
      &:hover {
        background-color: #f3f6f6;
      }
      &.unread {
        h5 {
          color: $color-text-default;
        }
      }
      .conver-time {
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 0.75rem;
        line-height: 1;
        padding-right: 30px;
        transform: translateY(-50%);
      }
    }
  }
  .plan-outer {
    &.plan-full {
      justify-content: flex-start;
      padding: 16px 18px;
      h3 {
        margin-right: 0.5rem;
        margin-bottom: 0;
        font-weight: $font-medium;
        @include sm {
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}
.conversation-popup {
  position: fixed;
  right: -100%;
  // z-index: 11;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  opacity: 1;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  max-width: 1200px;
  padding: 40px 20px;
  background: #fbfcfc;
  box-shadow: $box-shadow-light;
  overflow: auto;
  &.small-size {
    width: auto;
    max-width: 550px;
    @include xs {
      width: 100%;
    }
    .converexp,
    .chat-box-wrapper {
      height: 100%;
      .conexp-left {
        width: 100%;
        height: 100%;
      }
    }
    .card-outer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      // min-width: 450px;
      @include mdUp {
        min-width: 450px;
      }
    }
    .chat-box {
      margin-top: auto;
      height: calc(100% - 153px);
      overflow: auto;
      .chat-area {
        // height: 100%;
      }
    }
  }
  @include md {
    max-width: 96%;
  }
  &.active {
    opacity: 1;
    right: 0;
  }
  .close-btn {
    position: absolute;
    left: 20px;
    top: 3px;
    color: $color-body;
    &:hover {
      opacity: 0.8;
    }
  }
  .chat-policy {
    display: flex;
    align-items: center;
    border-top: solid 1px $border-light;
    padding: 10px 0 12px 0;
    margin-top: 10px;
    justify-content: center;
    .page-title-logo {
      width: 26px;
    }
    > span {
      max-width: calc(100% - 32px);
      padding-left: 5px;
      color: $color-text-default;
      font-size: 1.2rem;
      line-height: 1;
    }
  }
  .card-value-list {
    font-size: 14px;
    padding: 0;
    .value-list-item {
      margin: 0 0 0.5rem;
    }
    .list-info {
      margin-left: 0.3rem !important;
      @include lg {
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
    h3 {
      font-size: 1rem;
    }
  }
}
.conv-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  pointer-events: none;
  z-index: 1030;
  &.active {
    opacity: 1;
    pointer-events: inherit;
  }
}

/* ====== setting ======== */
.setting-outer {
  padding: 1rem 0;
  border-bottom: 1px solid $border-light;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .setting-msg {
    max-width: 80%;
    @include xs {
      max-width: 100%;
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
  .setting-title {
    font-weight: $font-medium;
    color: $color-text-default;
  }
  h4 {
    margin-bottom: 0;
  }
  h5 {
    margin-bottom: 0;
  }
  .custom-checkbox {
    input[type="checkbox"] {
      &:checked {
        ~ span ~ .emailcheck {
          color: $color-text-default;
          background: transparent !important;
        }
      }
      &:disabled {
        opacity: 1;
        ~ span {
          background: $color-body;
          border-color: $color-body;
          opacity: 1;
        }
      }
    }
  }
  .setting-checkbox {
    .custom-checkbox {
      + .custom-checkbox {
        margin-left: 20px;
      }
    }
  }
  .btn-groups {
    @include xs {
      text-align: center;
    }
  }
}
.change-password-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-weight: $font-medium;
    margin-bottom: 0;
  }
  h5 {
    margin-bottom: 0;
  }
}

/* ====== conversation-page ======== */
.conversation-page {
  display: flex;
  flex-wrap: wrap;
  box-shadow: $box-shadow-light;
  .conversation-list {
    width: 270px;
    .conversation-list-header {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid $border-light;
      height: 60px;
      h3 {
        margin-bottom: 0;
        font-weight: $font-medium;
        color: $color-text-title;
      }
    }
  }
  .conversation-msg {
    width: calc(100% - 270px);
  }
  .chat-area {
    padding: 1rem;
  }
  .chat-buttons {
    background: $color-light;
    padding: 1rem;
  }
  .plan-outer {
    padding: 1rem 0;
    border-bottom: 1px solid $border-light;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .policy-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .policy-company-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .policy-details {
        width: calc(100% - 32px);
        h3 {
          font-weight: $font-medium;
          margin-bottom: 0;
          font-size: 1rem;
        }
        h4 {
          margin-bottom: 0;
          font-size: 0.875rem;
        }
        .policy-name {
          display: flex;
          flex-wrap: wrap;
          h3 {
            margin-right: 1rem;
          }
        }
      }
    }
  }
  .conversation-policy-part {
    padding: 12px;
    .plan-outer {
      padding: 12px;
      box-shadow: $box-shadow-light;
      .btn {
        min-width: auto;
      }
    }
  }
}

.conversation-list-outer {
  padding: 8px;
  .conversation-person {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 8px;
    @include xs {
      align-items: flex-start;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      background: $color-light;
    }
    .conversation-person-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 8px;
      position: relative;
      @include sm {
        width: 32px;
        height: 32px;
        .react-loading-skeleton {
          width: 32px !important;
          height: 32px !important;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .conversation-person-details {
      width: calc(100% - 56px);
      // position: relative;
      @include sm {
        width: calc(100% - 40px);
      }
      h4 {
        color: $color-text-default;
        font-weight: $font-medium;
        display: inline-block;
        width: 220px;
        word-break: break-word;
        padding-right: 5px;
        vertical-align: middle;
        @include lg {
          width: 150px;
        }
        @include sm {
          margin-right: 0;
          padding-right: 110px;
          width: 100%;
        }
        .msg-text {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.875rem;
          line-height: 1.3rem;
          display: block;
          font-weight: $font-regular;
        }
      }
      h5 {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include sm {
          padding-right: 26px;
        }
      }
      .policy-details {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        vertical-align: middle;
        @include sm {
          margin-top: 2px;
        }
        h3 {
          font-size: 1rem;
          margin: 0 0.5rem 0;
          width: 190px;
          text-align: center;
          font-weight: $font-medium;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include mdtolg {
            width: 190px;
          }
          @include sm {
            width: 190px;
            text-align: left;
            margin: 0 0.4rem 0 0;
          }
        }
      }
      .msg-counter {
        padding: 3px 4px;
        background: $color-primary;
        border-radius: 50%;
        font-size: 12px;
        color: $color-white;
        font-weight: $font-medium;
        position: absolute;
        top: 50%;
        right: 10px;
        bottom: 10px;
        transform: translateY(-50%);
        line-height: 14px;
        width: 20px;
        height: 20px;
        text-align: center;
      }
      .chips-outer {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        @include sm {
          display: block;
          .chips-label {
            font-size: 12px;
            line-height: 1.3rem;
            padding: 1px 5px;
          }
        }
      }
    }
  }
}

/* Help page */

.phone-number-outer {
  border: 1px solid $border-light;
  padding: 1rem 1.5rem;
  display: inline-block;
  @include xs {
    width: 100%;
    margin-top: 0.5rem;
  }
  .material-icons {
    color: $color-primary;
  }
  a {
    color: $color-text-default;
    font-size: 1.25rem;
    font-weight: $font-medium;
    line-height: 1.5rem;
  }
}

.help-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  .help-title {
    h3 {
      margin-bottom: 8px;
      font-weight: $font-medium;
      @include xs {
        margin-bottom: 0;
      }
    }
    h4 {
      margin-bottom: 0;
    }
  }
}

/*==================== Begin: My Payouts page ====================*/
.with-semititle {
  .semititle-primary {
    color: $color-primary;
  }
}
.edit {
  &:hover {
    color: $color-primary !important;
  }
}

.added-accounts {
  .accounts-list {
    padding: 0.5rem 0;
    .account {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 0.75rem 0;
      &:last-child {
        margin-bottom: 0;
      }
      .account-details {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: calc(100% - 150px);
        .account-icon {
          margin-right: 10px;
        }
        .account-text {
          padding-bottom: 0;
          margin-right: 8px;
          max-width: calc(100% - 120px);
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $color-text-default;
        }
      }
      .account-options {
        .btn {
          margin-right: 1.125rem;
          padding: 0 10px;
        }
        .edit {
          color: $color-body;
        }
      }
    }
  }
}
/*==================== End: My Payouts page ====================*/

/* ======= Custom accordion ========= */

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: flex-start;
  .accordion-header-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    span {
      margin-right: 16px;
      color: $color-text-default;
      font-weight: $font-medium;
      width: 24px;
    }
    h4 {
      margin-bottom: 0;
      color: $color-text-default;
      font-weight: $font-medium;
      width: calc(100% - 40px);
    }
  }
  .btn-link {
    padding: 0;
  }
}
.accordion-body {
  padding-bottom: 1rem;
  padding-left: 40px;
  border-bottom: 1px solid $border-light;
}

/* ======= Policy information ======== */

.filled-value {
  margin-bottom: 1rem;
  label {
    margin-bottom: 0;
    font-size: 12px;
  }
  h4 {
    margin-bottom: 0;
    color: $color-text-default;
  }
}

/* Data table */
.status-open {
  padding: 0 12px;
  background: $border-color;
  color: $color-text-default;
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: $font-medium;
  text-transform: capitalize;
  cursor: pointer;
}

.status-success {
  padding: 0 12px;
  background: $color-sucess-light;
  color: $color-primary;
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: $font-medium;
  text-transform: capitalize;
  cursor: pointer;
}

.status-lost {
  padding: 0 12px;
  background: rgba(255, 0, 0, 0.33);
  color: $color-text-default;
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: $font-medium;
  text-transform: capitalize;
  cursor: pointer;
}
.status-renew {
  padding: 0 12px;
  background: $color-primary;
  color: $color-white;
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: $font-medium;
  text-transform: capitalize;
  cursor: pointer;
}

/* ======= qualification-status ========= */
.qualification-status {
  .title-header {
    h4 {
      min-height: 48px;
    }
  }
  .guardian-outer {
    .guardian-details {
      width: 100%;
      padding-bottom: 1rem;
      padding-right: 0;
      justify-content: center;
      .guardian-name {
        display: flex;
        align-items: center;
        width: auto;
        h2 {
          margin-right: 1rem;
        }
      }
    }
    .guardian-info {
      width: 100%;
      padding: 1rem 0;
      border-top: 1px solid $border-light;
      ul {
        justify-content: center;
      }
    }
  }
  .policy-outer {
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;
    .policy-name {
      justify-content: center;
      h3 {
        margin-right: 0;
      }
    }
    .chips-outer {
      margin: 0 -4px;
    }
  }
}

/* Owl carousol */

.owl-carousel {
  padding-right: 19px;
  padding-left: 40px;
  @include md {
    padding-right: 30px;
  }
  .boxmodal-radio,
  .boxmodal-checkbox {
    margin-bottom: 8px;
    height: calc(100% - 8px);
    @include sm {
      margin-bottom: 20px;
      height: calc(100% - 20px);
    }
  }
  .owl-dots {
    display: none;
  }
  .card-inner {
    margin: 0 15px;
  }
  .owl-nav {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    z-index: -1;
    @include md {
      width: initial;
      left: 10px;
      right: 10px;
    }
    .owl-prev {
      font-size: 0;
      width: 17px;
      height: 20px;
      display: inline-block;
      background: url("../assets/images/icon/back-icon.svg");
      &.disabled {
        opacity: 0.4;
        &:hover {
          background: url("../assets/images/icon/back-icon.svg");
          color: $color-text-default;
        }
      }
      &:hover {
        background: url("../assets/images/icon/back-icon.svg");
        color: $color-text-default;
      }
    }
    .owl-next {
      font-size: 0;
      width: 17px;
      height: 20px;
      display: inline-block;
      background: url("../assets/images/icon/next-icon.svg");
      &.disabled {
        opacity: 0.4;
        &:hover {
          background: url("../assets/images/icon/next-icon.svg");
          color: $color-text-default;
        }
      }
      &:hover {
        background: url("../assets/images/icon/next-icon.svg");
        color: $color-text-default;
      }
    }
  }
}

.table {
  width: 100%;
  th,
  td {
    border-top: 0;
  }
  thead {
    th {
      border-bottom: 0;
    }
  }
  tr {
    border: 1px solid $border-light;
    border-bottom: 0;
    &:last-child {
      border-bottom: 1px solid $border-light;
    }
  }
  .carrier-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .carrier-logo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.motivation-outer {
  h4 {
    font-size: 1rem;
    line-height: 1.75rem;
    color: $color-body;
  }
}

/* How it work */
.work-outer {
  margin: 50px 0 150px 0;
  position: relative;
  @include md {
    margin: 30px 0 100px 0;
  }
  @include sm {
    margin: 20px 0 50px 0;
  }
  .work-inner {
    max-width: 770px;
    width: 770px;
    @include sm {
      width: 100%;
    }
  }
}

.work-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &:first-child {
    .work-info {
      &:after {
        height: 50%;
        border-top: transparent;
        bottom: -3px;
      }
      &:before {
        height: 50%;
        border-top: transparent;
        bottom: -3px;
      }
    }
  }
  &.right-part {
    &:last-child {
      .work-info {
        &:before {
          display: none;
        }
        &:after {
          height: 50%;
          border-bottom: transparent;
          top: 0;
        }
      }
    }
  }
  .work-arrow2 {
    background: url("../assets/images/bgi/arrow-work.png") no-repeat center
      center;
    height: 12px;
    width: 8px;
    position: absolute;
    left: 440px;
    bottom: -8px;
    @include md {
      display: none;
    }
  }
  .work-arrow3 {
    background: url("../assets/images/bgi/arrow-work.png") no-repeat center
      center;
    height: 12px;
    width: 8px;
    position: absolute;
    right: 440px;
    bottom: -8px;
    transform: rotate(-180deg);
    @include md {
      display: none;
    }
  }
  .work-arrow4 {
    background: url("../assets/images/bgi/arrow-work.png") no-repeat center
      center;
    height: 12px;
    width: 8px;
    position: absolute;
    left: 440px;
    bottom: -6px;
    @include md {
      display: none;
    }
  }
  .work-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: $color-sucess-lighter;
    @include sm {
      width: 80px;
      height: 80px;
    }
    svg {
      @include sm {
        width: 32px;
        height: auto;
      }
    }
  }

  .work-info {
    width: calc(100% - 205px);
    margin-left: 65px;
    padding: 45px 0;
    padding-left: 85px;
    position: relative;
    @include sm {
      padding: 30px 0;
      margin-left: 15px;
      padding-left: 30px;
      width: calc(100% - 110px);
    }
    &:before {
      content: "";
      width: calc(100% - 275px);
      height: 35px;
      border-bottom: dashed 1px $color-primary;
      border-left: 1px dashed transparent;
      border-right: 1px dashed transparent;
      border-radius: 0 20px 0 0;
      position: absolute;
      pointer-events: none;
      left: 35px;
      bottom: -1px;
      z-index: -1;
      @include sm {
        width: calc(100% - 180px);
      }
    }
    &:after {
      content: "";
      width: 35px;
      height: 100%;
      border-top: 1px dashed $color-primary;
      border-bottom: 1px dashed $color-primary;
      position: absolute;
      pointer-events: none;
      border-radius: 20px 0px 0 20px;
      border-left: 1px dashed $color-primary;
      left: 0;
      bottom: -1px;
      z-index: -1;
    }
    p {
      padding-bottom: 0;
    }
  }
  .work-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $color-primary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    @include sm {
      width: 30px;
      height: 30px;
      left: -15px;
    }
    span {
      font-size: 1.25rem;
      font-weight: $font-medium;
      color: $color-white;
    }
  }
  &.right-part {
    .work-info {
      width: calc(100% - 205px);
      margin-right: 65px;
      padding: 45px 0;
      padding-right: 85px;
      padding-left: 0;
      margin-left: 0;
      position: relative;
      text-align: right;
      @include sm {
        padding: 30px 0;
        margin-right: 15px;
        padding-right: 30px;
        width: calc(100% - 110px);
      }
      &:before {
        content: "";
        width: calc(100% - 275px);
        height: 35px;
        border-top: dashed 1px transparent;
        border-left: 1px dashed transparent;
        border-right: 1px dashed transparent;
        border-radius: 0 0 0 0;
        position: absolute;
        pointer-events: none;
        left: inherit;
        right: 35px;
        bottom: -2px;
        z-index: -1;
        @include sm {
          width: calc(100% - 180px);
        }
      }
      &:after {
        content: "";
        width: 35px;
        height: 100%;
        border-top: 1px dashed $color-primary;
        border-bottom: 1px dashed $color-primary;
        position: absolute;
        pointer-events: none;
        border-radius: 0 20px 20px 0;
        border-left: 1px dashed transparent;
        border-right: 1px dashed $color-primary;
        left: inherit;
        right: 0;
        bottom: -2px;
        z-index: -1;
      }
    }
    .work-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $color-primary;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -20px;
      left: inherit;
      @include sm {
        width: 30px;
        height: 30px;
        right: -15px;
      }
      span {
        font-size: 1.25rem;
        font-weight: $font-medium;
        color: $color-white;
      }
    }
  }
}

/* addmore-outer */

.addmore-outer {
  position: relative;
  .delete-btn {
    min-width: auto;
    position: absolute;
    right: -20px;
    top: 12px;
  }
}

.dropdown-withlogo {
  display: flex;
  align-items: center;
  .dropdown-logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $color-white;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: inherit;
    }
  }
}

//  No Chart
.no-chart {
  text-align: center;
  min-height: 328px;
  border: solid 1px $border-color;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

// Documents Begin
.documents {
  .document-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    + .document-row {
      margin-top: 5px;
    }
  }
  .document-icon {
    width: 30px;
    &.material-icons {
      font-size: 20px;
      &.pdf-icon {
        color: $color-danger;
      }
      &.drive-icon {
        color: $color-info;
      }
    }
    &.svg-icon {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
  .document-content {
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .document-name {
      width: calc(100% - 40px);
    }
    .view-link {
      width: 40px;
      font-size: 14px;
      text-align: right;
      a,
      span {
        color: $color-primary;
        cursor: pointer;
      }
    }
  }
  &.documents-card {
    .document-row {
      background-color: $bg-white;
      border: solid 1px $border-light;
      padding: 20px 15px;
      margin-bottom: 30px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      @include sm {
        margin-bottom: 20px;
      }
      &.with-border {
        box-shadow: none;
        border: solid 1px $border-light;
      }
    }
    .document-icon {
      width: 100%;
      &.material-icons {
        font-size: 60px;
      }
      &.svg-icon {
        svg {
          width: 60px;
          height: 60px;
        }
      }
    }
    .document-content {
      width: 100%;
      .document-name {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
// Documents End

// Under Process Begin
.under-process {
  .plan-outer {
    // align-items: flex-start;
    &.app-center {
      .application-id {
        position: relative;
        top: 22px;
        @include sm {
          top: 0;
        }
      }
      .policy-right-btn {
        position: relative;
        top: 20px;
        @include sm {
          top: 0;
        }
      }
    }
    .application-id {
      width: 178px;
      word-break: break-all;
      padding-right: 5px;
      @include lg {
        width: 170px;
      }
      @include sm {
        width: 100%;
      }
    }
    .policy-details {
      width: calc(100% - 178px);
      @include lg {
        width: calc(100% - 170px);
      }
      @include sm {
        width: 100%;
      }
      .policy-name {
        h3 {
          margin-right: 0.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 198px;
          text-align: left;
          @include lg {
            min-width: 112px;
            margin-bottom: 3px;
          }
          @include md {
            min-width: 128px;
          }
          @include sm {
            min-width: 0;
            text-align: left;
          }
        }
      }
    }
    .policy-info {
      width: calc(100% - 240px);
      align-items: flex-start;
      @include sm {
        width: 100%;
      }
    }
    .same-policy {
      padding-top: 10px;
    }
    @include sm {
      width: 100%;
    }
    .policy-name {
      h3 {
        margin-right: 0.5rem;
      }
    }
  }
  .policy-info {
    width: calc(100% - 170px);
    align-items: flex-start;
    @include sm {
      width: 100%;
    }
  }
  .policy-right {
    max-width: 240px;
    text-align: right;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include sm {
      max-width: 100%;
      margin-top: 12px;
    }
    .btn {
      min-width: 80px;
      font-size: 14px;
      padding: 7px 13px;
      &.btn-outline-with-icon {
        min-width: 0;
        font-size: 0.75rem;
        padding: 4px 10px;
        margin-right: 5px;
        @include sm {
          font-size: 1rem;
          padding: 7px 13px;
          .material-icons {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .plan-icons-outer {
    display: inline-flex;
    margin: 0 4px 0 0;
    .plan-icons {
      margin: 1px;
      width: 36px;
      height: 36px;
      .plan-chat {
        font-size: 22px;
      }
    }
  }
  &.same-policy {
    margin-top: -1px;
    background: #fff;
    padding-top: 0;
    z-index: 1;
    position: relative;
  }
}

// Under Process End

// Begin: Dropzone
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 2px;
  border-color: $border-color;
  border-style: dashed;
  // background-color: transparent;
  color: $color-secondary;
  outline: none;
  transition: border 0.24s ease-in-out;
  background-color: rgba($color-secondary, 0.05);
  justify-content: center;
  text-align: center;
  .material-icons {
    font-size: 2.5rem;
  }
  &.dropzone-small {
    padding: 2px 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 36px;
    position: relative;
    h5 {
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .material-icons {
      font-size: 1.5rem;
    }
  }
  h5 {
    font-size: 1rem;
    color: $color-body;
    font-weight: $font-regular;
  }
}
.dropzone:focus {
  border-color: $color-primary;
}
.dropzone.disabled {
  opacity: 0.6;
}
.uploaded-file {
  margin-top: 0.5rem;
  &.uploaded {
    margin: 0 0 1.3rem 0;
  }
}
.file-name {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  color: $color-title;
  margin-right: 15px;
  a {
    color: $color-primary;
    text-decoration: underline !important;
  }
  .material-icons {
    margin-right: 0.2rem;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    font-size: 1rem;
    top: -1px;
    position: relative;
  }
}
// End: Dropzone

// skeleton loader
.skeleton-loader {
  // position: absolute;
  // width: 100%;
  // left: 0;
  .d-flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .css-1y8mk30-SkeletonTheme {
    .react-loading-skeleton {
      background-image: linear-gradient(90deg, #eee, #e4e4e4, #eee);
    }
  }
}
.underprocess-loader {
  .d-flex-between {
    flex-wrap: wrap;
  }
  .w-20 {
    width: 18%;
  }
  .w-60 {
    width: 58%;
  }
  .w-100 {
    width: 100%;
  }
}

.zEWidget-launcher,
div.zsiq_custommain,
div.zsiq_floatmain {
  z-index: 999 !important;
}

/*==================== Begin: Become Member Page ====================*/
.recommended-part {
  padding: 0;
  margin: -15px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  .member-plan-block {
    border: 2px solid $color-primary;
    background: $color-primary;
  }
  .recommended {
    display: block !important;
  }
  .member-plan-block {
    .plan-block-details {
      .plan-img {
        g {
          fill: $color-white;
        }
      }
      .plan-title {
        color: $color-white;
      }
      .plan-text {
        color: $color-white;
      }
      .plan-price-block {
        .plan-price {
          color: $color-white;
        }
        .plan-per {
          color: $color-white;
        }
      }
      .btn {
        color: $color-primary;
        background: $color-white;
        &:hover {
          background: $color-white;
        }
      }
    }
    .plan-info {
      .info-list {
        color: $color-white;
        .check-mark {
          background: $color-white;
          color: $color-primary;
        }
      }
    }
  }
}
.become-member-page {
  .title-header {
    margin-bottom: 4rem;
  }
}
.member-plan-block {
  height: calc(100% - 32px);
  padding: 2.5rem 1.5rem;
  background: $color-white;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border-radius: 8px;
  margin-bottom: 32px;
  .recommended {
    text-transform: uppercase;
    font-weight: $font-medium;
    margin-bottom: 1rem;
    display: none;
    color: $color-white;
  }
  .plan-block-details {
    text-align: center;
    margin-bottom: 2rem;
    .plan-img {
      margin-bottom: 2rem;
    }
    .plan-title {
      font-size: 2rem;
      line-height: 2.688rem;
      font-weight: $font-medium;
      color: $color-text-default;
      margin-bottom: 0.5rem;
    }
    .plan-text {
      min-height: 52px;
      font-size: 1.25rem;
      line-height: 1.625rem;
      font-weight: $font-regular;
      color: $color-text-default;
      padding: 0;
    }
    .plan-price-block {
      margin-top: 1.5rem;
      .plan-price {
        font-size: 2rem;
        line-height: 2.688rem;
        font-weight: $font-light;
        color: $color-text-default;
        padding: 0;
        margin-bottom: 0.5rem;
      }
      .plan-per {
        line-height: 1.312rem;
        font-weight: $font-regular;
        padding: 0;
      }
    }
    .btn {
      margin-top: 1.5rem;
    }
  }
  .plan-info {
    .info-list {
      font-size: 0.875rem;
      color: $color-text-default;
      line-height: 1.25rem;
      font-weight: $font-regular;
      padding-left: 1.75rem;
      margin-bottom: 1rem;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      .check-mark {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $color-primary;
        font-size: 0.65rem;
        color: $color-white;
        font-weight: $font-bold;
      }
    }
  }
}
/*==================== End: Become Member Page ====================*/

// Start Reting Start
.dv-star-rating {
  label {
    margin: 0 10px 0 0;
    color: rgba($color-body, 0.5) !important;
    font-size: 1.5rem;
    &.dv-star-rating-full-star {
      color: $color-primary !important;
    }
  }
}

// Chat Boat bottom right
div.zsiq_theme1 {
  .zsiq_flt_rel {
    @include sm {
      width: 50px;
      height: 50px;
    }
  }
  .siqico-chat {
    &:before {
      @include sm {
        font-size: 22px;
        line-height: 47px;
      }
    }
  }
  .zsiq_cnt {
    @include sm {
      display: none !important;
    }
  }
}

// Basic Details page
.basic-details {
  margin-bottom: 40px;
  .default-select {
    .custom-select__menu-list {
      max-height: 160px;
    }
  }
}

// Thank you Page
.thankyou-box {
  display: inline-block;
  box-shadow: $box-shadow-global;
  background: $bg-white;
  padding: 50px 30px;
  border-radius: 10px;
  width: 94%;
  max-width: 610px;
  margin: 20px 0;
  @include sm {
    margin: 10px 0;
    padding: 40px 30px;
    .please-wait-img {
      margin-bottom: 30px;
      svg {
        max-width: 160px;
        height: auto;
      }
    }
  }
}

// Process Banner
.banner-wrapper {
  width: 100%;
  max-width: 770px;
  margin: 0 auto 20px;
  h2 {
    font-size: 1.6rem;
  }
}
.process-banner {
  display: flex;
  flex-wrap: wrap;
  // TODO: Background color for banner
  background-color: $color-primary;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  padding: 22px 3px 20px 24px;
  color: $color-white;
  min-height: 180px;
  margin-bottom: 24px;
  font-size: 0.9rem;
  line-height: 24px;
  @include sm {
    padding: 20px 16px;
    line-height: 21px;
  }
  h3 {
    color: $color-white;
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: $font-medium;
    margin-bottom: 16px;
    @include md {
      font-size: 1.35rem;
    }
  }
  .banner-left {
    width: calc(100% - 255px);
    text-align: left;
    @include sm {
      width: 100%;
    }
  }
  .banner-image {
    width: 250px;
    margin-bottom: -20px;
    @include sm {
      width: 100%;
      margin-top: 24px;
    }
  }
}

.select-goal-dropdown {
  width: 230px;
}
.select-Duration-dropdown {
  width: 160px;
}

.plan-list-item-style {
  list-style-type: disc;
  padding: 0px;
  margin: 0px;
}

.plan-bullet-list {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
  li {
    position: relative;
    list-style-type: none;
    font-size: 1.2rem;
    padding-left: 10px;
    + li {
      margin-top: 10px;
    }
    &::before {
      position: absolute;
      top: 8px;
      left: -10px;
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: $color-text-default;
      content: "";
    }
  }
}
.steps {
  &.getstart-page {
    h2 {
      font-size: 1.8rem;
      @include lg {
        font-size: 1.7em;
      }
      @include md {
        font-size: 1.6em;
      }
    }
  }
}

.container-frame {
  position: relative;
  float: left;
}

.overlay-frame {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.list-info {
  svg {
    * {
      // fill: $color-body;
    }
  }
}
.card-img {
  svg {
    * {
      fill: $color-primary;
    }
    .st0 {
      fill: #ffffff !important;
    }
  }
}

// .more-info-wrapper {
//   .css-yk16xz-control {
//     background: transparent;
//   }
// }
.default-select {
  .css-1uccc91-singleValue {
    color: $color-text-default;
  }
}
.form-group.fixed-floating-label .fixed-label,
.custom-datepicker .datepicker-label,
.custom-datepicker .datepicker-label, .form-group.fixed-floating-label .fixed-label {
  background: #fff;
  color: #797979!important;
  font-size: 12px !important;
}
.custom-datepicker .react-datepicker-wrapper::before {
  z-index: 99;
  pointer-events: none;
}

.form-control[readonly],
.form-control:disabled {
  background-color: #fff;
  opacity: 1;
}
.popover-content {
  font-family: $font-global;
}

.custom-slider .rangeslider-horizontal .rangeslider__handle:after {
  background: url("../assets/images/slider.png");
}

.lang-dropdown {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  @include md {
    justify-content: center;
  }
  .lang-inner {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 0;
    @include md {
      position: relative;
      top: 0;
      margin-bottom: 20px;
    }
  }
  .lang-title {
    margin-right: 14px;
  }
  .default-select {
    width: 130px;
  }
}

.compare-plan-data-same {
  padding: 6px;
  background: #f9e79f;
}

.compare-plan-data-greater {
  padding: 6px;
  background: rgba(107, 174, 85, 0.5);
}

.compare-plan-data-lesser {
  padding: 6px;
  background: #fadbd8;
}

.compare-plan-data-same-right {
  padding: 6px;
  background: #f9e79f;
  margin-left: 20px;
}

.compare-plan-data-greater-right {
  padding: 6px;
  background: rgba(107, 174, 85, 0.5);
  margin-left: 20px;
}

.compare-plan-data-lesser-right {
  padding: 6px;
  background: #fadbd8;
  margin-left: 20px;
}

.plan-list-wrapper .owl-carousel .owl-dots {
  display: block;
}

.plan-list-wrapper {
  margin-top: 50px;
  .owl-theme {
    .owl-dots {
      .owl-dot.active {
        span {
          background: #2ba84a;
        }
      }
      .owl-dot {
        &:hover {
          span {
            background: #2ba84a;
          }
        }
      }
    }
  }
  .owl-carousel {
    .owl-nav {
      .owl-prev {
        font-size: 0;
        width: 19px;
        margin-left: 10px;
        height: 26px;
        background-image: url(../assets/images/icon/left-arrow.svg);
        background-size: 25px !important;
        background-repeat: no-repeat !important;
        display: inline-block;
        @include lg {
          background-size: 18px !important;
        }
      }
      .owl-prev.disabled {
        &:hover {
          font-size: 0;
          width: 179x;
          margin-left: 10px;
          height: 26px;
          background-image: url(../assets/images/icon/left-arrow.svg);
          background-size: 25px !important;
          background-repeat: no-repeat !important;
          display: inline-block;
        }
      }
      .owl-next {
        font-size: 0;
        width: 19px;
        height: 26px;
        background-image: url(../assets/images/icon/right-arrow.svg);
        background-size: 25px !important;
        background-repeat: no-repeat !important;
        display: inline-block;
        @include lg {
          background-size: 18px !important;
        }
      }
      .owl-next.disabled {
        &:hover {
          font-size: 0;
          width: 19px;
          height: 26px;
          background-image: url(../assets/images/icon/right-arrow.svg);
          background-size: 25px !important;
          background-repeat: no-repeat !important;
          display: inline-block;
        }
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .plan-list-wrapper .owl-carousel .owl-nav .owl-prev,
  .plan-list-wrapper .owl-carousel .owl-nav .owl-prev.disabled:hover {
    margin-left: initial;
  }
}

.getstart-container {
  align-items: center;
  @media screen and (min-width: 991px) {
    margin-right: 10px;
  }
  .getstart-button {
    margin-right: 20px;
    .btn.btn-lg {
      padding: 14px 38px;
    }
  }
}

//Welcome Page new design
.demopage {
  .demopagebody {
    // background-size: cover;
    background-position: center;
  }
  .demopagebody-about {
    // background-size: cover;
    background-position: center;
    margin-bottom: -40px;
  }
  .about-main-body {
    padding: 60px 70px;
    min-height: 700px;
    display: flex;
    flex-direction: row;

    @include md {
      flex-direction: column-reverse;
    }

    @include sm {
      padding: 30px;
    }

    .left-content {
      width: 65%;
      text-align: left;

      @include md {
        width: 100%;
      }

      @include xlUp {
        margin-right: 50px;
      }

      .username {
        font-size: 28px;
        font-weight: $font-regular;
        margin-bottom: 30px;

        @include md {
          font-size: 22px;
          margin-bottom: 15px;
        }
      }

      .userbio {
        font-size: 16px;
        font-weight: $font-light;
        color: $color-black;
        line-height: 20px;

        // @include xlUp {
        //     margin-right: 50px;
        // }
      }
    }
    .yourquote-block {
      padding: 20px;
      border: 1px solid $border-color;
      border-radius: 8px;
      text-align: center;
      display: block;
      margin: 0 auto;
      max-width: 560px;
      margin-top: 60px;

      .yourquote-msg {
        font-size: 16px;
        font-weight: $font-light;
        margin-bottom: 10px;
      }

      .btn {
        font-family: "Manrope", sans-serif;
        font-weight: $font-semibold;
        font-size: 16px;
        padding: 10px 30px;
      }
    }
    .right-content {
      max-width: 40%;
      margin-left: 10px;

      @include md {
        width: 100%;
      }

      text-align: center;

      .aboutimg {
        width: auto;
        height: auto;
        max-width: 400px;
        // border-radius: 50%;
        flex-wrap: wrap;
        display: flexbox;
        overflow: hidden;
        margin: 0 auto;
        box-shadow: 1px 3px 7px 0px #dfdede;
        padding: 10px;

        @include md {
          margin-bottom: 25px;
          width: auto;
          height: auto;
          max-width: 250px;
        }

        img {
          // width: 100%;
          // height: 100%;
          object-fit: cover;
          object-position: center;
          // border-radius: 50%;
        }
      }
    }
  }
  .head-row {
    padding: 15px 70px;
    display: flex;
    flex-direction: row;
    background-color: $bg-white;
    @include md {
      padding: 15px 20px;
    }

    .pre-logo {
      max-width: 150px;
      max-height: 150px;

      @include md {
        max-width: 100px;
      }
    }

    .head-title {
      margin-left: 30px;

      .ins-title {
        font-weight: $font-regular;
        font-size: 22px;
        color: $color-primary;
        margin-bottom: 2px;

        @include md {
          font-size: 18px;
        }

        @include xxs {
          font-size: 16px;
        }
      }

      .ins-copy {
        font-size: 12px;
        font-weight: $font-regular;
        color: $color-black;
        line-height: 12px;
      }
    }
  }

  .pre-main-body {
    // min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    // background-size: contain;

    @include sm {
      /* padding: 20px;*/
      min-height: 300px;
    }

    .body-block {
      padding: 20px 15px;
      position: absolute;
      box-shadow: 2px 2px 5px 1px #dcdcdc;
      text-align: center;
      max-width: 550px;
      border-radius: 6px;
      background-color: rgba(254, 254, 254, 0.85);
      @include sm {
        margin: 20px;
      }
      .body-title {
        color: $color-primary;
        font-weight: $font-regular;
        font-family: $font-global;

        @include sm {
          font-size: 18px;
          line-height: 20px;
        }
      }

      .body-msg {
        font-size: 22px;
        color: #091f1f;
        text-align: center;
        line-height: 28px;

        @include sm {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .btn {
        &.gets-quote-btn {
          // background: #37b8e5;
          // border: 0;
          font-weight: $font-regular;
          margin-top: 30px;
          font-size: 16px;
          padding: 10px 25px;
          margin-bottom: 10px;

          @include sm {
            font-size: 14px;
            line-height: 20px;
            margin-top: 10px;
          }

          &:hover {
            // background-color: $color-light-blue;
          }
        }
      }
    }
    img {
      max-width: 100%;
    }
  }
  .premodel-footer {
    background-color: $light-black-bg;
    justify-content: center;
    padding: 0 70px;
    border-radius: 0;
    flex-direction: column;

    @include md {
      padding: 0 20px;
    }

    .ftitle-block {
      border-bottom: 1px solid #747474;
      width: 100%;
      padding: 15px 0 5px;

      .ftitle {
        color: $color-primary;
      }
    }

    .fcontact {
      padding: 10px 0;
      margin-left: 0;
      width: 100%;

      @include md {
        width: 100%;
      }

      p {
        display: inline-flex;
        padding-bottom: 0;
        color: $color-white;
        font-weight: $font-regular;
        width: calc((100% / 3) - 0px);
        justify-content: center;

        @include sm {
          display: flex;
          // justify-content:flex-start;
          width: 100%;
        }

        &.f-address {
          @include mdUp {
            justify-content: flex-end;
            width: 35%;
          }
        }

        &.f-tell {
          @include mdUp {
            padding: 0 20px;
            justify-content: center;
            width: 30%;
          }

          @include md {
            margin: 0;
          }
        }

        &.f-web {
          @include mdUp {
            width: 35%;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
.footer-appllication-id {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.postion-inherit {
  position: inherit;
}

// Design fixes
html,
body,
#root,
.App {
  height: 100%;
}

.AppRouting {
  height: 100%;
  display: flex;
  flex-direction: column;
}
footer {
  &.overflow-hidden {
    overflow: visible !important;
    padding-top: 0;
  }
  &::before {
    width: 100%;
  }
  margin-top: auto;
  .disclaimer-block {
    padding-bottom: 20px;
    @include sm {
      padding-bottom: 10px;
    }
  }
}
.page-wrapper {
  flex-shrink: 0 !important;
}

.verification-modal .table th,
.verification-modal .table td {
  min-width: 150px;
}
.form-block-questions .questions-wrapper {
  margin: 0;
}
.pdf-modal {
  margin: 0 15px;
}
.pdf-modal.modal {
  margin: 0 auto;
  max-width: calc(100% - 30px);
  right: 0;
}

@include sm {
  header .agent-info .img-container svg {
    max-width: 25px;
  }
  header {
    .agent-info {
      padding-right: 10px;
      padding-left: 10px;
      .img-container {
        padding-right: 10px;
      }
      .agent-name,
      .agent-info {
        font-size: 12px;
      }
    }
    .company-info {
      margin-left: 5px;
      padding-left: 5px;
    }
  }
  .conversation-page.lead-conver .conversation-person .conver-time {
    margin-top: 15px;
    position: static;
  }
}
.welcomeContainer {
  flex: 1;
  justify-content: center;
}

// Design change
.demopage {
  .demopagewrapper {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    & > div {
      height: 100%;
      // display: flex;
      flex-direction: column;
      align-items: center;
      @include sm {
        display: block;
      }
      @include lgtomd {
        display: block;
      }
    }
    .pre-main-body {
      // max-width: 1250px;
      min-height: 500px;

      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: center;
      flex-wrap: wrap;
      // height: calc(100vh - 180px);
      @include md {
        flex-direction: column-reverse;
        // min-height: initial;
        // height: auto;
      }
      img {
        display: inline-flex;
        max-width: calc(100% - 430px);
        width: 100%;
        height: 100%;
        // object-fit: cover;
        @include md {
          max-width: calc(100%);
        }
      }
      .body-block {
        position: static;
        width: 430px;
        padding: 20px 30px;
        display: inline-flex;
        flex-direction: column;
        box-shadow: none;
        @include md {
          margin: 0;
          width: auto;
        }
        .btn.gets-quote-btn {
          min-width: 250px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

// .demopage .premodel-footer .ftitle-block,
// .demopage .premodel-footer .fcontact{
//   max-width: 1250px;
//   margin: 0 auto;
// }

.demopage .premodel-footer {
  margin-top: auto;
}
.demopage .about-main-body {
  .right-content {
    margin-left: 0;
    margin-right: 35px;
    min-width: 300px;
    @include md {
      margin: 0 auto;
    }
    .aboutimg {
      margin-bottom: 25px;
      width: 250px;
      height: 250px;
      max-width: 250px;
      border-radius: 250px;
      overflow: hidden;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 50%;
      }
    }
  }
  display: block;
  @include md {
    min-height: auto;
    padding: 0;
  }
  .about-top {
    flex-direction: row-reverse;
    display: flex;
    @include md {
      flex-direction: column-reverse;
    }
  }
  .yourquote-block {
    margin-top: 30px;
    @include md {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.input-effect.otp-inputs .btn {
  font-size: 14px;
}
.converexp .guardian-outer .guardian-details {
  width: 100%;
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
  @include xs {
    margin-bottom: 30px;
    .guardian-name h2 {
      font-size: 16px;
    }
  }
  .resume-action {
    margin-left: auto;
    .btn {
      @include xs {
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100px;
        min-width: 100px;
      }
    }
  }
}

@include md {
  .getstart-container .getstart-button .btn.btn-lg {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.mobile-toggle {
  display: none;
}
.header-info-toggle .close {
  display: none;
}
@include md {
  .mobile-toggle,
  .header-info-toggle .close {
    display: block;
  }
  .header-right > .agent-info {
    // position: absolute;
    // top: 100%;
    // background: #ffffff;
    // width: calc(100% + 30px);
    // right: -15px;
    // left: 7px;
    // border-bottom: 1px solid #e0e0e0;
    &.header-info-toggle {
      position: absolute;
      top: 0;
      background: #ffffff;
      width: 100%;
      right: 0;
      left: 0;
      border: 1px solid $color-primary;
      padding: 15px 15px;
      visibility: hidden;
      transform: translatey(-100%);
      transition: all ease 0.3s;
      z-index: 9;
      .img-container {
        display: none;
      }
      .close {
        cursor: pointer;
        float: right;
      }
      &.active {
        visibility: visible;
        transform: translatey(0%);
      }
    }
  }
  // .page-wrapper {
  //   padding-top: 125px;
  // }
}

@include xxs {
  .box-title.no-border .plan-icons-outer {
    margin-top: 15px;
  }
}

.status-online {
  color: #fff;
  background: #20e21a;
  padding: 6px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 10px;
  font-size: 14px;
}

.status-offline {
  color: #fff;
  background: #dc3545;
  padding: 6px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 10px;
  font-size: 14px;
}

// ======================
//   New design changes
//=======================

.planspage {
  .filter-block {
    position: relative;
    z-index: 22;
  }
  .filter-block2 {
    position: relative;
    z-index: 2;
  }
  .filter-block3 {
    position: relative;
    z-index: 0;
    .filter-item {
      flex-direction: inherit;
    }
  }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
    top: 100% !important;
    transform: translate(1px, 20px) !important;
    .react-datepicker__triangle {
      top: -8px;
      background: transparent !important;
      border: transparent !important;
      &::before {
        border-width: 8px;
        border-bottom-color: #2ba84a;
        border-top: none;
        transform: rotate(0);
        bottom: -8px;
      }
    }
  }
}

.title-header .page-title {
  font-weight: $font-bold;
}
.title-header h4 {
  color: $color-text-default;
  font-weight: $font-regular;
  // margin-top: 10px;
  max-width: 740px;
  margin: 0 auto;
  font-size: 16px;
  @include md {
    font-size: 14px;
  }
}

.notes {
  color: $color-primary;
  font-weight: $font-semibold;
  line-height: 1.4;
}

.your-age-page {
  @include xs {
    .custom-datepicker .rsd__react-select-datepicker {
      display: flex;
      flex-direction: column;
    }
    .custom-datepicker .rsd__react-select-datepicker > div {
      margin-bottom: 14px;
    }
  }
  .react-datepicker-wrapper .text-danger {
    display: block;
  }

  .white-box {
    box-shadow: none;
    margin-bottom: 0;
  }
  .custom-datepicker {
    text-align: center;
    .text-danger {
      display: block;
    }
    .react-datepicker-wrapper {
      &:before {
        background-image: url("../assets/images/icon/calendar.svg");
      }
      max-width: 200px;
      input {
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid $color-primary;
        background: #eeeeee;
      }
    }
  }

  .datepicker-label {
    opacity: 0;
  }
}
.years-page {
  .yearblock-width {
    max-width: 240px;
    @include xs {
      max-width: initial;
    }
  }
  .boxmodal-radio input[type="radio"] + .card-inner .card-text,
  .boxmodal-checkbox input[type="radio"] + .card-inner .card-text {
    &.year-box {
      height: 125px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 600;
      font-size: 30px;
      line-height: 34px;
      @include md {
        font-size: 24px;
      }
    }
  }
}

.inverst-type-page .card-inner {
  display: flex;
  flex-direction: column;
  .pros-cons {
    margin-bottom: 15px;
    height: 100%;
  }
}

.boxmodal-radio {
  input[type="radio"] + div.card-inner,
  input[type="radio"] + div.card-inner {
    border: 1px solid $border-color;
    padding-left: 0;
    padding-right: 0;
    // padding-top: 25px;
    // padding-bottom: 25px;
    background: $bg-white;

    > * {
      padding-left: 20px;
      padding-right: 20px;
    }
    .card-img {
      padding-left: 0;
      padding-right: 0;
    }
  }
  h2.page-title {
    font-size: 26px;
    font-weight: 900;
    @include mdtolg {
      font-size: 18px;
    }
    @include xlUptoDesktopLarge {
      font-size: 22px;
    }
    @include md {
      font-size: 20px;
    }
  }
  .cart-desc {
    text-align: left;
    color: $color-text-default;
    display: inline-block;
    margin-bottom: 40px;
    font-weight: 400;
    @include lg {
      font-size: 14px;
    }
  }
  .commin-soon-main {
    .card-body {
      margin-top: 105px;
      margin-bottom: 105px;
      @include md {
        margin-top: 70px;
        margin-bottom: 70px;
      }
    }
    .comming-soon {
      font-size: 35px;
      font-weight: 800;
      margin: 40px 0;
      display: inline-block;
      color: $color-primary;
    }
  }

  .bestfor {
    border-top: 2px solid $color-primary;
    // background-color: $color-primary-default;
    text-align: left;
    color: $color-text-default;
    // font-weight: $font-semibold;
    padding: 15px;
    font-size: 16px;
    line-height: 1.4;
    height: 100%;
    max-height: 120px;
    margin-top: auto;
    @include lg {
      font-size: 14px;
    }
  }
  .pros-cons {
    text-align: left;
    margin-top: 15px;
    strong {
      color: $color-text-default;
      font-size: 16px;
      font-weight: $font-bold;
      @include lg {
        font-size: 14px;
      }
    }
    ul li {
      font-weight: $font-medium;
      color: $color-dark-gray;
      font-size: 16px;
      line-height: 24px;
      @include lg {
        font-size: 14px;
      }
    }
    ul.list-dot li {
      position: relative;
      padding-left: 12px;
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        display: inline-block;
        background: #797979;
        border-radius: 50%;
        top: 9px;
        left: 0;
        position: absolute;
      }
    }
  }
}
.demopage {
  .demopagebody {
    background-size: cover;
    background-position: center;
  }
  .head-row {
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include md {
      padding: 15px 0px;
    }
    .pre-logo {
      max-width: 150px;
      max-height: 150px;
      @include md {
        max-width: 100px;
      }
      @include xs {
        max-width: 80px;
      }
    }
    .head-title {
      margin-left: 30px;
      @include md {
        margin-left: 10px;
      }
      .ins-title {
        font-weight: $font-regular;
        font-size: 22px;
        color: $color-primary;
        margin-bottom: 2px;
        @include md {
          font-size: 18px;
        }
        @include xxs {
          font-size: 16px;
        }
      }
      .ins-copy {
        font-size: 12px;
        font-weight: $font-regular;
        color: $color-black;
        line-height: 12px;
      }
    }
  }
  .premodel-footer {
    background-color: $light-black-bg;
    justify-content: center;
    padding: 0 70px;
    border-radius: 0;
    flex-direction: column;
    @include md {
      padding: 0 20px;
    }
    .ftitle-block {
      border-bottom: 1px solid #747474;
      width: 100%;
      padding: 15px 0 5px;
      .ftitle {
        color: $color-primary;
      }
    }
    .footerTitle {
      color: $color-white;
    }
    .fcontact {
      padding: 10px 0;
      margin-left: 0;
      width: 100%;
      @include md {
        width: 100%;
      }
      p {
        display: inline-flex;
        padding-bottom: 0;
        color: $color-white;
        font-weight: $font-regular;
        width: calc((100% / 3) - 0px);
        justify-content: center;
        @include md {
          display: flex;
          // justify-content: flex-start;
          width: 100%;
        }
        &.f-address {
          @include mdUp {
            justify-content: flex-end;
            width: 35%;
          }
        }
        &.f-tell {
          @include mdUp {
            padding: 0 20px;
            justify-content: center;
            width: 30%;
          }
          @include md {
            margin: 0;
          }
        }
        &.f-web {
          @include mdUp {
            justify-content: flex-start;
            width: 35%;
          }
        }
      }
    }
  }
}

.uploadlogo .upload-btn-wrapper {
  display: inline-block;
  position: relative;
  .img-delete {
    position: absolute;
    top: 10px;
    right: 10px;
    color: red;
  }
}

.uploadbgimg .upload-btn-wrapper {
  display: block;
  width: 100%;
}
.uploadbgimg .upload-btn-wrapper input[type="file"] {
  width: 100%;
}

.what-type {
  input[type="radio"] + .card-inner {
    padding-bottom: 0;
    .bestfor {
      margin-top: auto;
    }
  }
  .boxmodal-radio {
    .page-title {
      font-size: 22px;
      height: 50px;
      line-height: 24px;
      margin-bottom: 30px !important;
    }
  }

  .boxmodal-radio input[type="radio"] + .card-inner,
  .boxmodal-radio input[type="radio"] + .card-inner {
    display: flex;
    flex-direction: column;
    .notes {
      margin-top: auto;
      padding: 0 60px;
      font-weight: $font-semibold;
      @include md {
        padding: 0 20px;
      }
    }
  }
  .pros-cons {
    margin-bottom: 15px;
    min-height: 100px;
    @include lg {
      min-height: 130px;
    }
    @include md {
      min-height: inherit;
    }
  }
  .cart-desc {
    margin-bottom: 40px;
    min-height: 210px;
    font-size: 18px;
    font-weight: 400;
    @include lg {
      font-size: 14px;
    }
    @include sm {
      min-height: inherit !important;
      font-size: 14px;
    }
    @include md {
      min-height: inherit;
    }
  }
}

.inverst-type-page {
  @extend .what-type;
  .cart-desc {
    min-height: 110px;
    @include sm {
      min-height: initial;
    }
    @include lgtomd {
      min-height: 170px;
      font-size: 14px;
    }
    @include mdtolg {
      min-height: 170px;
      font-size: 16px;
    }
  }
}

.inverst-type-page .card-inner .pros-cons {
  margin-bottom: 15px;
}

.ranchers-best-for {
  &.bestfor {
    max-height: 95px;
    @include md {
      max-height: 110px;
    }
  }
}
.props-height {
  height: 100%;
  min-height: 160px;
}

// Navbar

.welcome-background {
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  // background-size: contain;
}
.tooltip-outer {
  background: $color-primary;
  padding: 15px;
  border-radius: 2px;
  color: $color-white;
  font-size: 14px;
  @include lg {
    min-width: 300px;
  }
}
@media only screen and (min-width: 1921px) {
  .planspage .owl-carousel.owl-theme.owl-loaded {
    padding-left: 0;
    padding-right: 0;
  }
  .planspage .plan-list-wrapper .owl-carousel .owl-nav .owl-prev {
    margin-left: -25px;
  }
  .planspage .plan-list-wrapper .owl-carousel .owl-nav .owl-next {
    margin-right: -7px;
  }
}

// Datepicker Css
.single-page-application .rsd__react-select-datepicker {
  justify-content: start;
  .rsd__select-month{
    margin-left: 0;
  }
}

.rsd__react-select-datepicker {
  justify-content: center;
  select {
    padding: 10px 30px 10px 10px;
    background: #f5f5f5;
    border: none;
    border-radius: 3px;
    margin: 0 10px;
  }
}

.rsd {
  .error-message {
    color: #dc3545;
  }
}

.offlinemessage {
  padding: 20px;
  border-radius: 10px;
  background-color: #f1f4ed;
  margin-top: 20px;
}
.enquiryForm {
  padding: 20px;
  margin-top: 20px;
  input {
    box-shadow: 2px 2px 5px 1px #dcdcdc;
  }
}
.with-video {
  .body-block,
  .iframe-video {
    width: 560px;
    height: 400px !important;
    max-width: 100%;
    @include sm {
      margin: 10px 0;
      width: 100% !important;
      max-width: 550px;
    }
  }
  @include sm {
    .body-block {
      height: auto !important;
      margin-top: 50px !important;
      min-height: 300px;
    }
  }
  .iframe-video {
    min-height: 300px !important;
    background-color: #000;
  }
}

.welcomePageBg {
  /* The image used */

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.demopagebody {
  z-index: 9;
}
form#manual-form {
  .custom-datepicker .react-datepicker-wrapper input {
    font-size: 0.9rem;
    padding: 10px 25px 10px 18px;
  }
  .custom-datepicker .react-datepicker-wrapper::before {
    width: 15px;
    height: 18px;
    right: 10px;
    background-size: 15px;
  }
}

// Progress layout css

.custom-radio {
  label {
    color: #333;
  }
}
.progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step;
  display: flex;
  justify-content: center;
  li.focus {
    &:before {
      color: #2ba84a;
      border: 2px solid #2ba84a;
      content: counter(step);
      counter-increment: step;
    }
  }
  li.active {
    &:before {
      color: #2ba84a;
      border: 2px solid #2ba84a;
      content: "";
      background-image: url(/assets/images/check.png);
      height: 30px;
      width: 30px;
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 6px 7px;
    }
    color: #2ba84a;
  }
  li {
    list-style-type: none;
    color: white;
    font-size: 14px;
    color: #000;
    flex: 0.1;
    position: relative;

    text-align: center;
    &:before {
      content: counter(step);
      counter-increment: step;
      width: 30px;
      line-height: 25px;
      display: block;
      font-weight: 600;
      color: #797979;
      font-size: 16px;
      background: white;
      border-radius: 3px;
      margin: 0 auto 5px auto;
      border-radius: 50px;
      text-align: center;
      height: 30px;
      border: 2px solid #797979;
    }
    &:after {
      content: "";
      width: 70%;
      height: 2px;
      background: #d1d1d1;
      position: absolute;
      left: -35%;
      top: 14px;
      z-index: -1;
    }
    &:first-child {
      &:after {
        content: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .progressbar {
    li {
      flex: 1;
      span {
        display: none;
      }
    }
  }
}

.single-page-custom-slider {
  .range-block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .rangeslider-horizontal {
      height: 8px;
      width: 100%;
      max-width: 500px;
      box-shadow: none;
      @include md {
        max-width: 100%;
        margin-inline-start: auto;
        .rangeslider__labels {
          .rangeslider__label-item:first-child {
            left: calc(100% - 40px) !important;
          }
          .rangeslider__label-item:last-child {
            left: 30px !important;
          }
        }
      }
      .rangeslider__handle {
        width: 20px;
        height: 20px;
        border-radius: 20px;

        .rangeslider__handle-label {
          display: none;
        }

        &:after {
          display: none;
        }
      }
      .rangeslider__fill {
        box-shadow: none;
      }
    }

    .value {
      min-width: 150px;
      height: 40px;
      padding: 1px 10px;
      text-align: center;
      border: 1px solid $border-color;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      @include md {
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
      }
      p {
        color: $color-black;
      }
    }
  }
}

.citizen {
  label {
    display: block;
    span {
      color: #333333;
    }
  }
}

// -------------- Single Page Css ----------------

.single-page-application {
  @media screen and (min-width: 991px) {
    max-width: 860px;
    .number-format-amount {
      margin-left: 0;
      // margin-top: 10px;
    }
  }
  h2 {
    color: $color-text-title;
  }
  .single-page-box {
    margin-bottom: 50px;
    h4 {
      font-size: 18px;
      color: $color-text-title;
      margin-bottom: 15px;
    }
    @media screen and (max-width: 991px) {
      .num_value {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media screen and (max-width: 400px) {
      &.birthday {
        .rsd {
          .rsd__react-select-datepicker select {
            padding: 10px 0px 10px 10px;
          }
          .rsd_year-container,
          .rsd_month-container {
            width: 100px;
          }
        }
      }
    }

    .number-format-amount {
      border-radius: 50px;
      margin-left: 30px;
      text-align: center;
      border: 2px solid $color-primary;
      color: #091f1f;
      @media screen and (max-width: 991px) {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
  .browse-btn {
    margin-top: 10px;
  }
  .btn-back-single-page {
    margin-right: 10px;
    border-color: #091f1f;
    color: #091f1f;
  }
  .gender {
    label {
      div {
        border: 2px solid #cdd7d9;
        display: inline-block;
        padding: 7px 36px;
        border-radius: 50px;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 10px;
        color: #091f1f;
        background-color: var(--color-white);
        &:hover {
          background-color: var(--color-white);
          border: 2px solid var(--color-over-effect);
        }
      }
      div.active {
        background-color: var(--background-gray);
        border: 2px solid $color-primary;
        color: var(--color-txt-df);
      }
    }
  }
  .health {
    label {
      height: auto;
    }
    div {
      .health-box {
        border: 2px solid #cdd7d9;
        width: 120px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        padding: 5px;
        justify-content: center;
        margin-right: 20px;
        border-radius: 15px;
        cursor: pointer;
        height: 100%;
        color: #091f1f;
        background-color: var(--color-white);
        img {
          display: block;
          text-align: center;
          @media screen and (max-width: 991px) {
            width: 50px !important;
          }
          color: $color-primary;
        }
        &:hover {
          background-color: var(--color-white);
          border: 2px solid var(--color-over-effect);
        }
      }
      .health-box.active {
        background-color: var(--background-gray);
        border: 2px solid $color-primary;
        color: var(--color-txt-df);
      }
    }
  }
  .smoke {
    .smoke-box {
      border: 2px solid #cdd7d9;
      border-radius: 14px;
      width: 120px;
      height: 120px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      margin-right: 20px;
      cursor: pointer;
      color: #091f1f;
      background-color: var(--color-white);
      img {
        width: 62px;
        height: 62px;
        display: block;
        margin: auto;
      }
      label {
        margin: 0;
      }
      &:hover {
        background-color: var(--color-white);
        border: 2px solid var(--color-over-effect);
      }
    }
    .smoke-box.active {
      background-color: var(--background-gray);
      border: 2px solid $color-primary;
      color: var(--color-txt-df);
    }
  }

  .coveragelast {
    .smoke-box {
      width: auto;
      height: auto;
      padding: 10px;
      text-transform: initial;
      img {
        width: auto;
        height: 48px;
        margin-right: 10px;
      }
    }
  }
  &.active {
    background-color: var(--color-white);
    border: 2px solid $color-primary;
  }
}

@media screen and (max-width: 600px) {
  .health {
    div {
      .health-box {
        width: 100px !important;
      }
    }
  }
}
// new planlisting page css
.plan-list-wrapper .item {
  width: calc((100% / 3) - 32px);
  display: inline-block;
  vertical-align: top;
  @include md {
    width: calc((100% / 2) - 32px);
  }
  @include sm {
    width: calc((100% / 1) - 32px);
  }
}
.planspage {
  .plan-btn-block {
    position: fixed;
    right: 115px;
    bottom: 40px;
    background: #fff;
    border-radius: 59px;
    z-index: 99;
    @include sm {
      right: 70px;
      bottom: 10px;
    }

    .btn {
      @include xs {
        margin: 5px;
        font-size: 10px;
        max-width: 100px;
        min-width: 100px;
        padding: 5px;
      }
      &.btn-primary {
        background: rgb(1, 160, 178);
        background: linear-gradient(
          90deg,
          rgba(1, 160, 178, 1) 0%,
          rgba(42, 168, 81, 1) 100%
        );
        color: #fff;
        border: 0;
        height: 45px;
        @include md {
          height: 40px;
          font-size: 16px;
        }
        @include xs {
          font-size: 10px;
        }

        &:hover {
          background: linear-gradient(
            90deg,
            rgba(1, 160, 178, 1) 100%,
            rgba(42, 168, 81, 1) 0%
          );
        }
      }
      &.btn-primary-outline {
        height: 45px;
        @include md {
          height: 40px;
        }
      }
    }
  }
  span.header-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #ff9500;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    padding: 2px 0;
  }
}
.show-more {
  text-align: center;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
}

.compare-bank-acc {
  max-width: 250px;
  margin: 0 auto;
  border-bottom: 0;
  .custom-select__single-value {
    font-size: 1.2rem;
  }
  .default-select.no-shadow .custom-select-menu .custom-select__control {
    background-color: #f1f4ed;
  }
}
.comparison-block {
  .card-value-list {
    padding-bottom: 0;
    .value-list-item {
      display: inline-block;
      margin: 10px;
      .list-label {
        margin-right: 6px;
        &:after {
          content: ":";
          padding-left: 6px;
        }
        span {
          display: none;
        }
      }
      > * {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .card-inner {
    border-color: $color-primary-default;
    background-color: #f1f4ed;
    .card-title-fixed {
      color: $color-primary;
      font-size: 1.2rem;
      position: static;
    }
  }
}
// new planlisting page css
.planlist-main {
  .planList-tab {
    display: flex;
    justify-content: center;
  }
  text-align: center;
  .online-carriers-btn {
    &.active {
      background: #ff9500;
      border-color: #ff9500;
      color: $color-white;
    }
    background: #fff;
    border: 2px solid #787878;
    color: $color-text-default;
    padding: 15px 25px;
    font-weight: 600;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 18px;
    margin: 0 5px;
    @include sm {
      padding: 10px 10px;
      font-size: 13px;
      img {
        width: 10px;
      }
    }
  }
  .offline-carriers-btn {
    &.active {
      background: #2ba849;
      border-color: #2ba849;
      color: $color-white;
    }
    background: #fff;
    border: 2px solid #787878;
    color: $color-text-default;
    padding: 15px 25px;
    font-weight: 600;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 18px;
    margin: 0 5px;

    @include sm {
      padding: 10px 10px;
      font-size: 13px;
    }
  }
  .planlist-tab-content {
    border: 3px solid #000;
    border-radius: 10px;
    padding: 30px 30px;
    text-align: left;
    position: relative;
    z-index: 1;
    top: -3px;
    min-height: 500px;
    &.offline {
      border-color: #2ba849;
      background: #f1f4ed;
    }
    &.online {
      border-color: #ff9500;
      background-color: #fff9f1;
    }
  }
  .card-value-list {
    height: 160px;
    @include md {
      height: 180px;
    }
  }
  .policy-name {
    @include md {
      height: 50px;
    }
  }
  // .plan-list-wrapper .item:nth-child(3n+1) label {
  //     margin-bottom: 0;
  // }
}
.policy-type-title {
  color: #333333;
}

.error {
  border-radius: 3px;
  background: #dc35451c;
  padding: 6px;
  margin-top: 15px;
  p {
    padding: 0;
  }
}
.color-grey {
  color: $color-dark-gray;
}
.font-size-18 {
  font-size: 18px;
  @include md {
    font-size: 16px;
  }
}

// New SBLI Layout Css

.sbli-main-footer {
  display: flex;
  align-items: baseline;
  background-color: #f2f2f2;
  position: relative;
  left: 0;
  border-top: 2px solid $color-primary;
  bottom: 0;
  padding: 1rem 2rem;

  img {
    width: 90px;
    margin-right: 20px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0;
    color: #797979;
  }
}

#myProgress {
  width: 100%;
  background-color: #f5f5f5;
  position: absolute;
  top: 80px;
  left: 0;
}

#myBar {
  height: 15px;
  background-color: #2ba849;
  transition: all 0.4s;
}

.sbli-main-question,
.sbli-user-detail,
.sbli-terms-container,
.sbli-beneficiary-container,
.sbli-documents-main,
.denial-page-container {
  width: 100%;
  max-width: 900px;
  margin: auto;
  margin-bottom: 75px;
  .btn-default {
    height: 53px;
    width: 168px;
    font-size: 16px;
    color: $color-primary;
    &:hover {
      background-color: $color-primary;
      color: #fff;
    }
  }
  .btn-link {
    font-size: 16px;
    color: #797979;
  }
}

.sbli-main-user-detail-question {
  h4 {
    font-weight: 500;
    font-size: 18px;
    color: #333;
  }
}

.third-party-notification {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 75px;
}

.sbli-question-container {
  h4 {
    font-weight: 500;
    font-size: 18px;
    color: #333;
  }

  .custom-radio {
    label {
      border: 1px solid #d7dadb;
      display: flex !important;
      padding: 1rem 3rem;
      border-radius: 5px;
      font-size: 18px;
      color: #333;
      height: 67px;
      align-items: center;
      transition: all 0.3s;

      input[type="radio"] + span {
        margin-left: 1rem;
        border: 1px solid #2ba849 !important;
      }
      &:hover {
        border: 1px solid $color-primary;
      }
    }
  }
  .custom-checkbox {
    label {
      border: 1px solid #d7dadb;
      display: flex;
      padding: 1rem 3rem;

      font-size: 18px;
      color: #333;
      min-height: 67px;
      align-items: center;
      transition: all 0.3s;
      input[type="radio"] + span {
        margin-left: 1rem;
        border: 1px solid #2ba849 !important;
      }
      &:hover {
        border: 1px solid $color-primary;
      }
      &::first-letter {
        font-size: 200%;
        color: #8a2be2 !important;
      }
    }
  }
}

.highlighted-header {
  background-color: #f2f2f2;
  border-bottom: 3px solid $color-primary;
  padding: 1rem 2rem;
  text-align: center;
  color: #000;
  p,
  b {
    font-size: 16px;
    color: #333;
  }
}

.sbli-terms-container {
  p {
    color: #000;
  }
}

.sbli-beneficiary-container {
  table {
    td {
      vertical-align: middle;
      img {
        width: 25px;
      }
    }
  }
}

.beneficiary-body {
  h4 {
    color: #000;
  }

  .label-checkbox {
    label {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 0.5rem 2rem;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      margin-bottom: 10px;
      &.active {
        border: 2px solid #2ba84a;
        background-color: #f6f4f5;
      }
      input {
        visibility: hidden;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }
}

.sbli-coverage {
  .sbli-premium {
    .premium-box {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 1px solid #dbe2de;
      padding: 2rem;
      text-align: center;
      background-color: #fff;
      h1 {
        font-size: 3.5rem;
        color: #2ba849;
        sup {
          font-size: 22px;
          top: -1.4em;
        }
      }
      h4 {
        font-size: 20px;
        color: #000;
        font-weight: 600;
        span {
          font-size: 16px;
          color: #5a5a5a;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../assets/images/bg-victory.png);
        width: 100%;
        height: 100%;
        z-index: -2;
        margin: 0 auto;
        background-size: 94%;
        background-repeat: no-repeat;
      }
    }
  }
  .sbli-terms {
    label {
      width: 100%;
      border: 1px solid #dbe2de;
      padding: 1rem;
      border-radius: 8px;
      color: #000;
      margin-bottom: 15px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      span {
        span {
          font-size: 22px;
        }
      }
      &.active {
        border: 1px solid #2ba849;
      }
    }
  }
}
.recaptcha {
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
}
.process {
  text-align: center;
  @include sm {
    margin-bottom: 20px;
  }
  .img-block {
    width: calc(100% - 15px);
    // height: 300px;
    height: auto;
    background-color: #efefef;
    border-radius: 12px;
    position: relative;
    img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
    }
    &.green-shadow {
      &:after {
        content: "";
        width: 100%;
        height: 100%;

        position: absolute;
        right: -15px;
        bottom: -15px;
        z-index: -1;
        border-radius: 15px;
        background: linear-gradient(90deg, #fff 0%, #ebf9e8 47%, #c5efb8 84%);
      }
    }

    &.blue-shadow {
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        right: -15px;
        bottom: -15px;
        z-index: -1;
        border-radius: 15px;
        background: linear-gradient(90deg, #fff 0%, #ffffff 47%, #d1eff8 84%);
      }
    }
  }
  .process-desc {
    font-size: 18px;
    color: $light-black-bg;
    padding-bottom: 0;
    margin: 40px 10px 25px;
    font-weight: $font-bold;
    @include sm {
      font-size: 16px;
    }
  }
}

.sbli-coverage-loader {
  background: linear-gradient(
    270deg,
    rgba(43, 168, 74, 1) 0%,
    rgba(4, 159, 181, 1) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12345678;
  .sbli-coverage-progress {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      font-weight: 600;
      font-size: 22px;
    }
    p {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .coverage-loader {
      border: 2px solid #fff;
      height: 50px;
      width: 80%;
      border-radius: 5px;
      padding: 5px;
      .sbli-cv-progress {
        background-color: #fff;
        height: 100%;
        width: 0;
        transition: all 0.2s;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .percentage {
      font-size: 26px;
      font-weight: 600;
    }
  }
}

.pre-bg {
  background-image: url(../assets/images/bg-congratulation.png);

  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 3rem 0;
  .pre-approval-box {
    border: 1px solid #cacaca;
    width: 100%;
    max-width: 400px;
    margin: auto;
    text-align: center;

    div {
      height: 100px;
      padding: 1rem;
      background-color: #fff;
    }
    .pre-approval-bg {
      background-color: #eee;
      border-bottom: 1px solid #cacaca;
    }
  }
}

.sbli-documents-main {
  .pdf-container {
    background-color: #ddd;
    height: 700px;
    border-radius: 5px;
  }
  .pdf-container-button {
    border: 2px solid #ddd;

    button {
      color: #000;
      img {
        width: 25px;
      }
    }
  }
}

.apply-modal {
  .modal-dialog {
    max-width: 400px;
    .modal-content {
      .modal-body {
        h4 {
          font-size: 23px;
          color: #000;
          text-align: center;
        }
        p {
          text-align: center;
          line-height: 24px;
          color: #000;
        }
      }
    }
  }
}
.sbli-question-container {
  .sbli-parent-question {
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-bottom: 75px;
  }
  .attr-question {
    .height-box {
      display: flex;
      justify-content: center;
      .unit-box {
        width: 70px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          border: 1px solid #2ba849;
        }
        &.active {
          border: 1px solid #2ba849;
        }
      }
    }
  }
}
.validate-question-parent {
  .parent_question_head {
    color: #dc3545 !important;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: shake;
    animation-name: shake;
  }
}

.assurity-limitation {
  ul {
    padding: 1rem;
    li {
      list-style: disc !important;
    }
  }
}

.assurity-validate {
  color: #dc3545 !important;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: shake;
  animation-name: shake;
}
.validate-question-child {
  .child-question_head,
  h4 {
    color: #dc3545 !important;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: shake;
    animation-name: shake;
  }
}

@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(10px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

// --------------- Assurity Design CSS 1 -----------------
.design-1 {
  .assurity-progress-1 {
    display: flex;
    align-items: center;
    div {
      flex: 1;
      position: relative;
      margin: 0 0.5rem;
      p {
        font-size: 16px;
        color: #b5b5b5;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4px;
        border-radius: 5px;
        background-color: #d7d7d7;
      }
      &.active {
        p {
          color: #2ba849;
        }
        &::after {
          background-color: #2ba849;
        }
      }
    }
  }
}

.design-2 {
  .assurity-content-container {
    margin-top: -13px;
    background-color: #fff;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
  }
  .assurity-progress-2 {
    background-color: #2ba849;
    padding-top: 15px;
    height: 85px;
    .progressbar {
      li {
        font-size: 11px;
        color: #fff;
        &::before {
          width: 20px;
          height: 20px;
          line-height: 16px;
          font-size: 10px;
          border: 2px solid #fff;
        }
        &:after {
          top: 10px;
          background: #fff;
          z-index: 0;
        }
      }
    }
  }
}

.design-3 {
  position: relative;
  .assurity-progress-3 {
    #myProgress {
      top: 13px;
    }
    #myBar {
      height: 5px;
    }
    .step-bar {
      position: absolute;
      top: 0;
      width: 100%;
      .as-step {
        flex: 1;
        text-align: center;
        div {
          width: 30px;
          height: 30px;
          background: #f5f5f5;
          border-radius: 50%;
          margin: auto;
        }
        p {
          font-size: 12px;
        }
        &.active {
          div {
            background-color: #2ba849;
            color: #fff;
          }
          p {
            color: #2ba849;
          }
        }
      }
    }
  }
}

.card-inner {
  overflow: hidden;
  position: relative;
  .card-strip {
    // background-color: #039fb5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

// assurity page
.assurity {
  color: $color-black;
  max-width: 776px;
  margin: 0 auto;
  .heading-block {
    text-align: center;
    margin-bottom: 25px;
    h3 {
      font-size: 24px;
      color: $color-black;
    }
    h4 {
      font-size: 16px;
    }
  }
  .white-box {
    border: 1px solid #333;
  }
}

.pre-questions-container {
  .parent_question_head {
    ul {
      list-style: circle;
      list-style-type: circle;
      li {
        margin: 8px 0;
        font-size: 14px;
      }
    }
  }
}

.menu-list {
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  font-size: 90%;

  overflow: auto;
  max-height: 300px;
  div {
    margin-bottom: 6px;
    &:hover {
      font-weight: 600;
      color: #000;
      cursor: pointer;
    }
  }
}
.StripeElement {
  height: 50px;
  padding: 10px 12px;
  width: 550px;
  color: #32325d;
  background-color: white;
  border: 1px solid #dbe2de;
  border-radius: 4px;
  // box-shadow: 0 1px 3px 0 #e6ebf1;
  // -webkit-transition: box-shadow 150ms ease;
  // transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-images {
  align-items: center;
  display: flex;
  height: 100%;
  img {
    width: 150px;
  }
}

.progress {
  display: block;
  width: 100%;
  height: 12px;
  position: relative;
  z-index: 5;
}

.progress[value] {
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.progress[value]::-ms-fill {
  background-color: #0074d9;
  border: 0;
}

.progress[value]::-moz-progress-bar {
  background-color: #0074d9;
  margin-right: 8px;
}

.progress[value]::-webkit-progress-inner-element {
  background-color: #eee;
}

.progress[value]::-webkit-progress-value {
  background-color: #0074d9;
}

.progress[value]::-webkit-progress-bar {
  background-color: #eee;
}

.progress-circle {
  width: 24px;
  height: 24px;
  position: absolute;
  right: -21px;
  top: -5px;
  z-index: 999;
  border-radius: 50%;
}

.progress-circle:before {
  content: "";
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  display: block;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.progress-group {
  // margin-top: 50px;
  position: sticky;
  top: 80px;
  z-index: 999;
  background: #fff;
  padding: 20px 15px;
}

@media (max-width: 991px) {
  .progress-group {
    margin-left: -18px;
    margin-right: -18px;
    flex-basis: 100%;
    padding: 18px;
  }
}

@media (max-width: 768px) {
  .progress-group {
    padding: 18px 18px 0;
    margin-bottom: 12px;
  }
}

.progress-group .title {
  margin-bottom: 18px;
}

.progress-group .wrapper {
  background: white;
  border: 1px solid #eee;
  border-radius: 12px;
  display: flex;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.3));
  padding-right: 20px;
}

.progress-group .step {
  width: 20%;
  position: relative;
}

.progress-group .step:after {
  content: "";
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: -27px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  opacity: 0;
}

.progress-group .step:first-of-type .progress[value]::-moz-progress-bar {
  border-radius: 5px 0 0 5px;
}

.progress-group .step:first-of-type .progress[value]::-webkit-progress-value {
  border-radius: 5px 0 0 5px;
}

.progress-group .step:not(:first-of-type) .progress[value]::-moz-progress-bar {
  border-radius: 0;
}

.progress-group
  .step:not(:first-of-type)
  .progress[value]::-webkit-progress-value {
  border-radius: 0;
}

.progress-group .step .progress[value] + .progress-circle {
  background: #eee;
}

.progress-group .step.step .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step01 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step01 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step01 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step02 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step02 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step02 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step03 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step03 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step03 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step04 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step04 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step04 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step05 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step05 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step05 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
}

.progress-labels .label {
  text-align: center;
  text-transform: uppercase;
  margin: 12px 0;
  width: 20%;
  font-size: 11px;
  padding-right: 24px;
  font-weight: 600;
  opacity: 0.7;
}

.page-title {
  letter-spacing: -0.05rem;
}

.substeps {
  position: absolute;
  top: -23px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.substeps span {
  /* width: calc(100% / 9 - (24px / 9)); */
  width: calc(100% / 9);
  display: inline-block;
  text-align: right;
  font-size: 10px;
}

// // No page found CSS
// .error-page {
//   position: absolute;
//   top: 15%;
//   left: 15%;
//   right: 15%;
//   bottom: 15%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #fff;
//   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
// }
// .error-page .content {
//   max-width: 600px;
//   text-align: center;
// }
.content h2.header {
  font-size: 6vw;
  line-height: 1em;
  position: relative;
}

.content h4 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;
}
.content p {
  font-size: 1.2em;
  color: #0d0d0d;
}
.content .btns {
  margin: 25px 0;
  display: inline-flex;
}
.content .btns button {
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid $color-primary;
  color: $color-primary;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.content .btns button:hover {
  background: $color-primary;
  color: #fff;
}

.illustrationModel {
  .modal-dialog {
    .modal-body {
      .react-pdf__Document {
        .react-pdf__Page__canvas {
          margin: auto;
        }
      }
    }
  }
}

.text-field-success{
  cursor: pointer;
  position: absolute;
  margin-top: 12px;
  margin-left: 92%;
}
.carrier-desc-check {
  display: flex;
  align-items: baseline;
  span {
    border: 2px solid #2ba84a;
    font-size: 14px;
    border-radius: 50%;
    color: #2ba84a;
    margin-right: 10px;
  }
}
// ===========
// Error page
// ==========
// No page found CSS
.error-page {
  // position: absolute;
  margin-top: 75px;
   top: 15%;
   left: 15%;
   right: 15%;
   bottom: 15%;
   display: flex;
   align-items: center;
   justify-content: center;
   // background: #fff;
   // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
 }

 .error-page .content {
   max-width: 680px;
   text-align: center;
 }
 .fourzerofourimg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  z-index: -1;
}
// ===========
// Error page
// ==========

.rsd__select-container-day label, .rsd__select-container-year label {
  margin-left: 10px;
}

.single-page-box.birthday{
  .rsd__select-container-day label, .rsd__select-container-year label {
      margin-left: 10px;
  }
}