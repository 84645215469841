@import url(https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Epilogue:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Langar:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nerko+One:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ranchers:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap);
/* You can add global styles to this file, and also import other style files */
/*@import "../scss/bootstrap.scss";*/
/*Fonts*/
:root {
  --color-primary: #2ba84a;
  --color-secondary: #dd0000;
  --color-additional: #ffce00;
  --color-body: #839c9f;
  --color-background: #fbfcfc;
  --color-background-white: #ffffff;
  --color-headerapply:#ffffff;
  --color-white: #ffffff;
  --background-gray: #f3f3f3;
  --color-txt-df: #091f1f;
  --color-over-effect: #2ba84a;
  --color-success-lgt: #d6ffe0;
  --font-var: "Manrope";
}

/*Color*/
/*Form*/
/*Border color Style*/
/*Button color Style*/
/* Box-shadow */
:root {
  --color-primary: #2ba84a;
  --color-secondary: #dd0000;
  --color-additional: #ffce00;
  --color-body: #839c9f;
  --color-background: #fbfcfc;
  --color-background-white: #ffffff;
  --color-headerapply:#ffffff;
  --color-white: #ffffff;
  --background-gray: #f3f3f3;
  --color-txt-df: #091f1f;
  --color-over-effect: #2ba84a;
  --color-success-lgt: #d6ffe0;
  --font-var: "Manrope";
}

/*Large devices (desktops, grater than 1200px)*/
/*Large devices (desktops, less than 1200px)*/
/*tablets,desktops, grater than 992px*/
/* Medium devices (tablets, less than 992px) */
/*tablets,desktops, grater than 768px*/
/* Small devices (landscape phones, less than 768px)*/
/* Extra small devices (portrait phones, less than 576px) */
/* Extra small devices (portrait phones, less than 413px) */
/*Fonts*/
:root {
  --color-primary: #2ba84a;
  --color-secondary: #dd0000;
  --color-additional: #ffce00;
  --color-body: #839c9f;
  --color-background: #fbfcfc;
  --color-background-white: #ffffff;
  --color-headerapply:#ffffff;
  --color-white: #ffffff;
  --background-gray: #f3f3f3;
  --color-txt-df: #091f1f;
  --color-over-effect: #2ba84a;
  --color-success-lgt: #d6ffe0;
  --font-var: "Manrope";
}

/*Color*/
/*Form*/
/*Border color Style*/
/*Button color Style*/
/* Box-shadow */
/*Large devices (desktops, grater than 1200px)*/
/*Large devices (desktops, less than 1200px)*/
/*tablets,desktops, grater than 992px*/
/* Medium devices (tablets, less than 992px) */
/*tablets,desktops, grater than 768px*/
/* Small devices (landscape phones, less than 768px)*/
/* Extra small devices (portrait phones, less than 576px) */
/* Extra small devices (portrait phones, less than 413px) */
/*Headings*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 400;
  color: #839c9f;
}

h1,
.h1 {
  font-size: 2.5rem;
  line-height: 3rem;
}

h2,
.h2 {
  font-size: 2rem;
  line-height: 2.75rem;
  color: #091f1f;
  font-weight: 500;
}
@media only screen and (max-width: 991.98px) {
  h2,
  .h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}
@media only screen and (max-width: 767.98px) {
  h2,
  .h2 {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

h2 span {
  font-weight: 300;
}

h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #091f1f;
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h5,
.h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

h6,
.h6 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.mw-auto {
  min-width: auto !important;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.0509ab09.woff2) format("woff2");
}
div.react-datepicker {
  font-family: var(--font-var), sans-serif;
  border: none;
  color: #091f1f;
  box-shadow: 0 8px 24px 0 rgba(131, 156, 159, 0.16);
}
div.react-datepicker .react-datepicker__day {
  padding-top: 1px;
  font-weight: 400;
}
div.react-datepicker .react-datepicker__day--keyboard-selected {
  background-color: var(--color-primary);
  opacity: 1 !important;
  color: var(--color-white) !important;
}
div.react-datepicker .react-datepicker__header {
  border-bottom: none;
  background-color: var(--color-primary);
}
div.react-datepicker .react-datepicker__current-month {
  color: var(--color-white);
  font-weight: 400;
}
div.react-datepicker .react-datepicker-time__header {
  color: var(--color-white);
  font-weight: 400;
}
div.react-datepicker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  border-bottom-color: var(--color-primary);
}
div.react-datepicker .react-datepicker__day-name {
  color: var(--color-white);
}
div.react-datepicker .react-datepicker__day--disabled {
  opacity: 0.4;
  color: #091f1f;
}
div.react-datepicker .react-datepicker__day--selected {
  color: var(--color-white);
}
div.react-datepicker button.react-datepicker__navigation--previous {
  border-right-color: var(--color-white);
}
div.react-datepicker button.react-datepicker__navigation--next {
  border-left-color: var(--color-white);
}
div.react-datepicker .react-datepicker__day--selected {
  background: var(--color-primary);
  font-weight: normal;
}
div.react-datepicker .react-datepicker__day--selected:hover {
  background: var(--color-primary);
}
div.react-datepicker .react-datepicker__day--in-selecting-range {
  background: var(--color-primary);
  font-weight: normal;
}
div.react-datepicker .react-datepicker__day--in-selecting-range:hover {
  background: var(--color-primary);
}
div.react-datepicker .react-datepicker__day--in-range {
  background: var(--color-primary);
  font-weight: normal;
}
div.react-datepicker .react-datepicker__day--in-range:hover {
  background: var(--color-primary);
}
div.react-datepicker .react-datepicker__month-read-view, div.react-datepicker .react-datepicker__year-read-view {
  color: var(--color-white);
  font-size: 13px;
}
div.react-datepicker .react-datepicker__year-read-view--down-arrow, div.react-datepicker .react-datepicker__month-read-view--down-arrow, div.react-datepicker .react-datepicker__month-year-read-view--down-arrow {
  border-top-color: var(--color-white) !important;
  border-width: 0.3rem;
}
div.react-datepicker .react-datepicker__year-dropdown, div.react-datepicker .react-datepicker__month-dropdown, div.react-datepicker .react-datepicker__month-year-dropdown {
  background-color: var(--color-white);
  border-color: #cdd7d9;
  max-height: 250px;
  overflow-x: auto;
}
div.react-datepicker .react-datepicker__year-option, div.react-datepicker .react-datepicker__month-option, div.react-datepicker .react-datepicker__month-year-option {
  color: #091f1f;
}
div.react-datepicker .react-datepicker__year-option:hover, div.react-datepicker .react-datepicker__month-option:hover, div.react-datepicker .react-datepicker__month-year-option:hover {
  background: var(--color-primary);
  color: var(--color-white);
}
div select.react-datepicker__month-select, div select.react-datepicker__year-select {
  border: solid 1px var(--color-white);
  background: transparent;
  color: var(--color-white);
  margin: 5px 0 3px 0;
}
div select.react-datepicker__month-select option, div select.react-datepicker__year-select option {
  color: #1b3030;
}

div.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  background: transparent !important;
  border: transparent !important;
}
div.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: var(--color-primary);
  top: 0;
}
div.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  border-top-color: var(--color-white) !important;
}
div.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -7px;
  right: 0;
  border: 7px solid #cdd7d9;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(-45deg);
}
div.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background: rgba(131, 156, 159, 0.15);
}

/* ====== Begin: Custom DatePicker ========== */
.custom-datepicker {
  position: relative;
}
.custom-datepicker .react-datepicker-wrapper {
  width: 100%;
  position: relative;
}
.custom-datepicker .react-datepicker-wrapper::before {
  content: " ";
  position: absolute;
  width: 18px;
  height: 20px;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: url(/static/media/calendar-light.f6acb5e0.svg) transparent;
  cursor: pointer;
}
.custom-datepicker .react-datepicker-wrapper input {
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: #091f1f;
  line-height: 1.429rem;
  border: 1px solid #dbe2de;
  font-size: 1rem;
  padding: 10px 50px 10px 18px;
  height: 48px;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.custom-datepicker .react-datepicker-wrapper input:focus, .custom-datepicker .react-datepicker-wrapper input.focus {
  border-color: var(--color-primary);
}
@media only screen and (max-width: 767.98px) {
  .custom-datepicker .react-datepicker-wrapper input {
    height: 40px;
  }
}
.custom-datepicker .datepicker-label {
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  left: 16px;
  top: -7px;
  background: var(--color-white);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  margin: 0;
  padding: 0 3px;
}
.custom-datepicker .react-datepicker__day--selected,
.custom-datepicker .react-datepicker__day--keyboard-selected {
  background: var(--color-primary);
}
.custom-datepicker .react-datepicker__header {
  background: #f3f6f6;
}
.custom-datepicker .react-datepicker__navigation--previous {
  border-right-color: #091f1f;
}
.custom-datepicker .react-datepicker__navigation--next {
  border-left-color: #091f1f;
}
.custom-datepicker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  border-bottom-color: #f3f6f6;
}
.custom-datepicker .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  border-top-color: #f3f6f6;
}

/* ====== End: Custom DatePicker ========== */
.about-page .about-us {
  margin-top: 160px;
}
@media only screen and (max-width: 991.98px) {
  .about-page .about-us {
    margin-top: 100px;
  }
}
.about-page .about-us.our-database {
  margin-top: 130px;
}
@media only screen and (max-width: 1199.98px) {
  .about-page .about-us.our-database {
    margin-top: 120px;
  }
}
@media only screen and (max-width: 991.98px) {
  .about-page .about-us.our-database {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-page .about-us.our-database {
    margin-top: 40px;
    margin-bottom: 0;
  }
}

.about-us .row {
  align-items: center;
}
.about-us .about-us-wrapper.with-border {
  flex-wrap: wrap;
}
.about-us .about-us-wrapper .front-title-block + .front-title-block {
  border-top: solid 2px #cdd7d9;
  margin-top: 15px;
  padding-top: 40px;
}
@media only screen and (max-width: 991.98px) {
  .about-us .about-us-wrapper .front-title-block + .front-title-block {
    margin-top: 0;
    padding-top: 22px;
  }
}
.about-us.about-left {
  margin-top: 130px;
}
@media only screen and (max-width: 1199.98px) {
  .about-us.about-left {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 991.98px) {
  .about-us.about-left {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us.about-left {
    margin-top: 0;
  }
}
.about-us.about-left .about-us-img {
  float: none;
}
@media only screen and (max-width: 767.98px) {
  .about-us.our-mission {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us.our-mission .row > div:first-child {
    order: 2;
  }
}
.about-us.landing-bottom {
  margin-bottom: 160px;
}
@media only screen and (max-width: 1199.98px) {
  .about-us.landing-bottom {
    margin-bottom: 130px;
  }
}
@media only screen and (max-width: 991.98px) {
  .about-us.landing-bottom {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us.landing-bottom {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us.our-support .row > div:first-child {
    order: 2;
  }
}

.ilife-team {
  padding: 80px 0 60px 0;
}
@media only screen and (max-width: 1199.98px) {
  .ilife-team {
    padding: 20px 0 10px 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .ilife-team {
    padding: 50px 0 10px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .ilife-team {
    padding: 80px 0 10px 0;
  }
}
.ilife-team .container {
  padding-bottom: 50px;
  border-bottom: solid 6px rgba(131, 156, 159, 0.1);
}
@media only screen and (max-width: 767.98px) {
  .ilife-team .container {
    padding-bottom: 30px;
    border-width: 4px;
  }
}
.ilife-team .team-box {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 5px;
}
@media only screen and (max-width: 991.98px) {
  .ilife-team .team-box {
    margin-top: 30px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .ilife-team .team-box {
    margin-top: 25px;
  }
}
.ilife-team .team-box .team-image img {
  border-radius: 100%;
  object-fit: cover;
}
.ilife-team .team-content {
  margin-top: 25px;
  font-size: 1.25rem;
}
@media only screen and (max-width: 991.98px) {
  .ilife-team .team-content {
    font-size: 1rem;
    margin-top: 14px;
  }
}
.ilife-team .team-content .team-member {
  color: #091f1f;
  font-weight: 700;
}

.privacy-box {
  margin-bottom: 130px;
  margin-top: 130px;
  background: var(--color-white);
}
@media only screen and (max-width: 1199.98px) {
  .privacy-box {
    margin-bottom: 100px;
    margin-top: 100px;
  }
}
@media only screen and (max-width: 991.98px) {
  .privacy-box {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}
@media only screen and (max-width: 767.98px) {
  .privacy-box {
    margin-bottom: 60px;
    margin-top: 0;
  }
}
.privacy-box .container {
  overflow: unset;
}
.privacy-box .privacy-inner {
  box-shadow: -9px 12px 48px 1px rgba(131, 156, 159, 0.1);
  padding: 60px 100px;
  text-align: center;
}
@media only screen and (max-width: 991.98px) {
  .privacy-box .privacy-inner {
    padding: 35px 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .privacy-box .privacy-inner {
    padding: 20px;
  }
}

.how-work .title {
  text-align: center;
}
.how-work .container {
  overflow: unset;
}
@media only screen and (max-width: 1199.98px) {
  .how-work .container {
    max-width: 100%;
  }
}
@media only screen and (max-width: 991.98px) {
  .how-work .container {
    padding-right: 15px;
    padding-left: 15px;
    max-width: 96%;
  }
}
.how-work .work-middle {
  margin: 100px 0 150px 0;
  position: relative;
}
@media only screen and (max-width: 1499px) {
  .how-work .work-middle {
    margin: 70px 0 130px 0;
  }
}
@media only screen and (max-width: 1199.98px) {
  .how-work .work-middle {
    margin-bottom: 90px;
  }
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-middle {
    margin: 70px 0 30px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .how-work .work-middle {
    margin: 50px 0 20px 0;
  }
}
.how-work .work-middle:before {
  content: "";
  width: calc(50% - 600px);
  height: 36px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--color-primary);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
  border-radius: 20px 0 20px 0;
  border-right: 1px solid var(--color-primary);
}
@media only screen and (max-width: 1299px) {
  .how-work .work-middle:before {
    width: calc(50% - 575px);
  }
}
@media only screen and (max-width: 1199.98px) {
  .how-work .work-middle:before {
    width: 35px;
  }
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-middle:before {
    display: none;
  }
}
.how-work .work-middle:after {
  content: "";
  width: calc(50% - 600px);
  height: 36px;
  border-top: 1px solid var(--color-primary);
  border-bottom: 1px solid transparent;
  border-left: 1px solid var(--color-primary);
  border-radius: 20px 0 20px 0;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
}
@media only screen and (max-width: 1299px) {
  .how-work .work-middle:after {
    width: calc(50% - 575px);
  }
}
@media only screen and (max-width: 1199.98px) {
  .how-work .work-middle:after {
    width: 35px;
  }
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-middle:after {
    display: none;
  }
}
.how-work .work-middle .work-arrow1 {
  background: url("/public/assets/images/bgi/arrow-work.png") no-repeat center center;
  height: 12px;
  width: calc(50% - 600px);
  transform: translate(0, -50%);
  top: calc(50% + 17px);
  position: absolute;
}
@media only screen and (max-width: 1299px) {
  .how-work .work-middle .work-arrow1 {
    width: calc(50% - 575px);
  }
}
@media only screen and (max-width: 1240px) {
  .how-work .work-middle .work-arrow1 {
    display: none;
  }
}
.how-work .work-middle .work-arrow2 {
  background: url("/public/assets/images/bgi/arrow-work.png") no-repeat center center;
  height: 12px;
  width: 8px;
  top: 54%;
  position: absolute;
  right: -3px;
  transform: rotate(90deg);
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-middle .work-arrow2 {
    display: none;
  }
}
.how-work .work-middle .work-arrow3 {
  background: url("/public/assets/images/bgi/arrow-work.png") no-repeat center center;
  height: 12px;
  width: 8px;
  top: 29%;
  position: absolute;
  right: -3px;
  transform: rotate(-90deg);
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-middle .work-arrow3 {
    display: none;
  }
}
.how-work .work-box {
  counter-reset: my-awesome-counter;
}
.how-work .work-box .work-progress {
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  padding: 40px 22px;
  height: calc(100% - 50px);
  margin-bottom: 50px;
  color: #091f1f;
  counter-increment: my-awesome-counter;
  position: relative;
}
@media only screen and (max-width: 1199.98px) {
  .how-work .work-box .work-progress {
    padding: 40px 15px 20px 15px;
  }
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .work-progress {
    padding: 40px 20px 20px 20px;
  }
}
.how-work .work-box .work-progress:before {
  content: counter(my-awesome-counter);
  position: absolute;
  width: 60px;
  height: 60px;
  background: var(--color-white);
  border: solid 1px #EEEEEE;
  border-radius: 100%;
  text-align: center;
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  font-size: 2rem;
  line-height: 1;
  color: var(--color-primary);
  font-weight: 400;
  padding: 13px;
  pointer-events: none;
  z-index: 1;
}
@media only screen and (max-width: 1199.98px) {
  .how-work .work-box .work-progress:before {
    width: 50px;
    height: 50px;
    font-size: 1.32rem;
    top: -26px;
    padding: 15px;
  }
}
.how-work .work-box .work-progress p {
  padding: 0;
}
.how-work .work-box .row > div {
  z-index: 1;
  position: relative;
}
.how-work .work-box .row > div:first-child:before, .how-work .work-box .row > div:nth-child(3):before {
  position: absolute;
  content: "";
  height: 50%;
  width: 35px;
  border-top: 1px solid transparent;
  border-left: 1px solid var(--color-primary);
  border-radius: 20px 20px 0 0;
  left: -1px;
  top: -17px;
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .row > div:first-child:before, .how-work .work-box .row > div:nth-child(3):before {
    display: none;
  }
}
.how-work .work-box .row > div:first-child:after, .how-work .work-box .row > div:nth-child(3):after {
  position: absolute;
  content: "";
  width: calc(100% + 2px);
  height: 35px;
  border-top: solid 1px var(--color-primary);
  border-left: 1px solid transparent;
  border-right: 1px solid var(--color-primary);
  border-radius: 20px 20px 0 0;
  left: -2px;
  top: -18px;
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .row > div:first-child:after, .how-work .work-box .row > div:nth-child(3):after {
    display: none;
  }
}
.how-work .work-box .row > div:first-child .work-progress:after, .how-work .work-box .row > div:nth-child(3) .work-progress:after {
  position: absolute;
  content: "";
  height: calc(100% - 20px);
  width: 35px;
  border-bottom: 1px solid transparent;
  border-left: 1px solid var(--color-primary);
  border-radius: 0 0 0 0;
  right: -50px;
  top: 10px;
  left: auto !important;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .row > div:first-child .work-progress:after, .how-work .work-box .row > div:nth-child(3) .work-progress:after {
    display: none;
  }
}
.how-work .work-box .row > div:nth-child(3):before {
  height: calc(100% - 45px);
}
.how-work .work-box .row > div:nth-child(2n) .work-progress {
  padding-top: 22px;
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .row > div:nth-child(2n) .work-progress {
    padding-top: 40px;
  }
}
.how-work .work-box .row > div:nth-child(2n) .work-progress:after {
  position: absolute;
  content: "";
  width: calc(100% + 33px);
  height: 35px;
  border-bottom: solid 1px var(--color-primary);
  border-left: 1px solid var(--color-primary);
  border-right: 1px solid var(--color-primary);
  border-radius: 0 0 20px 20px;
  left: -17px;
  bottom: -20px;
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .row > div:nth-child(2n) .work-progress:after {
    display: none;
  }
}
.how-work .work-box .row > div:nth-child(2n) .work-progress:before {
  top: auto;
  bottom: -44px;
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .row > div:nth-child(2n) .work-progress:before {
    bottom: auto;
    top: -26px;
  }
}
.how-work .work-box .row > div:last-child:before {
  position: absolute;
  content: "";
  height: calc(50% - 55px);
  width: 35px;
  border-top: 1px solid transparent;
  border-left: 1px solid var(--color-primary);
  border-radius: 0;
  right: -34px;
  bottom: 55px;
}
@media only screen and (max-width: 991.98px) {
  .how-work .work-box .row > div:last-child:before {
    display: none;
  }
}
.how-work .work-box h4 {
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 15px;
}
@media only screen and (max-width: 1199.98px) {
  .how-work .work-box h4 {
    font-size: 1.3rem;
    line-height: 24px;
  }
}

@media only screen and (min-width: 992px) {
  .our-database .front-title-block .title {
    font-size: 2.44rem;
  }
}

/* ========= Contact Page ========= */
.contact-box {
  padding: 160px 0 100px 0;
}
@media only screen and (max-width: 1199.98px) {
  .contact-box {
    padding: 125px 0 80px 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .contact-box {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  .contact-box {
    padding-top: 100px;
    padding-bottom: 20px;
  }
}
.contact-box .container {
  overflow: unset;
}
.contact-box .front-title-block {
  margin-top: 85px;
}
@media only screen and (max-width: 767.98px) {
  .contact-box .front-title-block {
    margin-top: 0;
  }
}
.contact-box .contact-address {
  margin-top: 77px;
  font-size: 1.25rem;
  color: rgba(9, 31, 31, 0.5);
}
@media only screen and (max-width: 991.98px) {
  .contact-box .contact-address {
    font-size: 1rem;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-address {
    margin-top: 22px;
  }
}
.contact-box .contact-address .i-address {
  color: #091f1f;
}
.contact-box .contact-address .call-mail {
  margin-top: 20px;
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-address .call-mail {
    margin-top: 8px;
  }
}
.contact-box .contact-address .call-mail a {
  color: rgba(9, 31, 31, 0.5);
}
.contact-box .contact-address .call-mail a:hover {
  color: var(--color-primary);
}
.contact-box .contact-address .call-mail .call-div + .call-div {
  margin-top: 15px;
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-address .call-mail .call-div + .call-div {
    margin-top: 6px;
  }
}
.contact-box .contact-address .call-mail .svg-icon {
  width: 38px;
  display: inline-block;
  vertical-align: middle;
}
@media only screen and (max-width: 991.98px) {
  .contact-box .contact-address .call-mail .svg-icon {
    width: 25px;
  }
}
.contact-box .contact-address .call-mail .svg-icon .fill {
  fill: #091f1f;
}
@media only screen and (max-width: 991.98px) {
  .contact-box .contact-address .call-mail .svg-icon svg {
    width: 1rem;
    height: 1rem;
  }
}
.contact-box .contact-address .call-mail .contact-div {
  display: inline-block;
  width: calc(100% - 38px);
  vertical-align: middle;
}
@media only screen and (max-width: 991.98px) {
  .contact-box .contact-address .call-mail .contact-div {
    width: calc(100% - 25px);
  }
}
.contact-box .contact-dropbox {
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border-radius: 8px;
  padding: 46px 100px;
  margin-top: 25px;
}
@media only screen and (max-width: 1199.98px) {
  .contact-box .contact-dropbox {
    padding: 40px 60px;
  }
}
@media only screen and (max-width: 991.98px) {
  .contact-box .contact-dropbox {
    padding: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-dropbox {
    margin-top: 30px;
    padding: 25px 20px;
  }
}
.contact-box .contact-dropbox h2 {
  text-align: center;
  font-size: 2.5rem;
  color: rgba(130, 141, 141, 0.5);
  line-height: 1;
  font-weight: 700;
  margin: 0 0 55px 0;
}
@media only screen and (max-width: 991.98px) {
  .contact-box .contact-dropbox h2 {
    font-size: 2rem;
    margin: 0 0 35px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-dropbox h2 {
    margin-bottom: 26px;
    font-size: 1.75rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-dropbox .form-group {
    margin-bottom: 25px;
  }
}
.contact-box .contact-dropbox textarea {
  height: 160px;
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-dropbox textarea {
    height: 140px;
  }
}
.contact-box .contact-dropbox .recepcha {
  max-width: 304px;
  margin: -6px auto 30px;
}
@media only screen and (max-width: 767.98px) {
  .contact-box .contact-dropbox .recepcha {
    margin: 0 auto 20px;
    max-width: 242px;
    height: 65px;
  }
  .contact-box .contact-dropbox .recepcha > div {
    transform: scale(0.8) !important;
    -webkit-transform: scale(0.8) !important;
    transform-origin: 0 0 0 !important;
    -webkit-transform-origin: 0 0 !important;
  }
}

/* ========= Accordion ========= */
.accordion {
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border-radius: 8px;
  padding: 12px 40px;
}
@media only screen and (max-width: 991.98px) {
  .accordion {
    padding: 6px 20px;
  }
}
.accordion .card {
  box-shadow: none;
  padding: 0;
  text-align: left;
  border-bottom: solid 2px rgba(131, 156, 159, 0.1) !important;
  display: flex;
  flex-wrap: wrap;
  background: transparent !important;
}
.accordion .card:last-child {
  border: none !important;
}
.accordion .card > div:first-child {
  order: 2;
}
.accordion .card-header {
  background: transparent;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border: none;
  align-items: flex-start;
  order: 1;
  cursor: pointer;
}
@media only screen and (max-width: 991.98px) {
  .accordion .card-header {
    padding: 15px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .accordion .card-header {
    padding: 12px 0;
  }
}
.accordion .card-header .btn-link {
  padding: 0;
  min-width: 0;
  text-align: center;
  width: 20px;
  transition: all 0.05s ease;
  -webkit-transition: all 0.05s ease;
  -moz-transition: all 0.05s ease;
  -ms-transition: all 0.05s ease;
  -o-transition: all 0.05s ease;
}
.accordion .card-header .btn-link i {
  color: #091f1f;
  font-size: 1.9rem;
  font-weight: 400;
  width: 20px;
}
@media only screen and (max-width: 991.98px) {
  .accordion .card-header .btn-link i {
    font-size: 1.5rem;
  }
}
.accordion .card-header h4 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #091f1f;
  width: calc(100% - 20px);
}
@media only screen and (max-width: 991.98px) {
  .accordion .card-header h4 {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
.accordion .card-body {
  line-height: 1.5rem;
  padding: 10px 16px 25px 16px;
  border-radius: 0;
  border: none;
}
@media only screen and (max-width: 991.98px) {
  .accordion .card-body {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767.98px) {
  .accordion .card-body {
    padding: 8px 8px 16px 8px;
  }
}
.accordion .card-body p {
  padding-bottom: 15px;
}
.accordion .show ~ .card-header .btn-link, .accordion .collapsing ~ .card-header .btn-link {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.accordion .show ~ .card-header .btn-link i, .accordion .collapsing ~ .card-header .btn-link i {
  left: -2px;
  color: #839c9f;
  position: relative;
}
.accordion.with-number {
  counter-reset: my-awesome-counter;
}
.accordion.with-number .card {
  counter-increment: my-awesome-counter;
  position: relative;
}
.accordion.with-number .card:before {
  content: counter(my-awesome-counter) ".";
  position: absolute;
  top: 1.5rem;
  left: 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #091f1f;
  font-weight: 500;
}
@media only screen and (max-width: 991.98px) {
  .accordion.with-number .card:before {
    font-size: 1rem;
    top: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  .accordion.with-number .card:before {
    top: 13px;
  }
}
.accordion.with-number .card .card-header {
  padding-left: 30px;
}
@media only screen and (max-width: 991.98px) {
  .accordion.with-number .card .card-header {
    padding: 14px 0 14px 25px;
  }
}
.accordion.with-number .card .card-body {
  padding: 0 0 5px 30px;
}
@media only screen and (max-width: 991.98px) {
  .accordion.with-number .card .card-body {
    padding: 0 0 5px 25px;
  }
}
.accordion.help-faq {
  background: transparent;
  box-shadow: none;
  padding: 0;
}
.accordion.help-faq .card {
  border-width: 1px !important;
}
.accordion.help-faq .card:before {
  font-size: 1rem;
  top: 1rem;
}
.accordion.help-faq .card-header {
  padding: 16px 0;
}
.accordion.help-faq .card-header h4 {
  font-size: 1rem;
  font-weight: 500;
  padding-right: 1rem;
}
@media only screen and (max-width: 767.98px) {
  .accordion.help-faq .card-header h4 {
    padding-right: 5px;
  }
}
.accordion.help-faq .card-header .btn-link i {
  font-size: 1.7rem;
}

/* ========= FAQ Page ========= */
.faqs-content {
  padding: 160px 0 200px 0;
}
@media only screen and (max-width: 1199.98px) {
  .faqs-content {
    padding: 140px 0 120px 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .faqs-content {
    padding: 130px 0 80px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .faqs-content {
    padding: 100px 0 40px 0;
  }
}
.faqs-content .container {
  overflow: unset;
}
.faqs-content .accordion {
  margin-top: 45px;
}
@media only screen and (max-width: 991.98px) {
  .faqs-content .accordion {
    margin-top: 35px;
  }
}
@media only screen and (max-width: 767.98px) {
  .faqs-content .accordion {
    margin-top: 25px;
  }
}

/* ========== Fonts ============= */
/* ======general purpose classes========== */
body {
  background: var(--color-background);
  font-family: var(--font-var), sans-serif;
  font-size: 16px;
  line-height: 1.629rem;
  color: #839c9f;
  font-weight: 400;
  padding-right: 0 !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
}
@media only screen and (max-width: 767.98px) {
  body {
    font-size: 15px;
  }
}

html,
body {
  font-size: 16px;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  html,
  body {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767.98px) {
  html,
  body {
    font-size: 14px;
  }
}

.txt-black,
.text-black {
  color: #000 !important;
}

.container {
  padding-bottom: 40px;
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1210px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .container {
    max-width: calc(96% + 10px);
  }
}
@media only screen and (max-width: 767.98px) {
  .container {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.page-wrapper {
  padding-top: 120px;
}
@media only screen and (max-width: 991.98px) {
  .page-wrapper {
    padding-top: 110px;
  }
}
@media only screen and (max-width: 767.98px) {
  .page-wrapper {
    padding-top: 100px;
  }
}

.page-wrapper-welcome {
  padding-top: 85px;
}
@media only screen and (max-width: 991.98px) {
  .page-wrapper-welcome {
    padding-top: 110px;
  }
}
@media only screen and (max-width: 767.98px) {
  .page-wrapper-welcome {
    padding-top: 100px;
  }
}
.page-wrapper-welcome .welcome-header {
  padding-left: 40px;
  padding-right: 40px;
}

/* =====Begin: Links============*/
button:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

a {
  text-decoration: none !important;
  color: var(--color-primary);
  cursor: pointer;
  outline: 0;
}
a:hover, a:focus {
  outline: 0;
  color: #839c9f;
}

.text-link {
  color: var(--color-primary);
  position: relative;
  display: inline-block;
  margin-left: 5px;
  font-weight: 500;
}
.text-link:after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  top: 1rem;
  left: 0;
  width: 0%;
  transition: width 0.3s ease;
}
.text-link:hover {
  color: var(--color-primary);
  text-decoration: none;
}
.text-link:hover:after {
  border-bottom: 1px solid var(--color-primary);
  width: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.text-link-gray {
  color: #839c9f;
  margin: 0;
  position: relative;
}
.text-link-gray:after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  top: 1.25rem;
  left: 0;
  width: 0%;
  transition: width 0.3s ease;
}
.text-link-gray:hover {
  color: var(--color-primary);
  text-decoration: none;
}
.text-link-gray:hover:after {
  border-bottom: 1px solid var(--color-primary);
  width: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.text-primary {
  color: var(--color-primary) !important;
}

.text-default {
  color: #091f1f !important;
  font-size: 14px;
}

a.text-primary:hover {
  color: var(--color-primary) !important;
}

.copy-icon {
  font-size: 20px;
  cursor: pointer;
}

.no-border {
  border: none !important;
}

/* =====End: Links ============*/
/* =====Begin: helper Classe============*/
p {
  padding: 0 0 10px 0;
  margin: 0;
}

img {
  max-width: 100%;
  object-fit: contain;
}

.material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

hr {
  border-top: 1px dashed #cdd7d9;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
hr.thickborder {
  border-top: 2px solid #cdd7d9;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

h3.font-24 {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--color-txt-df);
  font-weight: 500;
}

.text-medium {
  font-weight: 500;
  color: #091f1f;
}

/*===== Begin: Input Type Number Spinners Disabling CSS ======================*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*===== End: Input Type Number Spinners Disabling CSS ======================*/
/*===== Begin: Button Style ======================*/
.btn {
  font-weight: 600;
  font-family: var(--font-var), sans-serif;
  color: var(--color-primary);
  cursor: pointer;
  box-shadow: none;
  border: 2px solid var(--color-primary);
  text-transform: capitalize;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  font-size: 1rem;
  line-height: 18px;
  padding: 9px 16px;
  min-width: 120px;
}
@media only screen and (max-width: 767.98px) {
  .btn {
    padding: 7px 15px;
  }
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.disabled, .btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.btn:active, .btn.active {
  background-image: none;
  box-shadow: none !important;
}
.btn.btn-green {
  background: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
  /*@include x-linear-gradient($direction, 90, $from, $to);*/
}
.btn.btn-green:focus, .btn.btn-green:hover:not(:disabled), .btn.btn-green:active, .btn.btn-green:not(:disabled):not(.disabled):active, .btn.btn-green:not(:disabled):not(.disabled).active, .show > .btn.btn-green.dropdown-toggle {
  background: var(--color-primary);
  border-color: var(--color-primary);
}
.btn.btn-green.disable {
  background-color: #eeeeee;
  color: #c0bebe;
  cursor: not-allowed;
  border-color: #eeeeee;
}
.btn.btn-gradient {
  background: rgb(1, 160, 178);
  background: linear-gradient(90deg, rgb(1, 160, 178) 0%, rgb(42, 168, 81) 100%);
  color: #fff;
  border-color: transparent;
}
.btn.btn-gradient:hover {
  background: linear-gradient(90deg, rgb(42, 168, 81) 0%, rgb(1, 160, 178) 100%);
}
.btn.btn-green-outline {
  background-color: #f3f3f3;
  border: 2px solid #2ba84a;
  color: #45b260;
}
.btn.btn-green-outline:hover {
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.btn.btn-green-outline.transprant {
  background-color: transparent;
}
.btn.btn-primary {
  background: var(--color-white);
  color: #091f1f;
  border-color: #091f1f;
}
.btn.btn-primary:focus, .btn.btn-primary:hover:not(:disabled), .btn.btn-primary:active, .btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active, .show > .btn.btn-primary.dropdown-toggle {
  background: #091f1f;
  border-color: #091f1f;
  color: var(--color-white);
}
.btn.btn-secondary {
  background: #839c9f;
  color: var(--color-white);
  border-color: #839c9f;
}
.btn.btn-secondary:focus, .btn.btn-secondary:hover:not(:disabled), .btn.btn-secondary:active, .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active, .show > .btn.btn-secondary.dropdown-toggle {
  background: #688387;
  border-color: #688387;
  color: var(--color-white);
}
.btn.btn-light {
  font-size: 1rem;
  line-height: 1.32rem;
  color: #091f1f;
  font-weight: 400;
  border-radius: 35px;
  background: #f3f6f6;
  border: 0;
}
.btn.btn-light:focus, .btn.btn-light:hover:not(:disabled), .btn.btn-light:active, .btn.btn-light:not(:disabled):not(.disabled):active, .btn.btn-light:not(:disabled):not(.disabled).active, .show > .btn.btn-light.dropdown-toggle {
  background: #688387;
  border-color: #688387;
  color: var(--color-white);
}
.btn.btn-primary-outline {
  background: var(--color-white);
  color: #091f1f;
  border-color: #091f1f;
}
.btn.btn-primary-outline:focus, .btn.btn-primary-outline:hover:not(:disabled), .btn.btn-primary-outline:active, .btn.btn-primary-outline:not(:disabled):not(.disabled):active, .btn.btn-primary-outline:not(:disabled):not(.disabled).active, .show > .btn.btn-primary-outline.dropdown-toggle {
  background: #091f1f;
  border-color: #091f1f;
  color: var(--color-white);
}
.btn.btn-outline-secondary {
  background: var(--color-white);
  color: #839c9f;
  border-color: #839c9f;
}
.btn.btn-outline-secondary:focus, .btn.btn-outline-secondary:hover:not(:disabled), .btn.btn-outline-secondary:active, .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn.btn-outline-secondary.dropdown-toggle {
  background: #688387;
  border-color: #688387;
  color: var(--color-white);
}
.btn.btn-lg {
  padding: 13px 32px;
}
@media only screen and (max-width: 767.98px) {
  .btn.btn-lg {
    padding: 8px 18px;
  }
}
.btn.btn-sm {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 0;
  min-width: 60px;
}
.btn.btn-xs {
  font-size: 0.875rem;
  line-height: 1.8rem;
  font-weight: 500;
  padding: 0 18px;
  min-width: 80px;
}
.btn.btn-outline-with-icon {
  background: var(--color-white);
  color: #091f1f;
  border-color: #091f1f;
  padding: 4px 10px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}
.btn.btn-outline-with-icon .material-icons {
  font-size: 1rem;
  padding-right: 3px;
  text-align: left;
}
.btn.btn-outline-with-icon:hover {
  background-color: #091f1f;
  color: #fff;
}

.btn-link {
  color: #839c9f;
  border: none;
  font-weight: 500;
}
.btn-link:focus,
.btn-link .focus {
  box-shadow: none;
  outline: 0;
  text-decoration: none;
}
.btn-link:hover {
  color: #091f1f;
  text-decoration: none;
}
.btn-link.link-primary {
  color: #091f1f;
  font-weight: 500;
}

.btn-groups {
  margin: -8px;
}
.btn-groups .btn {
  margin: 8px;
}

/*========End: Button Style ======================*/
/*========Begin: Checkobox/Radio =====================*/
.custom-radio.vertical label,
.custom-checkbox.vertical label {
  display: block;
}
.custom-radio label,
.custom-checkbox label {
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 0 0 0 25px;
  margin: 0 10px 7px 0;
  color: #839c9f;
  font-weight: 400;
  font-size: 14px;
}
.custom-radio label input[type=checkbox],
.custom-radio label input[type=radio],
.custom-checkbox label input[type=checkbox],
.custom-checkbox label input[type=radio] {
  display: none;
}
.custom-radio label input[type=checkbox] + span,
.custom-checkbox label input[type=checkbox] + span {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  background: var(--color-white);
  border: 2px solid #839c9f;
  font-family: "LineAwesome", sans-serif;
}
.custom-radio label input[type=checkbox] + span:after,
.custom-checkbox label input[type=checkbox] + span:after {
  content: "";
  position: absolute;
  display: none;
  font-weight: 700;
}
.custom-radio label input[type=checkbox]:checked + span,
.custom-checkbox label input[type=checkbox]:checked + span {
  border: 2px solid var(--color-primary);
  background: var(--color-primary);
  color: var(--color-white);
  transition: all 0.3s;
}
.custom-radio label input[type=checkbox]:checked + span:after,
.custom-checkbox label input[type=checkbox]:checked + span:after {
  top: 50%;
  left: 50%;
  margin-left: -2px;
  margin-top: -6px;
  width: 5px;
  height: 10px;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
  display: block;
  border: solid var(--color-white);
}
.custom-radio label input[type=checkbox]:disabled,
.custom-checkbox label input[type=checkbox]:disabled {
  opacity: 0.6;
}
.custom-radio label input[type=checkbox]:disabled + span,
.custom-checkbox label input[type=checkbox]:disabled + span {
  opacity: 0.6;
}
.custom-radio label input[type=radio] + span,
.custom-checkbox label input[type=radio] + span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background: var(--color-white);
  border: 2px solid #839c9f;
}
.custom-radio label input[type=radio] + span:after,
.custom-checkbox label input[type=radio] + span:after {
  content: "";
  position: absolute;
  display: none;
  font-weight: 700;
}
.custom-radio label input[type=radio]:checked + span,
.custom-checkbox label input[type=radio]:checked + span {
  border: 2px solid var(--color-primary);
  transition: all 0.3s;
}
.custom-radio label input[type=radio]:checked + span:after,
.custom-checkbox label input[type=radio]:checked + span:after {
  content: "";
  display: block;
  top: 50%;
  width: 10px;
  height: 10px;
  background: var(--color-primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-radio label input[type=radio]:disabled,
.custom-checkbox label input[type=radio]:disabled {
  opacity: 0.6;
}
.custom-radio label input[type=radio]:disabled + span,
.custom-checkbox label input[type=radio]:disabled + span {
  opacity: 0.6;
}
.custom-radio.small-checkbox label,
.custom-checkbox.small-checkbox label {
  padding: 0 0 0 15px;
}
.custom-radio.small-checkbox label input[type=checkbox] + span,
.custom-checkbox.small-checkbox label input[type=checkbox] + span {
  width: 12px;
  height: 12px;
}
.custom-radio.small-checkbox label input[type=checkbox]:checked + span:after,
.custom-checkbox.small-checkbox label input[type=checkbox]:checked + span:after {
  margin-left: -1px;
  margin-top: -5px;
  width: 3px;
  height: 7px;
  border-width: 0 1px 1px 0 !important;
}
.custom-radio.small-checkbox label input[type=radio] + span,
.custom-checkbox.small-checkbox label input[type=radio] + span {
  width: 12px;
  height: 12px;
}
.custom-radio.small-checkbox label input[type=radio]:checked + span,
.custom-checkbox.small-checkbox label input[type=radio]:checked + span {
  border: 2px solid var(--color-primary);
  transition: all 0.3s;
}
.custom-radio.small-checkbox label input[type=radio]:checked + span:after,
.custom-checkbox.small-checkbox label input[type=radio]:checked + span:after {
  margin-left: -1px;
  margin-top: -5px;
  width: 3px;
  height: 7px;
  border-width: 0 1px 1px 0 !important;
}
.custom-radio.checkbox-green label,
.custom-checkbox.checkbox-green label {
  color: var(--color-primary);
}
.custom-radio.checkbox-green label input[type=checkbox] + span,
.custom-checkbox.checkbox-green label input[type=checkbox] + span {
  border-color: var(--color-primary);
}
.custom-radio.checkbox-red label,
.custom-checkbox.checkbox-red label {
  color: #dc3545;
}
.custom-radio.checkbox-red label input[type=checkbox] + span,
.custom-checkbox.checkbox-red label input[type=checkbox] + span {
  border-color: #dc3545;
}
.custom-radio.checkbox-red label input[type=checkbox]:checked + span,
.custom-checkbox.checkbox-red label input[type=checkbox]:checked + span {
  background-color: #dc3545;
}
.custom-radio.checkbox-black label,
.custom-checkbox.checkbox-black label {
  color: #1b3030;
}
.custom-radio.checkbox-black label input[type=checkbox] + span,
.custom-checkbox.checkbox-black label input[type=checkbox] + span {
  border-color: #1b3030;
}
.custom-radio.checkbox-black label input[type=checkbox]:checked + span,
.custom-checkbox.checkbox-black label input[type=checkbox]:checked + span {
  background-color: #1b3030;
}
.custom-radio.checkbox-blue label,
.custom-checkbox.checkbox-blue label {
  color: #1877f2;
}
.custom-radio.checkbox-blue label input[type=checkbox] + span,
.custom-checkbox.checkbox-blue label input[type=checkbox] + span {
  border-color: #1877f2;
}
.custom-radio.checkbox-blue label input[type=checkbox]:checked + span,
.custom-checkbox.checkbox-blue label input[type=checkbox]:checked + span {
  background-color: #1877f2;
}

.disable-checkbox {
  opacity: 0.5;
}

/*========Begin: Checkobox/Radio box modal=====================*/
.boxmodal-radio,
.boxmodal-checkbox {
  display: block;
  cursor: pointer;
  position: relative;
  text-align: center;
  height: calc(100% - 32px);
  margin-bottom: 32px;
}
.boxmodal-radio.disabled,
.boxmodal-checkbox.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.boxmodal-radio input[type=checkbox],
.boxmodal-radio input[type=radio],
.boxmodal-checkbox input[type=checkbox],
.boxmodal-checkbox input[type=radio] {
  display: none;
}
.boxmodal-radio.vertical,
.boxmodal-checkbox.vertical {
  display: block;
}
.boxmodal-radio input[type=checkbox] + .card-inner,
.boxmodal-checkbox input[type=checkbox] + .card-inner {
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 40px 24px;
  height: 100%;
  margin-bottom: 0;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .boxmodal-radio input[type=checkbox] + .card-inner,
  .boxmodal-checkbox input[type=checkbox] + .card-inner {
    padding: 2rem 1rem;
  }
}
.boxmodal-radio input[type=checkbox] + .card-inner .card-img,
.boxmodal-checkbox input[type=checkbox] + .card-inner .card-img {
  margin: 0 auto 24px auto;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxmodal-radio input[type=checkbox] + .card-inner .card-img svg,
.boxmodal-checkbox input[type=checkbox] + .card-inner .card-img svg {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
.boxmodal-radio input[type=checkbox] + .card-inner h2,
.boxmodal-checkbox input[type=checkbox] + .card-inner h2 {
  margin-bottom: 1rem;
}
.boxmodal-radio input[type=checkbox] + .card-inner .card-text,
.boxmodal-checkbox input[type=checkbox] + .card-inner .card-text {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #091f1f;
  padding-bottom: 0;
  margin-bottom: 24px;
  min-height: 48px;
  font-weight: 400;
}
.boxmodal-radio input[type=checkbox] + .card-inner .card-btn,
.boxmodal-checkbox input[type=checkbox] + .card-inner .card-btn {
  margin-bottom: 24px;
}
.boxmodal-radio input[type=checkbox] + .card-inner .boxmodal-radiobtn,
.boxmodal-checkbox input[type=checkbox] + .card-inner .boxmodal-radiobtn {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--color-over-effect);
  margin: 0 auto;
  margin-top: 16px;
  padding: 2px;
  background: var(--color-white);
}
.boxmodal-radio input[type=checkbox] + .card-inner .boxmodal-radiobtn span,
.boxmodal-checkbox input[type=checkbox] + .card-inner .boxmodal-radiobtn span {
  background: transparent;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 50%;
}
.boxmodal-radio input[type=checkbox]:checked + .card-inner,
.boxmodal-checkbox input[type=checkbox]:checked + .card-inner {
  background: var(--color-over-effect);
  border: 1px solid var(--color-over-effect);
  transform: scale(1.03);
}
.boxmodal-radio input[type=checkbox]:checked + .card-inner .boxmodal-radiobtn,
.boxmodal-checkbox input[type=checkbox]:checked + .card-inner .boxmodal-radiobtn {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--color-over-effect);
  margin: 0 auto;
  margin-top: 16px;
  padding: 2px;
  background: var(--color-white);
}
.boxmodal-radio input[type=checkbox]:checked + .card-inner .boxmodal-radiobtn span,
.boxmodal-checkbox input[type=checkbox]:checked + .card-inner .boxmodal-radiobtn span {
  background: var(--color-over-effect);
}
.boxmodal-radio input[type=checkbox]:disabled,
.boxmodal-checkbox input[type=checkbox]:disabled {
  opacity: 0.6;
}
.boxmodal-radio input[type=checkbox]:disabled + span,
.boxmodal-checkbox input[type=checkbox]:disabled + span {
  opacity: 0.6;
}
.boxmodal-radio input[type=radio] + .card-inner,
.boxmodal-checkbox input[type=radio] + .card-inner {
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 40px 24px;
  transition: all 300ms ease-in-out 0s;
  height: 100%;
  margin-bottom: 0;
}
.boxmodal-radio input[type=radio] + .card-inner.with-border,
.boxmodal-checkbox input[type=radio] + .card-inner.with-border {
  border: 1px solid #cdd7d9;
  box-shadow: none;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .boxmodal-radio input[type=radio] + .card-inner,
  .boxmodal-checkbox input[type=radio] + .card-inner {
    padding: 2rem 1rem;
  }
}
.boxmodal-radio input[type=radio] + .card-inner .card-img,
.boxmodal-checkbox input[type=radio] + .card-inner .card-img {
  margin: 0 auto 15px auto;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxmodal-radio input[type=radio] + .card-inner .card-img svg,
.boxmodal-checkbox input[type=radio] + .card-inner .card-img svg {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
.boxmodal-radio input[type=radio] + .card-inner h2,
.boxmodal-checkbox input[type=radio] + .card-inner h2 {
  margin-bottom: 1rem;
}
.boxmodal-radio input[type=radio] + .card-inner .card-text,
.boxmodal-checkbox input[type=radio] + .card-inner .card-text {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #091f1f;
  padding-bottom: 0;
  margin-bottom: 24px;
  min-height: 48px;
  font-weight: 400;
}
.boxmodal-radio input[type=radio] + .card-inner .card-btn,
.boxmodal-checkbox input[type=radio] + .card-inner .card-btn {
  margin-bottom: 24px;
}
.boxmodal-radio input[type=radio] + .card-inner .boxmodal-radiobtn,
.boxmodal-checkbox input[type=radio] + .card-inner .boxmodal-radiobtn {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  margin: 0 auto;
  margin-top: 16px;
  padding: 2px;
  background: var(--color-white);
}
.boxmodal-radio input[type=radio] + .card-inner .boxmodal-radiobtn span,
.boxmodal-checkbox input[type=radio] + .card-inner .boxmodal-radiobtn span {
  background: transparent;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 50%;
}
.boxmodal-radio input[type=radio]:checked + .card-inner,
.boxmodal-checkbox input[type=radio]:checked + .card-inner {
  border: 1px solid var(--color-primary);
  transform: scale(1.03);
}
.boxmodal-radio input[type=radio]:checked + .card-inner .boxmodal-radiobtn,
.boxmodal-checkbox input[type=radio]:checked + .card-inner .boxmodal-radiobtn {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  margin: 0 auto;
  margin-top: 16px;
  padding: 2px;
  background: var(--color-white);
}
.boxmodal-radio input[type=radio]:checked + .card-inner .boxmodal-radiobtn span,
.boxmodal-checkbox input[type=radio]:checked + .card-inner .boxmodal-radiobtn span {
  background: var(--color-primary);
}
.boxmodal-radio input[type=radio]:disabled,
.boxmodal-checkbox input[type=radio]:disabled {
  opacity: 0.6;
}
.boxmodal-radio input[type=radio]:disabled + span,
.boxmodal-checkbox input[type=radio]:disabled + span {
  opacity: 0.6;
}

.link-block {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.link-block .card {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  border: 1px solid transparent;
}
.link-block .boxmodal-radio input[type=checkbox] + .card-inner,
.link-block .boxmodal-radio input[type=radio] + .card-inner,
.link-block .boxmodal-checkbox input[type=checkbox] + .card-inner,
.link-block .boxmodal-checkbox input[type=radio] + .card-inner {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  border: 1px solid transparent;
}
.link-block:hover .card {
  border: 1px solid var(--color-primary);
  background: var(--color-over-effect);
  transform: scale(1.03);
}
.link-block:hover .card .btn-next {
  background: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.link-block:hover .card .btn-next span {
  color: var(--color-white) !important;
}
.link-block:hover .boxmodal-radio input[type=checkbox] + .card-inner,
.link-block:hover .boxmodal-radio input[type=radio] + .card-inner,
.link-block:hover .boxmodal-checkbox input[type=checkbox] + .card-inner,
.link-block:hover .boxmodal-checkbox input[type=radio] + .card-inner {
  border: 1px solid var(--color-primary);
  background: var(--color-over-effect);
  transform: scale(1.03);
}
.link-block .notes {
  color: #839c9f;
}

/*========End: Checkobox/Radio =====================*/
/*========Begin: Form Control ========================*/
label {
  font-weight: 500;
}

.form-group {
  margin-bottom: 32px;
  position: relative;
}
.form-group.fixed-floating-label .fixed-label {
  font-size: 0.75rem;
  line-height: 0.875rem;
  position: absolute;
  left: 16px;
  top: -6px;
  background: var(--color-white);
  margin: 0;
  padding: 0 3px;
}
.form-group .label-small {
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.form-group .text-danger {
  font-size: 0.85rem;
}

textarea {
  resize: none;
  border: 1px solid #dbe2de;
  border-radius: 4px;
  padding: 15px;
}

.col-form-label {
  color: #cdd7d9;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

/* WebKit browsers */
input:focus:-moz-placeholder {
  color: transparent;
}

/* Mozilla Firefox 4 to 18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* Mozilla Firefox 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* Internet Explorer 10+ */
input[type=file] {
  opacity: 0;
  display: none;
}

.form-control {
  font-family: var(--font-var), sans-serif;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: #091f1f;
  line-height: 1.429rem;
  border: 1px solid #dbe2de;
  font-size: 1em;
  padding: 10px 18px;
  height: 48px;
  width: 100%;
}
@media only screen and (max-width: 767.98px) {
  .form-control {
    height: 40px;
    padding: 8px 18px;
  }
}
.form-control ::-webkit-input-placeholder {
  color: #cdd7d9;
}
.form-control :-moz-placeholder {
  color: #cdd7d9;
}
.form-control ::-moz-placeholder {
  color: #cdd7d9;
}
.form-control :-ms-input-placeholder {
  color: #cdd7d9;
}
.form-control.form-control-sm {
  padding: 0.358rem 1.071rem;
  font-size: 0.875rem;
  line-height: 1.35rem;
}
.form-control.focus, .form-control:focus {
  border-color: var(--color-primary);
  color: #091f1f;
  box-shadow: none;
}
.form-control.focus ::-webkit-input-placeholder, .form-control:focus ::-webkit-input-placeholder {
  color: var(--color-primary);
}
.form-control.focus :-moz-placeholder, .form-control:focus :-moz-placeholder {
  color: var(--color-primary);
}
.form-control.focus ::-moz-placeholder, .form-control:focus ::-moz-placeholder {
  color: var(--color-primary);
}
.form-control.focus :-ms-input-placeholder, .form-control:focus :-ms-input-placeholder {
  color: var(--color-primary);
}
.form-control[readonly], .form-control:disabled {
  background-color: transparent;
  /*color: $color-secondary;*/
  opacity: 0.5;
}
.form-control[readonly] ~ label, .form-control:disabled ~ label {
  opacity: 0.5;
}
.form-control.line-input {
  border: none;
  border-bottom: 1px solid #cdd7d9;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  padding: 1rem 0;
}
.form-control.line-input ::-webkit-input-placeholder {
  color: #cdd7d9;
}
.form-control.line-input :-moz-placeholder {
  color: #cdd7d9;
}
.form-control.line-input ::-moz-placeholder {
  color: #cdd7d9;
}
.form-control.line-input :-ms-input-placeholder {
  color: #cdd7d9;
}
.form-control.line-input.focus, .form-control.line-input:focus {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.form-control.line-input.focus ::-webkit-input-placeholder, .form-control.line-input:focus ::-webkit-input-placeholder {
  color: var(--color-primary);
}
.form-control.line-input.focus :-moz-placeholder, .form-control.line-input:focus :-moz-placeholder {
  color: var(--color-primary);
}
.form-control.line-input.focus ::-moz-placeholder, .form-control.line-input:focus ::-moz-placeholder {
  color: var(--color-primary);
}
.form-control.line-input.focus :-ms-input-placeholder, .form-control.line-input:focus :-ms-input-placeholder {
  color: var(--color-primary);
}

.form-control-feedback {
  font-weight: 400;
  font-size: 0.85rem;
}

.has-danger {
  color: #dc3545;
}
.has-danger .form-control {
  border: 1px solid #dc3545;
}

.form-control::placeholder {
  opacity: 0;
}

.form-control-search::placeholder {
  opacity: 1;
}

.input-effect .form-control ~ label {
  color: #797979;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 12px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  margin: 0;
  padding: 0 3px;
  white-space: nowrap;
  max-width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 767.98px) {
  .input-effect .form-control ~ label {
    top: 9px;
  }
}

.input-effect .form-control:focus ~ label,
.has-content.form-control ~ label,
.input-effect .form-control:not(:placeholder-shown) ~ label {
  top: -13px;
  font-size: 12px;
  transform: translateY(0);
  background: #ffffff;
  opacity: 1;
}
@media only screen and (max-width: 767.98px) {
  .input-effect .form-control:focus ~ label,
  .has-content.form-control ~ label,
  .input-effect .form-control:not(:placeholder-shown) ~ label {
    top: -7px;
    line-height: 14px;
  }
}

.input-effect .form-control:-webkit-autofill ~ label {
  top: -13px;
  font-size: 14px;
  transform: translateY(0);
  background: #ffffff;
}
@media only screen and (max-width: 767.98px) {
  .input-effect .form-control:-webkit-autofill ~ label {
    top: -7px;
    line-height: 14px;
    font-size: 12px;
  }
}

.input-effect {
  position: relative;
}
.input-effect.otp-inputs input.form-control {
  padding-right: 130px;
}
.input-effect.otp-inputs .btn {
  padding: 0;
  position: absolute;
  top: 12px;
  right: 0.7rem;
  min-width: auto;
  line-height: 26px;
  font-weight: 500;
}
@media only screen and (max-width: 767.98px) {
  .input-effect.otp-inputs .btn {
    top: 7px;
    right: 1rem;
  }
}
.input-effect.otp-inputs .btn.btn-resend {
  color: #839c9f;
  cursor: text;
}
.input-effect.with-icon .floating-input {
  padding-right: 30px;
}
.input-effect.with-icon .form-control {
  padding-right: 38px;
}
@media only screen and (max-width: 767.98px) {
  .input-effect.with-icon .form-control {
    padding-right: 35px;
  }
}
.input-effect.with-icon .view-password {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.input-effect.with-icon .view-password.viewed {
  color: var(--color-primary);
}
@media only screen and (max-width: 767.98px) {
  .input-effect.with-icon .view-password {
    font-size: 20px;
    top: 10px;
    right: 10px;
  }
}
.input-effect.with-icon .input-icon {
  position: absolute;
  top: 17px;
  right: 10px;
}
@media only screen and (max-width: 767.98px) {
  .input-effect.with-icon .input-icon {
    top: 10px;
  }
}
.input-effect.with-icon .input-icon.show {
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 767.98px) {
  .input-effect.with-icon .input-icon.show {
    width: 20px;
    height: 20px;
    background-size: 100%;
  }
}
.input-effect.with-icon .input-icon.hide {
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 767.98px) {
  .input-effect.with-icon .input-icon.hide {
    width: 20px;
    height: 20px;
    background-size: 100%;
  }
}
.input-effect.with-icon .info-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  line-height: 1;
}

.edit-input {
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 14px;
  color: #839c9f;
  line-height: 1;
}
.edit-input:hover {
  color: var(--color-primary);
}
@media only screen and (max-width: 767.98px) {
  .edit-input {
    right: 13px;
    top: 11px;
  }
}
.edit-input .material-icons {
  font-size: 1.3rem;
}

textarea.form-control {
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #091f1f;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.input-group {
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  background: #ffffff;
  max-width: 160px;
}
.input-group .input-group-text {
  background: transparent;
  color: var(--color-primary);
  border: none;
  padding: 10px 10px;
}
.input-group .form-control {
  border: transparent;
  font-weight: 500;
  padding: 10px;
}
.input-group .form-control::placeholder {
  opacity: 1;
  display: inline-block;
}
.input-group .input-group-append {
  height: 48px;
}
.input-group .input-group-append .btn {
  min-width: unset;
  max-width: 100px;
  color: var(--color-primary);
  border: 1px solid #cdd7d9;
  border-radius: 0 4px 4px 0;
  line-height: 1.5rem;
  padding: 12px 32px;
}
@media only screen and (max-width: 767.98px) {
  .input-group .input-group-append {
    height: 40px;
  }
  .input-group .input-group-append .btn {
    padding: 8px 18px;
  }
}

/*========Error msg Style================*/
.error-block {
  color: #dc3545;
  font-size: 13px;
  line-height: 16px;
}

/*===DropDown==========*/
.dropdown-menu {
  border: 1px solid #cdd7d9;
  /*box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);*/
  margin: 0;
  font-size: 1em;
}
.dropdown-menu.show {
  animation: dropdown-fade-in 0.3s ease 1, dropdown-move-up 0.3s ease-out 1;
}
.dropdown-menu .dropdown-item {
  padding: 10px 0px;
  border-bottom: 1px solid #cdd7d9;
  white-space: inherit;
  display: flex;
  align-items: center;
  color: #839c9f;
}
.dropdown-menu .dropdown-item .fa {
  margin-right: 10px;
  color: var(--color-primary);
  font-size: 20px;
}
.dropdown-menu .dropdown-item:first-child {
  border-radius: 4px 4px 0 0;
}
.dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}
.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
  color: #091f1f;
  background: transparent;
}
.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
  color: #839c9f;
  background: transparent;
}
.dropdown-menu .dropdown-item.view-notification {
  color: var(--color-primary);
  padding: 10px 16px 12px 16px !important;
}
@media only screen and (max-width: 767.98px) {
  .dropdown-menu .dropdown-item.view-notification {
    padding: 10px 14px 12px 14px !important;
  }
}
.dropdown-menu:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border: 5px solid #cdd7d9;
  border-color: transparent transparent var(--color-white) var(--color-white);
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -1px 1px 1px 0 rgba(131, 156, 159, 0.16);
}

.profile-dropdown {
  display: flex;
  flex-wrap: wrap;
}
.profile-dropdown .dropdown #dropdownNotification,
.profile-dropdown .dropdown .profile-img {
  cursor: pointer;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown .dropdown {
    position: inherit;
  }
}
.profile-dropdown .notification-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.profile-dropdown .counter-icon {
  background-color: var(--color-primary);
  color: var(--color-white);
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -6px;
  line-height: 1;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  padding: 3px 0;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown .counter-icon {
    top: -7px;
    right: -6px;
  }
}
.profile-dropdown > div {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown > div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media only screen and (max-width: 359px) {
  .profile-dropdown > div {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-size: 11px;
  }
}
.profile-dropdown > div + div {
  position: relative;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown > div + div {
    position: inherit;
  }
}
.profile-dropdown > div + div:before {
  content: "";
  width: 1px;
  height: 32px;
  background: rgba(205, 215, 217, 0.24);
  position: absolute;
  left: 0;
  top: -3px;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown > div + div:before {
    display: none;
  }
}
.profile-dropdown .inquires-toggle .inq-text {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown .inquires-toggle .inq-text {
    margin-right: 5px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 359px) {
  .profile-dropdown .inquires-toggle .inq-text {
    font-size: 11px;
  }
}
.profile-dropdown .inquires-toggle .custom-switch {
  display: inline-block;
  vertical-align: middle;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown .inquires-toggle .custom-switch label {
    width: 36px;
    height: 20px;
  }
  .profile-dropdown .inquires-toggle .custom-switch label::after {
    width: 14px;
    height: 14px;
  }
}
.profile-dropdown .dropdown-menu {
  min-width: 270px;
  padding: 1.5rem;
  background: #ffffff;
  box-shadow: 0 -2px 8px 0 rgba(131, 156, 159, 0.08), 0 8px 16px 0 rgba(131, 156, 159, 0.16);
  border: none;
  top: 40px;
  right: 11px;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown .dropdown-menu {
    right: 0;
    top: 61px;
  }
  .profile-dropdown .dropdown-menu::before {
    right: 45px;
  }
}
@media only screen and (max-width: 359px) {
  .profile-dropdown .dropdown-menu {
    right: 0;
  }
  .profile-dropdown .dropdown-menu::before {
    right: 36px;
  }
}
.profile-dropdown .dropdown-menu.dropdown-menu-notifications {
  width: 470px;
  padding: 0.5rem;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown .dropdown-menu.dropdown-menu-notifications {
    width: 100%;
  }
  .profile-dropdown .dropdown-menu.dropdown-menu-notifications::before {
    right: 82px;
  }
}
@media only screen and (max-width: 359px) {
  .profile-dropdown .dropdown-menu.dropdown-menu-notifications::before {
    right: 74px;
  }
}
.profile-dropdown .dropdown-menu.dropdown-menu-notifications .notification-outer {
  padding: 0.5rem 0;
  border-bottom: 0;
}
.profile-dropdown .dropdown-menu.dropdown-menu-notifications .notification-outer .text-primary {
  font-weight: 500;
}
.profile-dropdown .dropdown-menu.dropdown-menu-notifications .notification-outer .notification-msg {
  width: 100%;
}
.profile-dropdown .dropdown-menu.dropdown-menu-notifications .notification-outer .notification-date {
  width: 100%;
}
.profile-dropdown .dropdown-menu .text-primary.dropdown-item {
  border: none;
}
.profile-dropdown .dropdown-menu .scrollbar-container {
  max-height: 480px;
  padding: 0.3rem 1rem 0 1rem;
}
@media only screen and (max-width: 767.98px) {
  .profile-dropdown .dropdown-menu .scrollbar-container {
    max-height: 280px;
  }
}
@media only screen and (max-width: 575.98px) {
  .profile-dropdown .dropdown-menu .scrollbar-container {
    max-height: 360px;
  }
}
.profile-dropdown .dropdown-menu .scrollbar-container .ps__rail-x {
  display: none !important;
}
.profile-dropdown .dropdown-menu .scrollbar-container .ps__rail-y {
  opacity: 0.4 !important;
}
.profile-dropdown .dropdown-menu .scrollbar-container:hover .ps__rail-y {
  opacity: 0.8 !important;
}
.profile-dropdown .dropdown-menu .scrollbar-container .notification-outer:last-child {
  padding-bottom: 0;
}
.profile-dropdown .dropdown-item {
  display: flex;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.profile-dropdown .dropdown-item .dropdown-icon {
  margin-right: 16px;
}
.profile-dropdown .dropdown-item:first-child {
  padding-top: 0;
}
.profile-dropdown .dropdown-item:last-child {
  padding-bottom: 0;
}
.profile-dropdown .profile-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
@media only screen and (max-width: 991.98px) {
  .profile-dropdown .profile-img {
    margin-right: 8px;
  }
}
.profile-dropdown .profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
@keyframes dropdown-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes dropdown-move-up {
  from {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes height-fade-in {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
@keyframes height-fade-out {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
@keyframes dropdown-zoom-in {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
/* =======  search input ======== */
.search-box {
  position: relative;
}
.search-box .form-group {
  margin-bottom: 1.5rem;
}
.search-box .form-control {
  padding-right: 130px;
}
.search-box input[type=search] + .btn.btn-light {
  border-radius: 4px;
  color: var(--color-white);
  background: #cdd7d9;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}
@media only screen and (max-width: 767.98px) {
  .search-box input[type=search] + .btn.btn-light {
    padding: 4px 15px;
  }
}
.search-box input[type=search]:focus {
  border-color: #cdd7d9;
  background: transparent;
}
.search-box input[type=search]:focus + .btn.btn-light {
  background: var(--color-primary);
}

/* ========= Reusable components ==========*/
.notes p {
  font-size: 0.75rem;
  padding-bottom: 0;
  line-height: 1.5rem;
}

/* ====== Begin: Select Box ========== */
.plan-list .planlist-bottombtn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.plan-list .planlist-bottombtn .btn-link {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 10px;
}
.plan-list .planlist-bottombtn .btn-link:first-child {
  padding: 0;
}
.plan-list .boxmodal-radio input[type=checkbox] + .card-inner,
.plan-list .boxmodal-checkbox input[type=checkbox] + .card-inner {
  box-shadow: none;
  border: 1px solid #cdd7d9;
}
.plan-list .boxmodal-radio input[type=checkbox]:checked + .card-inner,
.plan-list .boxmodal-checkbox input[type=checkbox]:checked + .card-inner {
  background: var(--color-white);
  border: 1px solid var(--color-over-effect);
  transform: scale(1);
  -webkit-transform: scale(1);
}
.plan-list .boxmodal-radio input[type=checkbox]:hover + .card-inner,
.plan-list .boxmodal-checkbox input[type=checkbox]:hover + .card-inner {
  border: 1px solid var(--color-primary);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.plan-list .boxmodal-radio input[type=radio] + .card-inner,
.plan-list .boxmodal-checkbox input[type=radio] + .card-inner {
  box-shadow: none;
  border: 1px solid #cdd7d9;
}
.plan-list .boxmodal-radio input[type=radio]:checked + .card-inner,
.plan-list .boxmodal-checkbox input[type=radio]:checked + .card-inner {
  background: var(--color-over-effect);
  border: 1px solid var(--color-over-effect);
  transform: scale(1);
  -webkit-transform: scale(1);
}
@media only screen and (max-width: 1199.98px) {
  .plan-list .owl-carousel {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 1199.98px) {
  .plan-list .owl-carousel .owl-nav {
    top: -36px;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    left: auto;
    right: 10px;
    width: auto;
  }
}
.plan-list .owl-carousel .owl-nav .owl-prev {
  left: 6px;
  position: relative;
}
@media only screen and (max-width: 1199.98px) {
  .plan-list .owl-carousel .owl-nav .owl-prev {
    left: 0;
  }
}
.plan-list .item {
  margin: 0 15px;
}
.plan-list .item .card-inner {
  margin: 0;
}

.plan-loaders {
  text-align: center;
  padding-left: 30px;
  padding-right: 15px;
}
@media only screen and (max-width: 1199.98px) {
  .plan-loaders {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .plan-loaders > .row > div + div {
    display: none;
  }
}
.plan-loaders .card-inner {
  padding: 40px 24px;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .plan-loaders .card-inner {
    padding: 2rem 1rem;
  }
}

.default-select {
  text-align: left;
}
.default-select .css-26l3qy-menu {
  box-shadow: 0 0 8px 0 rgba(131, 156, 159, 0.22);
}
.default-select .custom-select__menu {
  background: var(--color-white);
  z-index: 2;
}
.default-select .css-yt9ioa-option:active {
  background-color: #ecfdf1;
}
.default-select .custom-select-menu .custom-select__control {
  min-height: 48px;
  border: 0;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16) !important;
}
@media only screen and (max-width: 767.98px) {
  .default-select .custom-select-menu .custom-select__control {
    min-height: 40px;
  }
}
.default-select .custom-select-menu .custom-select__control.custom-select__control--is-focused {
  outline: none;
  box-shadow: none;
}
.default-select .custom-select-menu .custom-select__control .custom-select__value-container {
  padding: 5px 0.75rem;
  font-weight: 500;
}
.default-select .custom-select-menu .custom-select__control .custom-select__value-container .custom-select__placeholder {
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 400;
  color: #839c9f;
  padding: 0;
  margin: 0 0 0 2px;
}
.default-select .custom-select-menu .custom-select__control .custom-select__menu {
  background: var(--color-white);
}
.default-select .custom-select-menu .custom-select__control .custom-select__menu .custom-select__option {
  font-size: 1rem;
}
.default-select .custom-select-menu .custom-select__control .custom-select__indicator-separator {
  display: none;
}
.default-select .custom-select-menu .custom-select__control .custom-select__indicators svg {
  color: #091f1f;
}
.default-select .custom-select-menu .custom-select__control .custom-select__single-value--is-disabled {
  color: #091f1f;
  opacity: 0.5;
}
.default-select.no-shadow .custom-select-menu .custom-select__control {
  box-shadow: none !important;
}
.default-select.with-border .custom-select-menu .custom-select__control {
  box-shadow: none !important;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
}
.default-select .custom-select__menu-list {
  padding: 0;
  border-radius: 4px;
  max-height: 250px;
}
.default-select .custom-select__menu-list .custom-select__option {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.default-select .custom-select__menu-list .custom-select__option--is-focused {
  background-color: transparent;
  border: 1px solid var(--color-over-effect);
  border-radius: 4px;
}
.default-select .custom-select__menu-list .custom-select__option--is-selected {
  background-color: var(--color-primary);
}

/* ====== End: Select Box ========== */
/* ====== Begin: Modal ========== */
.modal .default-select .custom-select__menu-list {
  max-height: 100px;
}
.modal .modal-dialog {
  max-width: 970px;
  margin: 0 auto;
  padding: 24px 0;
}
@media only screen and (max-width: 991.98px) {
  .modal .modal-dialog {
    max-width: calc(100% - 30px);
  }
}
@media only screen and (max-width: 575.98px) {
  .modal .modal-dialog {
    padding: 0;
  }
}
.modal .modal-dialog .modal-content {
  border-radius: 8px;
}
.modal .modal-dialog .modal-content .modal-header {
  padding: 1.5rem;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
}
.modal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 500;
  color: #091f1f;
}
.modal .modal-dialog .modal-content .modal-header .close {
  font-size: 1.75rem;
  font-weight: 300;
  color: #839c9f;
  opacity: 1;
  padding: 0;
  margin: 0;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 1.5rem 1.5rem 2rem;
  background: var(--color-background);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}
.modal .modal-dialog .modal-content .modal-footer.btns-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal.custom-modal .modal-dialog .modal-content .modal-header .close {
  display: none;
}
.modal.custom-modal .modal-dialog .modal-content .modal-body {
  position: static;
}
@media only screen and (max-width: 1199.98px) {
  .modal {
    padding: 0 !important;
  }
}
.modal.call-agent-modal .modal-dialog {
  max-width: 600px;
}
@media only screen and (max-width: 767.98px) {
  .modal.call-agent-modal .modal-dialog {
    max-width: calc(100% - 30px);
  }
}
.modal.call-agent-modal .modal-dialog .custom-radio label {
  margin-bottom: 15px;
}
.modal.call-agent-modal .modal-dialog textarea {
  height: 110px;
}

.modal-open {
  overflow: hidden !important;
}

.modal-small.modal .modal-dialog {
  max-width: 700px;
}
.modal-small.modal .modal-dialog .modal-content .modal-body {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 2rem;
}
@media only screen and (max-width: 767.98px) {
  .modal-small.modal .modal-dialog {
    max-width: calc(100% - 30px);
  }
}

.documentin-modal .col-lg-9 {
  flex: 0 0 100%;
  max-width: 100%;
}
.documentin-modal .white-box2 {
  margin-bottom: 0;
}

.pdf-modal.modal {
  padding-right: 0 !important;
}
.pdf-modal.modal .modal-dialog {
  max-width: 1200px;
}
.pdf-modal.modal .modal-dialog.modal-90w {
  max-width: 90%;
}
@media only screen and (max-width: 767.98px) {
  .pdf-modal.modal .modal-dialog.modal-90w {
    max-width: 94%;
  }
}
.pdf-modal.modal .modal-dialog .modal-content .modal-body {
  padding-top: 0;
}

.modal .modal-dialog.modal-90w {
  max-width: 90%;
}
@media only screen and (max-width: 1199.98px) {
  .modal .modal-dialog.modal-90w iframe {
    max-height: 600px;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .modal-dialog.modal-90w {
    max-width: 94%;
  }
  .modal .modal-dialog.modal-90w iframe {
    max-height: 550px;
  }
}

.add-bank-account-modal.modal .modal-dialog {
  max-width: 418px;
}
.add-bank-account-modal.modal .modal-dialog .modal-content .modal-header {
  display: inline-block;
  position: relative;
  margin-bottom: 4px;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.1);
}
.add-bank-account-modal.modal .modal-dialog .modal-content .modal-header .modal-subtitle {
  padding-bottom: 0;
}
.add-bank-account-modal.modal .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  top: 22px;
  right: 20px;
  margin: 0;
}
.add-bank-account-modal.modal .modal-dialog .modal-content .modal-body {
  padding-top: 2rem;
  padding-bottom: 0;
}
.add-bank-account-modal.modal .modal-dialog .modal-content .modal-body .text-small {
  font-size: 0.875rem;
  color: #091f1f;
  line-height: 1.5rem;
  padding-bottom: 0.75rem;
}
.add-bank-account-modal.modal .modal-dialog .modal-content .modal-footer {
  border-top: 0;
  padding: 2rem 1.5rem 2.5rem;
}

.agent-modal .modal-dialog {
  max-width: 870px;
}
@media only screen and (max-width: 767.98px) {
  .agent-modal .modal-dialog {
    max-width: calc(100% - 30px);
  }
}
.agent-modal .modal-dialog .modal-content .modal-body {
  padding-right: 55px;
}

/* ====== End: Modal ========== */
/* ====== Begin: Common Chat Icon ========== */
.chat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--color-primary);
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 9999999;
}
.chat-icon .chat-counter {
  color: #fff;
  background: #dc3545;
  padding: 6px;
  min-width: 22px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: 2px;
  line-height: 10px;
  font-size: 14px;
}
.chat-icon svg {
  width: 32px;
}
@media only screen and (max-width: 767.98px) {
  .chat-icon {
    width: 40px;
    height: 40px;
    bottom: 15px;
    right: 15px;
  }
  .chat-icon svg {
    width: 20px;
  }
}

/* ====== Meterial Icom ========== */
.material-icons {
  vertical-align: middle;
}

/* ====== End: Common Chat Icon ========== */
/* ========= White-box ======== */
.white-box {
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 40px 25px 40px;
  margin-bottom: 40px;
}

.white-box2 {
  background: var(--color-white);
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
}
.white-box2.with-border {
  box-shadow: none;
  border: 1px solid rgba(205, 215, 217, 0.24);
  border-radius: 4px;
}

.card-inner {
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 1.5rem;
  transition: all 300ms ease-in-out 0s;
  height: calc(100% - 32px);
  margin-bottom: 2rem;
}
.card-inner.with-border {
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #cdd7d9;
}

.chips-outer {
  margin: -4px;
}
.chips-outer .chips-label {
  padding: 1px 8px;
  margin: 4px;
  background: rgba(9, 31, 31, 0.9);
  line-height: 1.5rem;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
}

/* ===== Loader ======= */
.pre-loader {
  display: none;
}

.loader-outer {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.57);
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-spinner div {
  transform-origin: 9px 9px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 14px;
  left: 8px;
  width: 1.4px;
  height: 5px;
  border-radius: 20%;
  background: var(--color-white);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
.lds-spinner.loader-lg {
  width: 80px;
  height: 80px;
}
.lds-spinner.loader-lg div {
  transform-origin: 40px 40px;
}
.lds-spinner.loader-lg div:after {
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.btn-loader .btn {
  max-height: 40px;
  min-width: 88px;
}

.loader-withtext {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.loader-withtext .lds-spinner div:after {
  background: var(--color-primary);
}
.loader-withtext h5 {
  color: var(--color-primary);
}

/* ========== custom range slider ============ */
.custom-slider {
  text-align: center;
  max-width: 970px;
  margin: 0 auto;
}
.custom-slider .value {
  display: inline-block;
}
.custom-slider .value h2 {
  display: inline-block;
  margin-bottom: 0;
}
.custom-slider .value h2 span {
  font-size: 1.25rem;
  margin-left: 15px;
  font-weight: 500;
  vertical-align: top;
  display: inline-block;
}
.custom-slider .value h2 input[type=number],
.custom-slider .value h2 .form-control {
  border: solid 1px #cdd7d9;
  padding: 5px 8px;
  width: auto;
  height: 44px;
  border-radius: 4px;
  display: inline-block;
  font-weight: 500;
  max-width: 180px;
}
@media only screen and (max-width: 1199.98px) {
  .custom-slider .value h2 input[type=number],
  .custom-slider .value h2 .form-control {
    height: 37px;
    max-width: 162px;
  }
}
@media only screen and (max-width: 991.98px) {
  .custom-slider .value h2 input[type=number],
  .custom-slider .value h2 .form-control {
    max-width: 166px;
  }
}
@media only screen and (max-width: 767.98px) {
  .custom-slider .value h2 input[type=number],
  .custom-slider .value h2 .form-control {
    height: 30px;
    max-width: 130px;
  }
}
.custom-slider .value h2 input[type=number].year-input,
.custom-slider .value h2 .form-control.year-input {
  max-width: 60px;
  padding: 5px 5px;
}
@media only screen and (max-width: 1199.98px) {
  .custom-slider .value h2 input[type=number].year-input,
  .custom-slider .value h2 .form-control.year-input {
    max-width: 50px;
  }
}
@media only screen and (max-width: 991.98px) {
  .custom-slider .value h2 input[type=number].year-input,
  .custom-slider .value h2 .form-control.year-input {
    max-width: 56px;
  }
}
@media only screen and (max-width: 991.98px) {
  .custom-slider .value h2 input[type=number].year-input,
  .custom-slider .value h2 .form-control.year-input {
    max-width: 42px;
  }
}
.custom-slider .value .edit-icon {
  font-size: 1.2rem;
  color: #839c9f;
  position: relative;
  top: -16px;
  margin-right: -8px;
  left: 2px;
}
@media only screen and (max-width: 767.98px) {
  .custom-slider .value .edit-icon {
    top: -14px;
    margin-right: -12px;
    left: 1px;
  }
}
.custom-slider .value .edit-icon:hover {
  color: var(--color-primary);
}
.custom-slider .rangeslider,
.custom-slider .rangeslider__fill {
  box-shadow: none;
}
.custom-slider .rangeslider-horizontal {
  height: 32px;
  border-radius: 4px;
  background: #cdd7d9;
}
.custom-slider .rangeslider-horizontal .rangeslider__fill {
  border-radius: 4px;
  background: #091f1f;
}
.custom-slider .rangeslider-horizontal .rangeslider__handle {
  width: 24px;
  height: 48px;
  border-radius: 4px;
  background: #091f1f;
  box-shadow: none;
  border: 2px solid var(--color-white);
}
.custom-slider .rangeslider-horizontal .rangeslider__handle:after {
  width: 10px;
  height: 18px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASAgMAAAAf2afYAAAADFBMVEUAAABV0HRWz3RVz3OpwBX8AAAAA3RSTlMAtLXB1OhhAAAAFklEQVQI12NYsGABw4cPHyjCEyZMAADbQjCRKXS4sQAAAABJRU5ErkJggg==) transparent;
  box-shadow: none;
  border-radius: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-slider .rangeslider-horizontal .rangeslider__handle .rangeslider__handle-label {
  display: none;
}
.custom-slider .rangeslider__labels .rangeslider__label-item {
  white-space: nowrap;
}

/* ========= custom-tab ========= */
.custom-tab .nav-tabs {
  border: none;
  margin-bottom: 2rem;
}
.custom-tab .nav-tabs .nav-link {
  color: #839c9f;
  padding: 0;
  padding-bottom: 1rem;
  margin-right: 2.5rem;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 1.5rem;
  font-weight: 500;
}
.custom-tab .nav-tabs .nav-link.active {
  color: #091f1f;
  border-bottom: 2px solid var(--color-primary);
}
.custom-tab .nav-tabs .nav-link:last-child {
  margin-right: 0;
}

/*========= Card ==========*/
.animated {
  height: 100%;
  display: inline-block;
  width: 100%;
  animation-fill-mode: none;
}

.animation-outer .animated {
  height: auto;
  display: inline-block;
  width: 100%;
}

.card-outer {
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border-radius: 8px;
  padding: 24px;
  display: block;
  margin-bottom: 1rem;
}
.card-outer.with-border {
  box-shadow: none;
  background: transparent;
  border: 1px solid #cdd7d9;
}
.card-outer.approved-steps {
  padding: 0 24px;
}
.card-outer.approved-steps .card-body {
  padding: 24px 0;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.card-outer.approved-steps .card-body:last-child {
  border-bottom: 0;
}
.card-outer.approved-steps .card-body h2 {
  color: var(--color-txt-df);
}

.chat-notification-text {
  background: var(--color-white);
  padding-left: 24px;
  display: block;
}

.card-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -15px;
}

.card {
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 40px 24px;
  height: 100%;
  text-align: center;
  display: block;
}
.card.with-border {
  box-shadow: none;
  border: 1px solid #cdd7d9;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .card {
    padding: 2rem 1rem 1rem;
  }
}
.card .card-img {
  margin-bottom: 24px;
  height: 110px;
}
.card h2 {
  margin-bottom: 1rem;
}
.card .card-text {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #091f1f;
  padding-bottom: 0;
  margin-bottom: 24px;
  min-height: 48px;
  font-weight: 400;
}
.card .card-btn {
  margin-bottom: 24px;
}
.card .boxmodal-radiobtn {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  margin: 0 auto;
  margin-top: 16px;
  padding: 2px;
  background: var(--color-white);
}
.card .boxmodal-radiobtn span {
  background: transparent;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 50%;
}

/*========= Form Wrapper ==========*/
.form-wrapper {
  padding: 40px 50px;
  border-radius: 8px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  background: var(--color-white);
}
@media only screen and (max-width: 767.98px) {
  .form-wrapper {
    padding: 40px 24px;
  }
}

/* Please wait page */
.please-wait-img {
  margin-bottom: 40px;
}

/*========== temporary Guidlines css =========*/
.used-color .color-bg-base {
  background: #fbfcfc;
  color: #091f1f;
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-bg-default {
  background: #ffffff;
  color: #091f1f;
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-text-base {
  background: #839c9f;
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-text-default {
  background: #091f1f;
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-text-title {
  background: #091f1f;
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-primary {
  background: var(--color-primary);
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-secondary {
  background: #cdd7d9;
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-danger {
  background: #dc3545;
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-info {
  background: #1aade2;
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}
.used-color .color-warning {
  background: #f8e9db;
  color: var(--color-white);
  padding: 15px;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
}

/*========= Begin: Header ==========*/
header {
  height: 80px;
  background: var(--color-headerapply);
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1030;
}
header .container {
  overflow-x: visible;
}
header .navbar-brand a {
  display: inline-block;
}
@media only screen and (max-width: 1199.98px) {
  header .navbar-brand {
    padding: 0;
  }
}
@media only screen and (max-width: 767.98px) {
  header .navbar-brand {
    margin: 0;
    max-width: 67px;
  }
}
@media only screen and (max-width: 359px) {
  header .navbar-brand {
    max-width: 60px;
  }
}
header .navbar-brand h1 {
  margin-bottom: 0;
  line-height: 0;
  font-size: 0;
}
header .navbar-brand img {
  height: auto;
}
header .navbar {
  padding: 1rem 0;
  height: 80px;
  justify-content: space-between;
}
@media only screen and (max-width: 991.98px) {
  header .navbar {
    height: 68px;
  }
}
@media only screen and (max-width: 767.98px) {
  header .navbar {
    height: 62px;
  }
}
header .navbar .navbar-collapse {
  justify-content: flex-end;
}
@media only screen and (max-width: 991.98px) {
  header .navbar .navbar-collapse {
    box-shadow: 0 1px 4px 0 rgba(131, 156, 159, 0.3);
    background: var(--color-white);
    padding: 0;
    position: fixed;
    top: 68px;
    width: 100%;
    left: -100%;
    transition: 0.3s ease-in-out all;
    height: calc(100% - 68px) !important;
    max-width: 300px;
  }
}
@media only screen and (max-width: 767.98px) {
  header .navbar .navbar-collapse {
    top: 60px;
    height: calc(100% - 60px) !important;
  }
}
header .navbar .navbar-collapse.collapsing {
  height: calc(100% - 60px);
}
@media only screen and (max-width: 991.98px) {
  header .navbar .navbar-collapse.show {
    left: 0;
  }
  header .navbar .navbar-collapse.show:after {
    position: fixed;
    content: "";
    height: calc(100vh - 68px);
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 68px;
    left: 0;
    right: 0;
    z-index: -1;
    transition: 0.3s ease-in-out all;
  }
}
@media only screen and (max-width: 767.98px) {
  header .navbar .navbar-collapse.show:after {
    height: calc(100vh - 60px);
    top: 60px;
  }
}
header .navbar .login {
  background: transparent;
}
@media only screen and (max-width: 991.98px) {
  header .navbar .login {
    display: inline-block;
  }
}
header .navbar .login .login-link {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  margin-left: 2rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-primary);
  background: transparent;
}
@media only screen and (max-width: 991.98px) {
  header .navbar .login .login-link {
    padding: 0;
    margin: 0 5px 0 12px;
  }
}
@media only screen and (max-width: 575.98px) {
  header .navbar .login .login-link {
    font-size: 13px;
  }
}
@media only screen and (max-width: 359px) {
  header .navbar .login .login-link {
    margin: 0 3px 0 8px;
    font-size: 12px;
  }
}
header .navbar .login h4 {
  margin: 0;
}
@media only screen and (max-width: 575.98px) {
  header .navbar .login h4 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 359px) {
  header .navbar .login h4 {
    font-size: 11px;
  }
}
@media only screen and (max-width: 575.98px) {
  header .navbar .login .copy-icon svg {
    max-width: 16px;
  }
}
@media only screen and (max-width: 359px) {
  header .navbar .login .copy-icon svg {
    max-width: 14px;
  }
}
@media only screen and (max-width: 991.98px) {
  header .navbar .login .become-reps {
    padding: 10px 20px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  header .navbar .login .become-reps {
    padding: 7px 14px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 575.98px) {
  header .navbar .login .become-reps {
    padding: 5px 12px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 359px) {
  header .navbar .login .become-reps {
    padding: 3px 10px;
    font-size: 11px;
  }
}
header .navbar .navbar-toggler {
  padding: 0 0 0 0.5rem;
  border: none;
  color: #839c9f;
}
@media only screen and (max-width: 767.98px) {
  header .navbar .navbar-toggler .navbar-toggler-icon {
    width: 1.3em;
    height: 1.3em;
  }
}
@media only screen and (max-width: 991.98px) {
  header {
    height: 68px;
  }
  header .navbar {
    padding: 0.75rem 0;
  }
}
@media only screen and (max-width: 767.98px) {
  header {
    height: 62px;
  }
  header .navbar {
    padding: 0.5rem 0;
  }
}
header .header-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  header .header-right {
    max-width: calc(100% - 80px);
  }
}
header .header-right form.login + .profile-dropdown {
  position: relative;
  margin-left: 1rem;
  justify-content: flex-end;
}
header .header-right form.login + .profile-dropdown::before {
  content: "";
  width: 1px;
  height: 32px;
  background: rgba(205, 215, 217, 0.24);
  position: absolute;
  left: 0;
  top: -3px;
}
@media only screen and (max-width: 767.98px) {
  header .header-right form.login + .profile-dropdown::before {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  header .header-right form.login + .profile-dropdown {
    margin-left: 6px;
  }
}
@media only screen and (max-width: 575.98px) {
  header .header-right form.login + .profile-dropdown {
    margin-top: 2px;
    margin-right: 0;
  }
  header .header-right form.login + .profile-dropdown .dropdown-menu {
    top: 40px;
  }
  header .header-right form.login + .profile-dropdown .dropdown-menu::before {
    right: 7px;
  }
}
header .custom-nav .navbar {
  background-color: var(--color-white);
}
header .custom-nav .navbar .navbar-toggler {
  border: none;
}
header .custom-nav .navbar .navbar-toggler-icon {
  background-size: 35px;
}
header .custom-nav .navbar .close-icon {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 15px;
  height: 15px;
}
header .custom-nav .navbar .close-icon:before,
header .custom-nav .navbar .close-icon:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 15px;
  width: 2px;
  background-color: var(--color-white);
}
header .custom-nav .navbar .close-icon:before {
  transform: rotate(45deg);
}
header .custom-nav .navbar .close-icon:after {
  transform: rotate(-45deg);
}
@media only screen and (max-width: 991.98px) {
  header .custom-nav .navbar {
    position: static;
  }
}
header .custom-nav .navbar li.nav-item.active a {
  color: var(--color-primary);
}
header .custom-nav .navbar li.nav-item a {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  color: #839c9f;
}
@media only screen and (max-width: 991.98px) {
  header .custom-nav .navbar li.nav-item a {
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-white);
    color: var(--color-white);
  }
}
@media only screen and (max-width: 991.98px) {
  header .custom-nav .navbar .navbar-nav {
    margin-top: 30px;
  }
  header .custom-nav .navbar .navbar-collapse {
    position: absolute;
    width: 200px;
    right: -200px;
    top: 0px;
    height: 100vh !important;
    text-align: center;
    background: #333333;
    z-index: 999;
    transition: all ease 0.5s;
    opacity: 0;
  }
  header .custom-nav .navbar .navbar-collapse.show {
    right: 0;
    opacity: 1;
    left: initial !important;
  }
  header .custom-nav .navbar .navbar-collapse.show:after {
    content: none !important;
  }
}
header .agent-info {
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
}
header .agent-info .img-container {
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}
header .agent-info .img-container svg {
  fill: var(--color-primary);
}
header .agent-info .agent-name {
  font-family: var(--font-var), sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: normal;
  color: #333333;
  text-align: left;
}
header .agent-info .agent-info {
  font-family: var(--font-var), sans-serif;
  font-weight: 400;
  line-height: normal;
  padding-top: 1.5px;
  font-size: 13px;
  color: #333333;
  text-align: left;
  padding-left: 0;
}
header .profile-logo {
  max-width: 120px;
  max-height: 60px;
  object-fit: cover;
  display: inline-block;
  vertical-align: middle;
}
@media only screen and (max-width: 767.98px) {
  header .profile-logo {
    width: 40px;
    height: 40px !important;
  }
}
header .powered {
  display: block;
  color: #333333;
  font-size: 0.65rem;
}
header .company-info {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-size: 1.2rem;
  margin-left: 18px;
  padding-left: 18px;
  position: relative;
  line-height: 1.4;
  color: var(--color-primary);
}
@media only screen and (max-width: 767.98px) {
  header .company-info {
    margin-left: 14px;
    padding-left: 14px;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  header .company-info {
    margin-left: 14px;
    padding-left: 14px;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
header .company-info::before {
  content: "";
  width: 2px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: rgba(205, 215, 217, 0.5);
}
@media only screen and (max-width: 767.98px) {
  header .company-info::before {
    width: 1px;
    height: 20px;
  }
}
header .company-info-logged-in {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-size: 1.2rem;
  margin-left: 18px;
  padding-left: 18px;
  position: relative;
  line-height: 1.4;
  color: var(--color-primary);
}
@media only screen and (max-width: 767.98px) {
  header .company-info-logged-in {
    margin-left: 14px;
    padding-left: 14px;
    max-width: 104px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  header .company-info-logged-in {
    margin-left: 14px;
    padding-left: 14px;
    max-width: 370px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
header .company-info-logged-in::before {
  content: "";
  width: 2px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: rgba(205, 215, 217, 0.5);
}
@media only screen and (max-width: 767.98px) {
  header .company-info-logged-in::before {
    width: 1px;
    height: 20px;
  }
}

.login-link .profile-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);
}
@media only screen and (max-width: 991.98px) {
  .login-link .profile-dropdown {
    width: 100%;
    padding-left: 15px;
  }
}
.login-link .profile-dropdown .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 5px;
}
.login-link .profile-dropdown .dropdown-menu {
  left: unset;
  right: 0;
}
@media only screen and (max-width: 991.98px) {
  .login-link .profile-dropdown .dropdown-menu {
    width: 100%;
  }
}

/*========= Begin: Navbar for Login, Forgot Password and Link Sent Successfully page ==========*/
.login-navbar.navbar-nav {
  background: var(--color-white);
}
@media only screen and (max-width: 991.98px) {
  .login-navbar.navbar-nav {
    height: 100%;
  }
}
.login-navbar.navbar-nav .become-reps {
  margin-left: 1.125rem;
  text-transform: none;
}
@media only screen and (max-width: 991.98px) {
  .login-navbar.navbar-nav .become-reps {
    margin: 15px 15px 0;
  }
}
.login-navbar.navbar-nav .nav-link {
  align-self: center;
  padding: 0 1rem;
  margin: 0 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: #839c9f;
  position: relative;
}
.login-navbar.navbar-nav .nav-link:after {
  content: "";
  position: absolute;
  left: 16px;
  bottom: -5px;
  width: 0%;
  height: 2px;
  background: var(--color-primary);
  transform-origin: left;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 991.98px) {
  .login-navbar.navbar-nav .nav-link:after {
    width: auto;
    left: 15px;
    right: 15px;
    bottom: 0;
  }
}
.login-navbar.navbar-nav .nav-link.active:after, .login-navbar.navbar-nav .nav-link:hover:after, .login-navbar.navbar-nav .nav-link:focus:after {
  width: 20%;
  transform-origin: left;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 991.98px) {
  .login-navbar.navbar-nav .nav-link.active:after, .login-navbar.navbar-nav .nav-link:hover:after, .login-navbar.navbar-nav .nav-link:focus:after {
    width: auto;
    left: 15px;
    right: 15px;
    bottom: 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .login-navbar.navbar-nav .nav-link {
    padding: 10px 15px;
    margin: 0;
    width: 100%;
  }
}

/*========= End: Navbar for Login, Forgot Password and Link Sent Successfully page ==========*/
/*========= Begin: Navbar for after user loged in ==========*/
.after-login-navbar.navbar-nav {
  padding: 5px 0 5px 20px;
  margin-left: 20px;
  margin-right: auto;
  border-left: 1px solid #cdd7d9;
  background: var(--color-white);
}
@media only screen and (max-width: 991.98px) {
  .after-login-navbar.navbar-nav {
    padding: 0;
    margin: 0;
    border: 0;
  }
}
.after-login-navbar.navbar-nav .nav-link {
  padding: 0 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #839c9f;
  position: relative;
}
.after-login-navbar.navbar-nav .nav-link:after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -5px;
  width: 0%;
  height: 2px;
  background: var(--color-primary);
  transform-origin: left;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 991.98px) {
  .after-login-navbar.navbar-nav .nav-link:after {
    width: auto;
    left: 15px;
    right: 15px;
    bottom: 0;
  }
}
.after-login-navbar.navbar-nav .nav-link.active:after, .after-login-navbar.navbar-nav .nav-link:hover:after {
  width: 20%;
  transform-origin: left;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 991.98px) {
  .after-login-navbar.navbar-nav .nav-link.active:after, .after-login-navbar.navbar-nav .nav-link:hover:after {
    width: auto;
    left: 15px;
    right: 15px;
    bottom: 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .after-login-navbar.navbar-nav .nav-link {
    padding: 10px 15px;
    margin: 0;
  }
}

/*========= End: Navbar for after user loged in ==========*/
/*========= End: Header ==========*/
/*=============== Begin: Footer ===============*/
footer {
  position: relative;
}
footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100px;
  right: -100px;
  height: 100%;
  opacity: 0.5;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(131, 156, 159, 0.08) 100%);
  border-radius: 50% 50% 0 0;
  z-index: -1;
}
@media only screen and (max-width: 991.98px) {
  footer {
    padding-top: 65px;
  }
  footer::before {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  footer {
    padding-top: 30px;
  }
}

/*===== Begin: Footer Block =====*/
.footer-block {
  padding-bottom: 99px;
}
@media only screen and (max-width: 991.98px) {
  .footer-block {
    padding-bottom: 1.875rem;
  }
}
.footer-block .front-title-block .text-link-gray:after {
  width: 100%;
  top: unset;
  bottom: 2px;
  border-bottom: 1px solid #839c9f;
}
@media only screen and (max-width: 991.98px) {
  .footer-block .front-title-block {
    margin-bottom: 1.5rem;
  }
}

.footer-nav .footer-link {
  margin-bottom: 1.5rem;
}
.footer-nav .footer-link:last-child {
  margin-bottom: 0;
}
.footer-nav .footer-link a {
  font-weight: 500;
  line-height: 1.25rem;
}
@media only screen and (max-width: 767.98px) {
  .footer-nav {
    margin-bottom: 1rem;
  }
}

.footer-nav-title {
  font-size: 1rem;
  color: #091f1f;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: capitalize;
  margin-bottom: 2.5rem;
}
@media only screen and (max-width: 767.98px) {
  .footer-nav-title {
    margin-bottom: 1rem;
  }
}

/*===== End: Footer Block =====*/
/*===== Begin: Copy Right =====*/
.copyright-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 0 3.688rem;
  flex-wrap: wrap;
  border-top: 1px solid rgba(131, 156, 159, 0.1);
}
@media only screen and (max-width: 767.98px) {
  .copyright-block {
    flex-direction: column;
    padding: 1rem 0;
  }
  .copyright-block .footer-logo img {
    width: 75px;
    height: auto;
  }
  .copyright-block .copyright {
    margin: 1rem 0;
  }
}

.disclaimer-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3.688rem;
  padding-top: 20px;
  flex-wrap: wrap;
  border-top: 1px solid rgba(131, 156, 159, 0.1);
}
@media only screen and (max-width: 767.98px) {
  .disclaimer-block {
    flex-direction: column;
    padding: 10px 0;
  }
  .disclaimer-block .footer-logo img {
    width: 75px;
    height: auto;
  }
  .disclaimer-block .disclaimer {
    margin: 1rem 0;
  }
}
@media only screen and (max-width: 767.98px) and (max-width: 767.98px) {
  .disclaimer-block .disclaimer {
    margin: 5px 0;
  }
}

.social-links li {
  display: inline-block;
  margin-left: 1.25rem;
}
.social-links li:first-child {
  margin-left: 0;
}
.social-links li a svg g {
  fill: #839c9f;
}
.social-links li a:hover svg g {
  fill: var(--color-primary);
  opacity: 1;
}

/*===== End: Copy Right =====*/
/*=============== End: Footer ===============*/
/* ======== cms pages ========*/
/*===== Begin: Common Title Block =====*/
.front-title-block .subtitle {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 900;
  letter-spacing: 5.83px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 991.98px) {
  .front-title-block .subtitle {
    margin-bottom: 0.7rem;
  }
}
.front-title-block .title {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
  color: #1b3030;
}
@media only screen and (max-width: 991.98px) {
  .front-title-block .title {
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
}
.front-title-block .common-para {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 400;
  padding: 0;
}
@media only screen and (max-width: 991.98px) {
  .front-title-block .common-para {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.front-title-block .btn {
  margin-top: 2.1rem;
}
@media only screen and (max-width: 991.98px) {
  .front-title-block .btn {
    margin-top: 1.6rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .front-title-block .btn.btn-lg {
    padding: 8px 5px;
  }
}

/*===== End: Common Title Block =====*/
/*=============== Begin: Landing page ===============*/
/*===== Begin: Hero Section =====*/
.hero-section {
  padding-top: 160px;
  padding-bottom: 80px;
  overflow-x: hidden;
}
@media only screen and (max-width: 1499px) {
  .hero-section {
    padding-top: 130px;
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 991.98px) {
  .hero-section {
    padding-top: 110px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  .hero-section {
    padding-bottom: 30px;
  }
}
.hero-section .container {
  overflow: unset;
}
.hero-section .front-title-block {
  padding-top: 60px;
}
@media only screen and (max-width: 1199.98px) {
  .hero-section .front-title-block {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  .hero-section .front-title-block {
    padding-top: 0;
  }
}
@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .hero-section .front-title-block .btn {
    padding: 12px 22px;
    font-size: 15px;
  }
}
.hero-section .hero-image {
  margin: -36px -323px 0 -140px;
  position: relative;
  z-index: -1;
}
@media only screen and (max-width: 1749px) {
  .hero-section .hero-image {
    margin: -36px -298px 0 -165px;
  }
}
@media only screen and (max-width: 1499px) {
  .hero-section .hero-image {
    margin: -16px -190px 0 -165px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .hero-section .hero-image {
    margin: -36px -150px 0 -130px;
  }
}
@media only screen and (max-width: 991.98px) {
  .hero-section .hero-image {
    margin: -16px -120px 0 -90px;
  }
}
@media only screen and (max-width: 767.98px) {
  .hero-section .hero-image {
    margin: 0 -75px 0 -75px;
  }
}

.how-work {
  overflow-x: hidden;
}

/*===== End: Hero Section =====*/
/*===== Begin: Become Member =====*/
.become-member {
  position: relative;
  margin: 160px 0 2px 0;
}
@media only screen and (max-width: 991.98px) {
  .become-member {
    margin: 120px 0 2px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .become-member {
    margin: 110px 0 2px 0;
  }
}
.become-member .container {
  overflow: unset;
}
.become-member .row > div {
  position: inherit;
}
@media only screen and (max-width: 767.98px) {
  .become-member .row > div:first-child {
    order: 2;
  }
}
.become-member .become-member-img {
  position: absolute;
  left: 0;
  top: 0;
}
@media only screen and (max-width: 1499px) {
  .become-member .become-member-img img {
    max-height: 680px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .become-member .become-member-img img {
    max-height: 520px;
  }
}
@media only screen and (max-width: 991.98px) {
  .become-member .become-member-img img {
    max-height: 350px;
  }
}
@media only screen and (max-width: 767.98px) {
  .become-member .become-member-img {
    position: relative;
  }
  .become-member .become-member-img img {
    max-height: none;
    margin-left: -15px;
  }
}
.become-member .become-member-wrapper {
  min-height: 848px;
  padding-top: 130px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 1499px) {
  .become-member .become-member-wrapper {
    min-height: 680px;
    padding-top: 100px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .become-member .become-member-wrapper {
    min-height: 520px;
    padding-top: 60px;
  }
}
@media only screen and (max-width: 991.98px) {
  .become-member .become-member-wrapper {
    min-height: 350px;
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  .become-member .become-member-wrapper {
    padding: 0 0 70px 0;
    min-height: 0;
  }
}
.become-member .become-member-wrapper .front-title-block {
  max-width: 490px;
}
@media only screen and (max-width: 1199.98px) {
  .become-member .become-member-wrapper .front-title-block {
    max-width: 390px;
  }
}
@media only screen and (max-width: 767.98px) {
  .become-member .become-member-wrapper .front-title-block {
    max-width: 300px;
  }
}

/*===== End: Become Member =====*/
/*===== Begin: About Us =====*/
.about-us {
  margin-bottom: 100px;
}
.about-us .container {
  overflow: unset;
}
@media only screen and (max-width: 1199.98px) {
  .about-us {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 991.98px) {
  .about-us {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us {
    margin-bottom: 20px;
  }
}
.about-us .about-us-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.about-us .about-us-wrapper .title span {
  display: block;
}
@media only screen and (min-width: 768px) {
  .about-us.client-about .row > div:first-child {
    order: 2;
  }
}
.about-us.client-about .about-us-img {
  float: none;
}
.about-us.client-about .about-us-img:after {
  right: 30px;
}
@media only screen and (max-width: 991.98px) {
  .about-us.client-about .about-us-img:after {
    right: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us.client-about .about-us-img:after {
    right: 12px;
  }
}

.about-us-img {
  float: right;
  border-radius: 12px;
  position: relative;
}
@media only screen and (max-width: 767.98px) {
  .about-us-img {
    float: none;
    max-width: 50%;
  }
}
@media only screen and (max-width: 575.98px) {
  .about-us-img {
    float: none;
    max-width: 80%;
  }
}
.about-us-img:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: -30px;
  bottom: -30px;
  border-radius: 16px;
  background: rgba(var(--color-primary), 0.08);
}
@media only screen and (max-width: 1199.98px) {
  .about-us-img:after {
    right: -24px;
    bottom: -24px;
  }
}
@media only screen and (max-width: 991.98px) {
  .about-us-img:after {
    right: -20px;
    bottom: -20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us-img:after {
    right: -12px;
    bottom: -12px;
  }
}
.about-us-img.shadow-left:after {
  left: -30px;
  right: auto;
}
@media only screen and (max-width: 1199.98px) {
  .about-us-img.shadow-left:after {
    left: -24px;
  }
}
@media only screen and (max-width: 991.98px) {
  .about-us-img.shadow-left:after {
    left: -20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .about-us-img.shadow-left:after {
    left: -12px;
  }
}
.about-us-img.no-shadow:after {
  display: none;
}
@media only screen and (max-width: 767.98px) {
  .about-us-img {
    max-height: unset;
    margin: 30px auto 40px;
  }
}
.about-us-img img {
  object-fit: contain;
  object-position: center;
  border-radius: 12px;
  position: relative;
  z-index: 1;
}

/*===== End: About Us =====*/
/*===== Begin: Testimonials =====*/
.testimonials {
  margin: 130px 0;
}
@media only screen and (max-width: 1199.98px) {
  .testimonials {
    margin: 60px 0 80px 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .testimonials {
    margin: 50px 0 30px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .testimonials {
    margin: 10px 0;
  }
}
.testimonials .testi-title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.testimonials .testi-title-block .title span {
  display: block;
}

.testimonial-block {
  padding: 40px 16px;
  box-shadow: 0 2px 0 0 rgba(131, 156, 159, 0.2);
  margin-bottom: 15px;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 991.98px) {
  .testimonial-block {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .testimonial-block:hover {
    box-shadow: -9px 12px 48px 1px rgba(131, 156, 159, 0.1);
  }
}
.testimonial-block .testi-stars {
  margin-bottom: 1.688rem;
}
.testimonial-block .testi-stars span {
  color: #ffbc23;
  margin-right: 4px;
}
.testimonial-block .testi-stars span:last-child {
  margin-right: 0;
}
.testimonial-block .testi-text {
  font-size: 1.25rem;
  color: #839c9f;
  line-height: 2.125rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 1.5rem;
}
.testimonial-block .testi-text p {
  padding-bottom: 0;
}

.testi-profile {
  display: flex;
  align-items: center;
}
.testi-profile .testi-profile-img {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1.5rem;
}
.testi-profile .testi-profile-img img {
  object-fit: cover;
  object-position: center;
}
.testi-profile .testi-profile-details {
  max-width: calc(100% - 108px);
}
.testi-profile .testi-profile-details p {
  padding-bottom: 0;
}

/*===== End: Testimonials =====*/
/*=============== End: Landing page ===============*/
/* ======== End cms pages ========*/
/*========= Steps ==========*/
.title-header {
  margin-bottom: 2rem;
  text-align: center;
}
.title-header .page-title {
  margin-bottom: 8px;
  color: var(--color-txt-df);
}
.title-header .page-title.assign-header-title {
  font-size: 1.9rem;
}
@media only screen and (max-width: 991.98px) {
  .title-header .page-title.assign-header-title {
    font-size: 1.6rem;
  }
}
.title-header h4 {
  margin-bottom: 0;
}
.title-header h4 .text-primary {
  font-weight: 500;
}

.sub-title {
  font-weight: 500;
  color: #091f1f;
  font-size: 1.25rem;
}

.footer-buttons {
  text-align: center;
  max-width: 120px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.footer-buttons .btn {
  display: block;
  margin: 0 auto;
  margin-bottom: 0;
  order: 2;
}
.footer-buttons .btn:last-child {
  order: 1;
  margin-bottom: 2rem;
}

.steps .boxmodal-radio input[type=checkbox] + .card-inner,
.steps .boxmodal-radio input[type=radio] + .card-inner,
.steps .boxmodal-checkbox input[type=checkbox] + .card-inner,
.steps .boxmodal-checkbox input[type=radio] + .card-inner {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}
.steps .boxmodal-radio:hover input[type=checkbox] + .card-inner,
.steps .boxmodal-radio:hover input[type=radio] + .card-inner,
.steps .boxmodal-checkbox:hover input[type=checkbox] + .card-inner,
.steps .boxmodal-checkbox:hover input[type=radio] + .card-inner {
  border: 1px solid var(--color-over-effect);
  background: transparent;
  transform: scale(1.03);
}

/*========= Begin: Login Page ==========*/
.login-wrapper {
  max-width: 460px;
  margin: 0 auto;
}

.forgot-part {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.not-member {
  margin: 40px 0;
}
.not-member p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: center;
}
.not-member p a {
  color: var(--color-primary);
  font-weight: 500;
}

/*========= End: Login Page ==========*/
/* ============ Scan details =========== */
.scandetails-wrapper {
  max-width: 470px;
  margin: 0 auto;
}
.scandetails-wrapper h3 {
  font-weight: 500;
  margin-bottom: 2rem;
}
.scandetails-wrapper .scan-btn {
  align-items: center;
  margin: 0 auto;
  margin-bottom: 8px;
  display: inline-block;
}
.scandetails-wrapper .scan-btn img {
  margin-right: 1.25rem;
}
.scandetails-wrapper h5 {
  margin-bottom: 1.5rem;
}

.or-part {
  position: relative;
  margin-bottom: 2rem;
}
.or-part:after {
  width: 100%;
  height: 1px;
  background: #cdd7d9;
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 2;
}
.or-part h4 {
  padding: 0 8px;
  background: var(--color-white);
  position: relative;
  z-index: 3;
  display: inline-block;
  margin-bottom: 0;
}

/*========= Begin: Link Sent Successfully Page ==========*/
.link-sent .successfull-svg {
  text-align: center;
  margin: 25px 0 40px;
}
.link-sent .form-group .btn {
  padding: 10px 28px;
}

.more-info {
  padding-bottom: 60px;
}
.more-info .react-datepicker-popper[data-placement^=top] {
  top: 58px !important;
  transform: translate3d(0px, 0, 0px) !important;
  -webkit-transform: translate3d(0px, 0, 0px) !important;
}
@media only screen and (max-width: 767.98px) {
  .more-info .react-datepicker-popper[data-placement^=top] {
    top: 50px !important;
  }
}
.more-info .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  top: -8px;
  background: transparent !important;
  border: transparent !important;
}
.more-info .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-width: 8px;
  border-bottom-color: #2ba84a;
  border-top: none;
  transform: rotate(0);
  bottom: -8px;
}

/* =========== End: Link Sent Successfully Page =============== */
/* ====== Begin: OTP Varification Page ========== */
.signup-otp-wrapper {
  margin: 0 auto;
}
.signup-otp-wrapper .form-wrapper .form-text {
  max-width: 590px;
  margin: 0 auto;
}
.signup-otp-wrapper .form-wrapper .form-text .material-icons {
  color: #839c9f;
  vertical-align: middle;
}
.signup-otp-wrapper .form-wrapper .form-text h3 {
  line-height: 1.3;
}
.signup-otp-wrapper .form-wrapper .otp-input-wrapper {
  margin-bottom: 12px;
}
.signup-otp-wrapper .form-wrapper .otp-input-wrapper > div {
  justify-content: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575.98px) {
  .signup-otp-wrapper .form-wrapper .otp-input-wrapper > div {
    justify-content: space-around;
  }
}

.signup-otp .otp-input-wrapper > div {
  justify-content: center;
}
@media only screen and (max-width: 575.98px) {
  .signup-otp .otp-input-wrapper > div {
    justify-content: space-around;
  }
}

.otp-input {
  width: 48px !important;
  height: 48px !important;
  text-align: center;
  font-size: 1rem;
  color: #091f1f;
  background: var(--color-white);
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  margin: 0 12px 12px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
@media only screen and (max-width: 575.98px) {
  .otp-input {
    width: 40px !important;
    height: 40px !important;
    margin-left: 8px;
    margin-right: 8px;
  }
}
@media only screen and (max-width: 413.98px) {
  .otp-input {
    width: 30px !important;
    height: 30px !important;
    margin-right: 8px;
    margin-left: 0;
  }
}

.verification-modal .modal-dialog {
  max-width: 770px;
}
@media only screen and (max-width: 991.98px) {
  .verification-modal .modal-dialog {
    max-width: calc(100% - 30px);
  }
}
.verification-modal .signup-otp .form-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  box-shadow: none;
  background: transparent;
  max-width: 570px;
  margin: 0 auto;
}

/* ====== End: OTP Varification Page ========== */
/* ====== Begin: register-steps ========== */
/* ====== Begin: register-steps1 ========== */
.register-steps {
  margin-bottom: 2rem;
}
.register-steps .form-wrapper {
  padding: 1.5rem;
}
.register-steps .form-wrapper form:last-child .register-form-block {
  margin-bottom: 0;
  border-bottom: 0;
}

.register-form-block {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.register-form-block h3 {
  font-weight: 500;
}
.register-form-block .form-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.register-form-block .form-block.height-input {
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.register-form-block .form-block .form-group {
  max-width: 160px;
  margin: 0 0.5rem 1.5rem;
}
@media only screen and (max-width: 767.98px) {
  .register-form-block .form-block .form-group {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.register-form-block .form-block .form-group .input-effect {
  position: relative;
}
.register-form-block .form-block .form-group .input-effect .form-control {
  padding-right: 2.5rem;
}
.register-form-block .form-block .form-group .input-effect .form-control ~ label {
  color: #091f1f;
}
.register-form-block .form-block .form-group .input-effect span {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  font-size: 1rem;
  line-height: 1.5rem;
}
.register-form-block .form-block .fact-finder-third-question-option1 {
  max-width: 208px;
}
@media only screen and (max-width: 767.98px) {
  .register-form-block .form-block .fact-finder-third-question-option1 {
    max-width: 100%;
  }
}
.register-form-block .form-block .fact-finder-third-question-option2 {
  max-width: 238px;
}
@media only screen and (max-width: 767.98px) {
  .register-form-block .form-block .fact-finder-third-question-option2 {
    max-width: 100%;
  }
}
.register-form-block .form-block .fact-finder-third-question-option3 {
  max-width: 164px;
}
@media only screen and (max-width: 767.98px) {
  .register-form-block .form-block .fact-finder-third-question-option3 {
    max-width: 100%;
  }
}
.register-form-block .form-block .fact-finder-third-question-option4 {
  max-width: 200px;
}
@media only screen and (max-width: 767.98px) {
  .register-form-block .form-block .fact-finder-third-question-option4 {
    max-width: 100%;
  }
}
.register-form-block.weight-block .form-group {
  max-width: 240px;
}

/* ====== End: register-steps1 ========== */
/* ====== Begin: register-steps2 ========== */
.radio-block.form-block .form-group {
  width: 100%;
  border: 1px solid #dbe2de;
  border-radius: 4px;
}
.radio-block.form-block .form-group label {
  padding: 12px 18px 12px 50px;
  width: 100%;
  color: #091f1f;
}
.radio-block.form-block .form-group label span {
  left: 18px;
}

.form-block-questions {
  padding-bottom: 1.5rem;
}
.form-block-questions .custom-checkbox,
.form-block-questions .custom-radio {
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}
.form-block-questions .custom-checkbox:last-child,
.form-block-questions .custom-radio:last-child {
  margin-bottom: 0;
}
.form-block-questions .questions-wrapper {
  display: flex;
  justify-content: space-between;
  margin: -0.5rem;
  position: relative;
}
.form-block-questions .questions-wrapper .form-group {
  width: 100%;
  max-width: 33.33%;
  margin: 0.5rem 0.5rem 0.5rem;
}
.form-block-questions .questions-wrapper .form-group.full {
  max-width: calc(100% - 16px);
}
@media only screen and (max-width: 767.98px) {
  .form-block-questions .questions-wrapper {
    flex-wrap: wrap;
  }
  .form-block-questions .questions-wrapper .form-group {
    max-width: 100%;
    margin-right: 0;
  }
}
.form-block-questions .questions-wrapper .default-select .custom-select__menu-list {
  max-height: 180px;
}
.form-block-questions .questions-wrapper .add-remove {
  position: absolute;
  right: -5px;
  top: 2px;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 30px;
}
@media only screen and (max-width: 767.98px) {
  .form-block-questions .questions-wrapper .add-remove {
    width: 22px;
  }
}
.form-block-questions .questions-wrapper .add-remove .btn-link {
  background-color: transparent;
  padding: 0;
  border: none;
  min-width: 0;
  display: block;
  width: 100%;
}
.form-block-questions.register-step3 .questions-wrapper {
  flex-wrap: wrap;
}
.form-block-questions.register-step3 .questions-wrapper .form-group {
  max-width: calc(50% - 16px);
}
@media only screen and (max-width: 767.98px) {
  .form-block-questions.register-step3 .questions-wrapper .form-group {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

/* ====== End: register-steps2 ========== */
/* ====== End: register-steps ========== */
/* =========== getstart-page =========== */
.getstart-page .card {
  margin-bottom: 2rem;
  height: calc(100% - 2rem);
}
.getstart-page .card-inner {
  display: flex;
  flex-direction: column;
}
.getstart-page .card-inner .card-body {
  display: flex;
  flex-direction: column;
}
.getstart-page .card-inner .card-body h2.page-title {
  max-height: 50px;
  height: 100%;
  line-height: 1.6;
  font-size: 24px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .getstart-page .card-inner .card-body h2.page-title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365.98px) {
  .getstart-page .card-inner .card-body h2.page-title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .getstart-page .card-inner .card-body h2.page-title {
    font-size: 18px;
  }
}
.getstart-page .card-inner .card-body .notes {
  margin-top: auto;
  height: 100%;
  max-height: 75px;
}
@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .getstart-page .card-inner .card-body .notes {
    height: 100%;
    min-height: 75px;
    font-size: 18px;
  }
}
.getstart-page .card-inner .card-body .cart-desc {
  min-height: 170px;
  margin-bottom: 20px;
}

/* ========= Approved page ========== */
/* === guardian-outer === */
.guardian-outer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 575.98px) {
  .guardian-outer {
    justify-content: center;
  }
}
.guardian-outer .guardian-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 10px;
}
@media only screen and (max-width: 991.98px) {
  .guardian-outer .guardian-details {
    width: 100%;
    padding-right: 0;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .guardian-outer .guardian-details {
    justify-content: center;
  }
}
.guardian-outer .guardian-details .guardian-img {
  width: 48px;
  height: 48px;
  margin-right: 1rem;
}
.guardian-outer .guardian-details .guardian-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.guardian-outer .guardian-details .guardian-name h2 {
  margin-bottom: 0;
}
@media only screen and (max-width: 575.98px) {
  .guardian-outer .guardian-info {
    width: 100%;
    text-align: center;
  }
}
.guardian-outer .guardian-info ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -1.25rem;
}
@media only screen and (max-width: 575.98px) {
  .guardian-outer .guardian-info ul {
    margin: -0.5rem;
    justify-content: center;
  }
}
.guardian-outer .guardian-info ul li {
  margin: 0 1.25rem;
}
@media only screen and (max-width: 767.98px) {
  .guardian-outer .guardian-info ul li {
    margin: 0.5rem;
  }
}
@media only screen and (max-width: 413.98px) {
  .guardian-outer .guardian-info ul li {
    width: 100%;
    text-align: center;
  }
}
.guardian-outer .guardian-info ul li h3 {
  font-weight: 500;
  margin-bottom: 4px;
  text-align: center;
}
.guardian-outer .guardian-info ul li h3 span {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.guardian-outer .guardian-info ul li span {
  font-size: 14px;
  line-height: 20px;
  display: block;
}
.guardian-outer .edit-icon {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  .guardian-outer .edit-icon {
    margin-left: auto;
  }
}
.guardian-outer .edit-icon a {
  color: #839c9f;
}
.guardian-outer .edit-icon a:hover {
  color: var(--color-primary);
}

/* ==== policy-outer ==== */
.policy-outer .policy-name {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575.98px) {
  .policy-outer .policy-name {
    justify-content: center;
    text-align: center;
  }
}
.policy-outer .policy-name h3 {
  font-weight: 400;
  font-size: 1.2rem;
  margin-right: 8px;
}
@media only screen and (max-width: 767.98px) {
  .policy-outer .policy-name h3 {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .policy-outer .policy-name .chips-outer {
    width: calc(100% - 8px);
    margin-bottom: 0.5rem;
  }
}
.policy-outer h4 {
  height: 48px;
}
@media only screen and (max-width: 575.98px) {
  .policy-outer h4 {
    text-align: center;
  }
}

/* ==== policy-holder-outer ==== */
.policy-holder-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.policy-holder-outer .policy-holder-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767.98px) {
  .policy-holder-outer .policy-holder-details {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.policy-holder-outer .policy-holder-details .policy-holder-img {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.policy-holder-outer .policy-holder-details .policy-holder-name h3 {
  margin-bottom: 0;
  font-weight: 500;
  text-transform: capitalize;
}
.policy-holder-outer .policy-holder-info ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -1.25rem;
}
@media only screen and (max-width: 767.98px) {
  .policy-holder-outer .policy-holder-info ul {
    margin: -0.5rem;
  }
}
@media only screen and (max-width: 413.98px) {
  .policy-holder-outer .policy-holder-info ul {
    margin: 0;
  }
}
.policy-holder-outer .policy-holder-info ul li {
  margin: 0 1.25rem;
}
@media only screen and (max-width: 767.98px) {
  .policy-holder-outer .policy-holder-info ul li {
    margin: 0.5rem;
  }
}
@media only screen and (max-width: 413.98px) {
  .policy-holder-outer .policy-holder-info ul li {
    width: 100%;
    margin: 0 0 0.5rem;
  }
}
.policy-holder-outer .policy-holder-info ul li h4 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}
.policy-holder-outer .policy-holder-info ul li h4 span {
  font-size: 1rem;
  margin-left: 8px;
  color: #091f1f;
}

/* ==== policy-amount-outer ==== */
.payment-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575.98px) {
  .payment-outer {
    justify-content: center;
  }
}
.payment-outer .payment-amount {
  padding-right: 0.5rem;
}
@media only screen and (max-width: 767.98px) {
  .payment-outer .payment-amount {
    width: 100%;
    padding-right: 0;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .payment-outer .payment-amount {
    text-align: center;
  }
}
.payment-outer .payment-amount h3 {
  font-weight: 500;
  margin-bottom: 0;
}
.payment-outer .payment-amount h3 span {
  color: var(--color-primary);
}
.payment-outer .payment-amount p {
  font-size: 14px;
}
.payment-outer .payment-amount p .custom-checkbox {
  margin-right: 0.5rem;
}
.payment-outer .payment-amount p .custom-checkbox label {
  text-transform: inherit;
}
.payment-outer .payment-amount p .text-link {
  font-weight: 400;
  margin-left: 0;
}

/* === agent-outer === */
.agent-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.agent-outer .agent-details {
  display: flex;
  align-items: center;
  width: 100%;
}
.agent-outer .agent-details .agent-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}
.agent-outer .agent-details .agent-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.agent-outer .agent-details .agent-help {
  width: calc(100% - 64px);
}
.agent-outer .agent-details .agent-help h4 {
  font-weight: 500;
  color: #091f1f;
  margin-bottom: 0;
}
.agent-outer .agent-details .agent-help h5 {
  margin-bottom: 0;
}
.agent-outer.agent-shortchat {
  margin: 1.3rem 0 1.3rem 0;
  width: 100%;
}
.agent-outer.agent-shortchat .agent-help {
  width: calc(100% - 70px);
}
.agent-outer.agent-shortchat .agent-help h5 {
  line-height: 1.4;
}
@media only screen and (max-width: 991.98px) {
  .agent-outer.agent-shortchat .ml-auto {
    margin: auto;
  }
}
.agent-outer .chat-icons {
  display: inline-block;
  margin-top: 20px;
  width: 100%;
}
@media only screen and (max-width: 991.98px) {
  .agent-outer .chat-icons.ml-auto {
    margin: auto;
  }
}
.agent-outer .chat-icons svg {
  width: 26px;
  height: 30px;
  fill: var(--color-primary);
}
@media only screen and (max-width: 1199.98px) {
  .agent-outer .chat-icons svg {
    width: 26px;
    height: 28px;
  }
}
.agent-outer .chat-icons ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 991.98px) {
  .agent-outer .chat-icons ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.agent-outer .chat-icons ul li {
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 991.98px) {
  .agent-outer .chat-icons ul li {
    width: auto;
  }
}
@media only screen and (max-width: 767.98px) {
  .agent-outer .chat-icons ul li {
    width: 100%;
  }
}
.agent-outer .chat-icons ul li + li {
  margin-left: 12px;
}
@media only screen and (max-width: 1199.98px) {
  .agent-outer .chat-icons ul li + li {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  .agent-outer .chat-icons ul li + li {
    margin-left: 0;
  }
}
.agent-outer .chat-icons ul li.email-icon svg {
  width: 30px;
  height: 30px;
}
@media only screen and (max-width: 1199.98px) {
  .agent-outer .chat-icons ul li.email-icon svg {
    width: 28px;
    height: 28px;
  }
}
.agent-outer .chat-icons ul li .btn-with-icon {
  width: 100%;
}
@media only screen and (max-width: 991.98px) {
  .agent-outer .chat-icons ul li .btn-with-icon {
    margin-bottom: 15px;
  }
}
.agent-outer .chat-icons ul li .btn-with-icon svg {
  margin-right: 15px;
  fill: #091f1f;
  stroke: #091f1f;
}
.agent-outer .chat-icons ul li .btn-with-icon svg #phone {
  stroke: #091f1f;
}
.agent-outer .chat-icons ul li .btn-with-icon svg #message-square {
  stroke: #091f1f;
}
.agent-outer .chat-icons ul li .btn-with-icon:not(:disabled):not(.disabled).active, .show > .agent-outer .chat-icons ul li .btn-with-icon.dropdown-toggle, .agent-outer .chat-icons ul li .btn-with-icon:hover {
  background-color: #091f1f;
  color: #fff;
}
.agent-outer .chat-icons ul li .btn-with-icon:not(:disabled):not(.disabled).active svg, .show > .agent-outer .chat-icons ul li .btn-with-icon.dropdown-toggle svg, .agent-outer .chat-icons ul li .btn-with-icon:hover svg {
  margin-right: 15px;
  fill: #fff;
  stroke: #fff;
}
.agent-outer .chat-icons ul li .btn-with-icon:not(:disabled):not(.disabled).active svg #phone, .show > .agent-outer .chat-icons ul li .btn-with-icon.dropdown-toggle svg #phone, .agent-outer .chat-icons ul li .btn-with-icon:hover svg #phone {
  stroke: #fff;
}
.agent-outer .chat-icons ul li .btn-with-icon:not(:disabled):not(.disabled).active svg #message-square, .show > .agent-outer .chat-icons ul li .btn-with-icon.dropdown-toggle svg #message-square, .agent-outer .chat-icons ul li .btn-with-icon:hover svg #message-square {
  stroke: #fff;
}
.agent-outer .chat-icons ul li .btn-with-icon:focus, .agent-outer .chat-icons ul li .btn-with-icon:active, .agent-outer .chat-icons ul li .btn-with-icon.focusOnChatICon {
  background-color: #091f1f;
  color: #fff;
}
.agent-outer .chat-icons ul li .btn-with-icon:focus svg, .agent-outer .chat-icons ul li .btn-with-icon:active svg, .agent-outer .chat-icons ul li .btn-with-icon.focusOnChatICon svg {
  margin-right: 15px;
  fill: #fff;
  stroke: #fff;
}
.agent-outer .chat-icons ul li .btn-with-icon:focus svg #phone, .agent-outer .chat-icons ul li .btn-with-icon:active svg #phone, .agent-outer .chat-icons ul li .btn-with-icon.focusOnChatICon svg #phone {
  stroke: #fff;
}
.agent-outer .chat-icons ul li .btn-with-icon:focus svg #message-square, .agent-outer .chat-icons ul li .btn-with-icon:active svg #message-square, .agent-outer .chat-icons ul li .btn-with-icon.focusOnChatICon svg #message-square {
  stroke: #fff;
}

/* === help-outer === */
.help-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.help-outer .help-label h4 {
  font-weight: 500;
  color: #091f1f;
  margin-bottom: 0;
}
@media only screen and (max-width: 991.98px) {
  .help-outer .help-label h4 {
    margin-bottom: 1rem;
  }
}
.help-outer .help-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.help-outer .help-details .phone-number {
  margin-right: 0.5rem;
}
.help-outer .help-details .material-icons {
  color: var(--color-primary);
}
.help-outer .help-details a {
  color: #091f1f;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.terms-condition ul {
  list-style: disc;
  padding-left: 1rem;
}
.terms-condition ul li {
  list-style: outside;
  float: left;
  width: 100%;
  padding-right: 1.2rem;
}
@media only screen and (max-width: 767.98px) {
  .terms-condition ul li {
    width: 100%;
  }
}
.terms-condition ul li span {
  font-size: 12px;
}

/* ===== payment-method ====== */
.payment-method {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #cdd7d9;
  animation: height-fade-in 0.3s ease 1;
}
.payment-method h4 {
  color: #091f1f;
  margin-bottom: 1.5rem;
  font-weight: 500;
}
.payment-method .payment-mode {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}
.payment-method .payment-mode .custom-radio {
  margin-right: 86px;
}
.payment-method .payment-mode .custom-radio:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 413.98px) {
  .payment-method .payment-mode .custom-radio {
    margin-right: 1rem;
  }
}
.payment-method .payment-option {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px;
}
.payment-method .payment-option .boxmodal-radio,
.payment-method .payment-option .boxmodal-checkbox {
  height: auto;
  width: calc(12.5% - 8px);
  margin: 4px;
}
@media only screen and (max-width: 767.98px) {
  .payment-method .payment-option .boxmodal-radio,
  .payment-method .payment-option .boxmodal-checkbox {
    width: calc(25% - 8px);
  }
}
@media only screen and (max-width: 575.98px) {
  .payment-method .payment-option .boxmodal-radio,
  .payment-method .payment-option .boxmodal-checkbox {
    width: calc(33.33% - 8px);
  }
}
@media only screen and (max-width: 413.98px) {
  .payment-method .payment-option .boxmodal-radio,
  .payment-method .payment-option .boxmodal-checkbox {
    width: calc(50% - 8px);
  }
}
.payment-method .payment-option .boxmodal-radio input[type=checkbox] + .card-inner,
.payment-method .payment-option .boxmodal-checkbox input[type=checkbox] + .card-inner {
  background: transparent;
  box-shadow: none;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  padding: 8px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-method .payment-option .boxmodal-radio input[type=checkbox]:checked + .card-inner,
.payment-method .payment-option .boxmodal-checkbox input[type=checkbox]:checked + .card-inner {
  background: var(--color-over-effect);
  border: 1px solid var(--color-primary);
}
.payment-method .payment-option .boxmodal-radio input[type=radio] + .card-inner,
.payment-method .payment-option .boxmodal-checkbox input[type=radio] + .card-inner {
  background: transparent;
  box-shadow: none;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  padding: 8px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-method .payment-option .boxmodal-radio input[type=radio]:checked + .card-inner,
.payment-method .payment-option .boxmodal-checkbox input[type=radio]:checked + .card-inner {
  background: var(--color-over-effect);
  border: 1px solid var(--color-primary);
}
.payment-method .proceed-btn {
  margin-top: 1.5rem;
}
.payment-method .bank-detail-box {
  margin: -8px;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  animation: height-fade-in 0.3s ease 1;
}
.payment-method .bank-detail-box .input-effect {
  width: calc(33.33% - 16px);
  margin: 8px;
}
@media only screen and (max-width: 767.98px) {
  .payment-method .bank-detail-box .input-effect {
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 575.98px) {
  .payment-method .bank-detail-box .input-effect {
    width: calc(100% - 16px);
  }
}

/* === verification-info ======= */
.verification-outer .added-hospital .btn-upload {
  min-width: 140px;
}

.verification-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575.98px) {
  .verification-info {
    justify-content: center;
  }
}
@media only screen and (max-width: 991.98px) {
  .verification-info .verification-details {
    margin-bottom: 1rem;
    width: 100%;
  }
}
@media only screen and (max-width: 575.98px) {
  .verification-info .verification-details {
    text-align: center;
  }
}
.verification-info .verification-details h3 {
  margin-bottom: 2px;
  font-weight: 500;
}
.verification-info .verification-details h5 {
  margin-bottom: 0;
}
.verification-info .verification-details h5 .text-primary {
  font-weight: 500;
}
.verification-info small {
  font-size: 12px;
}
.verification-info .uploade-document {
  width: 200px;
}
@media only screen and (max-width: 575.98px) {
  .verification-info .uploade-document-outer {
    width: 100%;
    margin: 0 auto !important;
  }
}

.uploade-document {
  position: relative;
  z-index: 1;
  width: 100%;
}
@media only screen and (max-width: 575.98px) {
  .uploade-document {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
}
.uploade-document input[type=file] {
  width: 100%;
}
.uploade-document label {
  width: 100%;
  line-height: 1.429rem;
  border: 1px solid #dbe2de;
  font-size: 13px;
  padding: 10px 18px;
  height: 48px;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  .uploade-document label {
    height: 40px;
  }
}
.uploade-document span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  background: var(--color-white);
  min-width: 150px;
}
.uploade-document.large-file label,
.uploade-document.large-file input[type=file] {
  height: 100px;
}

.added-bank {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575.98px) {
  .added-bank {
    width: 100%;
    justify-content: center;
  }
}
.added-bank .account-number {
  padding: 10px 16px;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  margin-right: 1rem;
  min-width: 277px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #091f1f;
  font-weight: 500;
}
@media only screen and (max-width: 575.98px) {
  .added-bank .account-number {
    min-width: 100%;
    margin-bottom: 1rem;
  }
}
.added-bank .btn {
  min-width: 57px;
}

.added-hospital {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575.98px) {
  .added-hospital {
    width: 100%;
    justify-content: center;
  }
}
.added-hospital .hospital-info {
  padding: 10px 16px;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  margin-right: 1rem;
  min-width: 277px;
  display: flex;
  align-items: center;
  color: #091f1f;
  font-weight: 500;
}
@media only screen and (max-width: 575.98px) {
  .added-hospital .hospital-info {
    min-width: 100%;
    margin-bottom: 1rem;
  }
}
.added-hospital .hospital-info .hospital-name {
  width: calc(100% - 44px);
}
.added-hospital .pdf-info {
  padding: 10px 16px;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  margin-right: 1rem;
  min-width: 277px;
  display: flex;
  align-items: center;
  color: #091f1f;
  font-weight: 500;
}
@media only screen and (max-width: 575.98px) {
  .added-hospital .pdf-info {
    min-width: 100%;
    margin-bottom: 1rem;
  }
}
.added-hospital .pdf-info .material-icons {
  color: var(--color-primary);
  margin-right: 0.5rem;
  width: 24px;
}
.added-hospital .pdf-info .svg-icon {
  width: 24px;
  margin-right: 0.5rem;
}
.added-hospital .pdf-info .svg-icon svg {
  width: 24px;
  height: 24px;
}
.added-hospital .pdf-info .pdf-name {
  width: calc(100% - 44px);
}
.added-hospital .hospital-logo {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.added-hospital .hospital-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.added-hospital .btn {
  min-width: 57px;
}

.documents-smaller .pdf-info {
  min-width: 0;
  margin: 0;
  width: calc(100% - 55px);
}
@media only screen and (max-width: 767.98px) {
  .documents-smaller .pdf-info {
    width: calc(100% - 42px);
  }
}
@media only screen and (max-width: 767.98px) {
  .documents-smaller .plan-icons-outer .plan-icons {
    width: 38px;
    height: 38px;
  }
}
.documents-smaller .added-hospital {
  margin-top: 1rem;
}
.documents-smaller .more-documents {
  margin-top: 10px;
}
.documents-smaller .more-documents a {
  color: var(--color-primary);
}

/* ====== Medical verifiaction steps ========= */
.modal-part .title-header {
  margin-bottom: 1.5rem;
}
.modal-part .title-header h3 {
  margin-bottom: 0.5rem;
  font-weight: 500;
}
.modal-part h3 {
  margin-bottom: 12px;
}
.modal-part .boxmodal-radio input[type=checkbox] + .card-inner h2,
.modal-part .boxmodal-checkbox input[type=checkbox] + .card-inner h2 {
  margin-bottom: 1rem;
}
.modal-part .boxmodal-radio input[type=checkbox] + .card-inner .card-text,
.modal-part .boxmodal-checkbox input[type=checkbox] + .card-inner .card-text {
  font-size: 1rem;
}
.modal-part .boxmodal-radio input[type=checkbox] + .card-inner .card-text.text-grey,
.modal-part .boxmodal-checkbox input[type=checkbox] + .card-inner .card-text.text-grey {
  color: #839c9f;
}
.modal-part .boxmodal-radio input[type=checkbox] + .card-inner .card-btn,
.modal-part .boxmodal-checkbox input[type=checkbox] + .card-inner .card-btn {
  margin-bottom: 24px;
}
.modal-part .boxmodal-radio input[type=radio] + .card-inner h2,
.modal-part .boxmodal-checkbox input[type=radio] + .card-inner h2 {
  margin-bottom: 1rem;
}
.modal-part .boxmodal-radio input[type=radio] + .card-inner .card-text,
.modal-part .boxmodal-checkbox input[type=radio] + .card-inner .card-text {
  font-size: 1rem;
}
.modal-part .boxmodal-radio input[type=radio] + .card-inner .card-text.text-grey,
.modal-part .boxmodal-checkbox input[type=radio] + .card-inner .card-text.text-grey {
  color: #839c9f;
}
.modal-part .boxmodal-radio input[type=radio] + .card-inner .card-btn,
.modal-part .boxmodal-checkbox input[type=radio] + .card-inner .card-btn {
  margin-bottom: 24px;
}
.modal-part .boxmodal-radio:hover input[type=checkbox] + .card-inner,
.modal-part .boxmodal-radio:hover input[type=radio] + .card-inner,
.modal-part .boxmodal-checkbox:hover input[type=checkbox] + .card-inner,
.modal-part .boxmodal-checkbox:hover input[type=radio] + .card-inner {
  border: 1px solid var(--color-over-effect);
  background: transparent;
  transform: scale(1.03);
}
.modal-part .powered-by {
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #cdd7d9;
}
.modal-part .powered-by.position-top {
  position: absolute;
  top: 26px;
  right: 1.5rem;
}
@media only screen and (max-width: 413.98px) {
  .modal-part .powered-by.position-top {
    top: 45px;
  }
}
.modal-part.option-selection .boxmodal-radio input[type=radio] + .card-inner,
.modal-part.option-selection .boxmodal-checkbox input[type=radio] + .card-inner {
  padding: 36px 16px;
}
.modal-part.option-selection .boxmodal-radio input[type=radio] + .card-inner h2,
.modal-part.option-selection .boxmodal-checkbox input[type=radio] + .card-inner h2 {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
}
.modal-part.option-selection .boxmodal-radio input[type=radio] + .card-inner .card-text,
.modal-part.option-selection .boxmodal-checkbox input[type=radio] + .card-inner .card-text {
  margin-bottom: 20px;
}

.hospital-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -8px;
  margin-bottom: 1.5rem;
}
.hospital-list .boxmodal-radio,
.hospital-list .boxmodal-checkbox {
  width: calc(20% - 16px);
  margin: 8px;
  height: auto;
}
@media only screen and (max-width: 767.98px) {
  .hospital-list .boxmodal-radio,
  .hospital-list .boxmodal-checkbox {
    width: calc(33.33% - 16px);
  }
}
@media only screen and (max-width: 413.98px) {
  .hospital-list .boxmodal-radio,
  .hospital-list .boxmodal-checkbox {
    width: calc(50% - 16px);
  }
}
.hospital-list .boxmodal-radio input[type=checkbox] + .card-inner,
.hospital-list .boxmodal-checkbox input[type=checkbox] + .card-inner {
  padding: 1rem;
  border-radius: 4px;
}
.hospital-list .boxmodal-radio input[type=radio] + .card-inner,
.hospital-list .boxmodal-checkbox input[type=radio] + .card-inner {
  padding: 1rem;
  border-radius: 4px;
}
.hospital-list .hospital-logo {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 12px;
}
.hospital-list .hospital-logo img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}
.hospital-list h4 {
  margin-bottom: 0;
  color: #091f1f;
}

/* =======  search result ======== */
.search-result {
  margin-bottom: 1.5rem;
}
.search-result .boxmodal-radio,
.search-result .boxmodal-checkbox {
  margin: 0;
  height: auto;
  text-align: left;
}
.search-result .boxmodal-radio input[type=checkbox] + .search-result-list,
.search-result .boxmodal-checkbox input[type=checkbox] + .search-result-list {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
.search-result .boxmodal-radio input[type=checkbox] + .search-result-list .hospital-logo,
.search-result .boxmodal-checkbox input[type=checkbox] + .search-result-list .hospital-logo {
  width: 48px;
  height: 48px;
  padding: 5px;
  margin-right: 16px;
}
.search-result .boxmodal-radio input[type=checkbox] + .search-result-list .hospital-logo img,
.search-result .boxmodal-checkbox input[type=checkbox] + .search-result-list .hospital-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.search-result .boxmodal-radio input[type=checkbox] + .search-result-list .hospital-details,
.search-result .boxmodal-checkbox input[type=checkbox] + .search-result-list .hospital-details {
  width: calc(100% - 64px);
}
.search-result .boxmodal-radio input[type=checkbox] + .search-result-list .hospital-details h4,
.search-result .boxmodal-checkbox input[type=checkbox] + .search-result-list .hospital-details h4 {
  margin-bottom: 0;
  color: #091f1f;
}
.search-result .boxmodal-radio input[type=checkbox] + .search-result-list .hospital-details h5,
.search-result .boxmodal-checkbox input[type=checkbox] + .search-result-list .hospital-details h5 {
  margin-bottom: 0;
}
.search-result .boxmodal-radio input[type=checkbox]:checked + .search-result-list,
.search-result .boxmodal-checkbox input[type=checkbox]:checked + .search-result-list {
  background: var(--color-over-effect);
}
.search-result .boxmodal-radio input[type=radio] + .search-result-list,
.search-result .boxmodal-checkbox input[type=radio] + .search-result-list {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  border: 1px solid #cdd7d9;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
.search-result .boxmodal-radio input[type=radio] + .search-result-list .hospital-logo,
.search-result .boxmodal-checkbox input[type=radio] + .search-result-list .hospital-logo {
  width: 48px;
  height: 48px;
  padding: 5px;
  margin-right: 16px;
}
.search-result .boxmodal-radio input[type=radio] + .search-result-list .hospital-logo img,
.search-result .boxmodal-checkbox input[type=radio] + .search-result-list .hospital-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.search-result .boxmodal-radio input[type=radio] + .search-result-list .hospital-details,
.search-result .boxmodal-checkbox input[type=radio] + .search-result-list .hospital-details {
  width: calc(100% - 64px);
}
.search-result .boxmodal-radio input[type=radio] + .search-result-list .hospital-details h4,
.search-result .boxmodal-checkbox input[type=radio] + .search-result-list .hospital-details h4 {
  margin-bottom: 0;
  color: #091f1f;
}
.search-result .boxmodal-radio input[type=radio] + .search-result-list .hospital-details h5,
.search-result .boxmodal-checkbox input[type=radio] + .search-result-list .hospital-details h5 {
  margin-bottom: 0;
}
.search-result .boxmodal-radio input[type=radio]:checked + .search-result-list,
.search-result .boxmodal-checkbox input[type=radio]:checked + .search-result-list {
  background: var(--color-over-effect);
  border-color: var(--color-primary);
}

/* ====== hospital-portal ======== */
.hospital-portal .hospital-logo {
  width: 48px;
  height: 48px;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.hospital-portal .hospital-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.hospital-portal h4 {
  margin-bottom: 0;
}
.hospital-portal h4.hospital-name {
  margin-bottom: 1rem;
  color: #091f1f;
}
.hospital-portal .login-info {
  font-size: 12px;
  padding-bottom: 0;
  margin-bottom: 1rem;
}

/* =========  End approved flow =========== */
/* ====== Begin: Plan List ========== */
.filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  animation: height-fade-in 0.3s ease 1;
  position: relative;
}
@media only screen and (max-width: 991.98px) {
  .filter-block {
    margin-bottom: 1.4rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .filter-block {
    padding: 0;
  }
}
.filter-block .filter-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.filter-block .filter-wrapper .filter-item {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  margin-right: 6px;
  margin-bottom: 1rem;
  flex-direction: column;
}
@media only screen and (max-width: 413.98px) {
  .filter-block .filter-wrapper .filter-item {
    margin-right: 0;
  }
}
.filter-block .filter-wrapper .filter-item:last-child {
  margin-right: 0;
}
.filter-block .filter-wrapper .filter-item .input-effect,
.filter-block .filter-wrapper .filter-item .custom-select__control {
  border: 1px solid var(--color-primary);
  border-radius: 0px;
  font-size: 0.9rem;
}
.filter-block .filter-wrapper .filter-item .filter-item-lable {
  font-size: 0.9rem;
  font-weight: 400;
  color: #091f1f;
}
@media only screen and (max-width: 413.98px) {
  .filter-block .filter-wrapper .filter-item .filter-item-lable {
    width: 100%;
    margin-bottom: 0.625rem;
  }
}
.filter-block .filter-wrapper .filter-item .default-select {
  min-width: 120px;
  margin-left: 0;
  max-width: 160px;
}
.filter-block .filter-wrapper .filter-item .default-select.term-lenth {
  min-width: 120px;
}
@media only screen and (max-width: 575.98px) {
  .filter-block .filter-wrapper .filter-item .default-select.term-lenth {
    width: 100%;
    max-width: 100%;
  }
}
.filter-block .filter-wrapper .filter-item .default-select .custom-select-menu {
  width: 100%;
}
.filter-block .filter-wrapper .filter-item .default-select .custom-select-menu .custom-select__control {
  box-shadow: none !important;
}
@media only screen and (max-width: 413.98px) {
  .filter-block .filter-wrapper .filter-item .default-select {
    min-width: 100%;
    margin-left: 0;
    margin-bottom: 0.625rem;
  }
}
@media only screen and (max-width: 413.98px) {
  .filter-block .filter-wrapper .filter-item {
    width: 100%;
    margin-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 413.98px) {
  .filter-block .filter-wrapper .filter-item .input-effect {
    width: 100%;
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 413.98px) {
  .filter-block .filter-wrapper .filter-item .input-group {
    max-width: 100%;
  }
}
@media only screen and (max-width: 413.98px) {
  .filter-block .filter-wrapper {
    width: 100%;
  }
}
.filter-block:first-child {
  z-index: 3;
}

.filter-btn-outer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  justify-content: center !important;
}

.filter-btn.btn.btn-secondary {
  font-size: 1rem;
  color: #839c9f;
  font-weight: 500;
  line-height: 0.8rem;
  background: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  min-width: auto;
  border-bottom: 1px solid #839c9f;
}
.filter-btn.btn.btn-secondary:hover:not(:disabled), .filter-btn.btn.btn-secondary:hover:not(.disabled) {
  background: none;
  color: #839c9f;
}

.plan-block h2 {
  color: #091f1f;
}
.plan-block .page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.plan-block .page-title .page-title-logo {
  width: 200px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  overflow: hidden;
}
.plan-block .page-title .page-title-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.plan-block .boxmodal-radio input[type=radio]:checked + .card-inner {
  background: var(--color-over-effect);
  border: 1px solid black;
}
.plan-block .boxmodal-radio input[type=radio] + .card-inner .card-text {
  font-size: 1rem;
  font-weight: 400;
  margin: 1.5rem 0 1rem;
  min-height: auto;
}

.card-value-list-min-height {
  min-height: 210px;
}
.card-value-list-min-height h3 {
  font-size: 16px;
}
@media only screen and (max-width: 991.98px) {
  .card-value-list-min-height {
    min-height: inherit;
  }
  .card-value-list-min-height h3 {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1440px) {
  .card-value-list-min-height {
    min-height: 210px;
  }
}

.card-value-list {
  display: inline-block;
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.card-value-list .value-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem;
  height: 25px;
}
.card-value-list .value-list-item:last-child {
  margin: 0;
}
.card-value-list .value-list-item .list-label {
  display: inline-block;
  align-items: center;
  line-height: 1.5rem;
  font-weight: 400;
  margin-right: 1rem;
  text-align: left;
}
.card-value-list .value-list-item .list-label .material-icons {
  font-size: 17px;
  color: #605656;
  margin-left: 5px;
  margin-top: 5px;
}
.card-value-list .value-list-item .list-label .list-info {
  margin-left: 0.5rem;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.card-value-list .value-list-item .list-label .list-info:hover svg g {
  fill: #688387;
}
.card-value-list .value-list-item h3 {
  font-weight: 500;
  margin: 0;
}
.card-value-list .value-list-item h4 {
  font-weight: 400;
  margin: 0;
  color: #091f1f;
}
.card-value-list .value-list-item .btn-link {
  padding: 0;
  min-width: auto;
  color: #839c9f;
}

.plan-modals-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}
.plan-modals-wrapper .btn {
  max-width: 130px;
  margin: 0.5rem;
  white-space: pre-wrap;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .plan-modals-wrapper .btn {
    max-width: inherit;
  }
}
@media only screen and (max-width: 767.98px) {
  .plan-modals-wrapper {
    justify-content: center;
  }
  .plan-modals-wrapper .btn {
    max-width: 100%;
  }
}

.plan-modal.modal .modal-dialog .modal-content .modal-body {
  padding-top: 40px;
  overflow-x: hidden;
  min-height: 210px;
}
@media only screen and (max-width: 767.98px) {
  .plan-modal.modal .modal-dialog .modal-content .modal-body {
    padding-top: 30px;
    min-height: 200px;
  }
}
@media only screen and (max-width: 991.98px) {
  .plan-modal.modal .modal-dialog .modal-content .modal-body .chart-outer {
    overflow-x: auto;
    min-height: 340px;
  }
}
.plan-modal.modal .modal-dialog .modal-content .modal-body .risk-return {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.plan-modal.modal .modal-dialog .modal-content .modal-body .risk-return .default-select {
  width: 100%;
  max-width: 270px;
  margin-bottom: 1rem;
}
.plan-modal.modal .modal-dialog .modal-content .modal-body .recharts-wrapper {
  position: relative;
  padding-top: 2.5rem;
  margin-bottom: 2rem;
}
.plan-modal.modal .modal-dialog .modal-content .modal-body .recharts-wrapper .chart-year {
  position: absolute;
  bottom: 20px;
  left: 0;
}
.plan-modal.modal .modal-dialog .modal-content .modal-body .recharts-wrapper .recharts-legend-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: auto !important;
  top: 0;
  left: unset !important;
  right: 5px;
  bottom: unset !important;
}
.plan-modal.modal .modal-dialog .modal-content .modal-body .recharts-wrapper .recharts-legend-wrapper .recharts-legend-item:last-child {
  margin-right: 0 !important;
}
@media only screen and (max-width: 991.98px) {
  .plan-modal.modal .modal-dialog .modal-content .modal-body .recharts-wrapper .recharts-legend-wrapper {
    display: block;
  }
}
.plan-modal.modal .modal-dialog .modal-content .modal-body .no-plan {
  margin-top: 10px;
}
.plan-modal.modal.analytics-modal .modal-body {
  overflow-x: auto;
}
.plan-modal .min-width {
  width: 170px;
  display: inline-block;
  vertical-align: top;
}
@media only screen and (max-width: 1199.98px) {
  .plan-modal .min-width {
    width: 150px;
  }
}
.plan-modal .term-value {
  display: inline-block;
  color: #1b3030;
  vertical-align: top;
}
@media only screen and (max-width: 767.98px) {
  .plan-modal .table.risk-table {
    width: 100%;
  }
}
.plan-modal .notes {
  margin-top: 12px;
  font-size: 0.85rem;
  line-height: 1.4rem;
}

.plan-btn-block .btn {
  margin: 0.75rem;
}

.comparison-block {
  display: block;
  cursor: default;
  position: relative;
  text-align: center;
  height: calc(95% - 10px);
}
.comparison-block .card-inner {
  background: var(--color-white);
  border: 1px solid #cdd7d9;
  border-radius: 8px;
  padding: 40px 24px;
  transition: all 300ms ease-in-out 0s;
  height: calc(100% - 0px);
  margin-bottom: 8px;
  position: relative;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .comparison-block .card-inner {
    padding: 2rem 1rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .comparison-block .card-inner {
    height: auto;
  }
}
.comparison-block .card-inner .card-title-fixed {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
}
.comparison-block .card-inner .card-value-list {
  border-bottom: 0;
}

.compare-bank-acc {
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.compare-bank-acc .default-select .custom-select-menu .custom-select__control .custom-select__single-value {
  color: #091f1f;
}
.compare-bank-acc .default-select .custom-select-menu .custom-select__control .custom-select__value-container {
  padding-left: 0;
}
.compare-bank-acc .default-select .custom-select-menu .custom-select__control .custom-select__value-container .custom-select__placeholder {
  font-size: 2rem;
  font-weight: 300;
  color: #091f1f;
}
.compare-bank-acc .custom-select__single-value {
  font-size: 2rem;
  line-height: 2.75rem;
  font-weight: 300;
  color: #091f1f;
}

/* ====== End: Plan List ========== */
/* ====== Begin: Chat Screen ========== */
/* ====== Begin: Chat Profile ========== */
.chat-details-wrapper {
  padding: 0 0 1rem;
  text-align: center;
}
.chat-details-wrapper .chat-img {
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.chat-details-wrapper .chat-details h4 {
  color: #091f1f;
  font-weight: 500;
  text-transform: capitalize;
}
.chat-details-wrapper .chat-details .chat-ratings .rating-star {
  font-size: 1.25rem;
  color: var(--color-primary);
  margin-right: 5px;
}

.certification {
  padding: 1rem 0 0;
  border-top: 1px solid rgba(205, 215, 217, 0.24);
}
.certification h5 {
  margin: 0 0 0.75rem;
  text-transform: uppercase;
}

.certification-list .certification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.certification-list .certification-item:last-child {
  margin-bottom: 0;
}
.certification-list .certification-item .certification-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid #f3f6f6;
  border-radius: 50%;
}
.certification-list .certification-item .certification-details {
  width: calc(100% - 60px);
}
.certification-list .certification-item .certification-details h5 {
  margin-bottom: 0;
}
.certification-list .certification-item .certification-details .certification-name {
  color: #091f1f;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
}

/* ====== End: Chat Profile ========== */
/* ====== Begin: Reting and Review ========== */
.rating-review h4 {
  color: #091f1f;
}
.rating-review .rating-stars-wrapper {
  margin: 0.5rem 0 1rem;
}
.rating-review .rating-stars-wrapper .rating-star {
  font-size: 1.5rem;
  color: var(--color-primary);
  font-weight: 700;
  margin-right: 1rem;
}
.rating-review .rating-stars-wrapper .rating-star:last-child {
  margin-right: 0;
}

/* ====== End: Reting and Review ========== */
/* ====== Begin: Chat Component Header ========== */
.chat-screen .card-outer,
.conversation-page .card-outer {
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
}

.chat-component-header .card-outer {
  padding: 0;
}
.chat-component-header .card-outer .guardian-outer {
  padding: 1.25rem;
}
.chat-component-header .card-outer .policy-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.25rem;
  border-top: 1px solid rgba(205, 215, 217, 0.24);
}

/* ====== End: Chat Component Header ========== */
/* ====== Begin: Chat Box Header ========== */
.chat-box-header {
  padding: 1.25rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.chat-box-header h3 {
  font-weight: 500;
}

.conversation-header {
  padding: 1rem 1.5rem;
  background: #f3f6f6;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
}
.conversation-header .conversation-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}
.conversation-header .conversation-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: inherit;
}
.conversation-header h3 {
  font-weight: 500;
  width: calc(100% - 32px);
}

/* ====== End: Chat Box Header ========== */
/* ====== Begin: Chat Area ========== */
.chat-box {
  position: relative;
  height: 410px;
  overflow-y: auto;
}
.chat-box .chat-area {
  padding: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media only screen and (max-width: 1199.98px) {
  .chat-box .chat-area {
    height: 390px;
  }
}
.chat-box .chat-area .chat-msg-item {
  display: flex;
  margin-bottom: 1.25rem;
}
@media only screen and (max-width: 767.98px) {
  .chat-box .chat-area .chat-msg-item {
    margin-bottom: 3rem;
  }
}
.chat-box .chat-area .chat-msg-item:last-child {
  margin-bottom: 0;
}
.chat-box .chat-area .chat-msg-item .chat-msg {
  display: inline-block;
  max-width: calc(100% - 142px);
  padding: 1rem;
  background: var(--color-success-lgt);
  border-radius: 12px 12px 12px 0;
  position: relative;
}
@media only screen and (max-width: 767.98px) {
  .chat-box .chat-area .chat-msg-item .chat-msg {
    width: auto;
    max-width: unset;
    padding: 0.7rem;
    border-radius: 8px 8px 8px 0;
  }
}
.chat-box .chat-area .chat-msg-item .chat-msg p {
  font-weight: 400;
  color: #091f1f;
  padding-bottom: 0;
  word-break: break-word;
}
.chat-box .chat-area .chat-msg-item .chat-msg .msg-time {
  position: absolute;
  top: 0;
  right: -60px;
  font-size: 0.75rem;
  line-height: 1.25rem;
  max-width: 55px;
  white-space: nowrap;
}
@media only screen and (max-width: 767.98px) {
  .chat-box .chat-area .chat-msg-item .chat-msg .msg-time {
    top: unset;
    left: 0;
    right: auto;
    bottom: -36px;
    min-width: 50px;
    max-width: none;
  }
}
.chat-box .chat-area .chat-msg-item .chat-msg.no-padding {
  padding: 0;
  background-color: transparent;
}
.chat-box .chat-area .chat-msg-item .chat-msg.no-padding .msg-time {
  right: -165px;
}
.chat-box .chat-area .chat-msg-item.sent-msg {
  justify-content: flex-end;
}
@media only screen and (max-width: 767.98px) {
  .chat-box .chat-area .chat-msg-item.sent-msg {
    margin-bottom: 2.2rem;
  }
}
.chat-box .chat-area .chat-msg-item.sent-msg .chat-msg {
  background: #f3f6f6;
  border-radius: 12px 12px 0 12px;
}
@media only screen and (max-width: 767.98px) {
  .chat-box .chat-area .chat-msg-item.sent-msg .chat-msg {
    border-radius: 8px 8px 0 8px;
  }
}
.chat-box .chat-area .chat-msg-item.sent-msg .no-padding .msg-time {
  right: auto;
}
.chat-box .chat-area .chat-msg-item.sent-msg .msg-time {
  right: unset;
  left: -60px;
}
@media only screen and (max-width: 767.98px) {
  .chat-box .chat-area .chat-msg-item.sent-msg .msg-time {
    top: unset;
    left: auto;
    right: 0;
    bottom: -20px;
  }
}
.chat-box .chat-area .chat-msg-item.chat-date {
  justify-content: center;
  font-size: 0.8rem;
  margin: 0 0 20px 0;
  position: relative;
}
.chat-box .chat-area .chat-msg-item.chat-date::before {
  content: "";
  width: 100%;
  height: 0.5px;
  background: rgba(205, 215, 217, 0.4);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.chat-box .chat-area .chat-msg-item.chat-date .chat-date-span {
  border: solid 1px rgba(205, 215, 217, 0.5);
  border-radius: 8px;
  line-height: 1;
  padding: 4px 10px;
  position: relative;
  z-index: 1;
  background-color: var(--color-white);
}
.chat-box .chat-area .chat-msg-item .chat-image {
  max-width: 100px;
}
@media only screen and (max-width: 767.98px) {
  .chat-box .chat-area .chat-msg-item .chat-image {
    max-width: 60px;
  }
}
.chat-box .chat-area .chat-msg-item .chat-image img {
  border-radius: 4px;
}
.chat-box .chat-area .chat-msg-item .chat-image .svg-icon {
  display: flex;
  justify-content: center;
}
.chat-box .chat-area .chat-msg-item .chat-image .svg-icon a {
  color: #dc3545;
  font-size: 2rem;
}
.chat-box .chat-area .chat-msg-item .chat-image .svg-icon svg {
  width: 36px;
  height: 36px;
}
@media only screen and (max-width: 1199.98px) {
  .chat-box .chat-area .css-1y8mk30-SkeletonTheme .mb-2 {
    margin-bottom: 22px !important;
  }
}
@media only screen and (max-width: 575.98px) {
  .chat-box .chat-area .css-1y8mk30-SkeletonTheme .react-loading-skeleton {
    height: 36px !important;
    width: 180px !important;
  }
}
.chat-box .scrollbar-container {
  padding-right: 12px;
}
.chat-box .scrollbar-container .ps__rail-y {
  width: 6px;
}
.chat-box .scrollbar-container .ps__thumb-y {
  right: 0;
}
.chat-box .scrollbar-container .ps__rail-y:hover > .ps__thumb-y,
.chat-box .scrollbar-container .ps__rail-y:focus > .ps__thumb-y,
.chat-box .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 6px;
  right: 0;
}
.chat-box .no-conve {
  text-align: center;
  margin: 20px 0 0 0;
  position: absolute;
  width: 100%;
}
@media only screen and (max-width: 991.98px) {
  .chat-box .no-conve {
    position: relative;
  }
}

/* ====== End: Chat Area ========== */
/* ====== Begin: Chat Buttons ========== */
.chat-buttons {
  padding: 1.25rem;
}
@media only screen and (max-width: 767.98px) {
  .chat-buttons {
    padding: 1rem;
  }
}
.chat-buttons form,
.chat-buttons .chat-button-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.chat-buttons form .chat-input,
.chat-buttons .chat-button-inner .chat-input {
  width: calc(100% - 108px);
}
@media only screen and (max-width: 767.98px) {
  .chat-buttons form .chat-input,
  .chat-buttons .chat-button-inner .chat-input {
    width: calc(100% - 70px);
  }
}
.chat-buttons form .choose-file,
.chat-buttons .chat-button-inner .choose-file {
  position: absolute;
  right: 2px;
  top: 1px;
}
.chat-buttons form .choose-file .profile-input,
.chat-buttons .chat-button-inner .choose-file .profile-input {
  display: none !important;
}
.chat-buttons form .choose-file .add-file,
.chat-buttons .chat-button-inner .choose-file .add-file {
  margin: 0;
  cursor: pointer;
  height: 46px;
  width: 46px;
  text-align: center;
  padding: 9px;
  border-left: 1px solid #dbe2de;
  background-color: rgba(243, 246, 246, 0.75);
}
@media only screen and (max-width: 767.98px) {
  .chat-buttons form .choose-file .add-file,
  .chat-buttons .chat-button-inner .choose-file .add-file {
    height: 38px;
    width: 38px;
    font-size: 1.5rem;
    padding: 8px;
  }
}
.chat-buttons form .form-control::placeholder,
.chat-buttons .chat-button-inner .form-control::placeholder {
  opacity: 1;
}
@media only screen and (max-width: 767.98px) {
  .chat-buttons form .form-control::placeholder,
  .chat-buttons .chat-button-inner .form-control::placeholder {
    font-size: 0.75rem;
  }
}
.chat-buttons form .btn,
.chat-buttons .chat-button-inner .btn {
  width: 100px;
  height: 48px;
  border-radius: 4px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767.98px) {
  .chat-buttons form .btn,
  .chat-buttons .chat-button-inner .btn {
    width: 65px;
    height: 40px;
    font-size: 0.75rem;
    padding: 0 5px;
  }
}
.chat-buttons .text-danger {
  font-size: 0.9rem;
}

/* ====== End: Chat Buttons ========== */
/* ====== End: Chat Screen ========== */
/* ====== Begin: Policy Details page ========== */
.policy-detail .guardian-outer {
  padding-bottom: 1.5rem;
}
.policy-detail .guardian-outer .guardian-details {
  padding-left: 0;
  margin-right: auto;
}
@media only screen and (max-width: 413.98px) {
  .policy-detail .guardian-outer .btn-groups {
    margin-left: -8px !important;
  }
}
.policy-detail .policy-outer {
  padding: 1.5rem 0;
  border-top: 1px solid rgba(205, 215, 217, 0.24);
}
.policy-detail .policy-scheduled {
  padding-top: 1.5rem;
  border-top: 1px solid rgba(205, 215, 217, 0.24);
}
@media only screen and (max-width: 575.98px) {
  .policy-detail .policy-scheduled {
    text-align: center;
  }
}
.policy-detail .policy-scheduled h4 {
  color: #091f1f;
  font-weight: 500;
}

.thank-you {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5rem;
  margin: 0 0 1rem;
}
@media only screen and (max-width: 767.98px) {
  .thank-you {
    padding: 1rem 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .thank-you {
    justify-content: center;
  }
}
.thank-you h3 {
  font-weight: 300;
  color: #839c9f;
  margin-bottom: 0;
}
@media only screen and (max-width: 575.98px) {
  .thank-you h3 {
    margin-bottom: 1rem;
  }
}

/* ====== End: Policy Details page ========== */
/* ====== Begin: Document Sign page ========== */
.document-sign .document-wrapper .card-outer {
  max-width: 1000px;
  margin: 0 auto 2.5rem;
}
.document-sign .document-wrapper .card-outer img {
  width: 100%;
}

/* ====== End: Document Sign page ========== */
/* ======= Sidebar ========= */
.responsive-menu {
  display: none;
}
@media only screen and (max-width: 991.98px) {
  .responsive-menu {
    padding-right: 0 !important;
    display: block;
  }
}
.responsive-menu a {
  color: #839c9f;
}

.sidebar-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
  z-index: 1028;
}

@media only screen and (max-width: 991.98px) {
  .sidebar-part {
    position: fixed;
    left: -100%;
    top: 68px;
    z-index: 1029;
    padding: 15px;
    height: calc(100% - 68px);
    transition: 0.3s ease-in-out all;
    background: var(--color-white);
    min-width: 320px;
    overflow-y: auto;
    box-shadow: none;
  }
}
@media only screen and (max-width: 767.98px) {
  .sidebar-part {
    top: 60px;
    height: calc(100% - 60px);
  }
}

@media only screen and (max-width: 991.98px) {
  .sidebar-open .sidebar-part {
    left: 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .sidebar-open .sidebar-overlay {
    opacity: 1;
    pointer-events: inherit;
  }
}

.sidebar .sidebar-profile {
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  position: relative;
}
.sidebar .sidebar-profile .sidebar-profile-img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1rem;
  position: relative;
}
.sidebar .sidebar-profile .sidebar-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.sidebar .sidebar-profile .sidebar-profile-img.active {
  position: relative;
}
.sidebar .sidebar-profile .sidebar-profile-img.active:after {
  content: "";
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background: var(--color-primary);
  position: absolute;
  right: 2px;
  bottom: -1px;
  border: solid 2px var(--color-white);
}
.sidebar .sidebar-profile h3 {
  margin-bottom: 0;
  font-weight: 500;
}
.sidebar .sidebar-profile h5 {
  margin-bottom: 0;
}
.sidebar .sidebar-profile .back-btn {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  color: #839c9f;
}
.sidebar .sidebar-profile .back-btn .material-icons {
  font-size: 1rem;
  max-width: 20px;
}
.sidebar .sidebar-links-outer {
  padding: 12px;
}
.sidebar .sidebar-links-outer .sidebar-link {
  margin-bottom: 0.5rem;
}
.sidebar .sidebar-links-outer .sidebar-link:last-child {
  margin-bottom: 0;
}
.sidebar .sidebar-links-outer .sidebar-link .with-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #091f1f;
  width: 100%;
  margin: 0;
  font-weight: 500;
}
.sidebar .sidebar-links-outer .sidebar-link .with-arrow span {
  margin: 0;
  color: #839c9f !important;
}
.sidebar .sidebar-links-outer .sidebar-link a {
  padding: 12px;
  display: flex;
  align-items: center;
  color: #091f1f;
  font-weight: 500;
  border-radius: 4px;
}
.sidebar .sidebar-links-outer .sidebar-link a span {
  color: #839c9f;
  margin-right: 1rem;
}
.sidebar .sidebar-links-outer .sidebar-link a span.svg-icon {
  position: relative;
  top: -2px;
}
.sidebar .sidebar-links-outer .sidebar-link a span .fill {
  fill: #839c9f;
}
.sidebar .sidebar-links-outer .sidebar-link a:hover span, .sidebar .sidebar-links-outer .sidebar-link a.active span {
  color: var(--color-primary);
}
.sidebar .sidebar-links-outer .sidebar-link a:hover span .fill, .sidebar .sidebar-links-outer .sidebar-link a.active span .fill {
  fill: var(--color-primary);
}
.sidebar .sidebar-links-outer .sidebar-link .accordion {
  padding: 0;
  box-shadow: none;
}
.sidebar .sidebar-links-outer .sidebar-link .accordion .btn {
  padding: 0;
  width: 100%;
}
.sidebar .sidebar-links-outer .sidebar-link .accordion .btn a {
  padding-right: 0;
}
.sidebar .sidebar-links-outer .sidebar-link .accordion .btn a:hover, .sidebar .sidebar-links-outer .sidebar-link .accordion .btn a.active {
  background: transparent;
}
.sidebar .sidebar-links-outer .sidebar-link .accordion .btn a:hover span, .sidebar .sidebar-links-outer .sidebar-link .accordion .btn a.active span {
  color: #839c9f;
}
.sidebar .sidebar-links-outer .sidebar-link .accordion .btn a:hover span .fill, .sidebar .sidebar-links-outer .sidebar-link .accordion .btn a.active span .fill {
  fill: #839c9f;
}
.sidebar .sidebar-links-outer .sidebar-link .submenu {
  padding: 0.5rem 0 0 32px;
}
.sidebar .sidebar-links-outer .sidebar-link .submenu .sidebar-link a {
  padding: 3px 20px;
  font-size: 0.875rem;
  color: #839c9f;
}
.sidebar .sidebar-links-outer .sidebar-link .submenu .sidebar-link a.active, .sidebar .sidebar-links-outer .sidebar-link .submenu .sidebar-link a:hover {
  color: var(--color-primary);
}
.sidebar .sidebar-profile .email-id {
  font-size: 0.875rem;
}
.sidebar .sidebar-link a svg g path {
  fill: #839c9f;
}
.sidebar .sidebar-link a:hover svg g path {
  fill: var(--color-primary);
}
.sidebar .sidebar-link a.active svg g path {
  fill: var(--color-primary);
}

/* dashboard-header */
.box-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.box-title h3 {
  margin-bottom: 0;
  font-weight: 500;
}
.box-title .with-semititle h3 {
  margin-bottom: 4px;
}
.box-title .btn-link {
  padding: 0;
  min-width: auto;
  font-weight: 500;
}

.box-main-title {
  margin-bottom: 1rem;
}

.dashboard-header-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.dashboard-header-outer .dashboard-header {
  max-width: 600px;
}
@media only screen and (max-width: 1199.98px) {
  .dashboard-header-outer .dashboard-header {
    margin-bottom: 12px;
  }
}
.dashboard-header-outer .dashboard-header .font-24 {
  margin-bottom: 4px;
}
.dashboard-header-outer .dashboard-header h4 {
  margin-bottom: 0;
}
@media only screen and (max-width: 767.98px) {
  .dashboard-header-outer .dashboard-header h4 {
    margin-bottom: 0.5rem;
  }
}

.plan-outer {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.plan-outer:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.plan-outer .policy-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.plan-outer .policy-info .policy-company-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}
.plan-outer .policy-info .policy-company-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: inherit;
}
.plan-outer .policy-info .policy-details {
  width: calc(100% - 60px);
}
@media only screen and (max-width: 575.98px) {
  .plan-outer .policy-info .policy-details {
    width: 100%;
    margin-top: 0.5rem;
  }
}
.plan-outer .policy-info .policy-details h3 {
  font-weight: 500;
  margin-bottom: 0;
}
.plan-outer .policy-info .policy-details h4 {
  margin-bottom: 0;
}
@media only screen and (max-width: 575.98px) {
  .plan-outer .policy-info .policy-details h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.plan-outer .policy-info .policy-details .policy-name {
  display: flex;
  flex-wrap: wrap;
}
.plan-outer .policy-info .policy-details .policy-name h3 {
  margin-right: 1rem;
}
@media only screen and (max-width: 575.98px) {
  .plan-outer .policy-info .policy-details .policy-name h3 {
    margin-right: 0;
    margin-bottom: 0.5rem;
    width: 100%;
  }
}
.plan-outer.plan-outer2 {
  padding-bottom: 10px;
}

.plan-icons-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -8px;
}
.plan-icons-outer .plan-icons {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  margin: 4px;
  cursor: pointer;
}
.plan-icons-outer .plan-icons.active, .plan-icons-outer .plan-icons:hover, .plan-icons-outer .plan-icons:focus {
  background: #f3f6f6;
  color: #091f1f;
}
.plan-icons-outer .plan-icons.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: var(--color-white);
  color: #839c9f;
}
.plan-icons-outer a {
  color: #839c9f;
}

.policy-right-btn {
  display: inline-block;
  min-width: 81px;
  vertical-align: top;
}

/* profile-page */
.profile-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 575.98px) {
  .profile-details {
    display: block;
  }
}
.profile-details .profile-pic {
  padding: 4px;
  background: var(--color-white);
  width: 92px;
  height: 92px;
  border-radius: 50%;
  border: 2px dashed #cdd7d9;
  margin-right: 1rem;
  position: relative;
  display: inline-block;
}
.profile-details .profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile-details .profile-pic .profile-cam {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 26px;
  height: 26px;
  background-color: var(--color-primary);
  border-radius: 100%;
  display: inline-block;
  z-index: 1;
  color: var(--color-white);
  font-size: 15px;
  text-align: center;
  padding: 6px;
  cursor: pointer;
}
.profile-details .profile-pic .profile-input {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.profile-details .profile-pic input[type=file],
.profile-details .profile-pic input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}
.profile-details .btn-link {
  font-weight: 500;
  min-width: 0;
}
@media only screen and (max-width: 575.98px) {
  .profile-details .btn-link {
    min-width: inherit;
    padding-left: 6px;
    text-align: left;
  }
}

.my-profile-page .form-group {
  margin-bottom: 1.5rem;
}
.my-profile-page .default-select .custom-select__menu-list {
  max-height: 180px;
}

.ReactCrop .ReactCrop__crop-selection {
  box-shadow: none;
}

.crop-image {
  text-align: center;
}
.crop-image .crp-img {
  border-radius: 100%;
}
.crop-image .crop-lable {
  display: block;
  color: #091f1f;
  margin-bottom: 1.1rem;
  font-size: 1.1rem;
}
@media only screen and (max-width: 575.98px) {
  .crop-image .row > div + div {
    margin-top: 1.3rem;
  }
}

/* ====== notification ======== */
.notification-outer {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 575.98px) {
  .notification-outer .notification-msg {
    width: 100%;
  }
}
.notification-outer:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.notification-outer .notification-title {
  font-weight: 500;
  color: #091f1f;
}
.notification-outer h4 {
  margin-bottom: 0;
}
.notification-outer .notification-msg {
  width: calc(100% - 150px);
}
@media only screen and (max-width: 767.98px) {
  .notification-outer .notification-msg {
    width: 100%;
    margin-bottom: 5px;
  }
}

/* ========== ConversationExpert ============= */
.converexp {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.converexp .conexp-left {
  width: calc(100% - 300px);
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
  background: var(--color-white);
}
@media only screen and (max-width: 1199.98px) {
  .converexp .conexp-left {
    width: calc(100% - 220px);
  }
}
@media only screen and (max-width: 991.98px) {
  .converexp .conexp-left {
    width: 100%;
  }
}
.converexp .convertop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 4px 0 rgba(131, 156, 159, 0.08);
  margin-bottom: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 50px;
  padding: 5px 20px;
  margin-left: -5px;
  margin-right: -5px;
}
.converexp .convertop h3 {
  margin: 0;
}
.converexp .convertop .righticon a {
  color: #839c9f;
}
.converexp .convertop .righticon i {
  font-size: 1.4rem;
}
.converexp .card-outer {
  box-shadow: none;
}
.converexp .card-outer .chat-area {
  padding-top: 1rem;
}
@media only screen and (max-width: 991.98px) {
  .converexp .card-outer .chat-area {
    height: auto;
    max-height: 500px;
  }
}
.converexp .card-outer .chat-area .scrollbar-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
  max-height: 100%;
}
.converexp .card-outer .chat-area .scrollbar-container .ps__rail-x {
  display: none;
}
.converexp .card-outer .chat-area .chat-msg-item {
  width: 100%;
}
.converexp .card-outer .chat-area .chat-msg-item .chat-msg {
  max-width: calc(100% - 80px);
}
@media only screen and (max-width: 767.98px) {
  .converexp .card-outer .chat-area .chat-msg-item .chat-msg {
    max-width: 100%;
  }
}
.converexp .chat-buttons {
  background: #f3f6f6;
}
.converexp .chat-buttons .form-control {
  background-color: var(--color-white);
  padding: 12px 58px 12px 18px;
  height: 48px;
  resize: none;
}
@media only screen and (max-width: 767.98px) {
  .converexp .chat-buttons .form-control {
    height: 40px;
    padding: 9px 46px 9px 10px;
    font-size: 0.9rem;
  }
}
.converexp .conexp-links {
  width: 270px;
}
@media only screen and (max-width: 1199.98px) {
  .converexp .conexp-links {
    width: 220px;
  }
}
@media only screen and (max-width: 991.98px) {
  .converexp .conexp-links {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.converexp .conexp-links .link-box {
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
  margin-bottom: 1.5rem;
}
.converexp .conexp-links .documents .document-content {
  padding-left: 8px;
}
.converexp .conexp-links .pdf-info {
  width: 20px;
  height: 20px;
  position: relative;
  top: -4px;
}
.converexp .conexp-links .pdf-info .material-icons {
  color: #dc3545;
  font-size: 22px;
}
.converexp .conexp-links .pdf-info .svg-icon svg {
  width: 20px;
  height: 20px;
}
.converexp .conexp-links h3 {
  border-bottom: solid 1px rgba(205, 215, 217, 0.24);
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.converexp .conexp-links ul li + li {
  margin-top: 0.5rem;
}
.converexp .conexp-links ul li a {
  color: #839c9f;
}
.converexp .conexp-links ul li a:hover {
  color: var(--color-primary);
}
.converexp .btn {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  display: table;
}
.converexp .chat-component-header .card-outer .policy-outer {
  padding: 1.25rem 1.25rem 0.5rem 1.25rem;
}
.converexp .guardian-outer .guardian-details {
  margin-bottom: 10px;
}

/* ========== Lead conversations ============= */
.conversation-page.lead-conver .conversation-list {
  width: 100%;
}
.conversation-page.lead-conver .conversation-list-outer {
  padding: 10px;
}
.conversation-page.lead-conver .conversation-person-details {
  padding-right: 130px;
}
@media only screen and (max-width: 767.98px) {
  .conversation-page.lead-conver .conversation-person-details {
    padding-right: 0;
  }
}
.conversation-page.lead-conver .conversation-person {
  cursor: pointer;
  position: relative;
}
.conversation-page.lead-conver .conversation-person .conversation-person-details .msg-counter {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  bottom: 10px;
  right: 10px;
}
.conversation-page.lead-conver .conversation-person:hover {
  background-color: #f3f6f6;
}
.conversation-page.lead-conver .conversation-person.unread h5 {
  color: #091f1f;
}
.conversation-page.lead-conver .conversation-person .conver-time {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 0.75rem;
  line-height: 1;
  padding-right: 30px;
  transform: translateY(-50%);
}
.conversation-page .plan-outer.plan-full {
  justify-content: flex-start;
  padding: 16px 18px;
}
.conversation-page .plan-outer.plan-full h3 {
  margin-right: 0.5rem;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (max-width: 767.98px) {
  .conversation-page .plan-outer.plan-full h3 {
    margin-bottom: 0.3rem;
  }
}

.conversation-popup {
  position: fixed;
  right: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: all 0.5s ease-out;
  max-width: 1200px;
  padding: 40px 20px;
  background: #fbfcfc;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
  overflow: auto;
}
.conversation-popup.small-size {
  width: auto;
  max-width: 550px;
}
@media only screen and (max-width: 575.98px) {
  .conversation-popup.small-size {
    width: 100%;
  }
}
.conversation-popup.small-size .converexp,
.conversation-popup.small-size .chat-box-wrapper {
  height: 100%;
}
.conversation-popup.small-size .converexp .conexp-left,
.conversation-popup.small-size .chat-box-wrapper .conexp-left {
  width: 100%;
  height: 100%;
}
.conversation-popup.small-size .card-outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media only screen and (min-width: 992px) {
  .conversation-popup.small-size .card-outer {
    min-width: 450px;
  }
}
.conversation-popup.small-size .chat-box {
  margin-top: auto;
  height: calc(100% - 153px);
  overflow: auto;
}
@media only screen and (max-width: 991.98px) {
  .conversation-popup {
    max-width: 96%;
  }
}
.conversation-popup.active {
  opacity: 1;
  right: 0;
}
.conversation-popup .close-btn {
  position: absolute;
  left: 20px;
  top: 3px;
  color: #839c9f;
}
.conversation-popup .close-btn:hover {
  opacity: 0.8;
}
.conversation-popup .chat-policy {
  display: flex;
  align-items: center;
  border-top: solid 1px rgba(205, 215, 217, 0.24);
  padding: 10px 0 12px 0;
  margin-top: 10px;
  justify-content: center;
}
.conversation-popup .chat-policy .page-title-logo {
  width: 26px;
}
.conversation-popup .chat-policy > span {
  max-width: calc(100% - 32px);
  padding-left: 5px;
  color: #091f1f;
  font-size: 1.2rem;
  line-height: 1;
}
.conversation-popup .card-value-list {
  font-size: 14px;
  padding: 0;
}
.conversation-popup .card-value-list .value-list-item {
  margin: 0 0 0.5rem;
}
.conversation-popup .card-value-list .list-info {
  margin-left: 0.3rem !important;
}
.conversation-popup .card-value-list .list-info svg {
  width: 14px;
  height: 14px;
}
.conversation-popup .card-value-list h3 {
  font-size: 1rem;
}

.conv-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
  z-index: 1030;
}
.conv-overlay.active {
  opacity: 1;
  pointer-events: inherit;
}

/* ====== setting ======== */
.setting-outer {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.setting-outer .setting-msg {
  max-width: 80%;
}
@media only screen and (max-width: 575.98px) {
  .setting-outer .setting-msg {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
.setting-outer .setting-title {
  font-weight: 500;
  color: #091f1f;
}
.setting-outer h4 {
  margin-bottom: 0;
}
.setting-outer h5 {
  margin-bottom: 0;
}
.setting-outer .custom-checkbox input[type=checkbox]:checked ~ span ~ .emailcheck {
  color: #091f1f;
  background: transparent !important;
}
.setting-outer .custom-checkbox input[type=checkbox]:disabled {
  opacity: 1;
}
.setting-outer .custom-checkbox input[type=checkbox]:disabled ~ span {
  background: #839c9f;
  border-color: #839c9f;
  opacity: 1;
}
.setting-outer .setting-checkbox .custom-checkbox + .custom-checkbox {
  margin-left: 20px;
}
@media only screen and (max-width: 575.98px) {
  .setting-outer .btn-groups {
    text-align: center;
  }
}

.change-password-outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.change-password-outer h3 {
  font-weight: 500;
  margin-bottom: 0;
}
.change-password-outer h5 {
  margin-bottom: 0;
}

/* ====== conversation-page ======== */
.conversation-page {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
}
.conversation-page .conversation-list {
  width: 270px;
}
.conversation-page .conversation-list .conversation-list-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
  height: 60px;
}
.conversation-page .conversation-list .conversation-list-header h3 {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--color-txt-df);
}
.conversation-page .conversation-msg {
  width: calc(100% - 270px);
}
.conversation-page .chat-area {
  padding: 1rem;
}
.conversation-page .chat-buttons {
  background: #f3f6f6;
  padding: 1rem;
}
.conversation-page .plan-outer {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.conversation-page .plan-outer:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.conversation-page .plan-outer .policy-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.conversation-page .plan-outer .policy-info .policy-company-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.conversation-page .plan-outer .policy-info .policy-company-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.conversation-page .plan-outer .policy-info .policy-details {
  width: calc(100% - 32px);
}
.conversation-page .plan-outer .policy-info .policy-details h3 {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 1rem;
}
.conversation-page .plan-outer .policy-info .policy-details h4 {
  margin-bottom: 0;
  font-size: 0.875rem;
}
.conversation-page .plan-outer .policy-info .policy-details .policy-name {
  display: flex;
  flex-wrap: wrap;
}
.conversation-page .plan-outer .policy-info .policy-details .policy-name h3 {
  margin-right: 1rem;
}
.conversation-page .conversation-policy-part {
  padding: 12px;
}
.conversation-page .conversation-policy-part .plan-outer {
  padding: 12px;
  box-shadow: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
}
.conversation-page .conversation-policy-part .plan-outer .btn {
  min-width: auto;
}

.conversation-list-outer {
  padding: 8px;
}
.conversation-list-outer .conversation-person {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 575.98px) {
  .conversation-list-outer .conversation-person {
    align-items: flex-start;
  }
}
.conversation-list-outer .conversation-person:last-child {
  margin-bottom: 0;
}
.conversation-list-outer .conversation-person.active {
  background: #f3f6f6;
}
.conversation-list-outer .conversation-person .conversation-person-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
}
@media only screen and (max-width: 767.98px) {
  .conversation-list-outer .conversation-person .conversation-person-img {
    width: 32px;
    height: 32px;
  }
  .conversation-list-outer .conversation-person .conversation-person-img .react-loading-skeleton {
    width: 32px !important;
    height: 32px !important;
  }
}
.conversation-list-outer .conversation-person .conversation-person-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.conversation-list-outer .conversation-person .conversation-person-details {
  width: calc(100% - 56px);
}
@media only screen and (max-width: 767.98px) {
  .conversation-list-outer .conversation-person .conversation-person-details {
    width: calc(100% - 40px);
  }
}
.conversation-list-outer .conversation-person .conversation-person-details h4 {
  color: #091f1f;
  font-weight: 500;
  display: inline-block;
  width: 220px;
  word-break: break-word;
  padding-right: 5px;
  vertical-align: middle;
}
@media only screen and (max-width: 1199.98px) {
  .conversation-list-outer .conversation-person .conversation-person-details h4 {
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  .conversation-list-outer .conversation-person .conversation-person-details h4 {
    margin-right: 0;
    padding-right: 110px;
    width: 100%;
  }
}
.conversation-list-outer .conversation-person .conversation-person-details h4 .msg-text {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  line-height: 1.3rem;
  display: block;
  font-weight: 400;
}
.conversation-list-outer .conversation-person .conversation-person-details h5 {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 767.98px) {
  .conversation-list-outer .conversation-person .conversation-person-details h5 {
    padding-right: 26px;
  }
}
.conversation-list-outer .conversation-person .conversation-person-details .policy-details {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  vertical-align: middle;
}
@media only screen and (max-width: 767.98px) {
  .conversation-list-outer .conversation-person .conversation-person-details .policy-details {
    margin-top: 2px;
  }
}
.conversation-list-outer .conversation-person .conversation-person-details .policy-details h3 {
  font-size: 1rem;
  margin: 0 0.5rem 0;
  width: 190px;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .conversation-list-outer .conversation-person .conversation-person-details .policy-details h3 {
    width: 190px;
  }
}
@media only screen and (max-width: 767.98px) {
  .conversation-list-outer .conversation-person .conversation-person-details .policy-details h3 {
    width: 190px;
    text-align: left;
    margin: 0 0.4rem 0 0;
  }
}
.conversation-list-outer .conversation-person .conversation-person-details .msg-counter {
  padding: 3px 4px;
  background: var(--color-primary);
  border-radius: 50%;
  font-size: 12px;
  color: var(--color-white);
  font-weight: 500;
  position: absolute;
  top: 50%;
  right: 10px;
  bottom: 10px;
  transform: translateY(-50%);
  line-height: 14px;
  width: 20px;
  height: 20px;
  text-align: center;
}
.conversation-list-outer .conversation-person .conversation-person-details .chips-outer {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (max-width: 767.98px) {
  .conversation-list-outer .conversation-person .conversation-person-details .chips-outer {
    display: block;
  }
  .conversation-list-outer .conversation-person .conversation-person-details .chips-outer .chips-label {
    font-size: 12px;
    line-height: 1.3rem;
    padding: 1px 5px;
  }
}

/* Help page */
.phone-number-outer {
  border: 1px solid rgba(205, 215, 217, 0.24);
  padding: 1rem 1.5rem;
  display: inline-block;
}
@media only screen and (max-width: 575.98px) {
  .phone-number-outer {
    width: 100%;
    margin-top: 0.5rem;
  }
}
.phone-number-outer .material-icons {
  color: var(--color-primary);
}
.phone-number-outer a {
  color: #091f1f;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.help-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.help-box .help-title h3 {
  margin-bottom: 8px;
  font-weight: 500;
}
@media only screen and (max-width: 575.98px) {
  .help-box .help-title h3 {
    margin-bottom: 0;
  }
}
.help-box .help-title h4 {
  margin-bottom: 0;
}

/*==================== Begin: My Payouts page ====================*/
.with-semititle .semititle-primary {
  color: var(--color-primary);
}

.edit:hover {
  color: var(--color-primary) !important;
}

.added-accounts .accounts-list {
  padding: 0.5rem 0;
}
.added-accounts .accounts-list .account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.75rem 0;
}
.added-accounts .accounts-list .account:last-child {
  margin-bottom: 0;
}
.added-accounts .accounts-list .account .account-details {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 150px);
}
.added-accounts .accounts-list .account .account-details .account-icon {
  margin-right: 10px;
}
.added-accounts .accounts-list .account .account-details .account-text {
  padding-bottom: 0;
  margin-right: 8px;
  max-width: calc(100% - 120px);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #091f1f;
}
.added-accounts .accounts-list .account .account-options .btn {
  margin-right: 1.125rem;
  padding: 0 10px;
}
.added-accounts .accounts-list .account .account-options .edit {
  color: #839c9f;
}

/*==================== End: My Payouts page ====================*/
/* ======= Custom accordion ========= */
.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: flex-start;
}
.accordion-header .accordion-header-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.accordion-header .accordion-header-title span {
  margin-right: 16px;
  color: #091f1f;
  font-weight: 500;
  width: 24px;
}
.accordion-header .accordion-header-title h4 {
  margin-bottom: 0;
  color: #091f1f;
  font-weight: 500;
  width: calc(100% - 40px);
}
.accordion-header .btn-link {
  padding: 0;
}

.accordion-body {
  padding-bottom: 1rem;
  padding-left: 40px;
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}

/* ======= Policy information ======== */
.filled-value {
  margin-bottom: 1rem;
}
.filled-value label {
  margin-bottom: 0;
  font-size: 12px;
}
.filled-value h4 {
  margin-bottom: 0;
  color: #091f1f;
}

/* Data table */
.status-open {
  padding: 0 12px;
  background: #cdd7d9;
  color: #091f1f;
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.status-success {
  padding: 0 12px;
  background: var(--color-success-lgt);
  color: var(--color-primary);
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.status-lost {
  padding: 0 12px;
  background: rgba(255, 0, 0, 0.33);
  color: #091f1f;
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.status-renew {
  padding: 0 12px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

/* ======= qualification-status ========= */
.qualification-status .title-header h4 {
  min-height: 48px;
}
.qualification-status .guardian-outer .guardian-details {
  width: 100%;
  padding-bottom: 1rem;
  padding-right: 0;
  justify-content: center;
}
.qualification-status .guardian-outer .guardian-details .guardian-name {
  display: flex;
  align-items: center;
  width: auto;
}
.qualification-status .guardian-outer .guardian-details .guardian-name h2 {
  margin-right: 1rem;
}
.qualification-status .guardian-outer .guardian-info {
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid rgba(205, 215, 217, 0.24);
}
.qualification-status .guardian-outer .guardian-info ul {
  justify-content: center;
}
.qualification-status .policy-outer {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-top: 1px solid rgba(205, 215, 217, 0.24);
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.qualification-status .policy-outer .policy-name {
  justify-content: center;
}
.qualification-status .policy-outer .policy-name h3 {
  margin-right: 0;
}
.qualification-status .policy-outer .chips-outer {
  margin: 0 -4px;
}

/* Owl carousol */
.owl-carousel {
  padding-right: 19px;
  padding-left: 40px;
}
@media only screen and (max-width: 991.98px) {
  .owl-carousel {
    padding-right: 30px;
  }
}
.owl-carousel .boxmodal-radio,
.owl-carousel .boxmodal-checkbox {
  margin-bottom: 8px;
  height: calc(100% - 8px);
}
@media only screen and (max-width: 767.98px) {
  .owl-carousel .boxmodal-radio,
  .owl-carousel .boxmodal-checkbox {
    margin-bottom: 20px;
    height: calc(100% - 20px);
  }
}
.owl-carousel .owl-dots {
  display: none;
}
.owl-carousel .card-inner {
  margin: 0 15px;
}
.owl-carousel .owl-nav {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  z-index: -1;
}
@media only screen and (max-width: 991.98px) {
  .owl-carousel .owl-nav {
    width: initial;
    left: 10px;
    right: 10px;
  }
}
.owl-carousel .owl-nav .owl-prev {
  font-size: 0;
  width: 17px;
  height: 20px;
  display: inline-block;
  background: url(/static/media/back-icon.cdf45a16.svg);
}
.owl-carousel .owl-nav .owl-prev.disabled {
  opacity: 0.4;
}
.owl-carousel .owl-nav .owl-prev.disabled:hover {
  background: url(/static/media/back-icon.cdf45a16.svg);
  color: #091f1f;
}
.owl-carousel .owl-nav .owl-prev:hover {
  background: url(/static/media/back-icon.cdf45a16.svg);
  color: #091f1f;
}
.owl-carousel .owl-nav .owl-next {
  font-size: 0;
  width: 17px;
  height: 20px;
  display: inline-block;
  background: url(/static/media/next-icon.56be04c5.svg);
}
.owl-carousel .owl-nav .owl-next.disabled {
  opacity: 0.4;
}
.owl-carousel .owl-nav .owl-next.disabled:hover {
  background: url(/static/media/next-icon.56be04c5.svg);
  color: #091f1f;
}
.owl-carousel .owl-nav .owl-next:hover {
  background: url(/static/media/next-icon.56be04c5.svg);
  color: #091f1f;
}

.table {
  width: 100%;
}
.table th,
.table td {
  border-top: 0;
}
.table thead th {
  border-bottom: 0;
}
.table tr {
  border: 1px solid rgba(205, 215, 217, 0.24);
  border-bottom: 0;
}
.table tr:last-child {
  border-bottom: 1px solid rgba(205, 215, 217, 0.24);
}
.table .carrier-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.table .carrier-logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-right: 8px;
}
.table .carrier-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.motivation-outer h4 {
  font-size: 1rem;
  line-height: 1.75rem;
  color: #839c9f;
}

/* How it work */
.work-outer {
  margin: 50px 0 150px 0;
  position: relative;
}
@media only screen and (max-width: 991.98px) {
  .work-outer {
    margin: 30px 0 100px 0;
  }
}
@media only screen and (max-width: 767.98px) {
  .work-outer {
    margin: 20px 0 50px 0;
  }
}
.work-outer .work-inner {
  max-width: 770px;
  width: 770px;
}
@media only screen and (max-width: 767.98px) {
  .work-outer .work-inner {
    width: 100%;
  }
}

.work-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.work-box:first-child .work-info:after {
  height: 50%;
  border-top: transparent;
  bottom: -3px;
}
.work-box:first-child .work-info:before {
  height: 50%;
  border-top: transparent;
  bottom: -3px;
}
.work-box.right-part:last-child .work-info:before {
  display: none;
}
.work-box.right-part:last-child .work-info:after {
  height: 50%;
  border-bottom: transparent;
  top: 0;
}
.work-box .work-arrow2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMBAMAAACtsOGuAAAAG1BMVEUAAAAzs00xqk8uqUstqkorqUsrqUosqUsrqEqfECZhAAAACHRSTlMACipfYKDV9dwl7g8AAAAoSURBVAjXYwhkAII2BSDRUQIiOgJBBFCgowMoACSKYVyQRDFMMUgbALFCDiun70SKAAAAAElFTkSuQmCC) no-repeat center center;
  height: 12px;
  width: 8px;
  position: absolute;
  left: 440px;
  bottom: -8px;
}
@media only screen and (max-width: 991.98px) {
  .work-box .work-arrow2 {
    display: none;
  }
}
.work-box .work-arrow3 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMBAMAAACtsOGuAAAAG1BMVEUAAAAzs00xqk8uqUstqkorqUsrqUosqUsrqEqfECZhAAAACHRSTlMACipfYKDV9dwl7g8AAAAoSURBVAjXYwhkAII2BSDRUQIiOgJBBFCgowMoACSKYVyQRDFMMUgbALFCDiun70SKAAAAAElFTkSuQmCC) no-repeat center center;
  height: 12px;
  width: 8px;
  position: absolute;
  right: 440px;
  bottom: -8px;
  transform: rotate(-180deg);
}
@media only screen and (max-width: 991.98px) {
  .work-box .work-arrow3 {
    display: none;
  }
}
.work-box .work-arrow4 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMBAMAAACtsOGuAAAAG1BMVEUAAAAzs00xqk8uqUstqkorqUsrqUosqUsrqEqfECZhAAAACHRSTlMACipfYKDV9dwl7g8AAAAoSURBVAjXYwhkAII2BSDRUQIiOgJBBFCgowMoACSKYVyQRDFMMUgbALFCDiun70SKAAAAAElFTkSuQmCC) no-repeat center center;
  height: 12px;
  width: 8px;
  position: absolute;
  left: 440px;
  bottom: -6px;
}
@media only screen and (max-width: 991.98px) {
  .work-box .work-arrow4 {
    display: none;
  }
}
.work-box .work-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #ebf5ee;
}
@media only screen and (max-width: 767.98px) {
  .work-box .work-icon {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (max-width: 767.98px) {
  .work-box .work-icon svg {
    width: 32px;
    height: auto;
  }
}
.work-box .work-info {
  width: calc(100% - 205px);
  margin-left: 65px;
  padding: 45px 0;
  padding-left: 85px;
  position: relative;
}
@media only screen and (max-width: 767.98px) {
  .work-box .work-info {
    padding: 30px 0;
    margin-left: 15px;
    padding-left: 30px;
    width: calc(100% - 110px);
  }
}
.work-box .work-info:before {
  content: "";
  width: calc(100% - 275px);
  height: 35px;
  border-bottom: dashed 1px var(--color-primary);
  border-left: 1px dashed transparent;
  border-right: 1px dashed transparent;
  border-radius: 0 20px 0 0;
  position: absolute;
  pointer-events: none;
  left: 35px;
  bottom: -1px;
  z-index: -1;
}
@media only screen and (max-width: 767.98px) {
  .work-box .work-info:before {
    width: calc(100% - 180px);
  }
}
.work-box .work-info:after {
  content: "";
  width: 35px;
  height: 100%;
  border-top: 1px dashed var(--color-primary);
  border-bottom: 1px dashed var(--color-primary);
  position: absolute;
  pointer-events: none;
  border-radius: 20px 0px 0 20px;
  border-left: 1px dashed var(--color-primary);
  left: 0;
  bottom: -1px;
  z-index: -1;
}
.work-box .work-info p {
  padding-bottom: 0;
}
.work-box .work-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--color-primary);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}
@media only screen and (max-width: 767.98px) {
  .work-box .work-counter {
    width: 30px;
    height: 30px;
    left: -15px;
  }
}
.work-box .work-counter span {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--color-white);
}
.work-box.right-part .work-info {
  width: calc(100% - 205px);
  margin-right: 65px;
  padding: 45px 0;
  padding-right: 85px;
  padding-left: 0;
  margin-left: 0;
  position: relative;
  text-align: right;
}
@media only screen and (max-width: 767.98px) {
  .work-box.right-part .work-info {
    padding: 30px 0;
    margin-right: 15px;
    padding-right: 30px;
    width: calc(100% - 110px);
  }
}
.work-box.right-part .work-info:before {
  content: "";
  width: calc(100% - 275px);
  height: 35px;
  border-top: dashed 1px transparent;
  border-left: 1px dashed transparent;
  border-right: 1px dashed transparent;
  border-radius: 0 0 0 0;
  position: absolute;
  pointer-events: none;
  left: inherit;
  right: 35px;
  bottom: -2px;
  z-index: -1;
}
@media only screen and (max-width: 767.98px) {
  .work-box.right-part .work-info:before {
    width: calc(100% - 180px);
  }
}
.work-box.right-part .work-info:after {
  content: "";
  width: 35px;
  height: 100%;
  border-top: 1px dashed var(--color-primary);
  border-bottom: 1px dashed var(--color-primary);
  position: absolute;
  pointer-events: none;
  border-radius: 0 20px 20px 0;
  border-left: 1px dashed transparent;
  border-right: 1px dashed var(--color-primary);
  left: inherit;
  right: 0;
  bottom: -2px;
  z-index: -1;
}
.work-box.right-part .work-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--color-primary);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  left: inherit;
}
@media only screen and (max-width: 767.98px) {
  .work-box.right-part .work-counter {
    width: 30px;
    height: 30px;
    right: -15px;
  }
}
.work-box.right-part .work-counter span {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--color-white);
}

/* addmore-outer */
.addmore-outer {
  position: relative;
}
.addmore-outer .delete-btn {
  min-width: auto;
  position: absolute;
  right: -20px;
  top: 12px;
}

.dropdown-withlogo {
  display: flex;
  align-items: center;
}
.dropdown-withlogo .dropdown-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--color-white);
  overflow: hidden;
  margin-right: 10px;
}
.dropdown-withlogo .dropdown-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: inherit;
}

.no-chart {
  text-align: center;
  min-height: 328px;
  border: solid 1px #cdd7d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.documents .document-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.documents .document-row + .document-row {
  margin-top: 5px;
}
.documents .document-icon {
  width: 30px;
}
.documents .document-icon.material-icons {
  font-size: 20px;
}
.documents .document-icon.material-icons.pdf-icon {
  color: #dc3545;
}
.documents .document-icon.material-icons.drive-icon {
  color: #1aade2;
}
.documents .document-icon.svg-icon svg {
  width: 22px;
  height: 22px;
}
.documents .document-content {
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.documents .document-content .document-name {
  width: calc(100% - 40px);
}
.documents .document-content .view-link {
  width: 40px;
  font-size: 14px;
  text-align: right;
}
.documents .document-content .view-link a,
.documents .document-content .view-link span {
  color: var(--color-primary);
  cursor: pointer;
}
.documents.documents-card .document-row {
  background-color: var(--color-white);
  border: solid 1px rgba(205, 215, 217, 0.24);
  padding: 20px 15px;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
@media only screen and (max-width: 767.98px) {
  .documents.documents-card .document-row {
    margin-bottom: 20px;
  }
}
.documents.documents-card .document-row.with-border {
  box-shadow: none;
  border: solid 1px rgba(205, 215, 217, 0.24);
}
.documents.documents-card .document-icon {
  width: 100%;
}
.documents.documents-card .document-icon.material-icons {
  font-size: 60px;
}
.documents.documents-card .document-icon.svg-icon svg {
  width: 60px;
  height: 60px;
}
.documents.documents-card .document-content {
  width: 100%;
}
.documents.documents-card .document-content .document-name {
  width: 100%;
  margin-top: 10px;
}

.under-process .plan-outer.app-center .application-id {
  position: relative;
  top: 22px;
}
@media only screen and (max-width: 767.98px) {
  .under-process .plan-outer.app-center .application-id {
    top: 0;
  }
}
.under-process .plan-outer.app-center .policy-right-btn {
  position: relative;
  top: 20px;
}
@media only screen and (max-width: 767.98px) {
  .under-process .plan-outer.app-center .policy-right-btn {
    top: 0;
  }
}
.under-process .plan-outer .application-id {
  width: 178px;
  word-break: break-all;
  padding-right: 5px;
}
@media only screen and (max-width: 1199.98px) {
  .under-process .plan-outer .application-id {
    width: 170px;
  }
}
@media only screen and (max-width: 767.98px) {
  .under-process .plan-outer .application-id {
    width: 100%;
  }
}
.under-process .plan-outer .policy-details {
  width: calc(100% - 178px);
}
@media only screen and (max-width: 1199.98px) {
  .under-process .plan-outer .policy-details {
    width: calc(100% - 170px);
  }
}
@media only screen and (max-width: 767.98px) {
  .under-process .plan-outer .policy-details {
    width: 100%;
  }
}
.under-process .plan-outer .policy-details .policy-name h3 {
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 198px;
  text-align: left;
}
@media only screen and (max-width: 1199.98px) {
  .under-process .plan-outer .policy-details .policy-name h3 {
    min-width: 112px;
    margin-bottom: 3px;
  }
}
@media only screen and (max-width: 991.98px) {
  .under-process .plan-outer .policy-details .policy-name h3 {
    min-width: 128px;
  }
}
@media only screen and (max-width: 767.98px) {
  .under-process .plan-outer .policy-details .policy-name h3 {
    min-width: 0;
    text-align: left;
  }
}
.under-process .plan-outer .policy-info {
  width: calc(100% - 240px);
  align-items: flex-start;
}
@media only screen and (max-width: 767.98px) {
  .under-process .plan-outer .policy-info {
    width: 100%;
  }
}
.under-process .plan-outer .same-policy {
  padding-top: 10px;
}
@media only screen and (max-width: 767.98px) {
  .under-process .plan-outer {
    width: 100%;
  }
}
.under-process .plan-outer .policy-name h3 {
  margin-right: 0.5rem;
}
.under-process .policy-info {
  width: calc(100% - 170px);
  align-items: flex-start;
}
@media only screen and (max-width: 767.98px) {
  .under-process .policy-info {
    width: 100%;
  }
}
.under-process .policy-right {
  max-width: 240px;
  text-align: right;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767.98px) {
  .under-process .policy-right {
    max-width: 100%;
    margin-top: 12px;
  }
}
.under-process .policy-right .btn {
  min-width: 80px;
  font-size: 14px;
  padding: 7px 13px;
}
.under-process .policy-right .btn.btn-outline-with-icon {
  min-width: 0;
  font-size: 0.75rem;
  padding: 4px 10px;
  margin-right: 5px;
}
@media only screen and (max-width: 767.98px) {
  .under-process .policy-right .btn.btn-outline-with-icon {
    font-size: 1rem;
    padding: 7px 13px;
  }
  .under-process .policy-right .btn.btn-outline-with-icon .material-icons {
    font-size: 1.2rem;
  }
}
.under-process .plan-icons-outer {
  display: inline-flex;
  margin: 0 4px 0 0;
}
.under-process .plan-icons-outer .plan-icons {
  margin: 1px;
  width: 36px;
  height: 36px;
}
.under-process .plan-icons-outer .plan-icons .plan-chat {
  font-size: 22px;
}
.under-process.same-policy {
  margin-top: -1px;
  background: #fff;
  padding-top: 0;
  z-index: 1;
  position: relative;
}

.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 2px;
  border-color: #cdd7d9;
  border-style: dashed;
  color: #cdd7d9;
  outline: none;
  transition: border 0.24s ease-in-out;
  background-color: rgba(205, 215, 217, 0.05);
  justify-content: center;
  text-align: center;
}
.dropzone .material-icons {
  font-size: 2.5rem;
}
.dropzone.dropzone-small {
  padding: 2px 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 36px;
  position: relative;
}
.dropzone.dropzone-small h5 {
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone.dropzone-small .material-icons {
  font-size: 1.5rem;
}
.dropzone h5 {
  font-size: 1rem;
  color: #839c9f;
  font-weight: 400;
}

.dropzone:focus {
  border-color: var(--color-primary);
}

.dropzone.disabled {
  opacity: 0.6;
}

.uploaded-file {
  margin-top: 0.5rem;
}
.uploaded-file.uploaded {
  margin: 0 0 1.3rem 0;
}

.file-name {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  color: #1b3030;
  margin-right: 15px;
}
.file-name a {
  color: var(--color-primary);
  text-decoration: underline !important;
}
.file-name .material-icons {
  margin-right: 0.2rem;
  transform: rotate(-45deg);
  font-size: 1rem;
  top: -1px;
  position: relative;
}

.skeleton-loader .d-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.skeleton-loader .css-1y8mk30-SkeletonTheme .react-loading-skeleton {
  background-image: linear-gradient(90deg, #eee, #e4e4e4, #eee);
}

.underprocess-loader .d-flex-between {
  flex-wrap: wrap;
}
.underprocess-loader .w-20 {
  width: 18%;
}
.underprocess-loader .w-60 {
  width: 58%;
}
.underprocess-loader .w-100 {
  width: 100%;
}

.zEWidget-launcher,
div.zsiq_custommain,
div.zsiq_floatmain {
  z-index: 999 !important;
}

/*==================== Begin: Become Member Page ====================*/
.recommended-part {
  padding: 0;
  margin: -15px;
}
.recommended-part:first-child {
  margin-left: 0;
}
.recommended-part:last-child {
  margin-right: 0;
}
.recommended-part .member-plan-block {
  border: 2px solid var(--color-primary);
  background: var(--color-primary);
}
.recommended-part .recommended {
  display: block !important;
}
.recommended-part .member-plan-block .plan-block-details .plan-img g {
  fill: var(--color-white);
}
.recommended-part .member-plan-block .plan-block-details .plan-title {
  color: var(--color-white);
}
.recommended-part .member-plan-block .plan-block-details .plan-text {
  color: var(--color-white);
}
.recommended-part .member-plan-block .plan-block-details .plan-price-block .plan-price {
  color: var(--color-white);
}
.recommended-part .member-plan-block .plan-block-details .plan-price-block .plan-per {
  color: var(--color-white);
}
.recommended-part .member-plan-block .plan-block-details .btn {
  color: var(--color-primary);
  background: var(--color-white);
}
.recommended-part .member-plan-block .plan-block-details .btn:hover {
  background: var(--color-white);
}
.recommended-part .member-plan-block .plan-info .info-list {
  color: var(--color-white);
}
.recommended-part .member-plan-block .plan-info .info-list .check-mark {
  background: var(--color-white);
  color: var(--color-primary);
}

.become-member-page .title-header {
  margin-bottom: 4rem;
}

.member-plan-block {
  height: calc(100% - 32px);
  padding: 2.5rem 1.5rem;
  background: var(--color-white);
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  border-radius: 8px;
  margin-bottom: 32px;
}
.member-plan-block .recommended {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 1rem;
  display: none;
  color: var(--color-white);
}
.member-plan-block .plan-block-details {
  text-align: center;
  margin-bottom: 2rem;
}
.member-plan-block .plan-block-details .plan-img {
  margin-bottom: 2rem;
}
.member-plan-block .plan-block-details .plan-title {
  font-size: 2rem;
  line-height: 2.688rem;
  font-weight: 500;
  color: #091f1f;
  margin-bottom: 0.5rem;
}
.member-plan-block .plan-block-details .plan-text {
  min-height: 52px;
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: 400;
  color: #091f1f;
  padding: 0;
}
.member-plan-block .plan-block-details .plan-price-block {
  margin-top: 1.5rem;
}
.member-plan-block .plan-block-details .plan-price-block .plan-price {
  font-size: 2rem;
  line-height: 2.688rem;
  font-weight: 300;
  color: #091f1f;
  padding: 0;
  margin-bottom: 0.5rem;
}
.member-plan-block .plan-block-details .plan-price-block .plan-per {
  line-height: 1.312rem;
  font-weight: 400;
  padding: 0;
}
.member-plan-block .plan-block-details .btn {
  margin-top: 1.5rem;
}
.member-plan-block .plan-info .info-list {
  font-size: 0.875rem;
  color: #091f1f;
  line-height: 1.25rem;
  font-weight: 400;
  padding-left: 1.75rem;
  margin-bottom: 1rem;
  position: relative;
}
.member-plan-block .plan-info .info-list:last-child {
  margin-bottom: 0;
}
.member-plan-block .plan-info .info-list .check-mark {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--color-primary);
  font-size: 0.65rem;
  color: var(--color-white);
  font-weight: 700;
}

/*==================== End: Become Member Page ====================*/
.dv-star-rating label {
  margin: 0 10px 0 0;
  color: rgba(131, 156, 159, 0.5) !important;
  font-size: 1.5rem;
}
.dv-star-rating label.dv-star-rating-full-star {
  color: var(--color-primary) !important;
}

@media only screen and (max-width: 767.98px) {
  div.zsiq_theme1 .zsiq_flt_rel {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  div.zsiq_theme1 .siqico-chat:before {
    font-size: 22px;
    line-height: 47px;
  }
}
@media only screen and (max-width: 767.98px) {
  div.zsiq_theme1 .zsiq_cnt {
    display: none !important;
  }
}

.basic-details {
  margin-bottom: 40px;
}
.basic-details .default-select .custom-select__menu-list {
  max-height: 160px;
}

.thankyou-box {
  display: inline-block;
  box-shadow: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);
  background: var(--color-white);
  padding: 50px 30px;
  border-radius: 10px;
  width: 94%;
  max-width: 610px;
  margin: 20px 0;
}
@media only screen and (max-width: 767.98px) {
  .thankyou-box {
    margin: 10px 0;
    padding: 40px 30px;
  }
  .thankyou-box .please-wait-img {
    margin-bottom: 30px;
  }
  .thankyou-box .please-wait-img svg {
    max-width: 160px;
    height: auto;
  }
}

.banner-wrapper {
  width: 100%;
  max-width: 770px;
  margin: 0 auto 20px;
}
.banner-wrapper h2 {
  font-size: 1.6rem;
}

.process-banner {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-primary);
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  padding: 22px 3px 20px 24px;
  color: var(--color-white);
  min-height: 180px;
  margin-bottom: 24px;
  font-size: 0.9rem;
  line-height: 24px;
}
@media only screen and (max-width: 767.98px) {
  .process-banner {
    padding: 20px 16px;
    line-height: 21px;
  }
}
.process-banner h3 {
  color: var(--color-white);
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 16px;
}
@media only screen and (max-width: 991.98px) {
  .process-banner h3 {
    font-size: 1.35rem;
  }
}
.process-banner .banner-left {
  width: calc(100% - 255px);
  text-align: left;
}
@media only screen and (max-width: 767.98px) {
  .process-banner .banner-left {
    width: 100%;
  }
}
.process-banner .banner-image {
  width: 250px;
  margin-bottom: -20px;
}
@media only screen and (max-width: 767.98px) {
  .process-banner .banner-image {
    width: 100%;
    margin-top: 24px;
  }
}

.select-goal-dropdown {
  width: 230px;
}

.select-Duration-dropdown {
  width: 160px;
}

.plan-list-item-style {
  list-style-type: disc;
  padding: 0px;
  margin: 0px;
}

.plan-bullet-list {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}
.plan-bullet-list li {
  position: relative;
  list-style-type: none;
  font-size: 1.2rem;
  padding-left: 10px;
}
.plan-bullet-list li + li {
  margin-top: 10px;
}
.plan-bullet-list li::before {
  position: absolute;
  top: 8px;
  left: -10px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #091f1f;
  content: "";
}

.steps.getstart-page h2 {
  font-size: 1.8rem;
}
@media only screen and (max-width: 1199.98px) {
  .steps.getstart-page h2 {
    font-size: 1.7em;
  }
}
@media only screen and (max-width: 991.98px) {
  .steps.getstart-page h2 {
    font-size: 1.6em;
  }
}

.container-frame {
  position: relative;
  float: left;
}

.overlay-frame {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.card-img svg * {
  fill: var(--color-primary);
}
.card-img svg .st0 {
  fill: #ffffff !important;
}

.default-select .css-1uccc91-singleValue {
  color: #091f1f;
}

.form-group.fixed-floating-label .fixed-label,
.custom-datepicker .datepicker-label,
.custom-datepicker .datepicker-label, .form-group.fixed-floating-label .fixed-label {
  background: #fff;
  color: #797979 !important;
  font-size: 12px !important;
}

.custom-datepicker .react-datepicker-wrapper::before {
  z-index: 99;
  pointer-events: none;
}

.form-control[readonly],
.form-control:disabled {
  background-color: #fff;
  opacity: 1;
}

.popover-content {
  font-family: var(--font-var), sans-serif;
}

.custom-slider .rangeslider-horizontal .rangeslider__handle:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASCAYAAABit09LAAAAM0lEQVQoU2PklJf/z8DAwPD94UNGEI2Lz/j//3+wQkZGRrBCnPxRhaPBg5FIqJ8oiE24APFX0l/pq3G7AAAAAElFTkSuQmCC);
}

.lang-dropdown {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
@media only screen and (max-width: 991.98px) {
  .lang-dropdown {
    justify-content: center;
  }
}
.lang-dropdown .lang-inner {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 0;
}
@media only screen and (max-width: 991.98px) {
  .lang-dropdown .lang-inner {
    position: relative;
    top: 0;
    margin-bottom: 20px;
  }
}
.lang-dropdown .lang-title {
  margin-right: 14px;
}
.lang-dropdown .default-select {
  width: 130px;
}

.compare-plan-data-same {
  padding: 6px;
  background: #f9e79f;
}

.compare-plan-data-greater {
  padding: 6px;
  background: rgba(107, 174, 85, 0.5);
}

.compare-plan-data-lesser {
  padding: 6px;
  background: #fadbd8;
}

.compare-plan-data-same-right {
  padding: 6px;
  background: #f9e79f;
  margin-left: 20px;
}

.compare-plan-data-greater-right {
  padding: 6px;
  background: rgba(107, 174, 85, 0.5);
  margin-left: 20px;
}

.compare-plan-data-lesser-right {
  padding: 6px;
  background: #fadbd8;
  margin-left: 20px;
}

.plan-list-wrapper .owl-carousel .owl-dots {
  display: block;
}

.plan-list-wrapper {
  margin-top: 50px;
}
.plan-list-wrapper .owl-theme .owl-dots .owl-dot.active span {
  background: #2ba84a;
}
.plan-list-wrapper .owl-theme .owl-dots .owl-dot:hover span {
  background: #2ba84a;
}
.plan-list-wrapper .owl-carousel .owl-nav .owl-prev {
  font-size: 0;
  width: 19px;
  margin-left: 10px;
  height: 26px;
  background-image: url(/static/media/left-arrow.f722c6cc.svg);
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  display: inline-block;
}
@media only screen and (max-width: 1199.98px) {
  .plan-list-wrapper .owl-carousel .owl-nav .owl-prev {
    background-size: 18px !important;
  }
}
.plan-list-wrapper .owl-carousel .owl-nav .owl-prev.disabled:hover {
  font-size: 0;
  width: 179x;
  margin-left: 10px;
  height: 26px;
  background-image: url(/static/media/left-arrow.f722c6cc.svg);
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  display: inline-block;
}
.plan-list-wrapper .owl-carousel .owl-nav .owl-next {
  font-size: 0;
  width: 19px;
  height: 26px;
  background-image: url(/static/media/right-arrow.c3fe7f18.svg);
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  display: inline-block;
}
@media only screen and (max-width: 1199.98px) {
  .plan-list-wrapper .owl-carousel .owl-nav .owl-next {
    background-size: 18px !important;
  }
}
.plan-list-wrapper .owl-carousel .owl-nav .owl-next.disabled:hover {
  font-size: 0;
  width: 19px;
  height: 26px;
  background-image: url(/static/media/right-arrow.c3fe7f18.svg);
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  display: inline-block;
}

@media screen and (max-width: 1300px) {
  .plan-list-wrapper .owl-carousel .owl-nav .owl-prev,
  .plan-list-wrapper .owl-carousel .owl-nav .owl-prev.disabled:hover {
    margin-left: initial;
  }
}
.getstart-container {
  align-items: center;
}
@media screen and (min-width: 991px) {
  .getstart-container {
    margin-right: 10px;
  }
}
.getstart-container .getstart-button {
  margin-right: 20px;
}
.getstart-container .getstart-button .btn.btn-lg {
  padding: 14px 38px;
}

.demopage .demopagebody {
  background-position: center;
}
.demopage .demopagebody-about {
  background-position: center;
  margin-bottom: -40px;
}
.demopage .about-main-body {
  padding: 60px 70px;
  min-height: 700px;
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 767.98px) {
  .demopage .about-main-body {
    padding: 30px;
  }
}
.demopage .about-main-body .left-content {
  width: 65%;
  text-align: left;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body .left-content {
    width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .demopage .about-main-body .left-content {
    margin-right: 50px;
  }
}
.demopage .about-main-body .left-content .username {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body .left-content .username {
    font-size: 22px;
    margin-bottom: 15px;
  }
}
.demopage .about-main-body .left-content .userbio {
  font-size: 16px;
  font-weight: 300;
  color: #000;
  line-height: 20px;
}
.demopage .about-main-body .yourquote-block {
  padding: 20px;
  border: 1px solid #cdd7d9;
  border-radius: 8px;
  text-align: center;
  display: block;
  margin: 0 auto;
  max-width: 560px;
  margin-top: 60px;
}
.demopage .about-main-body .yourquote-block .yourquote-msg {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
}
.demopage .about-main-body .yourquote-block .btn {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 30px;
}
.demopage .about-main-body .right-content {
  max-width: 40%;
  margin-left: 10px;
  text-align: center;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body .right-content {
    width: 100%;
  }
}
.demopage .about-main-body .right-content .aboutimg {
  width: auto;
  height: auto;
  max-width: 400px;
  flex-wrap: wrap;
  display: flexbox;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 1px 3px 7px 0px #dfdede;
  padding: 10px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body .right-content .aboutimg {
    margin-bottom: 25px;
    width: auto;
    height: auto;
    max-width: 250px;
  }
}
.demopage .about-main-body .right-content .aboutimg img {
  object-fit: cover;
  object-position: center;
}
.demopage .head-row {
  padding: 15px 70px;
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
}
@media only screen and (max-width: 991.98px) {
  .demopage .head-row {
    padding: 15px 20px;
  }
}
.demopage .head-row .pre-logo {
  max-width: 150px;
  max-height: 150px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .head-row .pre-logo {
    max-width: 100px;
  }
}
.demopage .head-row .head-title {
  margin-left: 30px;
}
.demopage .head-row .head-title .ins-title {
  font-weight: 400;
  font-size: 22px;
  color: var(--color-primary);
  margin-bottom: 2px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .head-row .head-title .ins-title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 413.98px) {
  .demopage .head-row .head-title .ins-title {
    font-size: 16px;
  }
}
.demopage .head-row .head-title .ins-copy {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  line-height: 12px;
}
.demopage .pre-main-body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767.98px) {
  .demopage .pre-main-body {
    /* padding: 20px;*/
    min-height: 300px;
  }
}
.demopage .pre-main-body .body-block {
  padding: 20px 15px;
  position: absolute;
  box-shadow: 2px 2px 5px 1px #dcdcdc;
  text-align: center;
  max-width: 550px;
  border-radius: 6px;
  background-color: rgba(254, 254, 254, 0.85);
}
@media only screen and (max-width: 767.98px) {
  .demopage .pre-main-body .body-block {
    margin: 20px;
  }
}
.demopage .pre-main-body .body-block .body-title {
  color: var(--color-primary);
  font-weight: 400;
  font-family: var(--font-var), sans-serif;
}
@media only screen and (max-width: 767.98px) {
  .demopage .pre-main-body .body-block .body-title {
    font-size: 18px;
    line-height: 20px;
  }
}
.demopage .pre-main-body .body-block .body-msg {
  font-size: 22px;
  color: #091f1f;
  text-align: center;
  line-height: 28px;
}
@media only screen and (max-width: 767.98px) {
  .demopage .pre-main-body .body-block .body-msg {
    font-size: 16px;
    line-height: 20px;
  }
}
.demopage .pre-main-body .body-block .btn.gets-quote-btn {
  font-weight: 400;
  margin-top: 30px;
  font-size: 16px;
  padding: 10px 25px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767.98px) {
  .demopage .pre-main-body .body-block .btn.gets-quote-btn {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
}
.demopage .pre-main-body img {
  max-width: 100%;
}
.demopage .premodel-footer {
  background-color: #333333;
  justify-content: center;
  padding: 0 70px;
  border-radius: 0;
  flex-direction: column;
}
@media only screen and (max-width: 991.98px) {
  .demopage .premodel-footer {
    padding: 0 20px;
  }
}
.demopage .premodel-footer .ftitle-block {
  border-bottom: 1px solid #747474;
  width: 100%;
  padding: 15px 0 5px;
}
.demopage .premodel-footer .ftitle-block .ftitle {
  color: var(--color-primary);
}
.demopage .premodel-footer .fcontact {
  padding: 10px 0;
  margin-left: 0;
  width: 100%;
}
@media only screen and (max-width: 991.98px) {
  .demopage .premodel-footer .fcontact {
    width: 100%;
  }
}
.demopage .premodel-footer .fcontact p {
  display: inline-flex;
  padding-bottom: 0;
  color: var(--color-white);
  font-weight: 400;
  width: calc(33.3333333333% - 0px);
  justify-content: center;
}
@media only screen and (max-width: 767.98px) {
  .demopage .premodel-footer .fcontact p {
    display: flex;
    width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .demopage .premodel-footer .fcontact p.f-address {
    justify-content: flex-end;
    width: 35%;
  }
}
@media only screen and (min-width: 992px) {
  .demopage .premodel-footer .fcontact p.f-tell {
    padding: 0 20px;
    justify-content: center;
    width: 30%;
  }
}
@media only screen and (max-width: 991.98px) {
  .demopage .premodel-footer .fcontact p.f-tell {
    margin: 0;
  }
}
@media only screen and (min-width: 992px) {
  .demopage .premodel-footer .fcontact p.f-web {
    width: 35%;
    justify-content: flex-start;
  }
}

.footer-appllication-id {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.postion-inherit {
  position: inherit;
}

html,
body,
#root,
.App {
  height: 100%;
}

.AppRouting {
  height: 100%;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}
footer.overflow-hidden {
  overflow: visible !important;
  padding-top: 0;
}
footer::before {
  width: 100%;
}
footer .disclaimer-block {
  padding-bottom: 20px;
}
@media only screen and (max-width: 767.98px) {
  footer .disclaimer-block {
    padding-bottom: 10px;
  }
}

.page-wrapper {
  flex-shrink: 0 !important;
}

.verification-modal .table th,
.verification-modal .table td {
  min-width: 150px;
}

.form-block-questions .questions-wrapper {
  margin: 0;
}

.pdf-modal {
  margin: 0 15px;
}

.pdf-modal.modal {
  margin: 0 auto;
  max-width: calc(100% - 30px);
  right: 0;
}

@media only screen and (max-width: 767.98px) {
  header .agent-info .img-container svg {
    max-width: 25px;
  }
  header .agent-info {
    padding-right: 10px;
    padding-left: 10px;
  }
  header .agent-info .img-container {
    padding-right: 10px;
  }
  header .agent-info .agent-name,
  header .agent-info .agent-info {
    font-size: 12px;
  }
  header .company-info {
    margin-left: 5px;
    padding-left: 5px;
  }
  .conversation-page.lead-conver .conversation-person .conver-time {
    margin-top: 15px;
    position: static;
  }
}
.welcomeContainer {
  flex: 1 1;
  justify-content: center;
}

.demopage .demopagewrapper {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}
.demopage .demopagewrapper > div {
  height: 100%;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  .demopage .demopagewrapper > div {
    display: block;
  }
}
@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .demopage .demopagewrapper > div {
    display: block;
  }
}
.demopage .demopagewrapper .pre-main-body {
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 991.98px) {
  .demopage .demopagewrapper .pre-main-body {
    flex-direction: column-reverse;
  }
}
.demopage .demopagewrapper .pre-main-body img {
  display: inline-flex;
  max-width: calc(100% - 430px);
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 991.98px) {
  .demopage .demopagewrapper .pre-main-body img {
    max-width: 100%;
  }
}
.demopage .demopagewrapper .pre-main-body .body-block {
  position: static;
  width: 430px;
  padding: 20px 30px;
  display: inline-flex;
  flex-direction: column;
  box-shadow: none;
}
@media only screen and (max-width: 991.98px) {
  .demopage .demopagewrapper .pre-main-body .body-block {
    margin: 0;
    width: auto;
  }
}
.demopage .demopagewrapper .pre-main-body .body-block .btn.gets-quote-btn {
  min-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.demopage .premodel-footer {
  margin-top: auto;
}

.demopage .about-main-body {
  display: block;
}
.demopage .about-main-body .right-content {
  margin-left: 0;
  margin-right: 35px;
  min-width: 300px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body .right-content {
    margin: 0 auto;
  }
}
.demopage .about-main-body .right-content .aboutimg {
  margin-bottom: 25px;
  width: 250px;
  height: 250px;
  max-width: 250px;
  border-radius: 250px;
  overflow: hidden;
  padding: 0;
}
.demopage .about-main-body .right-content .aboutimg img {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 50%;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body {
    min-height: auto;
    padding: 0;
  }
}
.demopage .about-main-body .about-top {
  flex-direction: row-reverse;
  display: flex;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body .about-top {
    flex-direction: column-reverse;
  }
}
.demopage .about-main-body .yourquote-block {
  margin-top: 30px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .about-main-body .yourquote-block {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.input-effect.otp-inputs .btn {
  font-size: 14px;
}

.converexp .guardian-outer .guardian-details {
  width: 100%;
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}
@media only screen and (max-width: 575.98px) {
  .converexp .guardian-outer .guardian-details {
    margin-bottom: 30px;
  }
  .converexp .guardian-outer .guardian-details .guardian-name h2 {
    font-size: 16px;
  }
}
.converexp .guardian-outer .guardian-details .resume-action {
  margin-left: auto;
}
@media only screen and (max-width: 575.98px) {
  .converexp .guardian-outer .guardian-details .resume-action .btn {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100px;
    min-width: 100px;
  }
}

@media only screen and (max-width: 991.98px) {
  .getstart-container .getstart-button .btn.btn-lg {
    padding: 8px 15px;
    font-size: 14px;
  }
}
.mobile-toggle {
  display: none;
}

.header-info-toggle .close {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  .mobile-toggle,
  .header-info-toggle .close {
    display: block;
  }
  .header-right > .agent-info.header-info-toggle {
    position: absolute;
    top: 0;
    background: #ffffff;
    width: 100%;
    right: 0;
    left: 0;
    border: 1px solid var(--color-primary);
    padding: 15px 15px;
    visibility: hidden;
    transform: translatey(-100%);
    transition: all ease 0.3s;
    z-index: 9;
  }
  .header-right > .agent-info.header-info-toggle .img-container {
    display: none;
  }
  .header-right > .agent-info.header-info-toggle .close {
    cursor: pointer;
    float: right;
  }
  .header-right > .agent-info.header-info-toggle.active {
    visibility: visible;
    transform: translatey(0%);
  }
}
@media only screen and (max-width: 413.98px) {
  .box-title.no-border .plan-icons-outer {
    margin-top: 15px;
  }
}
.status-online {
  color: #fff;
  background: #20e21a;
  padding: 6px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 10px;
  font-size: 14px;
}

.status-offline {
  color: #fff;
  background: #dc3545;
  padding: 6px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 10px;
  font-size: 14px;
}

.planspage .filter-block {
  position: relative;
  z-index: 22;
}
.planspage .filter-block2 {
  position: relative;
  z-index: 2;
}
.planspage .filter-block3 {
  position: relative;
  z-index: 0;
}
.planspage .filter-block3 .filter-item {
  flex-direction: inherit;
}
.planspage .react-datepicker-popper[data-placement^=top] {
  margin-bottom: 10px;
  top: 100% !important;
  transform: translate(1px, 20px) !important;
}
.planspage .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  top: -8px;
  background: transparent !important;
  border: transparent !important;
}
.planspage .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-width: 8px;
  border-bottom-color: #2ba84a;
  border-top: none;
  transform: rotate(0);
  bottom: -8px;
}

.title-header .page-title {
  font-weight: 700;
}

.title-header h4 {
  color: #091f1f;
  font-weight: 400;
  max-width: 740px;
  margin: 0 auto;
  font-size: 16px;
}
@media only screen and (max-width: 991.98px) {
  .title-header h4 {
    font-size: 14px;
  }
}

.notes {
  color: var(--color-primary);
  font-weight: 600;
  line-height: 1.4;
}

@media only screen and (max-width: 575.98px) {
  .your-age-page .custom-datepicker .rsd__react-select-datepicker {
    display: flex;
    flex-direction: column;
  }
  .your-age-page .custom-datepicker .rsd__react-select-datepicker > div {
    margin-bottom: 14px;
  }
}
.your-age-page .react-datepicker-wrapper .text-danger {
  display: block;
}
.your-age-page .white-box {
  box-shadow: none;
  margin-bottom: 0;
}
.your-age-page .custom-datepicker {
  text-align: center;
}
.your-age-page .custom-datepicker .text-danger {
  display: block;
}
.your-age-page .custom-datepicker .react-datepicker-wrapper {
  max-width: 200px;
}
.your-age-page .custom-datepicker .react-datepicker-wrapper:before {
  background-image: url(/static/media/calendar.653f5fbf.svg);
}
.your-age-page .custom-datepicker .react-datepicker-wrapper input {
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid var(--color-primary);
  background: #eeeeee;
}
.your-age-page .datepicker-label {
  opacity: 0;
}

.years-page .yearblock-width {
  max-width: 240px;
}
@media only screen and (max-width: 575.98px) {
  .years-page .yearblock-width {
    max-width: initial;
  }
}
.years-page .boxmodal-radio input[type=radio] + .card-inner .card-text.year-box,
.years-page .boxmodal-checkbox input[type=radio] + .card-inner .card-text.year-box {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
}
@media only screen and (max-width: 991.98px) {
  .years-page .boxmodal-radio input[type=radio] + .card-inner .card-text.year-box,
  .years-page .boxmodal-checkbox input[type=radio] + .card-inner .card-text.year-box {
    font-size: 24px;
  }
}

.inverst-type-page .card-inner {
  display: flex;
  flex-direction: column;
}
.inverst-type-page .card-inner .pros-cons {
  margin-bottom: 15px;
  height: 100%;
}

.boxmodal-radio input[type=radio] + div.card-inner,
.boxmodal-radio input[type=radio] + div.card-inner {
  border: 1px solid #cdd7d9;
  padding-left: 0;
  padding-right: 0;
  background: var(--color-white);
}
.boxmodal-radio input[type=radio] + div.card-inner > *,
.boxmodal-radio input[type=radio] + div.card-inner > * {
  padding-left: 20px;
  padding-right: 20px;
}
.boxmodal-radio input[type=radio] + div.card-inner .card-img,
.boxmodal-radio input[type=radio] + div.card-inner .card-img {
  padding-left: 0;
  padding-right: 0;
}
.boxmodal-radio h2.page-title {
  font-size: 26px;
  font-weight: 900;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .boxmodal-radio h2.page-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .boxmodal-radio h2.page-title {
    font-size: 22px;
  }
}
@media only screen and (max-width: 991.98px) {
  .boxmodal-radio h2.page-title {
    font-size: 20px;
  }
}
.boxmodal-radio .cart-desc {
  text-align: left;
  color: #091f1f;
  display: inline-block;
  margin-bottom: 40px;
  font-weight: 400;
}
@media only screen and (max-width: 1199.98px) {
  .boxmodal-radio .cart-desc {
    font-size: 14px;
  }
}
.boxmodal-radio .commin-soon-main .card-body {
  margin-top: 105px;
  margin-bottom: 105px;
}
@media only screen and (max-width: 991.98px) {
  .boxmodal-radio .commin-soon-main .card-body {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
.boxmodal-radio .commin-soon-main .comming-soon {
  font-size: 35px;
  font-weight: 800;
  margin: 40px 0;
  display: inline-block;
  color: var(--color-primary);
}
.boxmodal-radio .bestfor {
  border-top: 2px solid var(--color-primary);
  text-align: left;
  color: #091f1f;
  padding: 15px;
  font-size: 16px;
  line-height: 1.4;
  height: 100%;
  max-height: 120px;
  margin-top: auto;
}
@media only screen and (max-width: 1199.98px) {
  .boxmodal-radio .bestfor {
    font-size: 14px;
  }
}
.boxmodal-radio .pros-cons {
  text-align: left;
  margin-top: 15px;
}
.boxmodal-radio .pros-cons strong {
  color: #091f1f;
  font-size: 16px;
  font-weight: 700;
}
@media only screen and (max-width: 1199.98px) {
  .boxmodal-radio .pros-cons strong {
    font-size: 14px;
  }
}
.boxmodal-radio .pros-cons ul li {
  font-weight: 500;
  color: #797979;
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 1199.98px) {
  .boxmodal-radio .pros-cons ul li {
    font-size: 14px;
  }
}
.boxmodal-radio .pros-cons ul.list-dot li {
  position: relative;
  padding-left: 12px;
}
.boxmodal-radio .pros-cons ul.list-dot li:before {
  content: "";
  width: 4px;
  height: 4px;
  display: inline-block;
  background: #797979;
  border-radius: 50%;
  top: 9px;
  left: 0;
  position: absolute;
}

.demopage .demopagebody {
  background-size: cover;
  background-position: center;
}
.demopage .head-row {
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 991.98px) {
  .demopage .head-row {
    padding: 15px 0px;
  }
}
.demopage .head-row .pre-logo {
  max-width: 150px;
  max-height: 150px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .head-row .pre-logo {
    max-width: 100px;
  }
}
@media only screen and (max-width: 575.98px) {
  .demopage .head-row .pre-logo {
    max-width: 80px;
  }
}
.demopage .head-row .head-title {
  margin-left: 30px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .head-row .head-title {
    margin-left: 10px;
  }
}
.demopage .head-row .head-title .ins-title {
  font-weight: 400;
  font-size: 22px;
  color: var(--color-primary);
  margin-bottom: 2px;
}
@media only screen and (max-width: 991.98px) {
  .demopage .head-row .head-title .ins-title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 413.98px) {
  .demopage .head-row .head-title .ins-title {
    font-size: 16px;
  }
}
.demopage .head-row .head-title .ins-copy {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  line-height: 12px;
}
.demopage .premodel-footer {
  background-color: #333333;
  justify-content: center;
  padding: 0 70px;
  border-radius: 0;
  flex-direction: column;
}
@media only screen and (max-width: 991.98px) {
  .demopage .premodel-footer {
    padding: 0 20px;
  }
}
.demopage .premodel-footer .ftitle-block {
  border-bottom: 1px solid #747474;
  width: 100%;
  padding: 15px 0 5px;
}
.demopage .premodel-footer .ftitle-block .ftitle {
  color: var(--color-primary);
}
.demopage .premodel-footer .footerTitle {
  color: var(--color-white);
}
.demopage .premodel-footer .fcontact {
  padding: 10px 0;
  margin-left: 0;
  width: 100%;
}
@media only screen and (max-width: 991.98px) {
  .demopage .premodel-footer .fcontact {
    width: 100%;
  }
}
.demopage .premodel-footer .fcontact p {
  display: inline-flex;
  padding-bottom: 0;
  color: var(--color-white);
  font-weight: 400;
  width: calc(33.3333333333% - 0px);
  justify-content: center;
}
@media only screen and (max-width: 991.98px) {
  .demopage .premodel-footer .fcontact p {
    display: flex;
    width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .demopage .premodel-footer .fcontact p.f-address {
    justify-content: flex-end;
    width: 35%;
  }
}
@media only screen and (min-width: 992px) {
  .demopage .premodel-footer .fcontact p.f-tell {
    padding: 0 20px;
    justify-content: center;
    width: 30%;
  }
}
@media only screen and (max-width: 991.98px) {
  .demopage .premodel-footer .fcontact p.f-tell {
    margin: 0;
  }
}
@media only screen and (min-width: 992px) {
  .demopage .premodel-footer .fcontact p.f-web {
    justify-content: flex-start;
    width: 35%;
  }
}

.uploadlogo .upload-btn-wrapper {
  display: inline-block;
  position: relative;
}
.uploadlogo .upload-btn-wrapper .img-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
}

.uploadbgimg .upload-btn-wrapper {
  display: block;
  width: 100%;
}

.uploadbgimg .upload-btn-wrapper input[type=file] {
  width: 100%;
}

.what-type input[type=radio] + .card-inner, .inverst-type-page input[type=radio] + .card-inner {
  padding-bottom: 0;
}
.what-type input[type=radio] + .card-inner .bestfor, .inverst-type-page input[type=radio] + .card-inner .bestfor {
  margin-top: auto;
}
.what-type .boxmodal-radio .page-title, .inverst-type-page .boxmodal-radio .page-title {
  font-size: 22px;
  height: 50px;
  line-height: 24px;
  margin-bottom: 30px !important;
}
.what-type .boxmodal-radio input[type=radio] + .card-inner, .inverst-type-page .boxmodal-radio input[type=radio] + .card-inner,
.what-type .boxmodal-radio input[type=radio] + .card-inner {
  display: flex;
  flex-direction: column;
}
.what-type .boxmodal-radio input[type=radio] + .card-inner .notes, .inverst-type-page .boxmodal-radio input[type=radio] + .card-inner .notes,
.what-type .boxmodal-radio input[type=radio] + .card-inner .notes {
  margin-top: auto;
  padding: 0 60px;
  font-weight: 600;
}
@media only screen and (max-width: 991.98px) {
  .what-type .boxmodal-radio input[type=radio] + .card-inner .notes, .inverst-type-page .boxmodal-radio input[type=radio] + .card-inner .notes,
  .what-type .boxmodal-radio input[type=radio] + .card-inner .notes {
    padding: 0 20px;
  }
}
.what-type .pros-cons, .inverst-type-page .pros-cons {
  margin-bottom: 15px;
  min-height: 100px;
}
@media only screen and (max-width: 1199.98px) {
  .what-type .pros-cons, .inverst-type-page .pros-cons {
    min-height: 130px;
  }
}
@media only screen and (max-width: 991.98px) {
  .what-type .pros-cons, .inverst-type-page .pros-cons {
    min-height: inherit;
  }
}
.what-type .cart-desc, .inverst-type-page .cart-desc {
  margin-bottom: 40px;
  min-height: 210px;
  font-size: 18px;
  font-weight: 400;
}
@media only screen and (max-width: 1199.98px) {
  .what-type .cart-desc, .inverst-type-page .cart-desc {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767.98px) {
  .what-type .cart-desc, .inverst-type-page .cart-desc {
    min-height: inherit !important;
    font-size: 14px;
  }
}
@media only screen and (max-width: 991.98px) {
  .what-type .cart-desc, .inverst-type-page .cart-desc {
    min-height: inherit;
  }
}

.inverst-type-page .cart-desc {
  min-height: 110px;
}
@media only screen and (max-width: 767.98px) {
  .inverst-type-page .cart-desc {
    min-height: initial;
  }
}
@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .inverst-type-page .cart-desc {
    min-height: 170px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px) {
  .inverst-type-page .cart-desc {
    min-height: 170px;
    font-size: 16px;
  }
}

.inverst-type-page .card-inner .pros-cons {
  margin-bottom: 15px;
}

.ranchers-best-for.bestfor {
  max-height: 95px;
}
@media only screen and (max-width: 991.98px) {
  .ranchers-best-for.bestfor {
    max-height: 110px;
  }
}

.props-height {
  height: 100%;
  min-height: 160px;
}

.welcome-background {
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
}

.tooltip-outer {
  background: var(--color-primary);
  padding: 15px;
  border-radius: 2px;
  color: var(--color-white);
  font-size: 14px;
}
@media only screen and (max-width: 1199.98px) {
  .tooltip-outer {
    min-width: 300px;
  }
}

@media only screen and (min-width: 1921px) {
  .planspage .owl-carousel.owl-theme.owl-loaded {
    padding-left: 0;
    padding-right: 0;
  }
  .planspage .plan-list-wrapper .owl-carousel .owl-nav .owl-prev {
    margin-left: -25px;
  }
  .planspage .plan-list-wrapper .owl-carousel .owl-nav .owl-next {
    margin-right: -7px;
  }
}
.single-page-application .rsd__react-select-datepicker {
  justify-content: start;
}
.single-page-application .rsd__react-select-datepicker .rsd__select-month {
  margin-left: 0;
}

.rsd__react-select-datepicker {
  justify-content: center;
}
.rsd__react-select-datepicker select {
  padding: 10px 30px 10px 10px;
  background: #f5f5f5;
  border: none;
  border-radius: 3px;
  margin: 0 10px;
}

.rsd .error-message {
  color: #dc3545;
}

.offlinemessage {
  padding: 20px;
  border-radius: 10px;
  background-color: #f1f4ed;
  margin-top: 20px;
}

.enquiryForm {
  padding: 20px;
  margin-top: 20px;
}
.enquiryForm input {
  box-shadow: 2px 2px 5px 1px #dcdcdc;
}

.with-video .body-block,
.with-video .iframe-video {
  width: 560px;
  height: 400px !important;
  max-width: 100%;
}
@media only screen and (max-width: 767.98px) {
  .with-video .body-block,
  .with-video .iframe-video {
    margin: 10px 0;
    width: 100% !important;
    max-width: 550px;
  }
}
@media only screen and (max-width: 767.98px) {
  .with-video .body-block {
    height: auto !important;
    margin-top: 50px !important;
    min-height: 300px;
  }
}
.with-video .iframe-video {
  min-height: 300px !important;
  background-color: #000;
}

.welcomePageBg {
  /* The image used */
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.demopagebody {
  z-index: 9;
}

form#manual-form .custom-datepicker .react-datepicker-wrapper input {
  font-size: 0.9rem;
  padding: 10px 25px 10px 18px;
}
form#manual-form .custom-datepicker .react-datepicker-wrapper::before {
  width: 15px;
  height: 18px;
  right: 10px;
  background-size: 15px;
}

.custom-radio label {
  color: #333;
}

.progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step;
  display: flex;
  justify-content: center;
}
.progressbar li.focus:before {
  color: #2ba84a;
  border: 2px solid #2ba84a;
  content: counter(step);
  counter-increment: step;
}
.progressbar li.active {
  color: #2ba84a;
}
.progressbar li.active:before {
  color: #2ba84a;
  border: 2px solid #2ba84a;
  content: "";
  background-image: url(/assets/images/check.png);
  height: 30px;
  width: 30px;
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 6px 7px;
}
.progressbar li {
  list-style-type: none;
  color: white;
  font-size: 14px;
  color: #000;
  flex: 0.1 1;
  position: relative;
  text-align: center;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  line-height: 25px;
  display: block;
  font-weight: 600;
  color: #797979;
  font-size: 16px;
  background: white;
  border-radius: 3px;
  margin: 0 auto 5px auto;
  border-radius: 50px;
  text-align: center;
  height: 30px;
  border: 2px solid #797979;
}
.progressbar li:after {
  content: "";
  width: 70%;
  height: 2px;
  background: #d1d1d1;
  position: absolute;
  left: -35%;
  top: 14px;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}

@media only screen and (max-width: 600px) {
  .progressbar li {
    flex: 1 1;
  }
  .progressbar li span {
    display: none;
  }
}
.single-page-custom-slider .range-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.single-page-custom-slider .range-block .rangeslider-horizontal {
  height: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: none;
}
@media only screen and (max-width: 991.98px) {
  .single-page-custom-slider .range-block .rangeslider-horizontal {
    max-width: 100%;
    margin-inline-start: auto;
  }
  .single-page-custom-slider .range-block .rangeslider-horizontal .rangeslider__labels .rangeslider__label-item:first-child {
    left: calc(100% - 40px) !important;
  }
  .single-page-custom-slider .range-block .rangeslider-horizontal .rangeslider__labels .rangeslider__label-item:last-child {
    left: 30px !important;
  }
}
.single-page-custom-slider .range-block .rangeslider-horizontal .rangeslider__handle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.single-page-custom-slider .range-block .rangeslider-horizontal .rangeslider__handle .rangeslider__handle-label {
  display: none;
}
.single-page-custom-slider .range-block .rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}
.single-page-custom-slider .range-block .rangeslider-horizontal .rangeslider__fill {
  box-shadow: none;
}
.single-page-custom-slider .range-block .value {
  min-width: 150px;
  height: 40px;
  padding: 1px 10px;
  text-align: center;
  border: 1px solid #cdd7d9;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
@media only screen and (max-width: 991.98px) {
  .single-page-custom-slider .range-block .value {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
}
.single-page-custom-slider .range-block .value p {
  color: #000;
}

.citizen label {
  display: block;
}
.citizen label span {
  color: #333333;
}

@media screen and (min-width: 991px) {
  .single-page-application {
    max-width: 860px;
  }
  .single-page-application .number-format-amount {
    margin-left: 0;
  }
}
.single-page-application h2 {
  color: var(--color-txt-df);
}
.single-page-application .single-page-box {
  margin-bottom: 50px;
}
.single-page-application .single-page-box h4 {
  font-size: 18px;
  color: var(--color-txt-df);
  margin-bottom: 15px;
}
@media screen and (max-width: 991px) {
  .single-page-application .single-page-box .num_value {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 400px) {
  .single-page-application .single-page-box.birthday .rsd .rsd__react-select-datepicker select {
    padding: 10px 0px 10px 10px;
  }
  .single-page-application .single-page-box.birthday .rsd .rsd_year-container,
  .single-page-application .single-page-box.birthday .rsd .rsd_month-container {
    width: 100px;
  }
}
.single-page-application .single-page-box .number-format-amount {
  border-radius: 50px;
  margin-left: 30px;
  text-align: center;
  border: 2px solid var(--color-primary);
  color: #091f1f;
}
@media screen and (max-width: 991px) {
  .single-page-application .single-page-box .number-format-amount {
    margin-left: 0;
    margin-top: 30px;
  }
}
.single-page-application .browse-btn {
  margin-top: 10px;
}
.single-page-application .btn-back-single-page {
  margin-right: 10px;
  border-color: #091f1f;
  color: #091f1f;
}
.single-page-application .gender label div {
  border: 2px solid #cdd7d9;
  display: inline-block;
  padding: 7px 36px;
  border-radius: 50px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 10px;
  color: #091f1f;
  background-color: var(--color-white);
}
.single-page-application .gender label div:hover {
  background-color: var(--color-white);
  border: 2px solid var(--color-over-effect);
}
.single-page-application .gender label div.active {
  background-color: var(--background-gray);
  border: 2px solid var(--color-primary);
  color: var(--color-txt-df);
}
.single-page-application .health label {
  height: auto;
}
.single-page-application .health div .health-box {
  border: 2px solid #cdd7d9;
  width: 120px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 5px;
  justify-content: center;
  margin-right: 20px;
  border-radius: 15px;
  cursor: pointer;
  height: 100%;
  color: #091f1f;
  background-color: var(--color-white);
}
.single-page-application .health div .health-box img {
  display: block;
  text-align: center;
  color: var(--color-primary);
}
@media screen and (max-width: 991px) {
  .single-page-application .health div .health-box img {
    width: 50px !important;
  }
}
.single-page-application .health div .health-box:hover {
  background-color: var(--color-white);
  border: 2px solid var(--color-over-effect);
}
.single-page-application .health div .health-box.active {
  background-color: var(--background-gray);
  border: 2px solid var(--color-primary);
  color: var(--color-txt-df);
}
.single-page-application .smoke .smoke-box {
  border: 2px solid #cdd7d9;
  border-radius: 14px;
  width: 120px;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-right: 20px;
  cursor: pointer;
  color: #091f1f;
  background-color: var(--color-white);
}
.single-page-application .smoke .smoke-box img {
  width: 62px;
  height: 62px;
  display: block;
  margin: auto;
}
.single-page-application .smoke .smoke-box label {
  margin: 0;
}
.single-page-application .smoke .smoke-box:hover {
  background-color: var(--color-white);
  border: 2px solid var(--color-over-effect);
}
.single-page-application .smoke .smoke-box.active {
  background-color: var(--background-gray);
  border: 2px solid var(--color-primary);
  color: var(--color-txt-df);
}
.single-page-application .coveragelast .smoke-box {
  width: auto;
  height: auto;
  padding: 10px;
  text-transform: initial;
}
.single-page-application .coveragelast .smoke-box img {
  width: auto;
  height: 48px;
  margin-right: 10px;
}
.single-page-application.active {
  background-color: var(--color-white);
  border: 2px solid var(--color-primary);
}

@media screen and (max-width: 600px) {
  .health div .health-box {
    width: 100px !important;
  }
}
.plan-list-wrapper .item {
  width: calc(33.3333333333% - 32px);
  display: inline-block;
  vertical-align: top;
}
@media only screen and (max-width: 991.98px) {
  .plan-list-wrapper .item {
    width: calc(50% - 32px);
  }
}
@media only screen and (max-width: 767.98px) {
  .plan-list-wrapper .item {
    width: calc(100% - 32px);
  }
}

.planspage .plan-btn-block {
  position: fixed;
  right: 115px;
  bottom: 40px;
  background: #fff;
  border-radius: 59px;
  z-index: 99;
}
@media only screen and (max-width: 767.98px) {
  .planspage .plan-btn-block {
    right: 70px;
    bottom: 10px;
  }
}
@media only screen and (max-width: 575.98px) {
  .planspage .plan-btn-block .btn {
    margin: 5px;
    font-size: 10px;
    max-width: 100px;
    min-width: 100px;
    padding: 5px;
  }
}
.planspage .plan-btn-block .btn.btn-primary {
  background: rgb(1, 160, 178);
  background: linear-gradient(90deg, rgb(1, 160, 178) 0%, rgb(42, 168, 81) 100%);
  color: #fff;
  border: 0;
  height: 45px;
}
@media only screen and (max-width: 991.98px) {
  .planspage .plan-btn-block .btn.btn-primary {
    height: 40px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 575.98px) {
  .planspage .plan-btn-block .btn.btn-primary {
    font-size: 10px;
  }
}
.planspage .plan-btn-block .btn.btn-primary:hover {
  background: linear-gradient(90deg, rgb(1, 160, 178) 100%, rgb(42, 168, 81) 0%);
}
.planspage .plan-btn-block .btn.btn-primary-outline {
  height: 45px;
}
@media only screen and (max-width: 991.98px) {
  .planspage .plan-btn-block .btn.btn-primary-outline {
    height: 40px;
  }
}
.planspage span.header-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #ff9500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  padding: 2px 0;
}

.show-more {
  text-align: center;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
}

.compare-bank-acc {
  max-width: 250px;
  margin: 0 auto;
  border-bottom: 0;
}
.compare-bank-acc .custom-select__single-value {
  font-size: 1.2rem;
}
.compare-bank-acc .default-select.no-shadow .custom-select-menu .custom-select__control {
  background-color: #f1f4ed;
}

.comparison-block .card-value-list {
  padding-bottom: 0;
}
.comparison-block .card-value-list .value-list-item {
  display: inline-block;
  margin: 10px;
}
.comparison-block .card-value-list .value-list-item .list-label {
  margin-right: 6px;
}
.comparison-block .card-value-list .value-list-item .list-label:after {
  content: ":";
  padding-left: 6px;
}
.comparison-block .card-value-list .value-list-item .list-label span {
  display: none;
}
.comparison-block .card-value-list .value-list-item > * {
  display: inline-block;
  vertical-align: top;
}
.comparison-block .card-inner {
  border-color: #2ba84a;
  background-color: #f1f4ed;
}
.comparison-block .card-inner .card-title-fixed {
  color: var(--color-primary);
  font-size: 1.2rem;
  position: static;
}

.planlist-main {
  text-align: center;
}
.planlist-main .planList-tab {
  display: flex;
  justify-content: center;
}
.planlist-main .online-carriers-btn {
  background: #fff;
  border: 2px solid #787878;
  color: #091f1f;
  padding: 15px 25px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 18px;
  margin: 0 5px;
}
.planlist-main .online-carriers-btn.active {
  background: #ff9500;
  border-color: #ff9500;
  color: var(--color-white);
}
@media only screen and (max-width: 767.98px) {
  .planlist-main .online-carriers-btn {
    padding: 10px 10px;
    font-size: 13px;
  }
  .planlist-main .online-carriers-btn img {
    width: 10px;
  }
}
.planlist-main .offline-carriers-btn {
  background: #fff;
  border: 2px solid #787878;
  color: #091f1f;
  padding: 15px 25px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 18px;
  margin: 0 5px;
}
.planlist-main .offline-carriers-btn.active {
  background: #2ba849;
  border-color: #2ba849;
  color: var(--color-white);
}
@media only screen and (max-width: 767.98px) {
  .planlist-main .offline-carriers-btn {
    padding: 10px 10px;
    font-size: 13px;
  }
}
.planlist-main .planlist-tab-content {
  border: 3px solid #000;
  border-radius: 10px;
  padding: 30px 30px;
  text-align: left;
  position: relative;
  z-index: 1;
  top: -3px;
  min-height: 500px;
}
.planlist-main .planlist-tab-content.offline {
  border-color: #2ba849;
  background: #f1f4ed;
}
.planlist-main .planlist-tab-content.online {
  border-color: #ff9500;
  background-color: #fff9f1;
}
.planlist-main .card-value-list {
  height: 160px;
}
@media only screen and (max-width: 991.98px) {
  .planlist-main .card-value-list {
    height: 180px;
  }
}
@media only screen and (max-width: 991.98px) {
  .planlist-main .policy-name {
    height: 50px;
  }
}

.policy-type-title {
  color: #333333;
}

.error {
  border-radius: 3px;
  background: rgba(220, 53, 69, 0.1098039216);
  padding: 6px;
  margin-top: 15px;
}
.error p {
  padding: 0;
}

.color-grey {
  color: #797979;
}

.font-size-18 {
  font-size: 18px;
}
@media only screen and (max-width: 991.98px) {
  .font-size-18 {
    font-size: 16px;
  }
}

.sbli-main-footer {
  display: flex;
  align-items: baseline;
  background-color: #f2f2f2;
  position: relative;
  left: 0;
  border-top: 2px solid var(--color-primary);
  bottom: 0;
  padding: 1rem 2rem;
}
.sbli-main-footer img {
  width: 90px;
  margin-right: 20px;
}
.sbli-main-footer p {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 0;
  color: #797979;
}

#myProgress {
  width: 100%;
  background-color: #f5f5f5;
  position: absolute;
  top: 80px;
  left: 0;
}

#myBar {
  height: 15px;
  background-color: #2ba849;
  transition: all 0.4s;
}

.sbli-main-question,
.sbli-user-detail,
.sbli-terms-container,
.sbli-beneficiary-container,
.sbli-documents-main,
.denial-page-container {
  width: 100%;
  max-width: 900px;
  margin: auto;
  margin-bottom: 75px;
}
.sbli-main-question .btn-default,
.sbli-user-detail .btn-default,
.sbli-terms-container .btn-default,
.sbli-beneficiary-container .btn-default,
.sbli-documents-main .btn-default,
.denial-page-container .btn-default {
  height: 53px;
  width: 168px;
  font-size: 16px;
  color: var(--color-primary);
}
.sbli-main-question .btn-default:hover,
.sbli-user-detail .btn-default:hover,
.sbli-terms-container .btn-default:hover,
.sbli-beneficiary-container .btn-default:hover,
.sbli-documents-main .btn-default:hover,
.denial-page-container .btn-default:hover {
  background-color: var(--color-primary);
  color: #fff;
}
.sbli-main-question .btn-link,
.sbli-user-detail .btn-link,
.sbli-terms-container .btn-link,
.sbli-beneficiary-container .btn-link,
.sbli-documents-main .btn-link,
.denial-page-container .btn-link {
  font-size: 16px;
  color: #797979;
}

.sbli-main-user-detail-question h4 {
  font-weight: 500;
  font-size: 18px;
  color: #333;
}

.third-party-notification {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 75px;
}

.sbli-question-container h4 {
  font-weight: 500;
  font-size: 18px;
  color: #333;
}
.sbli-question-container .custom-radio label {
  border: 1px solid #d7dadb;
  display: flex !important;
  padding: 1rem 3rem;
  border-radius: 5px;
  font-size: 18px;
  color: #333;
  height: 67px;
  align-items: center;
  transition: all 0.3s;
}
.sbli-question-container .custom-radio label input[type=radio] + span {
  margin-left: 1rem;
  border: 1px solid #2ba849 !important;
}
.sbli-question-container .custom-radio label:hover {
  border: 1px solid var(--color-primary);
}
.sbli-question-container .custom-checkbox label {
  border: 1px solid #d7dadb;
  display: flex;
  padding: 1rem 3rem;
  font-size: 18px;
  color: #333;
  min-height: 67px;
  align-items: center;
  transition: all 0.3s;
}
.sbli-question-container .custom-checkbox label input[type=radio] + span {
  margin-left: 1rem;
  border: 1px solid #2ba849 !important;
}
.sbli-question-container .custom-checkbox label:hover {
  border: 1px solid var(--color-primary);
}
.sbli-question-container .custom-checkbox label::first-letter {
  font-size: 200%;
  color: #8a2be2 !important;
}

.highlighted-header {
  background-color: #f2f2f2;
  border-bottom: 3px solid var(--color-primary);
  padding: 1rem 2rem;
  text-align: center;
  color: #000;
}
.highlighted-header p,
.highlighted-header b {
  font-size: 16px;
  color: #333;
}

.sbli-terms-container p {
  color: #000;
}

.sbli-beneficiary-container table td {
  vertical-align: middle;
}
.sbli-beneficiary-container table td img {
  width: 25px;
}

.beneficiary-body h4 {
  color: #000;
}
.beneficiary-body .label-checkbox label {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
}
.beneficiary-body .label-checkbox label.active {
  border: 2px solid #2ba84a;
  background-color: #f6f4f5;
}
.beneficiary-body .label-checkbox label input {
  visibility: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.sbli-coverage .sbli-premium .premium-box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #dbe2de;
  padding: 2rem;
  text-align: center;
  background-color: #fff;
}
.sbli-coverage .sbli-premium .premium-box h1 {
  font-size: 3.5rem;
  color: #2ba849;
}
.sbli-coverage .sbli-premium .premium-box h1 sup {
  font-size: 22px;
  top: -1.4em;
}
.sbli-coverage .sbli-premium .premium-box h4 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.sbli-coverage .sbli-premium .premium-box h4 span {
  font-size: 16px;
  color: #5a5a5a;
}
.sbli-coverage .sbli-premium .premium-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/static/media/bg-victory.f40efd14.png);
  width: 100%;
  height: 100%;
  z-index: -2;
  margin: 0 auto;
  background-size: 94%;
  background-repeat: no-repeat;
}
.sbli-coverage .sbli-terms label {
  width: 100%;
  border: 1px solid #dbe2de;
  padding: 1rem;
  border-radius: 8px;
  color: #000;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.sbli-coverage .sbli-terms label span span {
  font-size: 22px;
}
.sbli-coverage .sbli-terms label.active {
  border: 1px solid #2ba849;
}

.recaptcha {
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
}

.process {
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  .process {
    margin-bottom: 20px;
  }
}
.process .img-block {
  width: calc(100% - 15px);
  height: auto;
  background-color: #efefef;
  border-radius: 12px;
  position: relative;
}
.process .img-block img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
}
.process .img-block.green-shadow:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: -15px;
  bottom: -15px;
  z-index: -1;
  border-radius: 15px;
  background: linear-gradient(90deg, #fff 0%, #ebf9e8 47%, #c5efb8 84%);
}
.process .img-block.blue-shadow:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: -15px;
  bottom: -15px;
  z-index: -1;
  border-radius: 15px;
  background: linear-gradient(90deg, #fff 0%, #ffffff 47%, #d1eff8 84%);
}
.process .process-desc {
  font-size: 18px;
  color: #333333;
  padding-bottom: 0;
  margin: 40px 10px 25px;
  font-weight: 700;
}
@media only screen and (max-width: 767.98px) {
  .process .process-desc {
    font-size: 16px;
  }
}

.sbli-coverage-loader {
  background: linear-gradient(270deg, rgb(43, 168, 74) 0%, rgb(4, 159, 181) 100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12345678;
}
.sbli-coverage-loader .sbli-coverage-progress {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sbli-coverage-loader .sbli-coverage-progress h3 {
  font-weight: 600;
  font-size: 22px;
}
.sbli-coverage-loader .sbli-coverage-progress p {
  font-size: 16px;
}
.sbli-coverage-loader .sbli-coverage-progress span {
  font-size: 14px;
}
.sbli-coverage-loader .sbli-coverage-progress .coverage-loader {
  border: 2px solid #fff;
  height: 50px;
  width: 80%;
  border-radius: 5px;
  padding: 5px;
}
.sbli-coverage-loader .sbli-coverage-progress .coverage-loader .sbli-cv-progress {
  background-color: #fff;
  height: 100%;
  width: 0;
  transition: all 0.2s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sbli-coverage-loader .sbli-coverage-progress .percentage {
  font-size: 26px;
  font-weight: 600;
}

.pre-bg {
  background-image: url(/static/media/bg-congratulation.0e77b96c.png);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 3rem 0;
}
.pre-bg .pre-approval-box {
  border: 1px solid #cacaca;
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center;
}
.pre-bg .pre-approval-box div {
  height: 100px;
  padding: 1rem;
  background-color: #fff;
}
.pre-bg .pre-approval-box .pre-approval-bg {
  background-color: #eee;
  border-bottom: 1px solid #cacaca;
}

.sbli-documents-main .pdf-container {
  background-color: #ddd;
  height: 700px;
  border-radius: 5px;
}
.sbli-documents-main .pdf-container-button {
  border: 2px solid #ddd;
}
.sbli-documents-main .pdf-container-button button {
  color: #000;
}
.sbli-documents-main .pdf-container-button button img {
  width: 25px;
}

.apply-modal .modal-dialog {
  max-width: 400px;
}
.apply-modal .modal-dialog .modal-content .modal-body h4 {
  font-size: 23px;
  color: #000;
  text-align: center;
}
.apply-modal .modal-dialog .modal-content .modal-body p {
  text-align: center;
  line-height: 24px;
  color: #000;
}

.sbli-question-container .sbli-parent-question {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 75px;
}
.sbli-question-container .attr-question .height-box {
  display: flex;
  justify-content: center;
}
.sbli-question-container .attr-question .height-box .unit-box {
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.sbli-question-container .attr-question .height-box .unit-box:hover {
  border: 1px solid #2ba849;
}
.sbli-question-container .attr-question .height-box .unit-box.active {
  border: 1px solid #2ba849;
}

.validate-question-parent .parent_question_head {
  color: #dc3545 !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: shake;
}

.assurity-limitation ul {
  padding: 1rem;
}
.assurity-limitation ul li {
  list-style: disc !important;
}

.assurity-validate {
  color: #dc3545 !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: shake;
}

.validate-question-child .child-question_head,
.validate-question-child h4 {
  color: #dc3545 !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: shake;
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}
.design-1 .assurity-progress-1 {
  display: flex;
  align-items: center;
}
.design-1 .assurity-progress-1 div {
  flex: 1 1;
  position: relative;
  margin: 0 0.5rem;
}
.design-1 .assurity-progress-1 div p {
  font-size: 16px;
  color: #b5b5b5;
}
.design-1 .assurity-progress-1 div::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-color: #d7d7d7;
}
.design-1 .assurity-progress-1 div.active p {
  color: #2ba849;
}
.design-1 .assurity-progress-1 div.active::after {
  background-color: #2ba849;
}

.design-2 .assurity-content-container {
  margin-top: -13px;
  background-color: #fff;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}
.design-2 .assurity-progress-2 {
  background-color: #2ba849;
  padding-top: 15px;
  height: 85px;
}
.design-2 .assurity-progress-2 .progressbar li {
  font-size: 11px;
  color: #fff;
}
.design-2 .assurity-progress-2 .progressbar li::before {
  width: 20px;
  height: 20px;
  line-height: 16px;
  font-size: 10px;
  border: 2px solid #fff;
}
.design-2 .assurity-progress-2 .progressbar li:after {
  top: 10px;
  background: #fff;
  z-index: 0;
}

.design-3 {
  position: relative;
}
.design-3 .assurity-progress-3 #myProgress {
  top: 13px;
}
.design-3 .assurity-progress-3 #myBar {
  height: 5px;
}
.design-3 .assurity-progress-3 .step-bar {
  position: absolute;
  top: 0;
  width: 100%;
}
.design-3 .assurity-progress-3 .step-bar .as-step {
  flex: 1 1;
  text-align: center;
}
.design-3 .assurity-progress-3 .step-bar .as-step div {
  width: 30px;
  height: 30px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto;
}
.design-3 .assurity-progress-3 .step-bar .as-step p {
  font-size: 12px;
}
.design-3 .assurity-progress-3 .step-bar .as-step.active div {
  background-color: #2ba849;
  color: #fff;
}
.design-3 .assurity-progress-3 .step-bar .as-step.active p {
  color: #2ba849;
}

.card-inner {
  overflow: hidden;
  position: relative;
}
.card-inner .card-strip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.assurity {
  color: #000;
  max-width: 776px;
  margin: 0 auto;
}
.assurity .heading-block {
  text-align: center;
  margin-bottom: 25px;
}
.assurity .heading-block h3 {
  font-size: 24px;
  color: #000;
}
.assurity .heading-block h4 {
  font-size: 16px;
}
.assurity .white-box {
  border: 1px solid #333;
}

.pre-questions-container .parent_question_head ul {
  list-style: circle;
  list-style-type: circle;
}
.pre-questions-container .parent_question_head ul li {
  margin: 8px 0;
  font-size: 14px;
}

.menu-list {
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  font-size: 90%;
  overflow: auto;
  max-height: 300px;
}
.menu-list div {
  margin-bottom: 6px;
}
.menu-list div:hover {
  font-weight: 600;
  color: #000;
  cursor: pointer;
}

.StripeElement {
  height: 50px;
  padding: 10px 12px;
  width: 550px;
  color: #32325d;
  background-color: white;
  border: 1px solid #dbe2de;
  border-radius: 4px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-images {
  align-items: center;
  display: flex;
  height: 100%;
}
.card-images img {
  width: 150px;
}

.progress {
  display: block;
  width: 100%;
  height: 12px;
  position: relative;
  z-index: 5;
}

.progress[value] {
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

.progress[value]::-ms-fill {
  background-color: #0074d9;
  border: 0;
}

.progress[value]::-moz-progress-bar {
  background-color: #0074d9;
  margin-right: 8px;
}

.progress[value]::-webkit-progress-inner-element {
  background-color: #eee;
}

.progress[value]::-webkit-progress-value {
  background-color: #0074d9;
}

.progress[value]::-webkit-progress-bar {
  background-color: #eee;
}

.progress-circle {
  width: 24px;
  height: 24px;
  position: absolute;
  right: -21px;
  top: -5px;
  z-index: 999;
  border-radius: 50%;
}

.progress-circle:before {
  content: "";
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  display: block;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.progress-group {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 999;
  background: #fff;
  padding: 20px 15px;
}

@media (max-width: 991px) {
  .progress-group {
    margin-left: -18px;
    margin-right: -18px;
    flex-basis: 100%;
    padding: 18px;
  }
}
@media (max-width: 768px) {
  .progress-group {
    padding: 18px 18px 0;
    margin-bottom: 12px;
  }
}
.progress-group .title {
  margin-bottom: 18px;
}

.progress-group .wrapper {
  background: white;
  border: 1px solid #eee;
  border-radius: 12px;
  display: flex;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.3));
  padding-right: 20px;
}

.progress-group .step {
  width: 20%;
  position: relative;
}

.progress-group .step:after {
  content: "";
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: -27px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  opacity: 0;
}

.progress-group .step:first-of-type .progress[value]::-moz-progress-bar {
  border-radius: 5px 0 0 5px;
}

.progress-group .step:first-of-type .progress[value]::-webkit-progress-value {
  border-radius: 5px 0 0 5px;
}

.progress-group .step:not(:first-of-type) .progress[value]::-moz-progress-bar {
  border-radius: 0;
}

.progress-group .step:not(:first-of-type) .progress[value]::-webkit-progress-value {
  border-radius: 0;
}

.progress-group .step .progress[value] + .progress-circle {
  background: #eee;
}

.progress-group .step.step .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step01 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step01 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step01 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step02 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step02 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step02 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step03 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step03 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step03 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step04 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step04 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step04 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-group .step.step05 .progress[value]::-moz-progress-bar {
  background-color: green;
}

.progress-group .step.step05 .progress[value]::-webkit-progress-value {
  background-color: green;
}

.progress-group .step.step05 .progress[value="100"] + .progress-circle {
  background-color: green;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
}

.progress-labels .label {
  text-align: center;
  text-transform: uppercase;
  margin: 12px 0;
  width: 20%;
  font-size: 11px;
  padding-right: 24px;
  font-weight: 600;
  opacity: 0.7;
}

.page-title {
  letter-spacing: -0.05rem;
}

.substeps {
  position: absolute;
  top: -23px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.substeps span {
  /* width: calc(100% / 9 - (24px / 9)); */
  width: 11.1111111111%;
  display: inline-block;
  text-align: right;
  font-size: 10px;
}

.content h2.header {
  font-size: 6vw;
  line-height: 1em;
  position: relative;
}

.content h4 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;
}

.content p {
  font-size: 1.2em;
  color: #0d0d0d;
}

.content .btns {
  margin: 25px 0;
  display: inline-flex;
}

.content .btns button {
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.content .btns button:hover {
  background: var(--color-primary);
  color: #fff;
}

.illustrationModel .modal-dialog .modal-body .react-pdf__Document .react-pdf__Page__canvas {
  margin: auto;
}

.text-field-success {
  cursor: pointer;
  position: absolute;
  margin-top: 12px;
  margin-left: 92%;
}

.carrier-desc-check {
  display: flex;
  align-items: baseline;
}
.carrier-desc-check span {
  border: 2px solid #2ba84a;
  font-size: 14px;
  border-radius: 50%;
  color: #2ba84a;
  margin-right: 10px;
}

.error-page {
  margin-top: 75px;
  top: 15%;
  left: 15%;
  right: 15%;
  bottom: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-page .content {
  max-width: 680px;
  text-align: center;
}

.fourzerofourimg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  z-index: -1;
}

.rsd__select-container-day label, .rsd__select-container-year label {
  margin-left: 10px;
}

.single-page-box.birthday .rsd__select-container-day label, .single-page-box.birthday .rsd__select-container-year label {
  margin-left: 10px;
}
