div {
	&.react-datepicker {
		font-family: $font-global;
		border: none;
        color: $color-text-default;
        box-shadow: 0 8px 24px 0 rgba(131, 156, 159, 0.16);
		.react-datepicker__day {
			padding-top: 1px;
			font-weight: $font-regular;
		}
		.react-datepicker__day--keyboard-selected {
			background-color: $color-primary;
			opacity: 1 !important;
	    color: $color-white !important;
		}
		.react-datepicker__header {
			border-bottom: none;
			background-color: $color-primary;
		}
		.react-datepicker__current-month {
			color: $color-white;
			font-weight: $font-regular;
		}
		.react-datepicker-time__header {
			color: $color-white;
			font-weight: $font-regular;
		}
		.react-datepicker-popper[data-placement^="bottom"] {
			.react-datepicker__triangle {
				border-bottom-color: $color-primary;
			}
		}
		.react-datepicker__day-name {
			color: $color-white;
		}
		.react-datepicker__day--disabled {
			opacity: 0.4;
			color: $color-text-default;
		}
		.react-datepicker__day--selected {
			color: $color-white;
		}
		button.react-datepicker__navigation--previous {
			border-right-color: $color-white;
		}
		button.react-datepicker__navigation--next {
			border-left-color: $color-white;
		}
		.react-datepicker__day--selected {
			background: $color-primary;
			font-weight: normal;
			&:hover {
				background: $color-primary;
			}
		}
		.react-datepicker__day--in-selecting-range {
			background: $color-primary;
			font-weight: normal;
			&:hover {
				background: $color-primary;
			}
		}
		.react-datepicker__day--in-range {
			background: $color-primary;
			font-weight: normal;
			&:hover {
				background: $color-primary;
			}
		}
		.react-datepicker__month-read-view, .react-datepicker__year-read-view {
			color: $color-white;
			font-size: 13px;
		}
		.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
			border-top-color: $color-white !important;
			border-width: 0.30rem;	
		}
		.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
			background-color: $color-white;
			border-color: $border-color;
			max-height: 250px;
    		overflow-x: auto;
		}
		.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
			color: $color-text-default;
			&:hover {
				background: $color-primary;
				color: $color-white;
			}
		}
	}
	select {
		&.react-datepicker__month-select , &.react-datepicker__year-select {
			border: solid 1px $bg-white;
			background: transparent;
			color: $bg-white;
			margin: 5px 0 3px 0;
			option {
				color: $color-title;
			}
		}
	}
}
div {
	&.react-datepicker-popper[data-placement^="bottom"] {
		.react-datepicker__triangle {
            background: transparent !important;
            border: transparent !important;
            &::before {
                border-bottom-color: $color-primary;
                top: 0;
            }
		}
    }
    &.react-datepicker-popper[data-placement^="top"] {
        .react-datepicker__triangle {   
            border-top-color: $color-white !important;         
            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                bottom: -7px;
                right: 0;
                border: 7px solid $color-secondary;
                border-color: transparent transparent #ffffff #ffffff;
                transform-origin: 0 0;
                transform: rotate(-45deg);
            }
        }        
	}
	&.react-datepicker-popper {
		z-index: 2;
	}
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
    background: rgba(#839C9F, 0.15);    
}

/* ====== Begin: Custom DatePicker ========== */
.custom-datepicker {
	position: relative;
	.react-datepicker-wrapper {
	  width: 100%;
	  position: relative;
	  &::before {
		content: " ";
		position: absolute;
		width: 18px;
		height: 20px;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		background: url("../../../assets/images/icon/calendar-light.svg") transparent;
		cursor: pointer;
		// z-index: 1;
	  }
	  input {
		width: 100%;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		color: $color-text-default;
		line-height: 1.429rem;
		border: 1px solid $border-disabled;
		font-size: 1rem;
		padding: 10px 50px 10px 18px;
		height: 48px;
		background-color: transparent;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		&:focus,
		&.focus {
		  border-color: $color-primary;
		}
		@include sm {
		  height: 40px;
		}
	  }
	}
	.datepicker-label {
	  font-size: 12px;
	  line-height: 14px;
	  position: absolute;
	  left: 16px;
	  top: -7px;
	  background: $bg-white;
	  transition: 0.2s ease all;
	  -moz-transition: 0.2s ease all;
	  -webkit-transition: 0.2s ease all;
	  margin: 0;
	  padding: 0 3px;
	}
	.react-datepicker__day--selected,
	.react-datepicker__day--keyboard-selected {
	  background: $color-primary;
	}
	.react-datepicker__header {
	  background: $color-light;
	}
	.react-datepicker__navigation--previous {
	  border-right-color: $color-text-default;
	}
	.react-datepicker__navigation--next {
	  border-left-color: $color-text-default;
	}
	.react-datepicker-popper[data-placement^="bottom"] {
	  .react-datepicker__triangle {
		border-bottom-color: $color-light;
	  }
	}
	.react-datepicker-popper[data-placement^="top"] {
	  .react-datepicker__triangle {
		border-top-color: $color-light;
	  }
	}
  }
  /* ====== End: Custom DatePicker ========== */