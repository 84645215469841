/*Large devices (desktops, grater than 1200px)*/
@mixin Desktop{
  @media only screen and (min-width: 1440px) {
    @content;
  }
}
@mixin xlUptoDesktopSmall{
  @media only screen and (min-width: 1200px) and (max-width: 1365.98px){
    @content;
  }
}
@mixin xlUptoDesktopLarge{
  @media only screen and (min-width: 1200px) and (max-width: 1400px){
    @content;
  }
}
@mixin xlUp {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}
/*Large devices (desktops, less than 1200px)*/
@mixin lg {
  @media only screen and (max-width: 1199.98px) {
    @content;
  }
}
@mixin mdtolg{
  @media only screen and (max-width: 1199.98px) and (min-width: 992px){
    @content;
  }
}
@mixin lgtomd{
  @media only screen and (max-width: 991.98px) and (min-width: 768px){
    @content;
  }
}
/*tablets,desktops, grater than 992px*/
@mixin mdUp{
  @media only screen and (min-width:992px) {
    @content;
  }
}
/* Medium devices (tablets, less than 992px) */
@mixin md{
  @media only screen and (max-width:991.98px) {
    @content;
  }
}
/*tablets,desktops, grater than 768px*/
@mixin smUp {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
/* Small devices (landscape phones, less than 768px)*/
 @mixin sm {
  @media only screen and (max-width: 767.98px) {
    @content;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@mixin xs {
  @media only screen and (max-width: 575.98px) {
    @content;
  }
}

/* Extra small devices (portrait phones, less than 413px) */
@mixin xxs {
  @media only screen and (max-width: 413.98px) {
    @content;
  }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin text($font-size,$font-weight,$font-color,$font-lineheight) {
  font-size: $font-size;
  font-weight: $font-weight;
  color: $font-color;
  line-height: $font-lineheight;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin x-linear-gradient($direction, $fallback, $from, $to) {
  $directions: helper-gradient-angle($direction);
  // Provide a fallback-color
  background-color: $fallback;
  // Cross-browser linear-gradients
  background-image: -webkit-gradient(linear,$directions, from($from), to($to)); // Android 2.1-3.0
  background-image: -webkit-linear-gradient($directions, $from, $to);
  background-image:linear-gradient($direction, $from, $to);
}
@mixin border-radius($border-radius) {
  border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
}
// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size-base, $line-height-base, $box-shadow) {
  font-size: $font-size-base;
  line-height:$line-height-base;
  box-shadow: $box-shadow;
}
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
@mixin box-shadow($top:0, $left:0, $blur:15px, $size:1px, $color:rgba(69, 65, 78, 0.2), $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $size $color;
        -moz-box-shadow:inset $top $left $blur $size $color;
        box-shadow:inset $top $left $blur $size $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $size $color;
        -moz-box-shadow: $top $left $blur $size $color;
        box-shadow: $top $left $blur $size $color;
    }
}