/*Fonts*/
// @function color($color-name) {
//   @return var(--color-#{$color-name});
// }

// body {
//   background: color(primary);
// }
@import "./css-variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Langar:wght@400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nerko+One:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ranchers:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
$font-global: var(--font-var), sans-serif;
$font-size-base: 16px;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-black: 900;
$line-height-base: 1.629rem;

/*Color*/

$bg-body: var(--color-background);
$bg-white: var(--color-white);
$color-white: var(--color-white);
$color-black: #000;
$color-text-default: #091f1f;
$color-primary: var(--color-primary);
$color-header-new: var(--color-headerapply);
$color-primary-default: #2ba84a;
$color-body: #839c9f;
$color-text-title: var(--color-txt-df);
$color-secondary: #cdd7d9;
$color-danger: #dc3545;
$color-info: #1aade2;
$color-warning: #f8e9db;
$color-light: #f3f6f6;
$color-hover: var(--color-over-effect);
$color-rangeslider: #a6b9bd;
$info-svg-hover: #688387;
$color-sucess-light: var(--color-success-lgt);
$color-sucess-lighter: #ebf5ee;
$color-title: #1b3030;
$testmonial-star: #ffbc23;
$fb-bg: #1877f2;
$messenger-bg: #0085ff;
$twitter-bg: #55acee;
$whatsapp-bg: #25d366;

/*Form*/
$input-color-placeholder: #72777f;
$input-padding-y: 0.625rem;
$input-padding-x: 0.9rem;

/*Border color Style*/
$border-color: #cdd7d9;
$border-radius: 30px;
$border-disabled: #dbe2de;
$border-light: rgba(205, 215, 217, 0.24);

/*Button color Style*/
$padding-y: 0.75rem;
$padding-x: 7.14rem;
$box-shadow: none;
$link-color: #4293b2;

/* Box-shadow */
$box-shadow-light: 0 2px 4px 0 rgba(131, 156, 159, 0.08);
$box-shadow-global: 4px 8px 24px 0 rgba(131, 156, 159, 0.16);


$color-light-blue: #0b94bf;
$light-black-bg: #333333;
$color-dark-gray:#797979;